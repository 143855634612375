import PropTypes from 'prop-types'
import React from 'react'
import { cx } from '../../utils/cx'
import styles from './PageContents.module.scss'

export const PageContents = ({ className, children }) => (
  <div date-testid="page-contents" className={cx('page-contents', styles.contents, className)}>
    {children}
  </div>
)

PageContents.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string
}

PageContents.defaultProps = {
  children: undefined,
  className: undefined
}

export default PageContents
