import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AngleDownLight, AngleUpLight, CheckCircleIcon, CircleIcon } from 'components/Graphic'
import { Row } from 'react-simple-flex-grid'
import { Task } from './Task'
import styles from './Checklist.module.scss'
import { cx } from '../../utils/cx'
import { ExpandTransition } from '../common/ExpandTransition'

export const ChecklistTask = ({
  title,
  completed,
  tasks,
  checklistTasks,
  simpleSaleFeedId,
  agentFeedId
}) => {
  const [showInfo, setShowInfo] = useState(false)
  const toggleTaskInfo = () => setShowInfo(!showInfo)

  function renderCheckCircle(isComplete) {
    return isComplete || tasks.length === 0 ? (
      <CheckCircleIcon className={styles.circleIcons} />
    ) : (
      <CircleIcon className={cx(styles.circleIcons, styles.disabled)} />
    )
  }

  function renderChevron(tasksArr) {
    if (tasksArr.length === 0) return null

    return showInfo ? (
      <AngleUpLight className={styles.angle} />
    ) : (
      <AngleDownLight className={styles.angle} />
    )
  }

  return (
    <div className={styles.checklistContent}>
      <Row className={styles.row} onClick={toggleTaskInfo}>
        {renderCheckCircle(completed)}
        {renderChevron(tasks)}
        <p className={styles.itemTitle}>{title}</p>
      </Row>
      <Row>
        <ExpandTransition expand={showInfo} unmountOnExit>
          <div>
            {checklistTasks.map(checklistTask => (
              <Task
                key={checklistTask.cta_text}
                task={checklistTask.task}
                checklistTask={checklistTask}
                simpleSaleFeedId={simpleSaleFeedId}
                agentFeedId={agentFeedId}
              />
            ))}
          </div>
        </ExpandTransition>
      </Row>
    </div>
  )
}

export default ChecklistTask

ChecklistTask.propTypes = {
  title: PropTypes.string.isRequired,
  completed: PropTypes.bool,
  tasks: PropTypes.arrayOf(PropTypes.object),
  checklistTasks: PropTypes.arrayOf(PropTypes.object),
  agentFeedId: PropTypes.string,
  simpleSaleFeedId: PropTypes.string
}

ChecklistTask.defaultProps = {
  completed: false,
  tasks: [],
  checklistTasks: [],
  agentFeedId: '',
  simpleSaleFeedId: ''
}
