import React from 'react'
import PropTypes from 'prop-types'
import { cx } from '../../utils/cx'
import styles from './Dropdown.module.scss'

export const Dropdown = ({ onChangeCb, optionValues, selectedValue, disable }) => {
  const handleChange = e => onChangeCb && onChangeCb(e)

  return (
    <div className={cx(styles.dropdownContainer, disable ? styles.disabled : '')}>
      <select onChange={handleChange} defaultValue={selectedValue} disabled={disable}>
        <option key="none" value="">
          Select a time
        </option>
        {optionValues.map(opt => (
          <option key={opt.value} value={opt.value}>
            {opt.option}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Dropdown

Dropdown.propTypes = {
  onChangeCb: PropTypes.func,
  selectedValue: PropTypes.string,
  disable: PropTypes.bool,
  optionValues: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, option: PropTypes.string })
  ).isRequired
}

Dropdown.defaultProps = {
  onChangeCb: null,
  selectedValue: '',
  disable: false
}
