import React from 'react'
import PropTypes from 'prop-types'
import { FeedItem } from 'components/FeedItem'
import { Loading } from 'components/Graphic'
import styles from './FeedItem.module.scss'

export const LoadingFeedItem = ({ occurredAt, id }) => (
  <FeedItem
    classname={styles.loadingFeedItem}
    showTime={false}
    occurredAt={occurredAt}
    isLoadingFeedItem
    id={id}
  >
    <Loading wrapperClass={styles.loadingAnimation} />
  </FeedItem>
)

LoadingFeedItem.propTypes = {
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
}
