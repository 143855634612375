import { buildJsonApiParams } from '../utils/apiPathBuilder/apiPathBuilder'

const paramSchemas = {
  feedItems: {
    include: ['user'],
    fields: [{ resource: 'user', values: ['id', 'first_name'] }]
  },

  inboxMessages: {
    include: [
      'message_participants',
      'message_participants.user',
      'message_classification',
      'parent_message'
    ],
    fields: [
      {
        resource: 'message',
        values: [
          'id',
          'is_read',
          'content',
          'created_at',
          'message_participants',
          'message_classification',
          'parent_message'
        ]
      },
      { resource: 'message_participant', values: ['is_sender', 'photo_url', 'slug', 'user'] },
      { resource: 'message_participants.user', values: ['id', 'first_name', 'last_name'] },
      { resource: 'message_classification', values: ['name'] },
      { resource: 'parent_message', values: ['id'] }
    ],
    filter: [{ resource: 'user_id', values: [''] }]
  },

  homelightMessage: {
    fields: [{ resource: 'message', values: ['id'] }],
    sort: 'created_at',
    limit: '1',
    filter: [{ resource: 'user_id', values: [''] }]
  },

  checklist: {
    include: ['tasks', 'checklist_tasks'],
    fields: [
      {
        resource: 'checklist',
        values: ['id', 'title', 'note', 'percent-complete', 'tasks', 'checklist_tasks']
      },
      { resource: 'task', values: ['title', 'checklist_task'] },
      { resource: 'checklist_task', values: ['cta_text', 'task', 'checklist'] }
    ]
  },

  team: {
    fields: [{ resource: 'user_feed_item', values: ['id', 'item'] }]
  },

  simpleSale: {
    fields: [
      {
        resource: 'sales_math_data',
        values: [
          'id',
          'address',
          'estimated_home_value',
          'investor',
          'use_opendoor',
          'opendoor_high_estimate',
          'opendoor_low_estimate'
        ]
      }
    ]
  }
}

export const API_ENDPOINTS = {
  feedItems: `/api/user-feed-service/feed${buildJsonApiParams(paramSchemas.feedItems)}`,
  inboxMessages: `/api/messaging-service/messages/inbox-messages${buildJsonApiParams(
    paramSchemas.inboxMessages
  )}`,
  homelightMessage: `/api/messaging-service/messages/inbox-messages${buildJsonApiParams(
    paramSchemas.homelightMessage
  )}`,
  checklist: `/api/task-service/checklists${buildJsonApiParams(paramSchemas.checklist)}`,
  simpleSale: `/api/lead-data-service/simple-sale/user-results${buildJsonApiParams(
    paramSchemas.simpleSale
  )}`,
  markItemSeen: '/api/user-feed-service/feed/mark-displayed-at',
  checkAgentClientConversationStatus: '/api/messaging-service/client/check-conversation-history',
  introAgent: '/intro-agent-from-message',
  getCalendlySalesEvent: '/api/user-feed-service/feed/get-calendly-sales-event',
  createCCTradeInLead: '/api/lead-data-service/client-dashboard/leads/create-duplicate-lead',
  newMessage: '/api/messaging-service/client/new-message',
  updateMessageRead: '/api/messaging-service/messages/update-delivery-by-parent',
  findOrInitializeExperiment: '/api/ab-test-service/find-or-initialize',
  recordUserEvent: '/api/events-service/user-events/record-user-event',
  queueLead: `/api/dialer-service/dialer/queue-lead`
}
