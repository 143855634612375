import PropTypes from 'prop-types'
import React from 'react'
import { cx } from '../../utils/cx'
import { PageTitle } from '../PageTitle'
import styles from './PageHeader.module.scss'

export const PageHeader = ({ className, title, subTitle, children }) => {
  if (title || children) {
    const titleTag = title ? <PageTitle>{title}</PageTitle> : null
    const subTitleTag = subTitle ? <div className={styles.subTitle}>{subTitle}</div> : null
    return (
      <div className={cx(styles.header, className)}>
        {titleTag}
        {subTitleTag}
        {children}
      </div>
    )
  }
  return null
}

PageHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  className: PropTypes.string
}

PageHeader.defaultProps = {
  children: undefined,
  title: undefined,
  subTitle: undefined,
  className: undefined
}

export default PageHeader
