import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import moment from 'moment-timezone'
import { clientCommunicationAction, updateUserInputItem } from '../../utils/feedItemClient'
import { openTimeslotsToday } from '../../utils/openTimeslotsToday'
import Dropdown from '../Form/Dropdown'

export const ScheduleCallSelectTime = ({
  feedItemId,
  refreshData,
  selectedAnswer,
  previousAnswer
}) => {
  const { leadSecureToken } = React.useContext(LeadContext)
  const [valueSelected, setValueSelected] = useState(selectedAnswer)
  const handleSelect = e => {
    const selectedTimeValue = e.target.value
    setValueSelected(selectedTimeValue)
    updateUserInputItem(selectedTimeValue, feedItemId)
      .then(refreshData)
      .then(clientCommunicationAction('schedule_call_select_time', leadSecureToken))
  }

  const startingDay = () => {
    const today = moment()
    switch (previousAnswer) {
      case 'second_day_selected':
        return today.startOf('day').add('1', 'day')
      case 'third_day_selected':
        return today.startOf('day').add('2', 'days')
      default:
        return today
    }
  }

  return (
    <div>
      <div style={{ marginBottom: '1rem' }}>Sounds good! What time should we contact you?</div>
      <Dropdown
        disable={!!valueSelected}
        selectedValue={valueSelected}
        onChangeCb={handleSelect}
        optionValues={openTimeslotsToday(startingDay())}
      />
    </div>
  )
}

export default ScheduleCallSelectTime

ScheduleCallSelectTime.propTypes = {
  feedItemId: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
  selectedAnswer: PropTypes.string,
  previousAnswer: PropTypes.string
}

ScheduleCallSelectTime.defaultProps = {
  selectedAnswer: '',
  previousAnswer: ''
}
