import { Factory } from 'miragejs'
import faker from 'faker'

const randomRange = () => Number((Math.random() * 0.3 - 0.15).toFixed(2))

const transactionFactory = () => ({
  transaction_info_hidden: false,
  transaction_price_hidden: false,
  address() {
    return `${faker.address.streetAddress()}, ${faker.address.city()}, ${faker.address.stateAbbr()}`
  },
  city() {
    return faker.address.city()
  },
  state() {
    return faker.address.stateAbbr()
  },
  propertyType() {
    return 'Single Family Home'
  },
  propertyDetails() {
    return `${faker.datatype.number({ min: 1, max: 6 })} bed / ${faker.datatype.number({
      min: 1,
      max: 6
    })} bath`
  },
  represented() {
    return 'Seller'
  },
  price() {
    return `$${faker.datatype.number({ min: 60000, max: 2000000 }).toLocaleString()}`
  },
  date() {
    return faker.date.past()
  },
  formatted_date() {
    return '2020-01-14'
  },
  lng() {
    return window.TEST_LEAD_LOCATION.longitude + randomRange()
  },
  lat() {
    return window.TEST_LEAD_LOCATION.latitude + randomRange()
  },
  year() {
    return 2019
  }
})

export default Factory.extend(transactionFactory())

export { transactionFactory }
