import React from 'react'
import { Graphic } from 'components/Graphic'
import PropTypes from 'prop-types'
import styles from '../AgentCard/AgentCard.module.scss'

export const MoveSafeCertifiedBadge = ({
  children,
  eliteStatus,
  iconStyle,
  descriptionStyle,
  factStyle
}) => (
  <div className={styles.awardBadgeSection}>
    <div className={factStyle}>
      <div className={iconStyle}>
        {eliteStatus ? <Graphic.MoveSafeElite /> : <Graphic.MoveSafe />}
      </div>
    </div>
    <div className={descriptionStyle}>
      Move Safe Certified
      <span className={styles.tooltipWrapper}>{children}</span>
    </div>
  </div>
)

MoveSafeCertifiedBadge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  eliteStatus: PropTypes.bool.isRequired,
  iconStyle: PropTypes.string,
  descriptionStyle: PropTypes.string,
  factStyle: PropTypes.string
}

MoveSafeCertifiedBadge.defaultProps = {
  iconStyle: styles.moveSafe,
  descriptionStyle: styles.description,
  factStyle: styles.fact
}
