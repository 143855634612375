function orderByMostRecent(a, b) {
  const feedItemA = a.created_at
  const feedItemB = b.created_at

  let comparison = 0
  if (feedItemA < feedItemB) {
    comparison = 1
  } else if (feedItemA > feedItemB) {
    comparison = -1
  }
  return comparison
}

/**
 * Unhide items where item_type is agent_recommendation OR agent_recommendation_feedback
 */
export const optimisticallyUnhideAgentRecommendations = (feed, updateFeed) => {
  const feedCopy = JSON.parse(JSON.stringify(feed.data))
  const sortedFeed = feedCopy.sort(orderByMostRecent)

  // eslint-disable-next-line no-restricted-syntax
  for (const feedItem of sortedFeed) {
    if (feedItem.item_type === 'agent_recommendation') {
      const matcher = item => item.id === feedItem.id
      const index = feed.data.findIndex(matcher)
      // eslint-disable-next-line no-param-reassign
      feed.data[index].hidden_at = null
      break
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const feedItem of sortedFeed) {
    if (feedItem.item_type === 'agent_recommendation_feedback') {
      const matcher = item => item.id === feedItem.id
      const index = feed.data.findIndex(matcher)
      // eslint-disable-next-line no-param-reassign
      feed.data[index].hidden_at = null
      break
    }
  }

  // refresh the feed realtime with our updated unhidden items
  updateFeed(feed)
}

/**
 * Unhide items where item_type is generic_message and item.message_type = 'thank_you_response'
 */
export const optimisticallyUnhideSimpleHomeValue = (feed, updateFeed) => {
  const feedCopy = JSON.parse(JSON.stringify(feed.data))
  const sortedFeed = feedCopy.sort(orderByMostRecent)
  // eslint-disable-next-line no-restricted-syntax
  for (const feedItem of sortedFeed) {
    if (
      feedItem.item_type === 'generic_message' &&
      feedItem.item.message_type === 'thank_you_response'
    ) {
      const matcher = item => item.id === feedItem.id
      const index = feed.data.findIndex(matcher)
      // eslint-disable-next-line no-param-reassign
      feed.data[index].hidden_at = null
      break
    }
  }

  // refresh the feed realtime with our updated unhidden items
  updateFeed(feed)
}

/**
 * Find feed items where item_type = 'multiple_choice' and feedItem.item.prompt_type = soft_agent_intro_option
 * and optimistically unhide them
 */
export const optimisticallyUnhideSoftAgentIntroOption = (feed, updateFeed) => {
  const feedCopy = JSON.parse(JSON.stringify(feed.data))
  const sortedFeed = feedCopy.sort(orderByMostRecent)
  // eslint-disable-next-line no-restricted-syntax
  for (const feedItem of sortedFeed) {
    if (
      feedItem.item_type === 'multiple_choice' &&
      feedItem.item.prompt_type === 'soft_agent_intro_option'
    ) {
      const matcher = item => item.id === feedItem.id
      const index = feed.data.findIndex(matcher)
      // eslint-disable-next-line no-param-reassign
      feed.data[index].hidden_at = null
      break
    }
  }

  // refresh the feed realtime with our updated unhidden items
  updateFeed(feed)
}

/**
 * Find feed items where item_type = investor_intro and optimistically unhide them
 */
export const optimisticallyUnhideInvestorIntroOption = (feed, updateFeed) => {
  const feedCopy = JSON.parse(JSON.stringify(feed.data))
  const sortedFeed = feedCopy.sort(orderByMostRecent)
  // eslint-disable-next-line no-restricted-syntax
  for (const feedItem of sortedFeed) {
    if (feedItem.item_type === 'investor_intro') {
      const matcher = item => item.id === feedItem.id
      const index = feed.data.findIndex(matcher)
      // eslint-disable-next-line no-param-reassign
      feed.data[index].hidden_at = null
      break
    }
  }

  // refresh the feed realtime with our updated unhidden items
  updateFeed(feed)
}
