import React from 'react'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import { Row } from 'react-simple-flex-grid'
import { Link } from 'react-router-dom'
import { signOutAndRedirect } from '../../utils/userAuth'
import { cx } from '../../utils/cx'
import { Button } from '../Button'
import styles from './AccountTab.module.scss'
import { trackAccountEvent } from '../../utils/tracking'

export function AccountDropDownMenu({ active, toggleActive }) {
  const onSignOutClicked = () => {
    trackAccountEvent('Tap', 'log_out')
    signOutAndRedirect()
  }
  const { leadType } = React.useContext(LeadContext)
  return (
    <div
      className={
        active
          ? cx(styles.accountDropDownMenuContainer, styles.menuActive)
          : styles.accountDropDownMenuContainer
      }
    >
      <Row align="middle" justify="start">
        <a
          href="https://www.homelight.com/homes"
          className={styles.option}
          target="_blank"
          rel="noopener noreferrer"
        >
          Home Search
        </a>
        <a
          href="https://www.homelight.com/simple"
          className={styles.option}
          target="_blank"
          rel="noopener noreferrer"
        >
          Simple Sale
        </a>
        <a
          href="https://www.homelight.com/trade-in"
          className={styles.option}
          target="_blank"
          rel="noopener noreferrer"
        >
          Buy Before You Sell
        </a>
        {leadType === 'buyer' ? (
          <a
            href="https://www.homelight.com/blog/buy"
            className={styles.option}
            target="_blank"
            rel="noopener noreferrer"
          >
            Buyer Resource Center
          </a>
        ) : (
          <a
            href="https://www.homelight.com/blog"
            className={styles.option}
            target="_blank"
            rel="noopener noreferrer"
          >
            Seller Resource Center
          </a>
        )}
        <Link to="/feedback" className={styles.option} onClick={toggleActive}>
          Give us feedback
        </Link>
        <a
          href="https://www.homelight.com/privacy-policy"
          className={styles.option}
          target="_blank"
          rel="noopener noreferrer"
        >
          Legal & Privacy
        </a>
        <Button as="link" className={styles.signOut} onClick={onSignOutClicked}>
          Sign out
        </Button>
      </Row>
    </div>
  )
}

export default AccountDropDownMenu

AccountDropDownMenu.propTypes = {
  active: PropTypes.bool.isRequired,
  toggleActive: PropTypes.func.isRequired
}
