/* istanbul ignore file */
import React from 'react'
import { Row, Col } from 'react-simple-flex-grid'
import { usePageResize } from 'components/PageResize'
import * as turbografix from 'components/Graphic/Graphic'
import { isDesktop, isSmallerViewport } from '../../utils/breakpoint'
import Icon from './Icon'
import './icon-gallery-styles.scss'

const allGraphics = Object.keys(turbografix)
  .filter(g => typeof turbografix[g] === 'function')
  .sort()
const allIcons = Object.keys(Icon).sort()

export const IconGallery = () => {
  const { width } = usePageResize()
  let columnSize = 3
  if (isDesktop(width)) {
    columnSize = 2
  } else if (isSmallerViewport(width)) {
    columnSize = 4
  }

  const renderDirectory = () => (
    <div className="directory">
      <ul className="list graphic-list">
        <h4>Graphic.js</h4>
        {allGraphics.map((graphic, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`graphic-${i}`}>
            <a href={`#graphic-${graphic}`}>{graphic}</a>
          </li>
        ))}
      </ul>
      <ul className="list icon-list">
        <h4>Icon.js</h4>
        {allIcons.map((icon, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`icon-${i}`}>
            <a href={`#icon-${icon}`}>{icon}</a>
          </li>
        ))}
      </ul>
    </div>
  )

  const renderGraphics = () =>
    allGraphics.map(graf => {
      const TheGraphic = turbografix[graf]
      const key = `graphic-${graf}`
      return (
        <Col key={key} id={key} className="icon-box" span={columnSize}>
          <div className="wrapper">
            <TheGraphic className="" />
            <div className="icon-name">{graf}</div>
          </div>
        </Col>
      )
    })

  const renderIcons = () =>
    allIcons.map(icon => {
      const TheIcon = Icon[icon]
      const key = `icon-${icon}`
      return (
        <Col key={key} id={key} className="icon-box" span={columnSize}>
          <div className="wrapper">
            <TheIcon className="" />
            <div className="icon-name">{icon}</div>
          </div>
        </Col>
      )
    })

  return (
    <div className="icon-gallery">
      <h2>Dashboard Icons</h2>
      <h3>Directory</h3>
      {renderDirectory()}
      <h3>Graphic.js</h3>
      <Row>{renderGraphics()}</Row>
      <h3>Icon.js</h3>
      <Row>{renderIcons()}</Row>
    </div>
  )
}
