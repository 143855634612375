import React, { useContext, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Graphic } from 'components/Graphic'
import { AgentFaceInCircle } from 'components/AgentFaceInCircle'
import { EliteAgentLogo } from 'components/AgentCard'
import { PageResizeContext } from 'components/PageResize'
import axios from 'axios'
import { VIMEO_OEMBED_URL } from 'constants/constants'
import styles from './AgentCard.module.scss'
import { AgentVideo } from '../AgentVideo/AgentVideo'
import { isLargerViewport } from '../../utils/breakpoint'

export const AgentCardHeader = ({ agentMatch, showArrow, eliteLogoStyle }) => {
  const [agentVideo, setAgentVideo] = useState(false)
  const { width } = useContext(PageResizeContext)
  const desktopLayout = isLargerViewport(width)
  const [videoHeight, setVideoHeight] = useState(null)
  const agentCardElementDesktop = useRef(null)
  const agentNames = agentMatch.agent_name.split(' ')
  const agentSlug = agentMatch.slug
  const agentDisplayName = `${agentNames[0]} ${agentNames[1][0]}.`
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Maps to this example: https://vimeo.com/homelight/agentSlug
        const videoUrl = `${process.env.REACT_APP_AGENT_INTRO_VIDEOS_CHANNEL}${agentSlug}`
        const result = await axios.get(`${VIMEO_OEMBED_URL}${videoUrl}`)
        setAgentVideo(result.status === 200)
      } catch (error) {
        setAgentVideo(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {agentMatch.elite_status && (
        <>
          {desktopLayout && !agentVideo && (
            <div className={eliteLogoStyle}>
              <EliteAgentLogo />
            </div>
          )}
          <div className={styles.eliteProfileHeader}>
            {agentVideo ? (
              <div className={styles.eliteAgentVideo}>
                {desktopLayout && (
                  <div ref={agentCardElementDesktop}>
                    <div style={{ height: `${videoHeight}` }}>
                      <AgentVideo
                        data-testid="agent-video-intro-desktop"
                        setVideoHeight={setVideoHeight}
                        agentSlug={agentMatch.slug}
                        parentElement={agentCardElementDesktop}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              desktopLayout && (
                <div className={styles.agentProfilePic}>
                  <AgentFaceInCircle photoUrl={agentMatch.picture} name={agentDisplayName} />
                </div>
              )
            )}
            <div className={styles.eliteAgentHeader}>
              {(!desktopLayout || agentVideo) && (
                <div className={eliteLogoStyle}>
                  <EliteAgentLogo />
                </div>
              )}
              {!desktopLayout && (
                <div className={styles.eliteAgentProfilePic}>
                  <AgentFaceInCircle photoUrl={agentMatch.picture} name={agentDisplayName} />
                </div>
              )}
              {agentVideo ? (
                <div className={styles.eliteAgentInfo}>
                  <div className={styles.agentName}>{agentDisplayName}</div>
                  <div className={styles.agencyName}>{agentMatch.office_display_name}</div>
                </div>
              ) : (
                <div className={styles.noVidEliteAgentInfo}>
                  <div className={styles.agentName}>{agentDisplayName}</div>
                  <div className={styles.agencyName}>{agentMatch.office_display_name}</div>
                </div>
              )}
              {showArrow && (
                <div className={styles.moreArrow}>
                  <Graphic.ArrowRight />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {!agentMatch.elite_status && (
        <div className={styles.agentHeader}>
          <div className={styles.agentProfilePic}>
            <AgentFaceInCircle photoUrl={agentMatch.picture} name={agentDisplayName} />
          </div>
          <div className={styles.agentInfo}>
            <div className={styles.agentName}>{agentDisplayName}</div>
            <div className={styles.agencyName}>{agentMatch.office_display_name}</div>
          </div>
          {showArrow && (
            <div className={styles.moreArrow}>
              <Graphic.ArrowRight />
            </div>
          )}
        </div>
      )}
    </>
  )
}

AgentCardHeader.propTypes = {
  agentMatch: PropTypes.shape({
    agent_id: PropTypes.number.isRequired,
    agent_name: PropTypes.string.isRequired,
    office_display_name: PropTypes.string.isRequired,
    elite_status: PropTypes.bool.isRequired,
    move_safe_certified: PropTypes.bool.isRequired,
    picture: PropTypes.string.isRequired,
    slug: PropTypes.string,
    badges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    contact_capabilities: PropTypes.shape({
      can_call: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  showArrow: PropTypes.bool,
  eliteLogoStyle: PropTypes.string
}

AgentCardHeader.defaultProps = {
  showArrow: false,
  eliteLogoStyle: styles.eliteAgentLogo
}
