import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { LoadingFeedItem } from '../FeedItem'
import styles from '../FeedItem/FeedItem.module.scss'
import { DayBreakWrapper } from '../DayBreak/DayBreakWrapper'
import { FEED_ITEM_ANCHOR } from '../../utils/feedHelpers'
import { trackFeedItemEvent } from '../../utils/tracking'
import { isMobile } from '../../utils/breakpoint'
import { cx } from '../../utils/cx'
import { putRequest } from '../../utils/makeRequest'
import { API_ENDPOINTS } from '../../constants/apiEndpoints'
import { integerInRange } from '../../utils/integerInRange'
import { usePrevious } from '../../hooks/usePrevious'

export const ItemLoadingSequence = ({
  seenItems,
  refreshData,
  loadingItem,
  loadingItemIndex,
  renderByLeadType,
  midFeedLoad,
  leadType
}) => {
  const loadingItemRef = useRef(null)
  const prevSeenItemsCount = usePrevious(seenItems.length) || 0

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const extraPadding = isMobile(window.innerWidth) ? 125 : 25
    const isDocumentBottom = () =>
      document.body.getBoundingClientRect().bottom <= window.innerHeight + extraPadding
    const loadingItemInViewportBottom = () =>
      loadingItemRef &&
      loadingItemRef.current &&
      loadingItemRef.current.getBoundingClientRect().bottom <= window.innerHeight + extraPadding

    const loadNextItem = () => (midFeedLoad ? loadingItemInViewportBottom() : isDocumentBottom())

    // vary delay time between 2s and 4s to feel a little less hard coded
    const delay = integerInRange(2000, 4000)

    // When we get a new loadingItem, activate animation and interval ping to check if it's in view before deactivating
    const interval = setInterval(() => {
      if (loadingItem && loadNextItem()) {
        putRequest(API_ENDPOINTS.markItemSeen, { user_feed_item_id: loadingItem.id }).then(() => {
          refreshData()
        })
      }
    }, delay)

    return () => clearInterval(interval)
  }, [leadType, loadingItem, midFeedLoad, refreshData])

  useEffect(() => {
    const lastSeenItem = seenItems[seenItems.length - 1]
    if (seenItems.length === prevSeenItemsCount + 1 && !!lastSeenItem) {
      trackFeedItemEvent(lastSeenItem, 'View', leadType)
    }
  }, [prevSeenItemsCount, seenItems, leadType])

  const renderLoadedItem = (item, index, previousItem) => (
    <div
      key={`${item.external_id}`}
      id={`${FEED_ITEM_ANCHOR}${item.external_id}`}
      className={cx(styles.dashboardFeedContainer, item.item_type)}
    >
      <DayBreakWrapper idx={index} currentFeedItem={item} prevFeedItem={previousItem} />
      {renderByLeadType(item)}
    </div>
  )

  const renderCurrentlyLoadingItem = previousItem => (
    <div
      key={`${loadingItem.external_id}`}
      id={`${FEED_ITEM_ANCHOR}${loadingItem.external_id}`}
      className={cx(
        styles.loadingItemWrapper,
        styles.dashboardFeedContainer,
        loadingItem.item_type
      )}
    >
      <DayBreakWrapper
        idx={seenItems.length}
        currentFeedItem={loadingItem}
        prevFeedItem={previousItem}
      />
      <LoadingFeedItem occurredAt={loadingItem.occurred_at} id={loadingItem.id} />
      <div className={styles.itemHidden} ref={loadingItemRef}>
        {renderByLeadType(loadingItem)}
      </div>
    </div>
  )

  const renderItems = () => {
    const items = []
    let previousItem
    let loadingLowerIndex = false
    const totalItemCount = loadingItem ? seenItems.length + 1 : seenItems.length

    for (let i = 0; i < totalItemCount; i += 1) {
      if (i === loadingItemIndex) {
        items.push(renderCurrentlyLoadingItem(previousItem))
        loadingLowerIndex = true
        previousItem = loadingItem
      } else {
        const feedItem = loadingLowerIndex ? seenItems[i - 1] : seenItems[i]
        items.push(renderLoadedItem(feedItem, i, previousItem))
        previousItem = feedItem
      }
    }

    return items
  }

  return renderItems()
}

ItemLoadingSequence.propTypes = {
  seenItems: PropTypes.arrayOf(PropTypes.shape({})),
  refreshData: PropTypes.func.isRequired,
  loadingItem: PropTypes.shape({}),
  loadingItemIndex: PropTypes.number,
  renderByLeadType: PropTypes.func.isRequired,
  midFeedLoad: PropTypes.bool,
  leadType: PropTypes.string.isRequired
}

ItemLoadingSequence.defaultProps = {
  loadingItem: null,
  loadingItemIndex: -1,
  midFeedLoad: false,
  seenItems: []
}
