import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { initializeGTM, trackPageView } from '../utils/tracking'

initializeGTM('DashboardDataLayer')

export const PageViewTracker = ({ children }) => {
  const location = useLocation()
  const { pathname, search } = location

  useEffect(() => {
    trackPageView(pathname + search, 'PageViewDataLayer')
  }, [location, pathname, search])

  return <>{children}</>
}

PageViewTracker.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}
