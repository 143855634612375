import { RenderWelcomeItem } from './Shared/WelcomeItem'

export const RenderBadNumberFeedItem = userFeedItem => {
  switch (userFeedItem.item_type) {
    case 'welcome_item':
      return RenderWelcomeItem(userFeedItem)
    default:
      return null
  }
}
