const BREAKPOINT_XL = 1200
const BREAKPOINT_LG = 992
const BREAKPOINT_MD = 768
const BREAKPOINT_SM = 576

export const breakpoint = width => {
  if (width > BREAKPOINT_XL) {
    return 'xl'
  }
  if (width > BREAKPOINT_LG) {
    return 'lg'
  }
  if (width > BREAKPOINT_MD) {
    return 'md'
  }
  if (width >= BREAKPOINT_SM) {
    return 'sm'
  }
  return 'xs'
}

// The convenience methods below can be passed the value of window.innerWidth
// OR with the width returned from pageResize custom hook - e.g. const { width } = useContext(PageResizeContext)

// under 576px
const isMobile = width => breakpoint(width) === 'xs'

// over 1200px
const isDesktop = width => breakpoint(width) === 'xl'

// between 576px and 1200px
const isTablet = width => breakpoint(width) === 'lg' || breakpoint(width) === 'md'

// at or under 768px
const isSmallerViewport = width => ['xs', 'sm'].includes(breakpoint(width))

// over 768px
const isLargerViewport = width => ['md', 'lg', 'xl'].includes(breakpoint(width))

export { isMobile, isDesktop, isTablet, isSmallerViewport, isLargerViewport }
export default breakpoint
