import React from 'react'
import { AgentRecommendations } from '../../../FeedItem'

export const RenderAgentRecommendation = userFeedItem => {
  const {
    item,
    id,
    occurred_at: occurredAt,
    displayed_at: displayedAt,
    external_id: externalId
  } = userFeedItem

  return (
    <AgentRecommendations
      key={id}
      city={item.city}
      additionalAreas={item.additional_areas}
      agentsInAreaCount={item.agents_in_area_count}
      agentMatches={item.agent_matches}
      occurredAt={occurredAt}
      id={id}
      externalId={externalId}
      displayedAt={displayedAt}
    />
  )
}
