import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { FeedItem } from 'components/FeedItem'
import { AgentCard } from 'components/AgentCard'
import { updateMultipleChoiceItem } from 'utils/feedItemClient'
import { LeadContext } from 'context/LeadContext'
import { GenericCallToActionOptions } from './GenericCallToActionOptions'
import styles from './FeedItem.module.scss'
import { trackFeedItemEvent } from '../../utils/tracking'

export const AgentRecommendationFeedback = ({ feedItem, occurredAt, id, displayedAt, city }) => {
  const itemObject = feedItem.item
  const [selectedAnswer, setSelectedAnswer] = useState(itemObject.selected_answer)
  const { leadType } = useContext(LeadContext)
  const agentMatches = itemObject.agent_matches
  const answerChoices = feedItem.item.answer_choices
  const options =
    answerChoices &&
    answerChoices.map(choice => {
      const answer = Object.keys(choice)[0]
      const updateFeedbackItem = () => {
        setSelectedAnswer(answer)
        updateMultipleChoiceItem(answer, feedItem)
        trackFeedItemEvent(feedItem, 'Tap', leadType, answer)
      }

      return {
        answerKey: answer,
        buttonText: Object.values(choice)[0],
        onOptionClick: updateFeedbackItem
      }
    })

  const showMoreAgents = selectedAnswer && selectedAnswer === 'more_agents'
  const numAgents = agentMatches.length
  const numAgentsCopy = numAgents === 1 ? 'is one more top agent' : 'are two more top agents'
  const moreAgentsSection = showMoreAgents ? (
    <div>
      <br />
      <p>{`No problem! Here ${numAgentsCopy} for you. `}</p>
      <br />
      {agentMatches.map(agentMatch => (
        <AgentCard
          key={agentMatch.agent_id}
          agentMatch={agentMatch}
          feedItemId={id}
          externalId={feedItem.external_id}
          city={city}
          feedItemType="agent_recommendation"
        />
      ))}
    </div>
  ) : null

  return (
    <FeedItem
      occurredAt={occurredAt}
      classname={showMoreAgents ? styles.agentFeedbackItem : null}
      id={id}
      displayedAt={displayedAt}
    >
      <GenericCallToActionOptions
        text="How do those agents look?"
        options={options}
        selectedAnswerKey={selectedAnswer}
        disableReselect={false}
      />
      {moreAgentsSection}
    </FeedItem>
  )
}

AgentRecommendationFeedback.propTypes = {
  feedItem: PropTypes.shape({
    item: PropTypes.shape({
      agent_matches: PropTypes.arrayOf(
        PropTypes.shape({
          agent_id: PropTypes.number,
          agent_name: PropTypes.string,
          office_display_name: PropTypes.string,
          elite_status: PropTypes.bool,
          move_safe_certified: PropTypes.bool,
          picture: PropTypes.string,
          slug: PropTypes.string,
          badges: PropTypes.arrayOf(PropTypes.shape({})),
          contact_capabilities: PropTypes.shape({
            can_call: PropTypes.bool
          })
        })
      ).isRequired,
      answer_choices: PropTypes.arrayOf(PropTypes.shape({})),
      selected_answer: PropTypes.string
    }),
    external_id: PropTypes.string.isRequired
  }),
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string,
  city: PropTypes.string
}

AgentRecommendationFeedback.defaultProps = {
  feedItem: {
    item: {
      agent_matches: [{}],
      answer_choices: [
        { accept: "Great, I'll continue with them" },
        { more_agents: "I'd like to see a few more" }
      ],
      selected_answer: null
    }
  },
  displayedAt: null,
  city: ''
}
