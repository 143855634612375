import { Factory } from 'miragejs'
import faker from 'faker'

export default Factory.extend({
  title() {
    return [faker.lorem.words(6), 'list_home_task'][faker.datatype.number({ min: 0, max: 1 })]
  },
  note() {
    return faker.lorem.paragraph()
  }
})
