import React from 'react'
import PropTypes from 'prop-types'
import { FeedItem } from 'components/FeedItem/index'
import { BlogArticleCard } from 'components/BlogArticleCard'

export const BlogArticleItem = ({ description, blogPost, occurredAt, id, displayedAt }) => (
  <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
    {description}
    <BlogArticleCard
      title={blogPost.title}
      text={blogPost.text}
      url={blogPost.url}
      imgUrl={blogPost.hero_image}
    />
  </FeedItem>
)

BlogArticleItem.propTypes = {
  description: PropTypes.string,
  occurredAt: PropTypes.string.isRequired,
  blogPost: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
    hero_image: PropTypes.string
  }),
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

BlogArticleItem.defaultProps = {
  description:
    'We think you might find this article helpful as you consider choosing a real estate agent.\n',
  blogPost: {
    title: 'Find a realtor you can trust in 30 minutes flat',
    text: 'As of this writing, there are over 30,000 active real estate agents in Los Angeles, CA. There are over 14,000 active real estate agents in Houston, TX.',
    url: 'https://www.homelight.com/blog/find-a-realtor/',
    hero_image:
      'https://3xlsey17pnzh3nf35w1wwnug-wpengine.netdna-ssl.com/wp-content/uploads/2017/01/adult-business-businessman-1061588-e1549586238907.jpg'
  },
  displayedAt: null
}
