import React, { useCallback, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { Graphic } from 'components/Graphic'
import { EliteAgentLogo } from 'components/AgentCard'
import { AgentFaceInCircle } from 'components/AgentFaceInCircle'
import { InteractiveMapWrapper } from './InteractiveMapWrapper'

import { cx } from '../../utils/cx'
import styles from './AgentDetailsModal.module.scss'
import cardStyles from '../AgentCard/AgentCard.module.scss'
import { AgentVideo } from '../AgentVideo/AgentVideo'

export const AgentDetailsModal = ({ active, close, agentMatch, agentDisplayName, modalBadges }) => {
  const activeClass = active ? styles.modalActive : ''
  const desktopLeftElement = useRef(null)
  const [videoHeight, setVideoHeight] = useState(null)

  const escFunction = useCallback(
    event => {
      // If escape key is pressed, call the close function
      if (event.keyCode === 27) {
        close()
      }
    },
    [close]
  )

  return createPortal(
    <div className={cx(styles.agentDetailsModal, activeClass)}>
      <button
        className={styles.overlay}
        aria-label="Close"
        onClick={close}
        type="button"
        onKeyDown={escFunction}
      />
      <div
        className={cx(
          styles.modal,
          cardStyles.modal,
          agentMatch.elite_status ? styles.eliteAgentModal : ''
        )}
      >
        <div className={styles.modalBody}>
          <div ref={desktopLeftElement} className={styles.desktopLeft}>
            {agentMatch.elite_status && active && (
              <div className={styles.introVideo} style={{ height: `${videoHeight}` }}>
                <AgentVideo
                  parentElement={desktopLeftElement}
                  setVideoHeight={setVideoHeight}
                  agentSlug={agentMatch.slug}
                />
              </div>
            )}
            <div className={styles.agentDetailsSection}>
              <div className={styles.modalHeader}>
                {agentMatch.elite_status ? (
                  <div className={styles.eliteAgentHeader}>
                    <div className={styles.eliteAgentLogo}>
                      <EliteAgentLogo />
                    </div>
                    <div className={styles.eliteAgentProfilePic}>
                      <AgentFaceInCircle photoUrl={agentMatch.picture} name={agentDisplayName} />
                    </div>
                    <div className={styles.eliteAgentInfo}>
                      <div className={styles.agentName}>{agentDisplayName}</div>
                      <div className={styles.agencyName}>{agentMatch.office_display_name}</div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.agentHeader}>
                    <div className={styles.agentProfilePic}>
                      <AgentFaceInCircle photoUrl={agentMatch.picture} name={agentDisplayName} />
                    </div>
                    <div className={styles.agentInfo}>
                      <div className={styles.agentName}>{agentDisplayName}</div>
                      <div className={styles.agencyName}>{agentMatch.office_display_name}</div>
                    </div>
                  </div>
                )}
              </div>
              {agentMatch.bio && (
                <div className={styles.bioSection}>
                  <div className={cardStyles.detailsHeader}>{`About ${agentDisplayName}`}</div>
                  <div className={cardStyles.agentBio}>{agentMatch.bio}</div>
                </div>
              )}
              <div className={cx(styles.modalSection, styles.keyInsights)}>
                Key insights and statistics
              </div>
              {modalBadges}
            </div>
          </div>
          <div className={styles.desktopRight}>
            <InteractiveMapWrapper agentId={agentMatch.agent_id} modalActive={active} />
          </div>
        </div>
      </div>
      <div
        className={cx(
          styles.closeWrapperBar,
          agentMatch.elite_status ? styles.eliteCloseWrapper : ''
        )}
      >
        <div
          className={styles.closeIcon}
          onClick={close}
          onKeyDown={escFunction}
          tabIndex="0"
          role="button"
        >
          <Graphic.CloseIcon />
        </div>
      </div>
    </div>,
    document.body
  )
}

AgentDetailsModal.propTypes = {
  active: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  agentMatch: PropTypes.shape({
    agent_id: PropTypes.number.isRequired,
    agent_name: PropTypes.string.isRequired,
    bio: PropTypes.string.isRequired,
    office_display_name: PropTypes.string.isRequired,
    elite_status: PropTypes.bool.isRequired,
    move_safe_certified: PropTypes.bool.isRequired,
    deals_count: PropTypes.number.isRequired,
    picture: PropTypes.string.isRequired,
    slug: PropTypes.string,
    badges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    contact_capabilities: PropTypes.shape({
      can_call: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  agentDisplayName: PropTypes.string.isRequired,
  modalBadges: PropTypes.node.isRequired
}
