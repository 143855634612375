import React from 'react'
import PropTypes from 'prop-types'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import Slider from 'react-slick'
import styles from './Carousel.module.scss'
import { RecentTransactionCard } from './RecentTransactionCard'

export const RecentTransactionsCarousel = ({ mobile }) => {
  const activeSlideCount = mobile ? 1 : 2

  return (
    <div>
      <Slider
        dots
        infinite
        speed={500}
        slidesToShow={activeSlideCount}
        slidesToScroll={activeSlideCount}
        variableWidth={!mobile}
        dotsClass={`${styles.ourDots} slick-dots`}
      >
        <div>
          <RecentTransactionCard
            price="$1,230,000"
            address="San Jose, CA 95135"
            numBeds="4"
            numBaths="3"
            sqFt="2,117"
            imgUrl={`${process.env.PUBLIC_URL}/san-jose-home-mobile.jpg`}
            closeTime="14 days"
          />
        </div>
        <div>
          <RecentTransactionCard
            price="$215,000"
            address="Lynnwood, WA 98036"
            numBeds="3"
            numBaths="2"
            sqFt="1,246"
            imgUrl={`${process.env.PUBLIC_URL}/lynnwood-wa-home-mobile.jpg`}
            closeTime="4 days"
          />
        </div>
        <div>
          <RecentTransactionCard
            price="$101,500"
            address="Humble, TX 77339"
            numBeds="3"
            numBaths="3"
            sqFt="1,492"
            imgUrl={`${process.env.PUBLIC_URL}/humble-tx-home-mobile.jpg`}
            closeTime="21 days"
          />
        </div>
        <div>
          <RecentTransactionCard
            price="$262,600"
            address="Kissimmee, FL 34744"
            numBeds="4"
            numBaths="2"
            sqFt="2,017"
            imgUrl={`${process.env.PUBLIC_URL}/kissimmee-fl-home-mobile.jpg`}
            closeTime="8 weeks"
          />
        </div>
        <div />
      </Slider>
    </div>
  )
}

RecentTransactionsCarousel.propTypes = {
  mobile: PropTypes.bool
}

RecentTransactionsCarousel.defaultProps = {
  mobile: true
}

export default RecentTransactionsCarousel
