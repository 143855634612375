import { useState, useEffect } from 'react'

export const useMessageCountNotification = () => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(window.unreadMessagesCount)

  useEffect(() => {
    const handleUnreadMessagesCountUpdate = e => {
      setUnreadMessagesCount(e.detail.count)
    }

    document.addEventListener('unreadMessagesCount', handleUnreadMessagesCountUpdate)

    return () => {
      document.removeEventListener('unreadMessagesCount', handleUnreadMessagesCountUpdate)
    }
  }, [])

  return { unreadMessagesCount }
}
