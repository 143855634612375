import { Factory } from 'miragejs'
import faker from 'faker'
import AgentRecommendationFactory from './FeedItemFactories/AgentRecommendationFactory'
import AgentRecommendationFeedbackFactory from './FeedItemFactories/AgentRecommendationFeedbackFactory'
import CallToActionFactory from './FeedItemFactories/CallToActionFactory'
import BlogArticleItemFactory from './FeedItemFactories/BlogArticleItemFactory'
import DirectAgentMatchFactory from './FeedItemFactories/DirectAgentMatchFactory'
import GenericMessageFactory from './FeedItemFactories/GenericMessageFactory'
import HomeEstimateFactory from './FeedItemFactories/HomeEstimateFactory'
import MissedAgentCallFactory from './FeedItemFactories/MissedAgentCallFactory'
import MissedHCCallFactory from './FeedItemFactories/MissedHCCallFactory'
import WelcomeItemFactory from './FeedItemFactories/WelcomeItemFactory'
import MultipleChoiceCardFactory from './FeedItemFactories/MultipleChoiceCardFactory'
import MyTeamFactory from './FeedItemFactories/MyTeamFactory'
import SimpleSaleFactory from './FeedItemFactories/SimpleSaleFactory'
import UpdatedHomeValEstimateFactory from './FeedItemFactories/UpdatedHomeValEstimateFactory'

import {
  WELCOME_ITEM,
  AGENT_RECOMMENDATION,
  AGENT_RECOMMENDATION_FEEDBACK,
  CALL_TO_ACTION,
  DIRECT_AGENT_MATCH,
  MISSED_HC_CALL,
  GENERIC_MESSAGE,
  MY_TEAM_AGENT,
  MISSED_AGENT_CALL,
  MULTIPLE_CHOICE,
  BLOG_POST,
  SIMPLE_SALE,
  HOME_ESTIMATE,
  USER_FEED_ITEM_TYPES,
  UPDATED_HOME_VAL_ESTIMATE
} from '../../constants/feedItemTypes'

function generateItemBasedOnItemType(itemType) {
  switch (itemType) {
    case MISSED_AGENT_CALL:
      return MissedAgentCallFactory()
    case HOME_ESTIMATE:
      return HomeEstimateFactory()
    case WELCOME_ITEM:
      return WelcomeItemFactory()
    case AGENT_RECOMMENDATION_FEEDBACK:
      return AgentRecommendationFeedbackFactory()
    case AGENT_RECOMMENDATION:
      return AgentRecommendationFactory()
    case CALL_TO_ACTION:
      return CallToActionFactory()
    case DIRECT_AGENT_MATCH:
      return DirectAgentMatchFactory()
    case MISSED_HC_CALL:
      return MissedHCCallFactory()
    case BLOG_POST:
      return BlogArticleItemFactory()
    case MULTIPLE_CHOICE:
      return MultipleChoiceCardFactory()
    case GENERIC_MESSAGE:
      return GenericMessageFactory()
    case MY_TEAM_AGENT:
      return MyTeamFactory()
    case SIMPLE_SALE:
      return SimpleSaleFactory()
    case UPDATED_HOME_VAL_ESTIMATE:
      return UpdatedHomeValEstimateFactory()
    default:
      return GenericMessageFactory()
  }
}

export default Factory.extend({
  user: null,
  activity: null,
  item_type() {
    return faker.random.arrayElement(USER_FEED_ITEM_TYPES)
  },
  item() {
    return generateItemBasedOnItemType(this.item_type)
  },
  occurred_at: faker.date.recent,
  hidden_at: null,
  created_at: faker.date.past,
  updated_at: faker.date.past,
  external_id: faker.random.uuid,
  id: faker.datatype.number,
  displayed_at: [null, faker.date.past][faker.datatype.number({ min: 0, max: 1 })]
})
