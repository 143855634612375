import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { PageContext } from '../../context/PageContext'
import { relativeDateParser } from '../../utils/relativeDateParser'
import { ChatMessagePreview } from './ChatMessagePreview'
import { EmailMessagePreview } from './EmailMessagePreview'
import { fireCustomEvent } from '../../utils/fireCustomEvent'
import { UnreadCountDisplay } from './UnreadCountDisplay'
import { LoadingSpinner } from '../Loading/LoadingSpinner'
import styles from './Message.module.scss'
import { pageLoadingIndicator } from '../../pages/Home/Home.module.scss'

export const MessagesTab = () => {
  const pageContext = React.useContext(PageContext)
  const messages = useMemo(() => pageContext.messages.data || [], [pageContext.messages.data])

  useEffect(() => {
    fireCustomEvent('inboxMessagesFetched', { messages })
  }, [messages])

  const renderLoadingSection = () => (
    <div className={pageLoadingIndicator}>
      <LoadingSpinner />
    </div>
  )

  const renderMessagesPreviewSection = () => (
    <div>
      {messages.map(message =>
        message.message_classification.name === 'email' ? (
          <EmailMessagePreview
            key={message.id}
            id={message.id}
            isRead={message.is_read}
            subject={message.content.subject}
            messageContent={message.content.body}
            timestamp={relativeDateParser(message.created_at)}
          />
        ) : (
          <Link
            to={`/messages/${message.parent_message ? message.parent_message.id : message.id}`}
            key={message.id}
          >
            <ChatMessagePreview
              isRead={message.is_read}
              participants={message.message_participants}
              messageContent={message.content.body}
              timestamp={relativeDateParser(message.created_at)}
            />
          </Link>
        )
      )}
    </div>
  )

  return (
    <div>
      <div className={styles.messagesContainer}>
        <div className={styles.tabHeading}>
          <h2>Messages</h2>
          <UnreadCountDisplay />
        </div>
        {pageContext.messages.isLoading ? renderLoadingSection() : renderMessagesPreviewSection()}
      </div>
    </div>
  )
}
