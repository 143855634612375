import styles from '../../App.module.scss'
import { isDesktop } from '../../utils/breakpoint'

export const CORE_PAGES = ['/', '/progress', '/messages']

export const pageTransitionProps = (pathname, isNotMobile) => {
  if (isNotMobile || CORE_PAGES.includes(pathname)) {
    return {
      classNames: 'fade',
      timeout: 500,
      mountOnEnter: false,
      unmountOnExit: false
    }
  }
  if (pathname.match(/\/messages\/\S+/)) {
    return {
      classNames: 'slide-from-right',
      timeout: 350,
      mountOnEnter: true,
      unmountOnExit: true
    }
  }

  return {
    classNames: 'slide-from-left',
    timeout: 350,
    mountOnEnter: true,
    unmountOnExit: true
  }
}

export const buildPageClasses = (pathname, width) => {
  const pageClasses = [styles.home]
  if (isDesktop(width)) {
    pageClasses.push(styles.mainPageDesktop)
  }
  if (!CORE_PAGES.includes(pathname)) {
    pageClasses.push(styles.branchPage)
  }

  return pageClasses.join(' ')
}
