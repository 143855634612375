import { RenderSimpleSale } from './SellerLead/SimpleSale'
import { RenderSharedFeedItem } from './SharedFeed'

export const RenderSellerFeedItem = (userFeedItem, leadType, refreshData) => {
  switch (userFeedItem.item_type) {
    case 'simple_sale':
      return RenderSimpleSale(userFeedItem, refreshData)
    default:
      return RenderSharedFeedItem(userFeedItem, leadType, refreshData)
  }
}
