import PropTypes from 'prop-types'
import React from 'react'
import styles from './PageSubTitle.module.scss'

export const PageSubTitle = ({ children }) => <div className={styles.subTitle}>{children}</div>

PageSubTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

PageSubTitle.defaultProps = {
  children: undefined
}
