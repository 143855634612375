import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { FeedItem, GenericMessage } from 'components/FeedItem'
import { updateMultipleChoiceItem } from 'utils/feedItemClient'
import { LeadContext } from 'context/LeadContext'
import { GenericCallToActionOptions } from './GenericCallToActionOptions'
import { trackFeedItemEvent } from '../../utils/tracking'

export const UpdatedHomeValueEstimateSellingOptions = ({
  feedItem,
  occurredAt,
  id,
  displayedAt
}) => {
  const itemObject = feedItem.item
  const [selectedAnswer, setSelectedAnswer] = useState(itemObject.selected_answer)
  const { leadType } = useContext(LeadContext)

  const answerChoices = [
    { within_three_months: 'Within 3 months' },
    { three_to_twelve_months: '3-12 months' },
    { more_than_a_year: 'More than a year' }
  ]
  const options =
    answerChoices &&
    answerChoices.map(choice => {
      const answer = Object.keys(choice)[0]
      const updateFeedbackItem = () => {
        setSelectedAnswer(answer)
        updateMultipleChoiceItem(answer, feedItem)
        trackFeedItemEvent(feedItem, 'Tap', leadType, answer)
      }

      return {
        answerKey: answer,
        buttonText: Object.values(choice)[0],
        onOptionClick: updateFeedbackItem
      }
    })

  const sellingSoonText =
    "Great! Since you're thinking of entering the market soon, we'll give you a call shortly and walk you through your options."
  const sellingLaterText = 'Thanks for letting us know.'
  const followUpText = selectedAnswer === 'more_than_a_year' ? sellingLaterText : sellingSoonText

  const followUpSection = selectedAnswer ? (
    <div>
      <GenericMessage
        occurredAt={occurredAt}
        id={id}
        displayedAt={displayedAt}
        text={followUpText}
      />
    </div>
  ) : null

  return (
    <div>
      <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
        <GenericCallToActionOptions
          text="When are you thinking of selling?"
          options={options}
          selectedAnswerKey={selectedAnswer}
          disableReselect={false}
        />
      </FeedItem>
      {followUpSection}
    </div>
  )
}

UpdatedHomeValueEstimateSellingOptions.propTypes = {
  feedItem: PropTypes.shape({
    item: PropTypes.shape({
      answer_choices: PropTypes.arrayOf(PropTypes.shape({})),
      selected_answer: PropTypes.string
    }),
    external_id: PropTypes.string.isRequired
  }),
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

UpdatedHomeValueEstimateSellingOptions.defaultProps = {
  feedItem: {
    item: {
      answer_choices: [],
      selected_answer: null
    }
  },
  displayedAt: null
}
