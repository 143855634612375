import UserFeedItem from './UserFeedItem'
import User from './User'
import Checklist from './Checklist'
import Task from './Task'
import Message from './Message'
import MessageParticipant from './MessageParticipant'
import MessageClassification from './MessageClassification'
import ChecklistTask from './ChecklistTask'
import AgentTransaction from './AgentTransaction'

export default () => ({
  userFeedItem: UserFeedItem,
  user: User,
  checklist: Checklist,
  checklistTask: ChecklistTask,
  task: Task,
  message: Message,
  messageParticipant: MessageParticipant,
  messageClassification: MessageClassification,
  agentTransaction: AgentTransaction
})
