import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import { AgentInvestorComparisonCard } from 'components/SimpleSale/AgentInvestorComparisonCard'
import Raven from 'raven-js'
import { FeedItem } from '../FeedItem'
import { updateFeedItem, unhideFeedItems } from '../../../utils/feedItemClient'
import styles from '../../SimpleSale/SimpleSaleCard.module.scss'
import { PageContext } from '../../../context/PageContext'
import { optimisticallyUnhideAgentRecommendations } from '../helpers/OptimisticUnhideFeedItem'
import { formatEstimatedValue } from '../../../utils/numberParser'
import { trackFeedItemEvent } from '../../../utils/tracking'

export const AgentOption = ({
  profitLow,
  profitHigh,
  occurredAt,
  id,
  displayedAt,
  userFeedItem,
  description,
  refreshData
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const { leadType } = useContext(LeadContext)
  const { feed, updateFeed } = React.useContext(PageContext)

  const onViewAgentsClick = () => {
    setIsSelected(true)
    optimisticallyUnhideAgentRecommendations(feed, updateFeed)
    unhideFeedItems(leadType, userFeedItem.item_type)
      .then(refreshData)
      .catch(error => {
        setIsSelected(false)
        Raven.captureException(error)
      })

    updateFeedItem('yes', userFeedItem)
  }

  useEffect(() => {
    if (userFeedItem.item.ss_item_type === 'hlss_eligible') {
      trackFeedItemEvent(userFeedItem, 'View', leadType)
    }
  })

  return (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      <div>
        <div className={styles.ssIntroDesc}>{description}</div>
        <AgentInvestorComparisonCard
          providerType="agent"
          itemType={userFeedItem.item_type}
          headerText="Sell with an agent"
          description="Your estimated profit"
          marketPrepVal="1%"
          agentCommissionVal="6%"
          closingCostVal="3%"
          amount={formatEstimatedValue(profitLow, profitHigh)}
          buttonText="View agent matches"
          onClick={onViewAgentsClick}
          selected={isSelected}
          disableSelected={false}
        />
      </div>
    </FeedItem>
  )
}

AgentOption.propTypes = {
  profitLow: PropTypes.number.isRequired,
  profitHigh: PropTypes.number.isRequired,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string,
  userFeedItem: PropTypes.shape({ item_type: PropTypes.string.isRequired }).isRequired,
  description: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired
}

AgentOption.defaultProps = {
  displayedAt: null
}
