import { RenderInvestorIntro } from './SimpleHomeValue/InvestorIntro'
import { RenderSharedFeedItem } from './SharedFeed'

export const RenderSimpleHomeValueFeedItem = (userFeedItem, leadType, refreshData) => {
  switch (userFeedItem.item_type) {
    case 'investor_intro':
      return RenderInvestorIntro(userFeedItem)
    default:
      return RenderSharedFeedItem(userFeedItem, leadType, refreshData)
  }
}
