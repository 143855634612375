export const findObjectIndicesByKey = (array, key, value) => {
  const foundIndices = []

  if (!key || !value) {
    return foundIndices
  }

  ;(array || []).forEach((item, index) => {
    if (array[index][key] === value) {
      foundIndices.push(index)
    }
  })

  return foundIndices
}
