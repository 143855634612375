import React from 'react'
import PropTypes from 'prop-types'
import { GenericCallToActionItem } from 'components/FeedItem/index'

export const CallToActionWrapper = ({ userFeedItem, occurredAt }) => {
  let text
  let buttonText
  if (userFeedItem.item.action_type === 'call_us') {
    if (
      userFeedItem.item.reason === 'valid_seller_concierge' ||
      userFeedItem.item.reason === 'buyer_concierge'
    ) {
      const renderNewCopy = process.env.REACT_APP_NEW_CONCIERGE_COPY === 'true'
      text = renderNewCopy
        ? 'We’d love to connect you with top real estate agents like you requested, but we need a little more info to confirm they’re the right fit. \nIf you have any questions, don’t hesitate to reach out. We’re here to help.'
        : "We looked at millions of real estate transactions, and tried to find the best match given your answers to our questions. But we weren't able to find the perfect agent. Sometimes this happens because we don't have enough transactions in your area, or our database tells us we need to ask a few more questions about you and your property to find the perfect match.\n  We'll be calling you shortly, but if you miss our call, give one of our Home Consultants a call. First, they’ll ask a few questions to make sure we can search for the right agent based on what's important to you. Then, they’ll personally walk you through all of your options and help you decide which one is best for you.\n You came here to sell your house faster and for more money. We’re here to help."
    } else if (
      userFeedItem.item.reason === 'simple_sale_concierge' ||
      userFeedItem.item.reason === 'home_value_concierge'
    ) {
      text =
        "Unfortunately, it doesn't appear that there are any investors or real estate agents currently available in your area.   Sometimes this happens because we don't have enough transactions in your area, or our database tells us we need to ask a few more questions about you and your property to find the perfect match. \n We'll be calling you shortly, but if you miss our call, give one of our Home Consultants a call.  First, they’ll ask a few questions to make sure we can search for the right agent based on what's important to you.  Then, they’ll personally walk you through all of your options and help you decide which one is best for you. \nYou came here to sell your house faster and for more money.  We’re here to help."
    }
  }

  if (userFeedItem.item.action_type === 'hlca_calendly') {
    text =
      'Next steps: Schedule a phone call with us to talk through your options and answer any questions you may have.'
    buttonText = 'Schedule a call'
  }

  return (
    <GenericCallToActionItem
      actionType={
        userFeedItem.item.action_type &&
        userFeedItem.item.action_type.replace(/([-_]\w)/g, g => g[1].toUpperCase())
      }
      text={text}
      buttonText={buttonText}
      occurredAt={occurredAt}
      id={userFeedItem.id}
      displayedAt={userFeedItem.displayed_at}
    />
  )
}

CallToActionWrapper.propTypes = {
  userFeedItem: PropTypes.shape({
    item: PropTypes.shape({
      reason: PropTypes.string,
      action_type: PropTypes.string
    }),
    user: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    id: PropTypes.string.isRequired,
    displayed_at: PropTypes.string
  }).isRequired,
  occurredAt: PropTypes.string.isRequired
}
