import { useEffect, useRef } from 'react'

// Stores a previous value to compare with an update. Adding specifically to store a previous prop to compare
// with a new prop - similar to the class component pattern of
// componentDidUpdate(prevProps) { ... if (props !== prevProps) etc.

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
