import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-simple-flex-grid'
import { Button } from 'components/Button'
import { PageResizeContext } from 'components/PageResize'
import axios from 'axios'
import styles from './SimpleSaleResultsPage.module.scss'
import { isMobile } from '../../utils/breakpoint'
import {
  prettifiedEstimatedVal,
  formatEstimatedValue,
  calculatePercentageOfEstimatedValue
} from '../../utils/numberParser'
import { SimpleSaleDefaultBenefits } from './SimpleSaleDefaultBenefits'
import RecentTransactions from './RecentTransactions'
import { LeadContext } from '../../context/LeadContext'
import { CheckIcon } from '../Graphic'
import { SimpleSaleToast } from '../SimpleSaleToast'

const ARIANA_EMOJIS = [':ariana1:', ':ariana2:', ':ariana3:', ':ariana4:', ':ariana5:', ':ariana6:']

export function SimpleSaleResultsWrapper({ resultsData }) {
  const [getAnOfferSelected, selectGetAnOffer] = useState(false)
  const { leadId, leadName, leadType, hlssEligible } = React.useContext(LeadContext)
  const { width } = useContext(PageResizeContext)
  const mobile = isMobile(width)
  const investorMath = resultsData.investor || { sale_low: null, sale_high: null }
  const useOpendoor = resultsData.use_opendoor
  const displayedInvestorLowEstimate = useOpendoor
    ? resultsData.opendoor_low_estimate
    : investorMath.sale_low
  const displayedInvestorHighEstimate = useOpendoor
    ? resultsData.opendoor_high_estimate
    : investorMath.sale_high
  const headerText = 'HomeLight Simple Sale'
  const description = 'Your estimated offer'
  const formatOfferAmount = (lowEstimate, highEstimate) => {
    if (leadType === 'simple_sale' && !useOpendoor) {
      return `${calculatePercentageOfEstimatedValue(
        lowEstimate,
        0.85
      )} - ${calculatePercentageOfEstimatedValue(highEstimate, 0.91)}`
    }
    if (lowEstimate && highEstimate) {
      return formatEstimatedValue(lowEstimate, highEstimate)
    }

    return 'Requires more information'
  }
  const offerAmount = formatOfferAmount(displayedInvestorLowEstimate, displayedInvestorHighEstimate)
  const formattedAddress =
    resultsData.address &&
    resultsData.address
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ')

  const sendSlackWebhook = () => {
    if (getAnOfferSelected) {
      return
    }

    selectGetAnOffer(true)

    const message = {
      text: `${leadName} (Lead #${leadId}) just indicated an interest in Simple Sale. Please reach out.`,
      username: 'Ariana', // Ariana Grande delivers this information for Ryan
      icon_emoji: ARIANA_EMOJIS[Math.floor(Math.random() * ARIANA_EMOJIS.length)]
    }

    axios
      .post(process.env.REACT_APP_SS_INTERESTED_SLACK_WEBHOOK, JSON.stringify(message), {
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            // eslint-disable-next-line no-param-reassign
            delete headers.post['Content-Type']
            return data
          }
        ]
      })
      .then(() => {
        // TODO: toggle toast on success
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (hlssEligible) {
    return (
      <div className={`${styles.ssResultsPage} ${mobile ? styles.mobile : styles.desktop}`}>
        <Row className={styles.textIntro}>
          <h1 className={styles.resultsHeading}>The simplest way to sell</h1>
          <p>
            Selling a home can be a time-consuming, stressful, and expensive endeavor. For the many
            homeowners who value simplicity, speed, and certainty, we’ve created Simple Sale ⏤ a new
            way to sell your home directly to HomeLight, whenever you’re ready to sell.
          </p>
        </Row>
        <h4 className={styles.homeValHeading}>Your estimated home value</h4>
        <p className={styles.homeValNumber}>
          {`${prettifiedEstimatedVal(resultsData.estimated_home_value)}`}
        </p>
        <p className={styles.estimateAddress}>{formattedAddress}</p>
        <Row>
          <SimpleSaleDefaultBenefits
            headerText={headerText}
            description={description}
            amount={offerAmount}
            itemType="simple_sale"
          />
        </Row>
        <Row className={styles.nextStepsHeading}>
          <h1>What happens next?</h1>
        </Row>
        <Row align="bottom" className={styles.nextStepsSectionHeader}>
          <div className={styles.filledInCirce}>1</div>
          <div>
            <h5 className={styles.nextStepTitle}>Schedule a call</h5>
          </div>
        </Row>
        <Row className={styles.nextStepsSectionDesc}>
          <p className={styles.nextStepDesc}>
            Pick a time that works for you. We’ll give you a call to talk through your options and
            answer any questions you may have.
          </p>
        </Row>
        <Row align="bottom" className={styles.nextStepsSectionHeaderMultiLine}>
          <div className={`${styles.filledInCirce} ${styles.multiLine}`}>2</div>
          <h2 className={styles.nextStepTitle}>We’ll learn more about your home</h2>
        </Row>
        <Row className={styles.nextStepsSectionDesc}>
          <p className={styles.nextStepDesc}>
            We will need to know more about the condition of your home. We’ll ask you a few
            questions questions to better understand major elements of your home such as the
            condition of your kitchen.
          </p>
        </Row>
        <Row align="bottom" className={styles.nextStepsSectionHeader}>
          <div className={styles.filledInCirce}>3</div>
          <h2 className={styles.nextStepTitle}>You’ll get a full cash offer</h2>
        </Row>
        <Row className={styles.nextStepsSectionDesc}>
          <p className={styles.nextStepDesc}>
            We will extend you an all cash offer within a matter of days. You’ll be able to choose
            when you move out at your own convenience.
          </p>
        </Row>
        <Button as="primary" className={styles.primaryCta} onClick={sendSlackWebhook}>
          {getAnOfferSelected && <CheckIcon className={`${styles.checkIcon} ${styles.selected}`} />}
          Get an offer
        </Button>
        <SimpleSaleToast show={getAnOfferSelected} />
        <RecentTransactions />
        <div className={styles.scrolldownSpace} />
      </div>
    )
  }
  return (
    <div className={`${styles.ssResultsPage} ${mobile ? styles.mobile : styles.desktop}`}>
      <Row className={styles.textIntro}>
        <h1 className={styles.resultsHeading}>The simplest way to sell</h1>
        <p>
          Selling a home can be a time-consuming, stressful, and expensive endeavor. For the many
          homeowners who value simplicity, speed, and certainty, we’ve created Simple Sale ⏤ a new
          way to sell your home directly to our network of pre-approved cash buyers, whenever you’re
          ready to sell.
        </p>
      </Row>
      <h4 className={styles.homeValHeading}>Your estimated home value</h4>
      <p className={styles.homeValNumber}>
        {`${prettifiedEstimatedVal(resultsData.estimated_home_value)}`}
      </p>
      <p className={styles.estimateAddress}>{formattedAddress}</p>
      <Row>
        <SimpleSaleDefaultBenefits
          headerText={headerText}
          description={description}
          amount={offerAmount}
          itemType="simple_sale"
        />
      </Row>
      <Row className={styles.nextStepsHeading}>
        <h1>What happens next?</h1>
      </Row>
      <Row align="bottom" className={styles.nextStepsSectionHeader}>
        <div className={styles.filledInCirce}>1</div>
        <div>
          <h5 className={styles.nextStepTitle}>We’ll introduce you to a buyer</h5>
        </div>
      </Row>
      <Row className={styles.nextStepsSectionDesc}>
        <p className={styles.nextStepDesc}>
          We reach out to dozens of buyers who invest in homes in your area. We’ll hunt for the
          highest Cash price and put you in touch with them within 1 business day.
        </p>
      </Row>
      <Row align="bottom" className={styles.nextStepsSectionHeaderMultiLine}>
        <div className={`${styles.filledInCirce} ${styles.multiLine}`}>2</div>
        <h2 className={styles.nextStepTitle}>Your buyer will learn more about your home</h2>
      </Row>
      <Row className={styles.nextStepsSectionDesc}>
        <p className={styles.nextStepDesc}>
          Your buyer will need to know more about the condition of your home. They’ll ask you a few
          questions to better understand major elements of your home such as the condition of your
          roof and kitchen
        </p>
      </Row>
      <Row align="bottom" className={styles.nextStepsSectionHeader}>
        <div className={styles.filledInCirce}>3</div>
        <h2 className={styles.nextStepTitle}>You’ll get a full cash offer</h2>
      </Row>
      <Row className={styles.nextStepsSectionDesc}>
        <p className={styles.nextStepDesc}>
          Your buyer will extend you an all cash offer within a matter of days. You’ll be able to
          choose when you move out at your own convenience
        </p>
      </Row>
      <Button as="primary" className={styles.primaryCta} onClick={sendSlackWebhook}>
        {getAnOfferSelected && <CheckIcon className={`${styles.checkIcon} ${styles.selected}`} />}
        Get an offer
      </Button>
      <SimpleSaleToast show={getAnOfferSelected} />
      <RecentTransactions />
      <div className={styles.scrolldownSpace} />
    </div>
  )
}

SimpleSaleResultsWrapper.propTypes = {
  resultsData: PropTypes.shape({
    investor: PropTypes.shape({
      sale_low: PropTypes.string,
      sale_high: PropTypes.string
    }),
    estimated_home_value: PropTypes.string,
    address: PropTypes.string,
    use_opendoor: PropTypes.bool,
    opendoor_high_estimate: PropTypes.string,
    opendoor_low_estimate: PropTypes.string
  })
}

SimpleSaleResultsWrapper.defaultProps = {
  resultsData: {
    investor: {
      sale_low: null,
      sale_high: null
    },
    estimated_home_value: null,
    address: 'your home',
    use_opendoor: false,
    opendoor_high_estimate: null,
    opendoor_low_estimate: null
  }
}
