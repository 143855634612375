import React from 'react'
import PropTypes from 'prop-types'
import { cx } from '../../utils/cx'
import styles from './AgentDetailsModal.module.scss'
import cardStyles from '../AgentCard/AgentCard.module.scss'
import {
  AwardIcon,
  BadgeMoreInfo,
  MoveSafeCertifiedBadge,
  RespondsQuicklyBadge
} from '../AgentBadge'

export const ModalBadges = ({
  agentMatch,
  agentDisplayName,
  yearsBadge,
  localAreaExpertBadge,
  achievementBadges,
  dealsBadge
}) => {
  const badgeSectionStyle = cx(styles.badgeSection, styles.modalSection)
  const iconStyle = cx(cardStyles.graphic, styles.graphicLarge)
  const respondsQuicklyIconStyle = cx(cardStyles.speechBubbleLarge, styles.graphicLarge)
  const moveSafeIconStyle = cx(cardStyles.moveSafe, styles.graphicLarge)
  const factStyle = cx(cardStyles.fact, styles.modalFact)
  const deprecatedBadges = ['cash_close', 'home_loans']

  return (
    <>
      <div className={badgeSectionStyle}>{yearsBadge}</div>
      <div className={badgeSectionStyle}>{localAreaExpertBadge}</div>
      {dealsBadge && <div className={badgeSectionStyle}>{dealsBadge}</div>}
      {agentMatch.responsiveness_narrative && (
        <div className={badgeSectionStyle}>
          <RespondsQuicklyBadge
            agentName={agentDisplayName}
            iconStyle={respondsQuicklyIconStyle}
            factStyle={factStyle}
            descriptionStyle={styles.badgeDescription}
          >
            <BadgeMoreInfo
              badge={{
                award_type: 'responsiveness_narrative',
                responsiveness_narrative: agentMatch.responsiveness_narrative
              }}
              agentName={agentDisplayName}
              infoAsTooltips={false}
              descriptionStyle={styles.badgeInfo}
            />
          </RespondsQuicklyBadge>
        </div>
      )}
      {achievementBadges.map(badge => {
        if (deprecatedBadges.includes(badge.award_type)) return

        return (
          <div key={achievementBadges.indexOf(badge)} className={badgeSectionStyle}>
            <div className={cardStyles.awardBadgeSection}>
              <div className={cx(cardStyles.fact, styles.modalFact)}>
                <AwardIcon
                  awardType={badge.award_type}
                  rankType={badge.rank_type}
                  iconStyle={iconStyle}
                />
              </div>
              <div className={cx(cardStyles.description, styles.badgeDescription)}>
                {badge.value}
                <BadgeMoreInfo
                  badge={badge}
                  agentName={agentDisplayName}
                  infoAsTooltips={false}
                  descriptionStyle={styles.badgeInfo}
                />
              </div>
            </div>
          </div>
        )
      })}
      {agentMatch.move_safe_certified && (
        <div className={badgeSectionStyle}>
          <MoveSafeCertifiedBadge
            agentName={agentDisplayName}
            eliteStatus={agentMatch.elite_status}
            iconStyle={moveSafeIconStyle}
            factStyle={factStyle}
            descriptionStyle={styles.badgeDescription}
          >
            <BadgeMoreInfo
              badge={{ award_type: 'move_safe' }}
              agentName={agentDisplayName}
              infoAsTooltips={false}
              descriptionStyle={styles.badgeInfo}
            />
          </MoveSafeCertifiedBadge>
        </div>
      )}
    </>
  )
}

ModalBadges.propTypes = {
  agentMatch: PropTypes.shape({
    agent_id: PropTypes.number.isRequired,
    agent_name: PropTypes.string.isRequired,
    office_display_name: PropTypes.string.isRequired,
    elite_status: PropTypes.bool.isRequired,
    move_safe_certified: PropTypes.bool.isRequired,
    responsiveness_narrative: PropTypes.string,
    picture: PropTypes.string.isRequired,
    slug: PropTypes.string,
    deals_count: PropTypes.number.isRequired,
    badges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    contact_capabilities: PropTypes.shape({
      can_call: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  agentDisplayName: PropTypes.string.isRequired,
  yearsBadge: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  localAreaExpertBadge: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
  dealsBadge: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  achievementBadges: PropTypes.arrayOf(
    PropTypes.shape({
      award_type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      rank_type: PropTypes.string
    })
  ).isRequired
}

ModalBadges.defaultProps = {
  dealsBadge: null
}
