import { Factory } from 'miragejs'
import faker from 'faker'

export default Factory.extend({
  cta_text() {
    return faker.lorem.words(3)
  },
  url() {
    return faker.lorem.sentence()
  }
})
