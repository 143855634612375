import React from 'react'
import PropTypes from 'prop-types'
import { Toast } from 'components/common/Toast'
import styles from './SimpleSaleToast.module.scss'

export const SimpleSaleToast = ({ show }) => (
  <Toast show={show} className={styles.simpleSaleToast} maxTimeout={7000} useIcon>
    <p>Great! We’ll reach out shortly to discuss your offer.</p>
  </Toast>
)

SimpleSaleToast.propTypes = {
  show: PropTypes.bool
}

SimpleSaleToast.defaultProps = {
  show: false
}
