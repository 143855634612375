import React from 'react'
import { FeedItem } from 'components/FeedItem/index'
import PropTypes from 'prop-types'
import styles from './WelcomeItem.module.scss'
import { Graphic } from '../Graphic'

export const WelcomeItemIncomplete = ({ name, occurredAt, id, displayedAt }) => (
  <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
    <div>
      <h3 className={styles.nameTitle}>Hi {name}! </h3>
      <div className={styles.welcomeText}>
        Thanks for using HomeLight! <Graphic.LaughingSmiley />
      </div>
      <br />
      <div>
        <div className={styles.boldText}>
          It looks like some of the information you submitted is incomplete or invalid.
        </div>
        <br />
        <div className={styles.boldText}>
          In order to match you with the real estate agents who best suit your needs, we need to
          gather some additional information.
        </div>
      </div>
    </div>
  </FeedItem>
)

WelcomeItemIncomplete.propTypes = {
  name: PropTypes.string.isRequired,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

WelcomeItemIncomplete.defaultProps = {
  displayedAt: null
}
