export const absoluteDateParser = timestamp => {
  const ts = Date.parse(timestamp)
  const now = new Date()
  const messageDate = new Date(ts + now.getTimezoneOffset())
  const dateComponents = messageDate.toString().split(' ')
  let minutes = messageDate.getMinutes()
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  let hours = messageDate.getHours() // value in 24 hour fmt
  const amOrPm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12

  return [`${dateComponents[1]} ${dateComponents[2]}`, `${hours}:${minutes} ${amOrPm}`]
}
