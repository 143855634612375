import faker from 'faker'

const MARKETPLACE_BADGE_TYPES = [
  'simple_sale',
  'home_loans',
  'title_escrow',
  'neighborhood_knowledge',
  'number_one',
  'property_type_expert',
  'luxury_specialist',
  'cash_close'
]

const RESPONSIVENESS_NARRATIVES = [
  'Responds in 30 mins',
  'Responds in a few hours',
  'Responds same day',
  'Responds quickly'
]

const generateTransactions = () => {
  const txCount = 30
  const transactions = []
  const testPoint = window.TEST_LEAD_LOCATION || { latitude: 37.8, longitude: -122.2 }
  const randomRange = () => Number((Math.random() * 0.3 - 0.15).toFixed(2))

  for (let i = 0; i < txCount; i += 1) {
    transactions.push({
      latitude: testPoint.latitude + randomRange(),
      longitude: testPoint.longitude + randomRange()
    })
  }

  // Simulate bad data to make sure we're filtering
  const nullTransaction = { latitude: null, longitude: null }
  const middleOfNowhere = { latitude: testPoint.latitude + 5, longitude: testPoint.longitude + 8 }
  const acrossTheWorld = { latitude: testPoint.latitude + 24, longitude: testPoint.longitude + 60 }
  transactions.push(nullTransaction)
  transactions.push(middleOfNowhere)
  transactions.push(acrossTheWorld)

  return transactions
}

export default (overrides = {}) => {
  const attributes = {
    slug: faker.lorem.slug(),
    badges: [
      { value: faker.datatype.number({ min: 0, max: 50 }), award_type: 'years_experience' },
      {
        value: faker.datatype.number({ min: 0, max: 1000 }),
        area_name: faker.address.city(),
        award_type: 'local_area_expert'
      },
      {
        value: faker.random.words(),
        award_type: faker.random.arrayElement(MARKETPLACE_BADGE_TYPES)
      },
      {
        value: faker.random.words(),
        award_type: faker.random.arrayElement(MARKETPLACE_BADGE_TYPES)
      },
      {
        value: faker.random.words(),
        award_type: faker.random.arrayElement(MARKETPLACE_BADGE_TYPES)
      },
      {
        value: faker.random.words(),
        award_type: faker.random.arrayElement(MARKETPLACE_BADGE_TYPES)
      }
    ],
    picture: faker.image.avatar(),
    agent_id: faker.datatype.number({ min: 0, max: 1000000 }),
    agent_name: `${faker.name.firstName()} ${faker.name.lastName()}`,
    phone_twilio: faker.phone.phoneNumber(),
    elite_status: faker.datatype.boolean(),
    agent_lead_id: faker.datatype.number({ min: 0, max: 1000000 }),
    years_experience: faker.datatype.number({ min: 0, max: 70 }),
    move_safe_certified: faker.datatype.boolean(),
    office_display_name: faker.name.firstName(),
    contact_capabilities: { can_call: faker.datatype.boolean() },
    transactions_count: faker.datatype.number({ min: 0, max: 1000 }),
    introduction_requested: faker.datatype.boolean(),
    area_transactions: generateTransactions(),
    responsiveness_narrative: faker.random.arrayElement(RESPONSIVENESS_NARRATIVES),
    deals_count: faker.datatype.number({ min: 0, max: 200 }),
    bio: faker.lorem.paragraph()
  }

  return { ...attributes, ...overrides }
}
