import React from 'react'
import 'react-simple-flex-grid/lib/main.css'
import { SimpleSaleResultsWrapper } from 'components/SimpleSale'
import { LoadingSpinner } from 'components/Loading/LoadingSpinner'
import { PageContext } from '../../context/PageContext'
import { GoBackHeader } from '../../components/PageHeader/GoBackHeader'
import { pageLoadingIndicator } from '../Home/Home.module.scss'

export const SimpleSaleResults = () => {
  const {
    simpleSale: { data, isLoading }
  } = React.useContext(PageContext)

  return (
    <div>
      <GoBackHeader headerText="Simple Sale" goBackLink="/" />
      {isLoading ? (
        <div className={pageLoadingIndicator}>
          <LoadingSpinner />
        </div>
      ) : (
        <SimpleSaleResultsWrapper resultsData={data} />
      )}
    </div>
  )
}
