/* istanbul ignore file */
import axios from 'axios'

export const instance = axios.create({
  baseURL: process.env.REACT_APP_HAPI_URL || '',
  withCredentials: true
})

export const hlInstance = axios.create({
  baseURL: process.env.REACT_APP_HOMELIGHT_REPO_URL || '',
  withCredentials: true
})

export const postHlRequest = (url, data = {}) => hlInstance.post(url, data)

export const getRequest = url => instance.get(url)

export const postRequest = (url, data = {}) => instance.post(url, data)

export const putRequest = (url, data = {}) => instance.put(url, data)
