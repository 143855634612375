import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { Graphic } from 'components/Graphic'
import { PageResizeContext } from 'components/PageResize'
import { cx } from '../../utils/cx'
import styles from './FeedItem.module.scss'
import { timeOnlyParser } from '../../utils/relativeDateParser'
import { isMobile, isDesktop, isSmallerViewport, isLargerViewport } from '../../utils/breakpoint'
import { putRequest } from '../../utils/makeRequest'
import { API_ENDPOINTS } from '../../constants/apiEndpoints'

export const FeedItem = ({
  children,
  classname,
  showTime,
  occurredAt,
  id,
  displayedAt,
  isLoadingFeedItem
}) => {
  const prettyTime = timeOnlyParser(occurredAt)
  const { width } = useContext(PageResizeContext)
  const feedItemRef = useRef(null)
  const [shouldMarkDisplayedAt, setShouldMarkDisplayedAt] = useState(true)

  useEffect(() => {
    function checkIfItemInView() {
      if (!feedItemRef.current) return

      const { top } = feedItemRef.current.getBoundingClientRect()
      const inView = top >= 0 && top <= window.innerHeight

      if (inView && !displayedAt && shouldMarkDisplayedAt && !isLoadingFeedItem) {
        const putData = {
          user_feed_item_id: id
        }

        setShouldMarkDisplayedAt(false)
        putRequest(API_ENDPOINTS.markItemSeen, putData)
      }
    }

    // Call once on load so we can update displayed_at without needing to scroll first
    checkIfItemInView()

    window.addEventListener('scroll', checkIfItemInView)

    return () => {
      window.removeEventListener('scroll', checkIfItemInView)
    }
  })

  return (
    <div className={cx(styles.feedItemContainer, classname)} ref={feedItemRef}>
      <Row align="bottom" justify="start">
        {isMobile(width) || isSmallerViewport(width) ? (
          <Col span={1}>
            <div className={styles.logoContainer}>
              <Graphic.Logo />
            </div>
          </Col>
        ) : null}
        {showTime ? (
          <Col span={3} offset={8} className={styles.timeStamp}>
            {prettyTime}
          </Col>
        ) : (
          <Col span={12} className={styles.timeStampPlaceholder} />
        )}
      </Row>
      <Row justify="start">
        {isDesktop(width) || isLargerViewport(width) ? (
          <Col>
            <div className={styles.logoContainer}>
              <Graphic.Logo />
            </div>
          </Col>
        ) : null}
        <Col span={isMobile(width) || isSmallerViewport(width) ? 12 : 11}>
          <div className={styles.feedItemContent}>
            <div className={styles.swoopy} />
            {children}
          </div>
        </Col>
      </Row>
    </div>
  )
}

FeedItem.propTypes = {
  children: PropTypes.node,
  classname: PropTypes.string,
  showTime: PropTypes.bool,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string,
  isLoadingFeedItem: PropTypes.bool
}

FeedItem.defaultProps = {
  children: null,
  classname: '',
  showTime: true,
  displayedAt: null,
  isLoadingFeedItem: false
}

export default FeedItem
