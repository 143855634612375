import React from 'react'
import PropTypes from 'prop-types'
import styles from './ProductEligibilityCard.module.scss'

export const ProductEligibilityCard = ({ productIcon, productHeader, productDescription }) => (
  <div className={styles.row}>
    <div className={styles.productIconContainer}>{productIcon}</div>
    <div className={styles.productHeaderContainer}>
      <h4>{productHeader}</h4>
    </div>
    <div className={styles.productDescriptionContainer}>
      <p>{productDescription}</p>
    </div>
  </div>
)

ProductEligibilityCard.propTypes = {
  productIcon: PropTypes.node.isRequired,
  productHeader: PropTypes.string.isRequired,
  productDescription: PropTypes.string.isRequired
}
