import React from 'react'
import { LeadContext } from 'context/LeadContext'
import { MultipleChoiceCard } from '../../../FeedItem/MultipleChoiceCard'
import {
  createNewItemsFromMultChoice,
  updateMultipleChoiceItem,
  clientCommunicationAction
} from '../../../../utils/feedItemClient'
import { initializeGTM, trackFeedItemEvent } from '../../../../utils/tracking'

export const RenderMultipleChoice = (userFeedItem, leadType, refreshData) => {
  const { leadSecureToken } = React.useContext(LeadContext)
  const { item, id, occurred_at: occurredAt, displayed_at: displayedAt } = userFeedItem

  function buildMultChoiceAnswers(answerChoices, feedItem) {
    return answerChoices.map(choice => {
      const answer = Object.keys(choice)[0]
      const updateAndCreateNewFeedItems = () =>
        updateMultipleChoiceItem(answer, feedItem)
          .then(
            createNewItemsFromMultChoice(
              answer,
              feedItem.item.agent_name,
              feedItem.item.agent_lead_id,
              feedItem.item.agent_twilio_phone
            )
          )
          .then(refreshData)
          .then(initializeGTM('DashboardDataLayer'))
          .then(trackFeedItemEvent(feedItem, 'Tap', leadType, answer))
          .then(clientCommunicationAction(answer, leadSecureToken))

      return {
        answerKey: answer,
        buttonText: Object.values(choice)[0],
        onOptionClick: updateAndCreateNewFeedItems
      }
    })
  }

  let answerChoices
  if (item.prompt_type === 'best_way_to_connect') {
    answerChoices = userFeedItem.item.answer_choices
  } else {
    answerChoices = buildMultChoiceAnswers(userFeedItem.item.answer_choices, userFeedItem)
  }
  return (
    <MultipleChoiceCard
      key={id}
      feedItemId={id}
      userFeedItem={userFeedItem}
      selectedAnswer={item.selected_answer}
      answerChoices={answerChoices}
      promptType={item.prompt_type}
      agentName={item.agent_name}
      occurredAt={occurredAt}
      id={id}
      displayedAt={displayedAt}
      refreshData={refreshData}
    />
  )
}
