import React, { useState, useEffect } from 'react'
import styles from './UnreadCountDisplay.module.scss'

export const UnreadCountDisplay = () => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(window.unreadMessagesCount)
  const unreadDisplay = unreadMessagesCount === 0 ? 'no' : unreadMessagesCount

  useEffect(() => {
    const handleUnreadMessagesCountUpdate = e => {
      setUnreadMessagesCount(e.detail.count)
    }

    document.addEventListener('unreadMessagesCount', handleUnreadMessagesCountUpdate)

    // for cleanup
    return () => {
      document.removeEventListener('unreadMessagesCount', handleUnreadMessagesCountUpdate)
    }
  }, [])

  return (
    <div className={styles.unreadCountDisplayContainer}>
      <p>
        You have {unreadDisplay} unread message{unreadDisplay === 1 ? '' : 's'}
      </p>
    </div>
  )
}
