import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-simple-flex-grid'
import { Link } from 'react-router-dom'
import { LeadContext } from 'context/LeadContext'
import { usePageResize } from 'components/PageResize'
import { PageContext } from '../../context/PageContext'
import { postRequest } from '../../utils/makeRequest'
import { Button } from '../Button'
import { isMobile, isSmallerViewport } from '../../utils/breakpoint'
import { GoBackHeader } from '../PageHeader/GoBackHeader'
import { formatPhoneNumber } from '../../utils/numberParser'
import { LoadingSpinner } from '../Loading/LoadingSpinner'
import { pageLoadingIndicator } from '../../pages/Home/Home.module.scss'
import styles from './MyTeam.module.scss'
import { MyTeamCard } from './MyTeamCard'

export const MyTeam = () => {
  const {
    setMobileMenuOpen,
    feed: { data }
  } = React.useContext(PageContext)

  const { width } = usePageResize()
  const { salesPhoneNumber } = React.useContext(LeadContext)
  const DIALABLE_HC_PHONE = salesPhoneNumber
    ? `tel:${salesPhoneNumber}`
    : `tel:${process.env.REACT_APP_PHONE_HC}`
  const [myTeamData, setMyTeamData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const url = '/api/user-feed-service/feed/find-or-create-agent-team-member-details'
      try {
        const result = await postRequest(url)
        setMyTeamData(result.data.agent_team_details)
        setIsLoading(false)
      } catch (error) {
        setIsError(true)
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  function chooseAnAgent() {
    setMobileMenuOpen(false)
    const agentRec = data.find(card => card.item_type === 'agent_recommendation')
    const cardId = agentRec ? `#feed-item-${agentRec.external_id}` : ''

    return (
      <Row>
        <div className={styles.tabHeading}>
          <h2>My Team</h2>
          <p>Once you choose an agent, you&apos;ll be able to reach them here.</p>
          <Link to={`/${cardId}`}>
            <Button as="primary">View agent matches</Button>
          </Link>
          {isMobile(width) || isSmallerViewport(width) ? (
            <div className={styles.callUs}>
              <p>Want to talk right now?</p>
              <a href={DIALABLE_HC_PHONE}>Call us {formatPhoneNumber(`${DIALABLE_HC_PHONE}`)}</a>
            </div>
          ) : null}
        </div>
      </Row>
    )
  }

  /* istanbul ignore next */
  function getLeadAgentData() {
    return (
      <div>
        <Row>
          <div className={styles.tabHeading}>
            <h2>My Team</h2>
            <p>My agent</p>
          </div>
        </Row>
        {myTeamData.map(myTeam => {
          const myTeamAgent = myTeam
          return (
            <Row key={`myTeam-${myTeam.id}`}>
              <Col span={12}>
                <MyTeamCard key={myTeamAgent.agent_id} myTeamAgent={myTeamAgent} />
              </Col>
            </Row>
          )
        })}
      </div>
    )
  }

  if (isError || !myTeamData) {
    return (
      <div className={styles.myTeamPage}>
        <GoBackHeader headerText="" goBackLink="/" />
        {isLoading ? 'Loading' : chooseAnAgent()}
      </div>
    )
  }
  return (
    <div className={styles.myTeamPage}>
      <GoBackHeader headerText="" goBackLink="/" />
      {isLoading ? (
        <div className={pageLoadingIndicator}>
          <LoadingSpinner />
        </div>
      ) : (
        myTeamData && getLeadAgentData()
      )}
    </div>
  )
}

export default MyTeam
