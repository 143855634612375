import React from 'react'
import PropTypes from 'prop-types'
import { Graphic } from 'components/Graphic'
import { relativeDateParser } from '../../utils/relativeDateParser'
import styles from './MessageItemCurrentUser.module.scss'

export const MessageItemCurrentUser = ({ message }) => (
  <div className={styles.overallContainer} id={message && message.id}>
    <div>
      <div className={styles.messageContainer}>
        <div className={styles.timestamp}>{relativeDateParser(message.created_at)}</div>
        <div className={styles.messageContent}>
          <div className={styles.swoopy} />
          {message.content.body}
        </div>
      </div>
    </div>
    <div className={styles.logoContainer}>
      <Graphic.AccountFill />
    </div>
  </div>
)

MessageItemCurrentUser.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.shape({ body: PropTypes.string }),
    created_at: PropTypes.string.isRequired,
    id: PropTypes.string
  }).isRequired
}

export default MessageItemCurrentUser
