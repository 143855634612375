import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { LeadContext } from 'context/LeadContext'
import styles from '../AgentCard/AgentCard.module.scss'

export const LocalAreaExpertBadge = ({ badge, agentName, infoAsTooltips }) => {
  let expertPhrase = 'an expert in your area'
  const areaName = badge.area_name
  const { leadType } = React.useContext(LeadContext)
  const agentAction = leadType === 'buyer' ? 'closed' : 'sold'
  if (areaName && areaName.indexOf('your area') === -1) {
    expertPhrase = ['a', 'e', 'i', 'o', 'u'].includes(areaName[0].toLowerCase())
      ? `an ${areaName} expert`
      : `a ${areaName} expert`
  }

  return (
    <div className={styles.agentBadge}>
      <Row align="middle" justify="start" gutter={10}>
        <Col span={12} className={styles.badgeTitleSection}>
          Homes {agentAction} in <span>{areaName}</span>
        </Col>
      </Row>
      <Row align="middle" justify="start" gutter={10}>
        <Col span={12} className={styles.badgeValue}>
          {badge.value}
        </Col>
      </Row>
      <div className={infoAsTooltips ? styles.badgeTooltip : styles.badgeInfo}>
        {`${agentName} is ${expertPhrase} with ${badge.value} homes ${agentAction} in ${areaName}.`}
        <div className={styles.tooltipPointer} />
      </div>
    </div>
  )
}

LocalAreaExpertBadge.propTypes = {
  badge: PropTypes.shape({
    value: PropTypes.number.isRequired,
    area_name: PropTypes.string.isRequired
  }).isRequired,
  agentName: PropTypes.string.isRequired,
  infoAsTooltips: PropTypes.bool
}

LocalAreaExpertBadge.defaultProps = {
  infoAsTooltips: true
}
