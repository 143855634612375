import React from 'react'
import PropTypes from 'prop-types'
import styles from './LoadingSpinner.module.scss'

export const LoadingSpinner = ({ size, text, color }) => (
  <div className={styles.loadingSpinner} data-testid="loading-spinner">
    <svg
      className={styles.chasingArc}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size }}
    >
      <circle cx="50" cy="50" r="45" {...{ stroke: color }} />
    </svg>
    <span className={styles.loadingMessage}>{text}</span>
  </div>
)

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  text: PropTypes.string,
  color: PropTypes.string
}

LoadingSpinner.defaultProps = {
  size: 32,
  text: '',
  color: '#46b6ff' // ($color-electric-blue)
}
