import { RenderHlcaFeed } from './HlcaLead'
import { RenderSharedFeedItem } from './SharedFeed'

export const RenderHlcaFeedItem = (userFeedItem, callReview, leadType, refreshData) => {
  switch (userFeedItem.item_type) {
    case 'hlca_product_eligibility':
      return RenderHlcaFeed(userFeedItem, callReview, leadType)
    default:
      return RenderSharedFeedItem(userFeedItem, leadType, refreshData)
  }
}
