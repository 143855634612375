import faker from 'faker'

const MESSAGE_TYPES = [
  'good_luck',
  'insufficient_transaction_data',
  'next_steps_expect_call',
  'direct_agent_intro',
  'generic_message',
  'delayed_intro',
  'thank_you_response'
]

export default (overrides = {}) => {
  const attributes = {
    message_type: faker.random.arrayElement(MESSAGE_TYPES),
    text: faker.random.words(),
    delayed_until: faker.date.future(),
    agent_name: `${faker.name.firstName()} ${faker.name.lastName()}`
  }

  return { ...attributes, ...overrides }
}
