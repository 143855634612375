import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Jsona from 'jsona'
import Raven from 'raven-js'
import { InteractiveTransactionMap } from './InteractiveTransactionMap'
import { LoadingSpinner } from '../Loading/LoadingSpinner'
import { getRequest } from '../../utils/makeRequest'
import { cx } from '../../utils/cx'
import { buildJsonApiParams } from '../../utils/apiPathBuilder/apiPathBuilder'
import styles from './InteractiveMap.module.scss'

export const buildUrl = agentId => {
  const schema = {
    fields: [
      {
        resource: 'map_transaction',
        values: [
          'lat',
          'lng',
          'address',
          'city',
          'state',
          'propertyType',
          'represented',
          'price',
          'date'
        ]
      }
    ],
    filter: [{ resource: 'agent_id', values: [agentId] }]
  }

  return `/api/transaction-service/agent-transactions/for-dynamic-map${buildJsonApiParams(schema)}`
}

export const InteractiveMapWrapper = ({ agentId, modalActive }) => {
  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const fetchTransactionsUrl = buildUrl(agentId)

  const fetchData = async () => {
    try {
      const dataFormatter = new Jsona()
      const result = await getRequest(fetchTransactionsUrl)
      setLoading(false)
      const allTransactions = dataFormatter.deserialize(result.data)
      setTransactions(allTransactions.filter(tx => tx.city && tx.lat && tx.lng))
    } catch (err) {
      setError(true)
      Raven.captureException(err)
    }
  }

  useEffect(() => {
    if (!transactions.length && !error) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!transactions.length) {
    return <span className={styles.hideMap} data-testid="hidden-map" />
  }

  /* istanbul ignore next */
  return (
    <div
      id={`interactive-transactions-${agentId}}`}
      className={cx(styles.interactiveMapWrapper, loading && styles.mapLoading)}
    >
      {loading && <LoadingSpinner text="Loading Transactions" />}
      <InteractiveTransactionMap
        transactions={transactions}
        modalActive={modalActive}
        setMapLoaded={() => setLoading(false)}
      />
    </div>
  )
}

InteractiveMapWrapper.propTypes = {
  agentId: PropTypes.number.isRequired,
  modalActive: PropTypes.bool.isRequired
}
