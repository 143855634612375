import React from 'react'
import PropTypes from 'prop-types'
import { usePageResize } from 'components/PageResize'
import { FeedItem } from './FeedItem'
import { Graphic } from '../Graphic'
import styles from './WelcomeItem.module.scss'
import { isMobile } from '../../utils/breakpoint'
import { WelcomeItem } from './WelcomeItem'
import { formatEstimatedValue } from '../../utils/numberParser'

export const WelcomeItemWithEstimate = ({
  type,
  name,
  address,
  city,
  propertyType,
  estimatedHomeValueLow,
  estimatedHomeValueHigh,
  occurredAt,
  id,
  displayedAt
}) => {
  const { width } = usePageResize()
  const greeting = isMobile(width) ? <h3 className={styles.nameTitle}>Hi {name}! </h3> : null
  const formattedPropertyType = propertyType && propertyType.split('_').join(' ')
  const formattedAddressPhrase = address ? ` at ` : ''
  const ssIntroText =
    type === 'simple_sale_concierge' ? `We're excited` : `We've got two great options`
  if (!estimatedHomeValueHigh || !estimatedHomeValueLow) {
    return (
      <WelcomeItem
        key={id}
        name={name}
        propertyType={propertyType}
        address={address}
        city={city}
        transactionType="sell"
        occurredAt={occurredAt}
        id={id}
        displayedAt={displayedAt}
        type={type}
      />
    )
  }
  const estimateTitle =
    type === 'simple_home_value' ? 'Estimated home value' : 'Your estimated home value'
  const description =
    type === 'simple_home_value' ? (
      <div className={styles.bodyText}>
        Here&apos;s our early estimate of your home value at <b>{address}</b> in <b>{city}</b>.
      </div>
    ) : (
      <div className={styles.bodyText}>
        {ssIntroText} to help you <b>sell</b> your <b>{formattedPropertyType}</b>
        {formattedAddressPhrase}
        <b>{address}</b> in <b>{city}</b>.
      </div>
    )
  return (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      <div>
        {greeting}
        <div className={styles.welcomeText}>
          Welcome to HomeLight! <Graphic.LaughingSmiley />
        </div>
      </div>
      <br />
      {description}
      <div className={styles.estimate}>
        <p className={styles.estimateTitle}>{estimateTitle}</p>
        <p className={styles.value}>
          {formatEstimatedValue(estimatedHomeValueLow, estimatedHomeValueHigh)}
        </p>
      </div>
    </FeedItem>
  )
}

WelcomeItemWithEstimate.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string,
  propertyType: PropTypes.string,
  city: PropTypes.string.isRequired,
  estimatedHomeValueLow: PropTypes.number,
  estimatedHomeValueHigh: PropTypes.number,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

WelcomeItemWithEstimate.defaultProps = {
  displayedAt: null,
  propertyType: 'property',
  address: '',
  estimatedHomeValueLow: null,
  estimatedHomeValueHigh: null
}
