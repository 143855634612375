import PropTypes from 'prop-types'
import React from 'react'
import { cx } from '../../utils/cx'
import { PageResizeProvider } from '../PageResize'
import styles from './SiteWrapper.module.scss'

export const SiteWrapper = ({ className, children }) => (
  <PageResizeProvider>
    <div className={cx(styles.wrapper, className)} data-testid="site-wrapper">
      {children}
    </div>
  </PageResizeProvider>
)

SiteWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string
}

SiteWrapper.defaultProps = {
  className: undefined
}

export default SiteWrapper
