import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Graphic } from 'components/Graphic'
import { relativeDateParser } from '../../utils/relativeDateParser'
import styles from './MessageItem.module.scss'
import { AgentFaceInCircle } from '../AgentFaceInCircle'

export const MessageItem = ({ message, messageParticipant }) => {
  const dashboardRegex = /your HomeLight dashboard/
  const messageBodyContent = message.content.body
  const isDashboardMessage = messageBodyContent.match(dashboardRegex)
  let messageBody
  if (isDashboardMessage) {
    const messageParts = messageBodyContent.split('HomeLight dashboard')
    messageBody = (
      <div className={styles.messageContent}>
        <div className={styles.swoopy} />
        {messageParts[0]}
        <Link to="/" className={styles.dashboardLink}>
          HomeLight dashboard
        </Link>
        {messageParts[1]}
      </div>
    )
  } else {
    messageBody = (
      <div className={styles.messageContent}>
        <div className={styles.swoopy} />
        {messageBodyContent}
      </div>
    )
  }

  return (
    <div className={styles.overallContainer} id={message && message.id}>
      <div className={styles.logoContainer}>
        {messageParticipant.user && messageParticipant.user.first_name === 'HomeLight' ? (
          <Graphic.Logo height="32px" />
        ) : (
          <div className={styles.profilePhotoWrap}>
            <AgentFaceInCircle photoUrl={messageParticipant.photo_url} />
          </div>
        )}
      </div>
      <div>
        <div className={styles.messageContainer}>
          <div className={styles.timestamp}>{relativeDateParser(message.created_at)}</div>
          {messageBody}
        </div>
      </div>
    </div>
  )
}

MessageItem.propTypes = {
  messageParticipant: PropTypes.shape({
    is_sender: PropTypes.bool.isRequired,
    photo_url: PropTypes.string,
    user: PropTypes.shape({
      first_name: PropTypes.string
    })
  }),
  message: PropTypes.shape({
    content: PropTypes.shape({ body: PropTypes.string }),
    created_at: PropTypes.string.isRequired,
    id: PropTypes.string
  }).isRequired
}

MessageItem.defaultProps = {
  messageParticipant: {
    photo_url: null,
    user: null
  }
}
