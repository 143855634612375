import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import styles from '../AgentCard/AgentCard.module.scss'

export const DealsBadge = ({ value, agentName, infoAsTooltips }) => (
  <div className={styles.agentBadge}>
    <Row align="middle" justify="start" gutter={10}>
      <Col span={12} className={styles.badgeTitleSection}>
        HomeLight clients
      </Col>
    </Row>
    <Row align="middle" justify="start" gutter={10}>
      <Col span={12} className={styles.badgeValue}>
        {value}
      </Col>
    </Row>
    <div className={infoAsTooltips ? styles.badgeTooltip : styles.badgeInfo}>
      {`The number of times ${agentName} has helped a HomeLight buyer or seller.`}
      <div className={styles.tooltipPointer} />
    </div>
  </div>
)

DealsBadge.propTypes = {
  value: PropTypes.number.isRequired,
  agentName: PropTypes.string.isRequired,
  infoAsTooltips: PropTypes.bool
}

DealsBadge.defaultProps = {
  infoAsTooltips: true
}
