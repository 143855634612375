import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import styles from '../AgentCard/AgentCard.module.scss'

export const YearsExperienceBadge = ({ badge, agentName, infoAsTooltips }) => (
  <div className={styles.agentBadge}>
    <Row align="middle" justify="start" gutter={10}>
      <Col span={12} className={styles.badgeTitleSection}>
        Years of experience
      </Col>
    </Row>
    <Row align="middle" justify="start" gutter={10}>
      <Col span={12} className={styles.badgeValue}>
        {badge.value}
      </Col>
    </Row>
    <div className={infoAsTooltips ? styles.badgeTooltip : styles.badgeInfo}>
      {`${agentName} has been an active agent for ${badge.value} years.`}
      <div className={styles.tooltipPointer} />
    </div>
  </div>
)

YearsExperienceBadge.propTypes = {
  badge: PropTypes.shape({
    value: PropTypes.number
  }),
  agentName: PropTypes.string.isRequired,
  infoAsTooltips: PropTypes.bool
}

YearsExperienceBadge.defaultProps = {
  badge: {
    value: 0
  },
  infoAsTooltips: true
}
