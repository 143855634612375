import React from 'react'
import PropTypes from 'prop-types'
import styles from './AgentFaceInCircle.module.scss'

export const AgentFaceInCircle = ({ name, photoUrl }) => {
  function addDefaultsrc(e) {
    e.target.src = 'https://hl-production-assets.s3.amazonaws.com/client-dashboard/dummy-image.svg'
  }

  const hostIncluded = photoUrl && photoUrl.includes('cloudfront')
  const agentPhotoUrl =
    process.env.REACT_APP_MIRAGE || hostIncluded
      ? photoUrl
      : `${process.env.REACT_APP_CLOUDFRONT_DISTRO_HOST}${photoUrl}`

  return (
    <div className={styles.profilePhotoWrap}>
      <img alt={name} src={agentPhotoUrl} onError={addDefaultsrc} />
    </div>
  )
}

AgentFaceInCircle.propTypes = {
  name: PropTypes.string,
  photoUrl: PropTypes.string
}

AgentFaceInCircle.defaultProps = {
  name: '',
  photoUrl: null
}
