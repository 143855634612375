import React from 'react'
import { Link } from 'react-router-dom'
import styles from './FeedbackForm.module.scss'
import { Button } from '../Button'

export const FeedbackConfirmation = () => (
  <div className={styles.feedbackFormContainer}>
    <h1>Feedback Sent</h1>
    <div className={styles.feedbackPrompt}>
      Thanks for your feedback! If you&apos;ve requested a response we&apos;ll be in touch within 2
      business days.
    </div>
    <Link to="/">
      <Button as="primary">Close</Button>
    </Link>
  </div>
)
