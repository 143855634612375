import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { PageResizeContext } from 'components/PageResize'
import { isMobile } from '../../utils/breakpoint'
import styles from './SimpleSaleCard.module.scss'

export const SimpleSaleCard = ({
  headerText,
  description,
  amount,
  benefitsNode,
  buttonSection
}) => {
  const { width } = useContext(PageResizeContext)
  const mobile = isMobile(width)
  return (
    <Row>
      <Col span={12}>
        <div
          className={`${styles.simpleSaleCardContainer} ${mobile ? styles.mobile : styles.desktop}`}
        >
          <Row align="middle" justify="start" gutter={10}>
            <Col span={12} className={styles.fact}>
              <div className={styles.title}>{headerText}</div>
            </Col>
            <Col span={12}>
              <div className={styles.lightBold}>{description}</div>
            </Col>
            <Col span={12}>
              <div className={styles.cardAmount}>{amount}</div>
            </Col>
            <Col span={12}>{benefitsNode}</Col>
          </Row>
          {buttonSection}
        </div>
      </Col>
    </Row>
  )
}

SimpleSaleCard.propTypes = {
  amount: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  benefitsNode: PropTypes.node.isRequired,
  buttonSection: PropTypes.node
}

SimpleSaleCard.defaultProps = {
  amount: '$540k',
  buttonSection: ''
}

export default SimpleSaleCard
