import { useContext } from 'react'
import { openPopupWidget } from 'react-calendly'

import { LeadContext } from '../../context/LeadContext'

export const useCalendlyPopup = () => {
  const { leadName, leadEmail, leadCampaign, leadSecureToken, leadType, hlcaQuizLead } =
    useContext(LeadContext)

  let url = ''

  if (hlcaQuizLead && leadType === 'buyer') {
    url = process.env.REACT_APP_CALENDLY_HLCA_BUYER_MEETING_URL
  } else {
    url = process.env.REACT_APP_CALENDLY_HLSS_MEETING_URL
  }

  function openCalendlyPopup() {
    openPopupWidget({
      url: `${url}?primary_color=46b6ff&hide_landing_page_details=0&hide_event_type_details=0`,
      prefill: { name: leadName, email: leadEmail },
      utm: {
        utmSource: 'client_dashboard',
        utmContent: leadSecureToken,
        utmMedium: `feed_card_product_eligibility_${leadType}`,
        utmCampaign: leadCampaign
      }
    })
  }

  return { openCalendlyPopup }
}
