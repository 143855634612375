import React, { useEffect } from 'react'
import 'react-simple-flex-grid/lib/main.css'
import { PageContext } from '../../context/PageContext'
import { DashboardFeed } from '../../components/DashboardFeed/DashboardFeed'
import { LoadingSpinner } from '../../components/Loading/LoadingSpinner'
import { pageLoadingIndicator } from './Home.module.scss'
import { scrollToFeedItem, anchorExistsAndMatchesRegex } from '../../utils/feedHelpers'

export const Home = () => {
  const {
    mobileMenuOpen,
    feed: { data, currentlyVisiting, previouslyVisited, refreshData, isLoading }
  } = React.useContext(PageContext)

  useEffect(() => {
    if (!isLoading && !mobileMenuOpen && !previouslyVisited && anchorExistsAndMatchesRegex()) {
      // on first visit to feed, window visibly scrolls - successive visits with a hash loads directly at element id
      scrollToFeedItem()
    }
  }, [isLoading, currentlyVisiting, previouslyVisited, mobileMenuOpen])

  return (
    <div key="dashboard-feed">
      {isLoading ? (
        <div data-testid="page-loading-indicator" className={pageLoadingIndicator}>
          <LoadingSpinner />
        </div>
      ) : (
        <DashboardFeed feedItems={data} refreshData={refreshData} mobileMenuOpen={mobileMenuOpen} />
      )}
    </div>
  )
}
