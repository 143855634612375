import React from 'react'
import PropTypes from 'prop-types'
import { FeedItem, GenericCallToActionOptions, ContactUsOptionAgent } from 'components/FeedItem'
import { ScheduleButton } from 'components/Calendly/ScheduleButton'
import styles from 'components/Button/Button.module.scss'
import { CallUsOption } from './CallUsOption'

export const GenericCallToActionItem = ({
  actionType,
  title,
  text,
  options,
  buttonText,
  occurredAt,
  id,
  displayedAt
}) => {
  let actionComponent

  switch (actionType) {
    case 'hlcaCalendly':
      actionComponent = (
        <ScheduleButton buttonText={buttonText} text={text} className={styles.hlca_calendly} />
      )
      break
    case 'callUs':
      actionComponent = <CallUsOption title={title} text={text} buttonText={buttonText} />
      break
    case 'agentContactUs':
      actionComponent = <ContactUsOptionAgent />
      break
    default:
      actionComponent = <GenericCallToActionOptions title={title} text={text} options={options} />
  }

  return (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      {actionType === 'hlcaCalendly' ? text : null}
      {actionComponent}
    </FeedItem>
  )
}

GenericCallToActionItem.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onOptionClick: PropTypes.func,
      buttonText: PropTypes.string
    })
  ),
  actionType: PropTypes.string,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

GenericCallToActionItem.defaultProps = {
  text: "We'd love to connect you with top real estate agents like you requested, but we need a little more info to confirm they're the right fit. \nWhat's the best way for us to connect?",
  title: null,
  actionType: null,
  buttonText: 'Call Us',
  options: [
    {
      onOptionClick: null,
      buttonText: 'Call me ASAP'
    },
    {
      onOptionClick: null,
      buttonText: 'Schedule a call'
    },
    {
      onOptionClick: null,
      buttonText: 'Text me'
    },
    {
      onOptionClick: null,
      buttonText: "I don't want to be contacted"
    }
  ],
  displayedAt: null
}
