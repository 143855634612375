export const findFirstUnreadMessage = messages => messages.find(message => !message.is_read)

export const scrollToMessage = messageId => {
  document
    .getElementById(messageId)
    .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
}

export const scrollToFirstUnreadMessage = (messages, isFirstPageLoad) => {
  if (isFirstPageLoad) {
    const firstUnreadMessage = findFirstUnreadMessage(messages)

    if (firstUnreadMessage) {
      scrollToMessage(firstUnreadMessage.id)
    } else if (messages && messages[messages.length - 1]) {
      scrollToMessage(messages && messages[messages.length - 1].id)
    }
  }
}
