import React from 'react'
import PropTypes from 'prop-types'
import { cx } from '../../utils/cx'
import styles from './Loading.module.scss'

export const Loading = ({ wrapperClass, dotClass }) => (
  <div className={cx(styles.loading, wrapperClass)}>
    <div className={cx(styles.loadingDot, dotClass)} />
    <div className={cx(styles.loadingDot, dotClass)} />
    <div className={cx(styles.loadingDot, dotClass)} />
  </div>
)

Loading.propTypes = {
  wrapperClass: PropTypes.string,
  dotClass: PropTypes.string
}

Loading.defaultProps = {
  wrapperClass: '',
  dotClass: ''
}
