/* istanbul ignore file */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import './errorReporting'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './additional_imports.scss'
import { SiteWrapper } from 'components/SiteWrapper'
import AppRouter from './routers/AppRouter'
import * as serviceWorker from './serviceWorker'

import { makeServer } from './server'
import { postRequest } from './utils/makeRequest'
import { getCurrentUser } from './utils/userAuthClient'
import { initializeGTM } from './utils/tracking'
import { initializeDatadogRum } from './utils/datadog'

function hasMagicLinkToken() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return !!urlParams.get('magic_link_token')
}

initializeGTM('DashboardDataLayer')
initializeDatadogRum()

if (process.env.REACT_APP_MIRAGE === 'true') {
  makeServer({ environment: 'development' })
}

function handleLoggedOutUser() {
  if (hasMagicLinkToken()) {
    const url = '/api/user-auth-service/user-login/login'
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const token = urlParams.get('magic_link_token')

    postRequest(url, {
      token
    }).then(result => {
      if (result.data && (result.data.expired || result.data.claimed)) {
        window.location.replace(
          `${
            process.env.REACT_APP_HOMELIGHT_REPO_URL
          }/client/expired-token?email=${encodeURIComponent(
            result.data.email
          )}&ref=client_dashboard${window.location.hash}`
        )
      } else {
        // delete the token and rebuild the window location without it
        urlParams.delete('magic_link_token')

        // preserve the other parameters and hash
        const callbackUrl = new URL(
          `${window.location.origin + window.location.pathname}?${urlParams.toString()}${
            window.location.hash
          }`
        )

        // redirect
        window.location.replace(callbackUrl)
      }
    })
  } else {
    // redirect them to the HomeLight sign in page with ?ref=client_dashboard#feed-item-{ID} set
    window.location.replace(
      `${process.env.REACT_APP_HOMELIGHT_SIGN_IN}?ref=client_dashboard${window.location.hash}`
    )
  }
}

getCurrentUser()
  .then(result => {
    if (result.data.data.type === 'user') {
      // If the user has an auth cookie, render the app
      ReactDOM.render(
        <SiteWrapper>
          <AppRouter />
        </SiteWrapper>,
        document.getElementById('root')
      )
    } else {
      handleLoggedOutUser()
    }
  })
  .catch(() => {
    handleLoggedOutUser()
  })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
