// Helpers to build JSON:API query params from easy to read and maintain structures - https://jsonapi.org/

// Expects array of strings
// ex: ['user', 'preference']
// will return '&include=user,preference'
export const buildInclude = resources => {
  if (!resources) {
    return ''
  }

  return `&include=${resources.join(',')}`
}

// Expects parameter name (e.g. 'fields' or 'filter') and an array of objects
// ex: parameter = 'fields', schema = [
//   { resource: 'user', values: ['id', 'name', 'email'] },
//   { resource: 'message', values: ['content'] }
// ]
// will return '&fields[user]=id,name,email&fields[message]=content'
export const buildKeyValuePair = (parameter, schema) => {
  if (!schema || !parameter) {
    return ''
  }

  const queryParams = schema.map(pair => {
    const values = pair.values.join(',')
    return `${parameter}[${pair.resource}]=${values}`
  })

  return `&${queryParams.join('&')}`
}

export const buildJsonApiParams = queryConfig => {
  let queryString = ''

  if (queryConfig.include) {
    queryString += buildInclude(queryConfig.include)
  }

  if (queryConfig.fields) {
    queryString += buildKeyValuePair('fields', queryConfig.fields)
  }

  if (queryConfig.sort) {
    queryString += `&?sort=${queryConfig.sort}`
  }

  if (queryConfig.limit) {
    queryString += `&limit=${queryConfig.limit}`
  }

  if (queryConfig.filter) {
    queryString += buildKeyValuePair('filter', queryConfig.filter)
  }

  return queryString.length ? queryString.replace('&', '?') : queryString
}
