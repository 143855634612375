import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import { PageResizeContext } from 'components/PageResize'
import styles from './Button.module.scss'
import { Button } from './Button'
import { isLargerViewport } from '../../utils/breakpoint'
import { DisplayNumberButton } from './DisplayNumberButton'
import { trackFeedItemGenericEvent } from '../../utils/tracking'

export const CallButton = ({ feedItemType, buttonText, phoneNumber }) => {
  const { width } = useContext(PageResizeContext)
  const { leadType } = React.useContext(LeadContext)
  const desktopLayout = isLargerViewport(width)
  const DIALABLE_HC_PHONE = phoneNumber
    ? `tel:${phoneNumber}`
    : `tel:${process.env.REACT_APP_PHONE_HC}`
  if (desktopLayout) {
    return (
      <DisplayNumberButton
        phone={phoneNumber || process.env.REACT_APP_PHONE_HC}
        text="Call Us"
        feedItemType={feedItemType}
      />
    )
  }
  return (
    <a href={DIALABLE_HC_PHONE}>
      <Button
        as="primary"
        className={styles.primary}
        onClick={() => {
          trackFeedItemGenericEvent(feedItemType, 'Tap', leadType, 'contact_call')
        }}
      >
        {buttonText}
      </Button>
    </a>
  )
}

CallButton.propTypes = {
  feedItemType: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  phoneNumber: PropTypes.string
}

CallButton.defaultProps = {
  buttonText: 'Call Us',
  phoneNumber: process.env.REACT_APP_PHONE_HC
}
