/* eslint-disable */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { cx } from '../../utils/cx'
import { Icon } from '../Icon'
import styles from './Button.module.scss'

export const Button = ({ children, text, className, as, large, size, onClick, ...props }) => {
  const [clickBackground, setClickBackground] = useState(0)
  let asClass = null
  let dataTestId = ''
  let buttonType = 'button'
  let icon
  let onButtonClick = onClick ? onClick : () => {}

  switch (as) {
    case 'major': // Deprecating this one. 'large' will be another prop.
      asClass = styles.major
      dataTestId = 'major-button'
      break
    case 'create':
      asClass = styles.create
      dataTestId = 'create-button'
      break
    case 'primary':
      asClass = styles.primary
      dataTestId = 'primary-button'
      break
    case 'submit':
      asClass = styles.primary
      dataTestId = 'primary-button'
      buttonType = 'submit'
      break
    case 'cancel':
      asClass = styles.transparent
      dataTestId = 'cancel-button'
      break
    case 'action':
      asClass = styles.action
      dataTestId = 'action-button'
      break
    case 'link':
      asClass = styles.link
      dataTestId = 'link-button'
      break
    case 'tab':
      dataTestId = 'tab-button'
      break
    case 'secondary':
      asClass = styles.secondary
      dataTestId = 'secondary-button'
      break
    case 'external':
      asClass = styles.external
      dataTestId = 'external-button'
      icon = <Icon.ExternalLink />
      break
    case 'menuButton':
      asClass = styles.menuButton
      dataTestId = 'menu-button'
      break
    case 'icon':
      asClass = styles.icon
      dataTestId = 'icon-button'
      break
    case 'iconInverted':
      asClass = styles.iconInverted
      dataTestId = 'icon-inverted-button'
      break
    case 'wrapper':
      asClass = styles.wrapper
      dataTestId = 'wrapper-button'
      break
    default:
      asClass = null
      dataTestId = 'button'
  }

  const sizeClass = (() => {
    if (large || size === 'large') {
      return styles.large
    }
    if (size === 'medium') {
      return styles.medium
    }
    return null
  })()

  const handleClick = () => {
    setClickBackground(1)
    onButtonClick()

    setTimeout(() => {
      setClickBackground(0)
    }, 1000)
  }

  return (
    <button
      type={buttonType}
      data-testid={dataTestId}
      className={cx(styles.button, sizeClass, asClass, className)}
      clickbackground={clickBackground}
      onClick={() => handleClick()}
      {...props}
    >
      {text}
      {children}
      {icon}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  text: PropTypes.string,
  large: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  as: PropTypes.string
}

Button.defaultProps = {
  className: "",
  text: undefined,
  large: false,
  size: undefined,
  as: ''
}

export default Button
