import React from 'react'
import PropTypes from 'prop-types'
import styles from './CardHeaderText.module.scss'

function splitTextOnNewline(text, graphic) {
  return (
    text &&
    text.split(/\n/).map(item => (
      <div key={item} className={styles.splitDescParagraph}>
        {item}
        {graphic}
      </div>
    ))
  )
}

function renderTitle(title) {
  if (title) {
    //  We don't want an empty h3 to render if no title exists, as it will add unwanted spacing
    return <h3>{title}</h3>
  }
  return null
}

export const CardHeaderText = ({ title, text, graphic }) => (
  <div className={styles.cardText}>
    {renderTitle(title)}
    <div className={styles.cardTextDesc}>{splitTextOnNewline(text, graphic)}</div>
  </div>
)

CardHeaderText.propTypes = {
  graphic: PropTypes.shape({}),
  title: PropTypes.string,
  text: PropTypes.string
}

CardHeaderText.defaultProps = {
  graphic: null,
  title: null,
  text: null
}
