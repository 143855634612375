import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { LeadContext } from 'context/LeadContext'
import { Button } from 'components/Button'
import { Graphic } from 'components/Graphic'
import styles from './WhyAgentsModal.module.scss'
import { trackFeedItemGenericEvent } from '../../utils/tracking'
import { cx } from '../../utils/cx'

export const WhyAgentsModal = ({ city, numWithDelimiter }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalBody, setModalBody] = useState(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const { leadType } = useContext(LeadContext)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const handleOnScroll = e => {
      const scroll = e.target.scrollTop
      if (isScrolled && scroll < 24) {
        setIsScrolled(false)
      } else if (!isScrolled && scroll >= 24) {
        setIsScrolled(true)
      }
    }

    if (modalBody && modalBody.addEventListener) {
      modalBody.addEventListener('scroll', handleOnScroll)
      return () => {
        modalBody.removeEventListener('scroll', handleOnScroll)
      }
    }
  }, [modalBody, isScrolled])

  function setContentRef(node) {
    setModalBody(node)
  }

  function renderModal() {
    const closeWrapperClasses = isScrolled
      ? cx(styles.closeWrapperBar, styles.contentScrolled)
      : styles.closeWrapperBar

    return (
      <div className={styles.whyAgentsModal} data-testid="modal-wrapper">
        <div className={closeWrapperClasses}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div
            className={styles.closeIcon}
            onClick={() => {
              setShowModal(false)
            }}
            tabIndex="0"
            role="button"
            data-testid="modal-close"
          >
            <Graphic.CloseIcon />
          </div>
        </div>
        <section>
          <h2>How did we choose your matches?</h2>
          <p>
            Anyone can call themselves a real estate agent but only a few can claim they’re at the
            top of their game. At HomeLight, we thoroughly analyze performance data on all the real
            estate agents in your search area. These agents can&apos;t pay HomeLight to be
            recommended, so our results are 100% objective. In a given area, we typically only work
            with the top 1% of agents.
          </p>
        </section>

        <section className={styles.bodySection}>
          <div className={styles.numberWrap}>
            <div className={styles.numberCircle}>1</div>
          </div>
          <div className={styles.textWrap}>
            <h4>Starting with comprehensive data</h4>
            <p>
              HomeLight is the leading resource for finding objective agent performance data.
              Here&apos;s how we got there:
            </p>
            <ul>
              <li>{`HomeLight partners with local data providers to gather transaction history on all the real estate agents in ${city}.`}</li>
              <li>
                We crunch the data to produce performance-based agent rankings ⏤ in total, we’ve
                analyzed over 50 million home transactions since 2009.
              </li>
              <li>
                The agents we show you{' '}
                <span className="underline">cannot pay HomeLight to be listed.</span>
              </li>
            </ul>
          </div>
        </section>

        <section className={styles.bodySection}>
          <div className={styles.numberWrap}>
            <div className={styles.numberCircle}>2</div>
          </div>
          <div className={styles.textWrap}>
            <h4>Matching you with the perfect agents</h4>
            <p>{`We use sophisticated algorithms to filter through all ${numWithDelimiter} agents in ${city} to generate matches based on the location, transaction type, property type and price you’ve specified.`}</p>
            <br />
            <p>
              For sellers, we will introduce you to the{' '}
              <span className="underline">
                agents who can sell your home faster and for more money.
              </span>
              &nbsp;Typically, our agents sell homes for thousands more than the average area agent.
              For buyers, we will introduce you to agents who are able to successfully negotiate the
              best possible price.
            </p>
          </div>
        </section>

        <section className={styles.bodySection}>
          <div className={styles.numberWrap}>
            <div className={styles.numberCircle}>3</div>
          </div>
          <div className={styles.textWrap}>
            <h4>Personalized review</h4>
            <p>
              Real estate is personal and every home is unique. We want to make absolutely sure that
              we’re matching you with an agent who’s dealt with situations just like yours before.
            </p>
            <br />
            <p>
              A HomeLight Consultant{' '}
              <span className="underline">
                reviews the algorithm&apos;s results and gives you a call to learn more about your
                situation
              </span>
              &nbsp;and ensure we’ve recommended an agent precisely suited for your needs.
            </p>
          </div>
        </section>
      </div>
    )
  }

  return (
    <>
      <Button
        as="link"
        onClick={() => {
          setShowModal(true)
          trackFeedItemGenericEvent('agent_recommendation', 'Tap', leadType, 'learn_more')
        }}
      >
        <div className={styles.learnMore}>Learn more</div>
      </Button>
      <ReactModal
        isOpen={showModal}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentRef={setContentRef}
      >
        {renderModal()}
      </ReactModal>
    </>
  )
}

WhyAgentsModal.propTypes = {
  city: PropTypes.string,
  numWithDelimiter: PropTypes.string
}

WhyAgentsModal.defaultProps = {
  city: 'your city',
  numWithDelimiter: ''
}
