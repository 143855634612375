import faker from 'faker'
import AgentCardFactory from './AgentCardFactory/AgentCardFactory'

const PROPERTY_TYPES = ['single_family_home', 'townhomes', 'condominium', 'tenancy_in_common']

export default (overrides = {}) => {
  const attributes = {
    agents_in_area_count: faker.datatype.number({ min: 0, max: 300 }),
    agent_matches: [AgentCardFactory(), AgentCardFactory(), AgentCardFactory()],
    city: faker.address.city(),
    additional_areas: [
      [],
      [
        { name: faker.address.city(), type: 'City' },
        { name: faker.address.city(), type: 'City' }
      ]
    ][faker.datatype.number({ min: 0, max: 1 })], // random; either is an empty array or an array of fake cities
    property_type: faker.random.arrayElement(PROPERTY_TYPES)
  }

  return { ...attributes, ...overrides }
}
