import React from 'react'
import { UserInputItem } from '../../../FeedItem/UserInputItem'

export const RenderUserInput = (userFeedItem, refreshData) => {
  const { item, id, occurred_at: occurredAt, displayed_at: displayedAt } = userFeedItem

  return (
    <UserInputItem
      id={id}
      key={id}
      selectedAnswer={item.selected_answer}
      previousAnswer={item.previous_answer}
      inputType={item.input_type}
      occurredAt={occurredAt}
      displayedAt={displayedAt}
      refreshData={refreshData}
    />
  )
}
