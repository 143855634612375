export const HardcodedChecklist = (leadType, salesPhoneNumber, concierge) => {
  const DIALABLE_HC_PHONE = salesPhoneNumber
    ? `tel:${salesPhoneNumber}`
    : `tel:${process.env.REACT_APP_PHONE_HC}`

  const createAccountItem = {
    id: 1,
    percent_complete: 1.0,
    title: 'Create HomeLight account'
  }

  const sellingStrategyItem = {
    id: 2,
    percent_complete: 0,
    title: 'Choose Your Selling Strategy',
    tasks: [{}],
    checklist_tasks: [
      {
        cta_text: 'View Your Agent Matches',
        task: {
          title: 'List with a HomeLight agent',
          note: 'Not all agents are created equal. We’ve crunched the numbers to find the agents who have had the most success selling homes in your neighborhood.'
        }
      },
      {
        cta_text: 'Learn more',
        url: '/simple',
        task: {
          title: 'Sell your home to HomeLight',
          note: "If you'd like to skip the listing process entirely, you can sell your home directly to HomeLight."
        }
      }
    ]
  }
  const listHomeItem = {
    id: 3,
    percent_complete: 0,
    title: 'List Your Home',
    tasks: [{}],
    checklist_tasks: [
      {
        task: {
          title: 'list_home_task',
          note: 'It’s a good time to walk through your home with your agent. You’ll discuss with him or her whether you need to prepare the home for any open house or marketing materials. Your agent will also give you advice on a sale price. When your home is ready, the agent will begin reaching out to potential buyers.'
        }
      }
    ]
  }
  const reviewOffersItem = {
    id: 4,
    percent_complete: 0,
    title: 'Consider Offers on Your Home',
    tasks: [{}],
    checklist_tasks: [
      {
        task: {
          title: 'review_offers_task',
          note: 'Once your home is on the market, sit back and wait for the offers to roll in. Depending on your market this could be a matter of days, weeks or months. Your agent will help you understand your options and work with you to choose the best offer.'
        }
      }
    ]
  }
  const closeHomeItem = {
    id: 5,
    percent_complete: 0,
    title: 'Close on Your Home Sale',
    tasks: [{}],
    checklist_tasks: [
      {
        task: {
          title: 'close_home_task',
          note: 'The price is right! Once you find a seller offering a price you accept, your HomeLight agent will bring all the relevant parties together to complete your home sale.'
        }
      }
    ]
  }
  const findAgentItem = {
    id: 6,
    percent_complete: 0,
    title: 'Find the Perfect Agent',
    tasks: [{}],
    checklist_tasks: [
      {
        cta_text: 'View Your Agent Matches',
        task: {
          title: 'List with a HomeLight agent',
          note: 'Not all agents are created equal. We’ve crunched the numbers to find the agents who have had the most success selling homes in your neighborhood.'
        }
      }
    ]
  }
  const findLoanItem = {
    id: 7,
    percent_complete: 0,
    title: 'Find the Perfect Loan',
    tasks: [{}],
    checklist_tasks: [
      {
        cta_text: 'Get Pre-Qualified',
        url: '/cash-close/trade-in-quiz',
        task: {
          title: 'find_loan_task',
          note: 'The right loan can give you an advantage over other buyers when it’s time to make an offer.  For example, with a Cash Offer loan from HomeLight Home Loans, you can use our capital to make an all-cash offer on your new home.'
        }
      }
    ]
  }
  const findHomeItem = {
    id: 8,
    percent_complete: 0,
    title: 'Find the Perfect Home',
    tasks: [{}],
    checklist_tasks: [
      {
        cta_text: 'Search for Homes',
        url: '/real-listings',
        task: {
          title: 'find_home_task',
          note: 'Our home search technology enables us to bring you all the publicly listed homes in your target area. Tell us your search criteria and we’ll keep tabs on when a home comes to market. You’ll get an alert before anyone else.'
        }
      }
    ]
  }
  const makeOfferItem = {
    id: 9,
    percent_complete: 0,
    title: 'Make the Perfect Offer',
    tasks: [{}],
    checklist_tasks: [
      {
        task: {
          title: 'make_offer_task',
          note: 'Once you’re ready to make an offer, your HomeLight agent will guide you through the strategy and mechanics of submitting the right offer.'
        }
      }
    ]
  }
  const buyerCloseHomeItem = {
    id: 10,
    percent_complete: 0,
    title: 'Close on Your New Home',
    tasks: [{}],
    checklist_tasks: [
      {
        task: {
          title: 'buyer_close_home_task',
          note: 'As soon as your offer is accepted, the listing agent will open escrow on the home.'
        }
      }
    ]
  }

  const conciergeSellingStrategyItem = {
    id: 11,
    percent_complete: 0,
    title: 'Pick Your Selling Strategy',
    tasks: [{}],
    checklist_tasks: [
      {
        cta_text: 'Call Us',
        url: `${DIALABLE_HC_PHONE}`,
        task: {
          title: 'concierge_sell_strategy_task',
          note: 'We’d love to connect you with top real estate agents like you requested, but we need a little more info to confirm they’re the right fit. \nIf you have any questions, don’t hesitate to reach out. We’re here to help.'
        }
      }
    ]
  }

  const conciergeBuyingStrategyItem = {
    id: 12,
    percent_complete: 0,
    title: 'Pick Your Buying Strategy',
    tasks: [{}],
    checklist_tasks: [
      {
        cta_text: 'Call Us',
        url: `${DIALABLE_HC_PHONE}`,
        task: {
          title: 'concierge_buy_strategy_task',
          note: 'We’d love to connect you with top real estate agents like you requested, but we need a little more info to confirm they’re the right fit. \nIf you have any questions, don’t hesitate to reach out. We’re here to help.'
        }
      }
    ]
  }

  const sellerItems = {
    data: [createAccountItem, sellingStrategyItem, listHomeItem, reviewOffersItem, closeHomeItem]
  }

  const conciergeSellerItems = {
    data: [
      createAccountItem,
      conciergeSellingStrategyItem,
      listHomeItem,
      reviewOffersItem,
      closeHomeItem
    ]
  }

  const buyerItems = {
    data: [
      createAccountItem,
      findAgentItem,
      findLoanItem,
      findHomeItem,
      makeOfferItem,
      buyerCloseHomeItem
    ]
  }

  const conciergeBuyerItems = {
    data: [
      createAccountItem,
      conciergeBuyingStrategyItem,
      findLoanItem,
      findHomeItem,
      makeOfferItem,
      buyerCloseHomeItem
    ]
  }

  const simpleSaleItems = {
    data: [createAccountItem, findAgentItem, listHomeItem, reviewOffersItem, closeHomeItem]
  }

  function returnHardcodedChecklist() {
    let items
    const renderConcierge = concierge && process.env.REACT_APP_NEW_CONCIERGE_COPY === 'true'
    if (leadType === 'simple_home_value' || leadType === 'simple_sale') {
      items = simpleSaleItems
    } else if (leadType === 'buyer') {
      items = renderConcierge ? conciergeBuyerItems : buyerItems
    } else {
      items = renderConcierge ? conciergeSellerItems : sellerItems
    }
    return items
  }

  return returnHardcodedChecklist()
}
