import React from 'react'
import PropTypes from 'prop-types'
import { FeedItem, ScheduleCallSelectTime } from 'components/FeedItem'

export const UserInputItem = ({
  id,
  inputType,
  occurredAt,
  displayedAt,
  refreshData,
  selectedAnswer,
  previousAnswer
}) => {
  let actionComponent

  switch (inputType) {
    case 'schedule_call_select_time':
      actionComponent = (
        <ScheduleCallSelectTime
          selectedAnswer={selectedAnswer}
          previousAnswer={previousAnswer}
          feedItemId={id}
          refreshData={refreshData}
        />
      )
      break
    default:
      actionComponent = null
  }

  return (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      {actionComponent}
    </FeedItem>
  )
}

UserInputItem.propTypes = {
  id: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  occurredAt: PropTypes.string.isRequired,
  displayedAt: PropTypes.string,
  refreshData: PropTypes.func.isRequired,
  selectedAnswer: PropTypes.string,
  previousAnswer: PropTypes.string
}

UserInputItem.defaultProps = {
  selectedAnswer: '',
  previousAnswer: '',
  displayedAt: ''
}
