export const PAGE_NAMES = {
  home: 'home',
  progress: 'progress',
  messages: 'messages',
  messageDetail: 'messageDetail',
  feedback: 'feedback',
  simpleSale: 'simpleSale',
  team: 'team'
}

export const ROUTE_MAP = {
  '/': PAGE_NAMES.home,
  '/progress': PAGE_NAMES.progress,
  '/messages': PAGE_NAMES.messages,
  '/messages/:id': PAGE_NAMES.messageDetail,
  '/feedback': PAGE_NAMES.feedback,
  '/simple-sale-results': PAGE_NAMES.simpleSale,
  '/my-team': PAGE_NAMES.team
}
