import React, { useEffect, useState } from 'react'
import { Graphic } from 'components/Graphic'
import { Row, Col } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import { Link } from 'react-router-dom'
import styles from './AccountTab.module.scss'
import { cx } from '../../utils/cx'
import { signOutAndRedirect } from '../../utils/userAuth'
import { Button } from '../Button'
import { initializeGTM, trackAccountEvent } from '../../utils/tracking'

initializeGTM('DashboardDataLayer')

export function AccountTab({ active, toggleActive }) {
  const [menuActiveClass, setMenuActiveClass] = useState(active ? styles.menuActive : '')
  const { leadFirstName, leadType } = React.useContext(LeadContext)

  useEffect(() => {
    /* istanbul ignore else */
    if (active) {
      setMenuActiveClass(styles.menuActive)
    } else {
      setMenuActiveClass(styles.menuClosing)
      setTimeout(() => setMenuActiveClass(''), 300)
    }
  }, [active])

  const onSignOutClicked = () => {
    trackAccountEvent('Tap', 'log_out')
    signOutAndRedirect()
  }

  return (
    <div className={cx(styles.accountTabContainer, menuActiveClass)}>
      {/* eslint-disable-next-line */}
      <div className={styles.backButton} onClick={toggleActive}>
        <Graphic.AngleUp width="16px" />
      </div>
      <Row align="middle" justify="start" className={styles.userInfo}>
        <Graphic.UserCircle className={styles.graphic} size={40} />
        <div className={styles.firstName}>{leadFirstName || 'Account'}</div>
      </Row>
      <Row align="middle" justify="start">
        <a
          href="https://www.homelight.com/homes"
          className={styles.option}
          target="_blank"
          rel="noopener noreferrer"
        >
          Home Search
        </a>
        <a
          href="https://www.homelight.com/simple"
          className={styles.option}
          target="_blank"
          rel="noopener noreferrer"
        >
          Simple Sale
        </a>
        <a
          href="https://www.homelight.com/trade-in"
          className={styles.option}
          target="_blank"
          rel="noopener noreferrer"
        >
          Buy Before You Sell
        </a>
        {leadType === 'buyer' ? (
          <a
            href="https://www.homelight.com/blog/buy"
            className={styles.option}
            target="_blank"
            rel="noopener noreferrer"
          >
            Buyer Resource Center
          </a>
        ) : (
          <a
            href="https://www.homelight.com/blog"
            className={styles.option}
            target="_blank"
            rel="noopener noreferrer"
          >
            Seller Resource Center
          </a>
        )}
        <Link to="/feedback" className={styles.option}>
          Give us feedback
        </Link>
        <a
          href="https://www.homelight.com/privacy-policy"
          className={styles.option}
          target="_blank"
          rel="noopener noreferrer"
        >
          Legal & Privacy
        </a>
        <Col span={12} className={styles.signOutContainer}>
          <Button as="link" className={styles.signOut} onClick={onSignOutClicked}>
            Sign out
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default AccountTab

AccountTab.propTypes = {
  active: PropTypes.bool.isRequired,
  toggleActive: PropTypes.func.isRequired
}
