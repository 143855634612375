import React, { useContext, useEffect, useState } from 'react'
import { PageResizeContext } from 'components/PageResize'
import PropTypes from 'prop-types'
import axios from 'axios'
import { VIMEO_OEMBED_URL } from 'constants/constants'

const VIMEO_URL = 'https://player.vimeo.com/video/'
const VIDEO_ASPECT_RATIO = 0.5625

export const AgentVideo = ({ parentElement, setVideoHeight, agentSlug }) => {
  const [agentVideo, setAgentVideo] = useState(null)

  const { width } = useContext(PageResizeContext)

  useEffect(() => {
    const calculateHeight = async () => {
      setVideoHeight(`${(parentElement.current.offsetWidth * VIDEO_ASPECT_RATIO).toString()}px`)
    }

    if (agentVideo && setVideoHeight && parentElement && parentElement.current.offsetWidth) {
      calculateHeight()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentVideo, width])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Maps to this example: https://vimeo.com/homelight/agentSlug
        const videoUrl = `${process.env.REACT_APP_AGENT_INTRO_VIDEOS_CHANNEL}${agentSlug}`
        const result = await axios.get(`${VIMEO_OEMBED_URL}${videoUrl}`)

        if (result.status === 200) {
          setAgentVideo(VIMEO_URL + result.data.video_id)
        }
        // If result status is 200, set the video url
      } catch (error) {
        setAgentVideo(false)
      }
    }

    if (parentElement && setVideoHeight && agentSlug) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderIframe = () => {
    if (!agentVideo) {
      return null
    }

    return (
      <iframe
        title="Agent Video"
        data-testid="agent-video"
        style={{ height: '100%' }}
        src={`${agentVideo}?title=false&byline=false&portrait=false`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    )
  }

  return renderIframe()
}

AgentVideo.propTypes = {
  parentElement: PropTypes.node,
  setVideoHeight: PropTypes.func,
  agentSlug: PropTypes.string.isRequired
}

AgentVideo.defaultProps = {
  parentElement: null,
  setVideoHeight: null
}
