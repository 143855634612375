import { Factory } from 'miragejs'
import faker from 'faker'

export default Factory.extend({
  first_name() {
    return faker.name.firstName()
  },
  last_name() {
    return faker.name.lastName()
  },
  id() {
    return faker.datatype.number()
  }
})
