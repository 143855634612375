import React from 'react'
import { Row, Col } from 'react-simple-flex-grid'
import { Link, useLocation } from 'react-router-dom'

import { Graphic } from 'components/Graphic'

import UnreadMessageCountNotification from 'components/Messages/UnreadMessageCountNotification'

import { useScroll } from 'hooks/useScroll'
import { useBreakpoint } from 'hooks/useBreakpoint'

import style from './DashboardNavigation.module.scss'

export const DashboardNavigation = () => {
  const { pathname } = useLocation()
  const { scrollingDown } = useScroll()
  const { mobile } = useBreakpoint()

  if (pathname.match(/my-team/) || pathname.match(/feedback/) || pathname.match(/messages\/\d+/)) {
    return null
  }

  return (
    <div>
      <div className={style.placeholder} />
      <div
        className={`${style.navigation} ${scrollingDown || !mobile ? style.hidden : style.visible}`}
      >
        <Row className={style.row}>
          <Col span={4}>
            <Link to="/" className={pathname === '/' ? style.active : style.inactive}>
              {pathname === '/' ? (
                <Graphic.HouseSolid className={style.graphic} />
              ) : (
                <Graphic.House className={style.graphic} />
              )}
              <br />
              <p>Feed</p>
            </Link>
          </Col>

          <Col span={4}>
            <Link
              to="progress"
              className={pathname === '/progress' ? style.active : style.inactive}
            >
              {pathname === '/progress' ? (
                <Graphic.ProgressSolid className={style.graphic} />
              ) : (
                <Graphic.Progress className={style.graphic} />
              )}
              <br />
              <p>Progress</p>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default DashboardNavigation
