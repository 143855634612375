import React from 'react'
import { BlogArticleItem } from '../../../FeedItem'

export const RenderBlogPost = userFeedItem => {
  const { item, id, occurred_at: occurredAt, displayed_at: displayedAt } = userFeedItem

  // TODO: will need either global lead state or to add some "type"
  // identifier to change the description in the future
  return (
    <BlogArticleItem
      key={id}
      blogPost={item}
      description={item.description}
      occurredAt={occurredAt}
      id={id}
      displayedAt={displayedAt}
    />
  )
}
