import React from 'react'
import { AgentRecommendationFeedback } from '../../../FeedItem'

export const RenderAgentRecommendationFeedback = userFeedItem => {
  const {
    item,
    id,
    occurred_at: occurredAt,
    displayed_at: displayedAt,
    external_id: externalId
  } = userFeedItem

  return (
    <AgentRecommendationFeedback
      key={id}
      city={item.city}
      feedItem={userFeedItem}
      occurredAt={occurredAt}
      id={id}
      externalId={externalId}
      displayedAt={displayedAt}
    />
  )
}
