import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../Button'
import { LeadContext } from '../../context/LeadContext'
import { initializeGTM, trackEvent } from '../../utils/tracking'
import { postRequest } from '../../utils/makeRequest'
import { API_ENDPOINTS } from '../../constants/apiEndpoints'
import { useCalendlyPopup } from '../../hooks/useCalendlyPopup'

export const ScheduleButton = ({
  className,
  buttonText,
  yesAndUnhideFeedItems,
  createDuplicateCCTILead
}) => {
  const { leadId, leadType, hlcaQuizLead } = useContext(LeadContext)
  const { openCalendlyPopup } = useCalendlyPopup()

  const createDuplicateLead = async () => {
    const postData = {
      lead_id: leadId
    }
    await postRequest(`${API_ENDPOINTS.createCCTradeInLead}`, postData)
  }

  const onClick = () => {
    yesAndUnhideFeedItems()
    initializeGTM('DashboardDataLayer')
    openCalendlyPopup()

    trackEvent(
      'Dashboard Feed',
      'Tap',
      `${leadType}_${hlcaQuizLead ? 'hlca' : 'hlss'}_eligible_calendly`,
      0,
      'DashboardDataLayer'
    )

    if (hlcaQuizLead && createDuplicateCCTILead) createDuplicateLead()
  }

  return (
    <Button as="primary" onClick={onClick} className={className}>
      {buttonText}
    </Button>
  )
}

ScheduleButton.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  buttonText: PropTypes.string,
  yesAndUnhideFeedItems: PropTypes.func,
  createDuplicateCCTILead: PropTypes.bool
}

ScheduleButton.defaultProps = {
  className: '',
  url: '',
  buttonText: 'Schedule call',
  yesAndUnhideFeedItems: () => {},
  createDuplicateCCTILead: false
}
