import faker from 'faker'

export default (overrides = {}) => {
  const attributes = {
    blogPost: {
      title: `How to ${faker.hacker.verb()} in real estate`,
      text: `You’re about to face one of the largest financial transactions of your lifetime. You need to ${faker.hacker.verb()}.`,
      url: faker.internet.url(),
      tag: null,
      hero_image: faker.random.image()
    }
  }

  return { ...attributes, ...overrides }
}
