import React from 'react'
import PropTypes from 'prop-types'
import { usePageResize } from 'components/PageResize'
import { CallButton } from 'components/Button/CallButton'
import { FeedItem } from './FeedItem'
import { Graphic } from '../Graphic'
import styles from './WelcomeItem.module.scss'
import { isMobile } from '../../utils/breakpoint'
import { listBuilder } from '../../utils/listBuilder/listBuilder'

export const WelcomeItem = ({
  name,
  propertyType,
  address,
  city,
  additionalAreas,
  transactionType,
  occurredAt,
  id,
  displayedAt,
  type
}) => {
  const formattedPropertyType = propertyType.split('_').join(' ')
  const formattedAddressPhrase = address ? ` at ` : ''
  const articleOrPossessiveAdjective = transactionType === 'buy' ? 'a' : 'your'
  const { width } = usePageResize()
  const greeting = isMobile(width) ? <h3 className={styles.nameTitle}>Hi {name}! </h3> : null
  const citiesSelected = [
    city,
    ...additionalAreas.filter(area => area.type.toLowerCase() === 'city').map(area => area.name)
  ]
  const areasSelected = listBuilder(citiesSelected)

  return (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      <div>
        {greeting}
        <div className={styles.welcomeText}>
          Welcome to HomeLight! <Graphic.LaughingSmiley />
        </div>
      </div>
      <br />
      {type === 'simple_home_value_concierge' ? (
        <div className={styles.bodyText}>
          Unfortunately, we are currently unable to provide an estimate of your home value
          {formattedAddressPhrase}
          <b>{address}</b> in <b>{city}</b>.
          <div>
            <br />
            <div>
              Sometimes this happens because we don&apos;t have enough transactions in your area, or
              our database tells us we need to ask a few more questions about your property.
            </div>
            <br />
            <div>
              We&apos;ll be calling you shortly, but if you miss our call, give one of our Home
              Consultants a call. First, they&apos;ll ask a few questions to make sure we can
              provide an estimate on your home&apos;s value. If you&apos;re interested in selling
              your home, they&apos;ll also walk you through all of your options and help you decide
              which one is best for you.
            </div>
            <br />
            <div>You came here to get an estimate on your home and we&apos;re here to help.</div>
          </div>
          <CallButton feedItemType="welcome_item" buttonText="Call us" />
        </div>
      ) : (
        <div className={styles.bodyText}>
          We&apos;re excited to help you <b>{transactionType}</b>{' '}
          {`${articleOrPossessiveAdjective} `}
          <b>{formattedPropertyType}</b>
          {formattedAddressPhrase}
          <b>{address}</b> in <b>{areasSelected}</b>.
        </div>
      )}
    </FeedItem>
  )
}

WelcomeItem.propTypes = {
  name: PropTypes.string.isRequired,
  propertyType: PropTypes.string.isRequired,
  address: PropTypes.string,
  city: PropTypes.string.isRequired,
  additionalAreas: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, type: PropTypes.string })
  ),
  transactionType: PropTypes.string,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string,
  type: PropTypes.string
}

WelcomeItem.defaultProps = {
  transactionType: 'sell',
  displayedAt: null,
  address: '',
  additionalAreas: [],
  type: null
}
