import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { CallButton } from 'components/Button/CallButton'
import { TextUsButton } from 'components/Button/TextUsButton'
import { FeedItem } from 'components/FeedItem'
import { PageResizeContext } from 'components/PageResize'
import { DisplayNumberButton } from 'components/Button/DisplayNumberButton'
import { LeadContext } from 'context/LeadContext'
import { CardHeaderText } from './CardHeaderText'
import { isMobile } from '../../utils/breakpoint'
import { MessageHomelightButton } from '../common/MessageHomelightButton'

export const CallOrTextUsCard = ({ answerChoices, text, occurredAt, id, displayedAt }) => {
  const { width } = useContext(PageResizeContext)
  const { salesPhoneNumber, salesTextingPhoneNumber } = React.useContext(LeadContext)
  const answerChoiceSection = answerChoices.map(answer => {
    const type = Object.keys(answer)[0]
    if (isMobile(width)) {
      if (type === 'text') {
        return (
          <TextUsButton
            key={`${type}-${id}`}
            buttonText={answer[type]}
            phoneNumber={salesTextingPhoneNumber}
            feedItemType="best_way_to_connect"
          />
        )
      }
      return (
        <CallButton
          key={`${type}-${id}`}
          buttonText={answer[type]}
          phoneNumber={salesPhoneNumber}
          feedItemType="best_way_to_connect"
        />
      )
    }
    if (type === 'call') {
      return (
        <div key={`${type}-${id}`}>
          <DisplayNumberButton
            key={`${type}-${id}`}
            text="Call us"
            phone={salesPhoneNumber}
            feedItemType="best_way_to_connect"
          />
        </div>
      )
    }

    return ''
  })
  return (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      <CardHeaderText text={text} />
      <div>{answerChoiceSection}</div>
    </FeedItem>
  )
}

CallOrTextUsCard.propTypes = {
  text: PropTypes.string,
  answerChoices: PropTypes.arrayOf(PropTypes.object),
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

CallOrTextUsCard.defaultProps = {
  text: '',
  answerChoices: null,
  displayedAt: null
}
