import React from 'react'
import PropTypes from 'prop-types'
import { FeedItem, GenericCallToActionOptions } from 'components/FeedItem'
import { PageContext } from 'context/PageContext'
import { CallOrTextUsCard } from './CallOrTextUsCard'
import { relativeDateParser } from '../../utils/relativeDateParser'
import { InvestorIntroOption } from './InvestorIntroOption'
import { SoftAgentIntroOption } from './SoftAgentIntroOption'
import { UpdatedHomeValueEstimateSellingOptions } from './UpdatedHomeValueEstimateSellingOptions'

export const MultipleChoiceCard = ({
  answerChoices,
  userFeedItem,
  promptType,
  selectedAnswer,
  agentName,
  occurredAt,
  id,
  displayedAt,
  refreshData
}) => {
  let cardText
  const { feed } = React.useContext(PageContext)
  const firstName = agentName && agentName.split(' ')[0]
  switch (promptType) {
    case 'best_way_to_connect':
      if (
        userFeedItem &&
        userFeedItem.item.source &&
        userFeedItem.item.source === 'updated_home_value_estimate'
      ) {
        return (
          <UpdatedHomeValueEstimateSellingOptions
            id={id}
            displayedAt={displayedAt}
            occurredAt={occurredAt}
            feedItem={userFeedItem}
          />
        )
      }
      cardText =
        "Next steps: We'll give you a call today to talk through your options and answer any questions you may have.  If you'd like to talk before then, please give us a call or send us a text."

      return (
        <CallOrTextUsCard
          answerChoices={answerChoices}
          text={cardText}
          occurredAt={occurredAt}
          id={id}
          key={id}
          displayedAt={displayedAt}
        />
      )
    case 'investor_intro_option':
      cardText = `Great news! We found an investor in your area that's willing to pay cash for your home! Is that an option you'd be willing to explore?`
      return (
        <InvestorIntroOption
          userFeedItem={userFeedItem}
          selectedAnswer={selectedAnswer}
          answerChoices={answerChoices}
          text={cardText}
          occurredAt={occurredAt}
          id={id}
          displayedAt={displayedAt}
          refreshData={refreshData}
        />
      )
    case 'soft_agent_intro_option':
      // Copy conversationally adapts based on whether this soft intro is a follow-up to an investor soft-intro card
      // eslint-disable-next-line no-case-declarations
      const isFollowUpToInvestorIntro =
        feed.data && !!feed.data.find(item => item.item.prompt_type === 'investor_intro_option')
      cardText = isFollowUpToInvestorIntro
        ? `No problem! We've also found the top performing agents in your area. Are you interested in seeing them?`
        : `Great news! We've found the top performing agents in your area. Are you interested in seeing them?`

      return (
        <SoftAgentIntroOption
          userFeedItem={userFeedItem}
          selectedAnswer={selectedAnswer}
          answerChoices={answerChoices}
          text={cardText}
          occurredAt={occurredAt}
          id={id}
          displayedAt={displayedAt}
          refreshData={refreshData}
        />
      )
    case 'agent_connected':
      // eslint-disable-next-line no-case-declarations
      const prettyDate = relativeDateParser(occurredAt, false)
      // "Monday" vs "2:00PM"
      // eslint-disable-next-line no-case-declarations
      const prettyDateString = prettyDate.match(/^\d/) ? `at ${prettyDate}` : prettyDate
      cardText = `You connected with real estate agent ${agentName} ${prettyDateString}. If you like ${firstName}, the next step is to schedule an in-person meeting with them.`
      break
    case 'best_way_to_connect_follow_up':
      cardText =
        'We’d love to connect you with top real estate agents like you requested, but we need a little more ' +
        'info to confirm they’re the right fit.\n What’s the best way for us to connect?'
      break
    case 'schedule_call_select_day':
      cardText = 'Great, what day works for you?'
      break
    case 'schedule_call_select_time':
      cardText = 'Sounds good, what time should we contact you?'
      break
    default:
      cardText = null
  }

  return (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      <GenericCallToActionOptions
        text={cardText}
        selectedAnswerKey={selectedAnswer}
        options={answerChoices}
      />
    </FeedItem>
  )
}

MultipleChoiceCard.propTypes = {
  userFeedItem: PropTypes.shape({
    item: PropTypes.shape({
      source: PropTypes.string
    })
  }),
  answerChoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  promptType: PropTypes.string.isRequired,
  selectedAnswer: PropTypes.string,
  agentName: PropTypes.string,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string,
  refreshData: PropTypes.func.isRequired
}

MultipleChoiceCard.defaultProps = {
  userFeedItem: null,
  selectedAnswer: null,
  agentName: '',
  displayedAt: null
}
