import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/Button'
import { cx } from '../../../utils/cx'

import styles from './MessageComposer.module.scss'

export const MessageComposer = ({ className, isSending, sendMessage }) => {
  const currentMessageRef = useRef(null)
  const send = () => {
    sendMessage(currentMessageRef.current.value)
  }

  return (
    <div className={cx(styles.messageComposer, className)}>
      {isSending ? (
        <div className={styles.sendingMessage}>Sending...</div>
      ) : (
        <>
          <textarea
            ref={currentMessageRef}
            className={styles.replyTextArea}
            placeholder="Write a message"
          />
          <Button as="button" type="button" className={styles.sendMessage} onClick={send}>
            Send
          </Button>
        </>
      )}
    </div>
  )
}

MessageComposer.propTypes = {
  className: PropTypes.string,
  isSending: PropTypes.bool,
  sendMessage: PropTypes.func
}

MessageComposer.defaultProps = {
  className: '',
  isSending: false,
  sendMessage: () => {}
}
