import React from 'react'
import PropTypes from 'prop-types'
import { AgentCard } from 'components/AgentCard'
import { FeedItem } from './FeedItem'
import styles from './FeedItem.module.scss'

export const DirectAgentMatchItem = ({
  directMatchType,
  agentMatch,
  city,
  occurredAt,
  id,
  externalId,
  displayedAt
}) => {
  let cardText
  switch (directMatchType) {
    case 'warm_transfer':
      cardText = `Here's the information for ${agentMatch.agent_name}, the agent you spoke with today.`
      break
    case 'hand_match':
      cardText = 'We found another great agent for you to interview.'
      break
    default:
      cardText = 'We found another great agent for you to interview.'
  }

  return (
    <FeedItem
      classname={styles.agentResultsFeedItem}
      occurredAt={occurredAt}
      id={id}
      displayedAt={displayedAt}
    >
      <p>{cardText}</p>
      <AgentCard
        key={agentMatch.agent_id}
        feedItemId={id}
        externalId={externalId}
        agentMatch={agentMatch}
        city={city}
        feedItemType="direct_agent_match"
      />
    </FeedItem>
  )
}

DirectAgentMatchItem.propTypes = {
  city: PropTypes.string.isRequired,
  occurredAt: PropTypes.string.isRequired,
  directMatchType: PropTypes.string.isRequired,
  agentMatch: PropTypes.shape({
    agent_id: PropTypes.number.isRequired,
    agent_name: PropTypes.string.isRequired,
    office_display_name: PropTypes.string.isRequired,
    elite_status: PropTypes.bool.isRequired,
    move_safe_certified: PropTypes.bool.isRequired,
    picture: PropTypes.string.isRequired,
    slug: PropTypes.string,
    responsiveness_narrative: PropTypes.string.isRequired,
    transactions_count: PropTypes.number.isRequired,
    area_transactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    deals_count: PropTypes.number.isRequired,
    badges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    contact_capabilities: PropTypes.shape({
      can_call: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  id: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

DirectAgentMatchItem.defaultProps = {
  displayedAt: null
}
