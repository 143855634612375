import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'components/Button'
import { CheckIcon } from 'components/Graphic'
import styles from './CallToAction.module.scss'

export const CallToAction = ({ selected, disabled, onClick, children }) => (
  <Button
    className={styles.callToAction}
    type="button"
    as="primary"
    disabled={disabled}
    onClick={() => onClick()}
  >
    {selected && <CheckIcon className={`${styles.checkIcon} ${styles.selected}`} />}
    {children}
  </Button>
)

CallToAction.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node
}

CallToAction.defaultProps = {
  children: null,
  selected: false,
  disabled: false,
  onClick: () => {}
}
