/* istanbul ignore file */
/**
 * Ignoring test coverage on this file due to its complexity and the ease of detection when something has broken,
 * since a broken method here would have very visible ripple effects across the app.
 */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Row, Col } from 'react-simple-flex-grid'
import 'normalize.css'
import '../scss/_animations.scss'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Page } from 'components/Page'
import { DashboardLogoHeader } from 'components/DashboardHeader'
import { DashboardNavigation } from 'components/DashboardNavigation'
import { DashboardDesktopNav } from 'components/DashboardDesktopNav/DashboardDesktopNav'
import { DashboardSidebar } from 'components/DashboardSidebar'
import { PageResizeContext } from 'components/PageResize'
import { Home } from '../pages/Home'
import { SimpleSaleResults } from '../pages/SimpleSale'
import { FullMessageView, FullMessageViewIsolated, MessagesTab } from '../components/Messages'
import { GoBackHeader } from '../components/PageHeader/GoBackHeader'
import { Checklist } from '../components/Checklist/Checklist'
import { isMobile, isDesktop } from '../utils/breakpoint'
import { MyTeam } from '../components/Account/MyTeam'
import { FeedbackForm } from '../components/Account'
import { LeadProvider } from '../context/LeadContext'
import { PageProvider } from '../context/PageContext'
import { ROUTE_MAP } from '../constants/pages'
import { fetchAndPublishUnreadMessagesCount } from '../components/Messages/services/fetchAndPublishUnreadMessagesCount'
import { IconGallery } from '../components/Icon/IconGallery'
import { PageViewTracker } from './PageViewTracker'
import { buildPageClasses, pageTransitionProps } from './helpers/AppRouterHelpers'
import { useOnClickOutside } from '../hooks/useOnClickOutside'
import { NewAgentMessageViewIsolated } from '../components/Messages/NewAgentMessageViewIsolated'
import { CallMeMaybeWrapper } from '../components/DashboardFeed/CallMeMaybeWrapper'

export const AppRouter = () => {
  const { width } = useContext(PageResizeContext)
  const [accountMenuOpen, setAccountMenuOpen] = useState(false)
  const showNewMenu = process.env.REACT_APP_SHOW_NEW_MENU === 'true'

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const messageCountUpdate = () => {
    // fetch unread messages count so that we can show it as notification next to Messages nav menu
    fetchAndPublishUnreadMessagesCount()
  }
  const ref = useRef()

  useOnClickOutside(ref, () => {
    setAccountMenuOpen(false)
  })

  useEffect(() => {
    messageCountUpdate()
  }, [])

  const wrapPage = (Component, pathname) => (
    <Page className={buildPageClasses(pathname, width)}>
      <Row justify="start">
        <Col span={12} xs={12} sm={12} md={12} lg={12}>
          <Component pathname={pathname} />
        </Col>
      </Row>
    </Page>
  )

  return (
    <LeadProvider>
      <BrowserRouter>
        <PageViewTracker>
          <Route
            render={({ location }) => {
              const { pathname, key } = location

              return (
                <PageProvider
                  currentPage={ROUTE_MAP[pathname]}
                  mobileMenuOpen={!isDesktop(width) && accountMenuOpen}
                  setMobileMenuOpen={setAccountMenuOpen}
                >
                  {isMobile(width) ? (
                    <div>
                      <DashboardNavigation />
                    </div>
                  ) : null}
                  {!isDesktop(width) ? (
                    <DashboardLogoHeader
                      showNewMenu={showNewMenu}
                      accountMenuActive={accountMenuOpen}
                      toggleAccountMenu={() => {
                        setAccountMenuOpen(!accountMenuOpen)
                      }}
                    />
                  ) : (
                    <div ref={ref}>
                      <DashboardDesktopNav
                        showNewMenu={showNewMenu}
                        accountMenuActive={accountMenuOpen}
                        toggleAccountMenu={() => {
                          setAccountMenuOpen(!accountMenuOpen)
                        }}
                      />
                    </div>
                  )}

                  {isDesktop(width) && !['/cmm', '/pv1', '/pv2'].includes(pathname) ? (
                    <DashboardSidebar />
                  ) : null}

                  <div style={{ width: '100%' }}>
                    <TransitionGroup>
                      <CSSTransition
                        key={key}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...pageTransitionProps(pathname, !isMobile(width))}
                      >
                        <Switch location={location}>
                          <Route exact path="/" render={() => wrapPage(Home, pathname)} />
                          {isDesktop(width) ? (
                            <Route path="/progress" render={() => wrapPage(Home, pathname)} />
                          ) : (
                            <Route path="/progress" render={() => wrapPage(Checklist, pathname)} />
                          )}
                          <Route
                            exact
                            path="/messages"
                            render={() => wrapPage(MessagesTab, pathname)}
                          />
                          {isMobile(width) ? (
                            <Route
                              exact
                              path="/messages/:id"
                              render={() => (
                                <Page className={buildPageClasses(pathname, width)}>
                                  <Row justify="start">
                                    <Col span={12} xs={12} sm={12} md={12} lg={12}>
                                      <FullMessageViewIsolated
                                        updateDeliveryCb={messageCountUpdate}
                                      />
                                    </Col>
                                  </Row>
                                </Page>
                              )}
                            />
                          ) : (
                            <Route
                              exact
                              path="/messages/:id"
                              render={() => (
                                <Page className={buildPageClasses(pathname, width)}>
                                  <Row justify="start">
                                    <Col span={12} xs={12} sm={12} md={12} lg={12}>
                                      <FullMessageView
                                        updateDeliveryCb={messageCountUpdate}
                                        header={<GoBackHeader goBackLink="/messages" />}
                                      />
                                    </Col>
                                  </Row>
                                </Page>
                              )}
                            />
                          )}
                          {isMobile(width) ? (
                            <Route
                              exact
                              path="/messages/new/:id"
                              render={() => (
                                <Page className={buildPageClasses(pathname, width)}>
                                  <Row justify="start">
                                    <Col span={12} xs={12} sm={12} md={12} lg={12}>
                                      <NewAgentMessageViewIsolated />
                                    </Col>
                                  </Row>
                                </Page>
                              )}
                            />
                          ) : (
                            <Route
                              exact
                              path="/messages/new/:id"
                              render={() => (
                                <Page className={buildPageClasses(pathname, width)}>
                                  <Row justify="start">
                                    <Col span={12} xs={12} sm={12} md={12} lg={12}>
                                      <NewAgentMessageViewIsolated />
                                    </Col>
                                  </Row>
                                </Page>
                              )}
                            />
                          )}
                          <Route path="/feedback" render={() => wrapPage(FeedbackForm, pathname)} />
                          <Route
                            path="/simple-sale-results"
                            render={() => wrapPage(SimpleSaleResults, pathname)}
                          />
                          <Route path="/my-team" render={() => wrapPage(MyTeam, pathname)} />
                          {process.env.NODE_ENV === 'development' && (
                            <Route path="/icons" render={() => wrapPage(IconGallery, pathname)} />
                          )}
                          <Route
                            path="/cmm"
                            render={() => wrapPage(CallMeMaybeWrapper, pathname)}
                          />
                          <Route
                            path="/pv1"
                            render={() => wrapPage(CallMeMaybeWrapper, pathname)}
                          />
                          <Route
                            path="/pv2"
                            render={() => wrapPage(CallMeMaybeWrapper, pathname)}
                          />
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                  </div>
                </PageProvider>
              )
            }}
          />
        </PageViewTracker>
      </BrowserRouter>
    </LeadProvider>
  )
}

export default AppRouter
