import React, { useCallback, useState } from 'react'
import { Graphic } from 'components/Graphic'
import { Row, Col } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'
import { AgentFaceInCircle } from 'components/AgentFaceInCircle'
import { EliteAgentLogo, ContactButtons } from 'components/AgentCard'
import { Button } from 'components/Button'
import { LeadContext } from 'context/LeadContext'
import { usePageResize } from 'components/PageResize'
import { AgentDetailsModal, ModalBadges } from '../AgentDetailsModal'
import { isMobile } from '../../utils/breakpoint'
import styles from './MyTeam.module.scss'
import { LocalAreaExpertBadge, YearsExperienceBadge, DealsBadge } from '../AgentBadge'
import { trackFeedItemGenericEvent } from '../../utils/tracking'

export const MyTeamCard = ({ myTeamAgent }) => {
  const agentOfficeName = myTeamAgent.office_display_name
  const eliteStatus = myTeamAgent.elite_status
  const moveSafeAgent = myTeamAgent.move_safe_certified
  const agentPhotoUrl = myTeamAgent.picture
  const agentPhoneNumber = myTeamAgent.phone_twilio
  const agentDisplayName = myTeamAgent.agent_name
  const callableAgent = myTeamAgent.contact_capabilities.can_call && myTeamAgent.phone_twilio

  const { width } = usePageResize()
  const [showContactButtons, setShowContactButtons] = useState(false)
  const [detailsModalActive, setDetailsModalActive] = useState(false)
  const { leadType } = React.useContext(LeadContext)
  const feedItemType = 'my_team_agent'

  const showModal = useCallback(() => {
    setDetailsModalActive(true)
    trackFeedItemGenericEvent(feedItemType, 'Tap', leadType, 'agent_details')
  }, [leadType])

  /* istanbul ignore next */
  const hideOpenContactbuttons = () => {
    if (showContactButtons) {
      setShowContactButtons(!showContactButtons)
    }
  }
  const toggleContactButtons = () => {
    if (callableAgent) {
      setShowContactButtons(!showContactButtons)
    }
  }

  const renderMobileContactButtons = () =>
    isMobile(width) ? (
      <ContactButtons
        show={showContactButtons}
        phoneNumber={agentPhoneNumber}
        feedItemType={feedItemType}
      />
    ) : (
      window.open(`tel:${agentPhoneNumber}`)
    )

  const enterFunction = useCallback(
    event => {
      // if the enter key is pressed, show modal
      if (event.keyCode === 13) {
        showModal()
      }
    },
    [showModal]
  )

  const localAreaExpertBadges = myTeamAgent.badges.filter(
    badge => badge.award_type === 'local_area_expert'
  )
  const renderLocalAreaExpertSection = (infoAsTooltips = true) =>
    localAreaExpertBadges.length && (
      <LocalAreaExpertBadge
        badge={localAreaExpertBadges[0]}
        agentName={agentDisplayName}
        infoAsTooltips={infoAsTooltips}
      />
    )

  const yearsExperienceBadges = myTeamAgent.badges.filter(
    badge => badge.award_type === 'years_experience'
  )
  const renderYearsExperienceSection = (infoAsTooltips = true) =>
    yearsExperienceBadges.length && (
      <YearsExperienceBadge
        badge={yearsExperienceBadges[0]}
        agentName={agentDisplayName}
        infoAsTooltips={infoAsTooltips}
      />
    )

  const renderDealsBadge = () =>
    myTeamAgent.deals_count &&
    myTeamAgent.deals_count > 0 && (
      <DealsBadge
        agentName={agentDisplayName}
        value={myTeamAgent.deals_count}
        infoAsTooltips={false}
      />
    )

  const allAchievementBadges = myTeamAgent.badges.filter(
    badge =>
      badge.award_type !== 'local_area_expert' &&
      badge.award_type !== 'years_experience' &&
      badge.award_type !== 'homes_sold_in_city'
  )

  const map = new Map()

  allAchievementBadges.map(badge => {
    if (!map.has(badge.value)) {
      map.set(badge.value, true)
    }
    return badge
  })

  const renderMoveSafeBadge = () => (eliteStatus ? <Graphic.MoveSafeElite /> : <Graphic.MoveSafe />)

  const modalBadges = (
    <ModalBadges
      agentMatch={myTeamAgent}
      agentDisplayName={agentDisplayName}
      yearsBadge={renderYearsExperienceSection(false)}
      localAreaExpertBadge={renderLocalAreaExpertSection(false)}
      dealsBadge={renderDealsBadge()}
      achievementBadges={allAchievementBadges}
    />
  )

  const renderDetailsModal = () => (
    <AgentDetailsModal
      active={detailsModalActive}
      close={() => setDetailsModalActive(false)}
      agentMatch={myTeamAgent}
      agentDisplayName={agentDisplayName}
      modalBadges={modalBadges}
    />
  )

  return (
    <Row onClick={hideOpenContactbuttons}>
      <div
        className={`${styles.agentTeamCardContainer} ${
          eliteStatus ? styles.eliteAgentTeamCardContainer : ''
        }`}
      >
        <Col span={12}>
          <div>{eliteStatus ? <EliteAgentLogo width={110} height={20} /> : null}</div>
        </Col>
        <Col span={12}>
          <div
            className={styles.agentDetails}
            onClick={showModal}
            onKeyDown={enterFunction}
            tabIndex="0"
            role="button"
          >
            <Col span={2}>
              <div className={styles.agentPhoto}>
                <AgentFaceInCircle photoUrl={agentPhotoUrl} name={agentDisplayName} />
                <div className={styles.moveSafeBadge}>
                  {moveSafeAgent && renderMoveSafeBadge({ eliteStatus })}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.agentName}>
                <h2>{agentDisplayName}</h2>
                <p>{agentOfficeName}</p>
              </div>
            </Col>
            <Col span={2}>
              <Graphic.ChevronDown className={styles.chevronDown} />
            </Col>
          </div>
        </Col>
        <Col span={12}>
          {callableAgent ? (
            <Button
              as="primary"
              style={{ width: '100%' }}
              onClick={() => {
                toggleContactButtons()
                trackFeedItemGenericEvent(feedItemType, 'Tap', leadType, 'contact_agent')
              }}
            >
              Contact {agentDisplayName}
            </Button>
          ) : null}
        </Col>
      </div>
      <Col span={12}>
        {showContactButtons ? renderMobileContactButtons({ agentPhoneNumber }) : null}
      </Col>
      {renderDetailsModal()}
    </Row>
  )
}

export default MyTeamCard

MyTeamCard.propTypes = {
  myTeamAgent: PropTypes.shape({
    agent_id: PropTypes.number.isRequired,
    agent_name: PropTypes.string.isRequired,
    phone_twilio: PropTypes.string.isRequired,
    office_display_name: PropTypes.string.isRequired,
    elite_status: PropTypes.bool.isRequired,
    move_safe_certified: PropTypes.bool.isRequired,
    picture: PropTypes.string.isRequired,
    slug: PropTypes.string,
    badges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    deals_count: PropTypes.number.isRequired,
    bio: PropTypes.string,
    area_transactions: PropTypes.arrayOf(
      PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number
      })
    ).isRequired,
    contact_capabilities: PropTypes.shape({
      can_call: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired
}
