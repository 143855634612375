import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import mapboxgl from 'mapbox-gl'
import { uniqBy } from 'lodash'
import { LeadContext } from 'context/LeadContext'
import { createGeoJSON, addClusters } from './interactiveMapHelper'
import { transactionGeometryStats, getExplicitMapboxZoom } from '../../utils/geometry'
import '../../scss/_mapbox-interactive.scss'

const MAP_STYLE = 'mapbox://styles/homelight/cjqsm3msvakki2sp7j1ugl9gy'

// Same zoom level that looks appropriate on mobile can look way too zoomed out on desktop because of dimensions/ratio
export const getZoomForMapRatio = zoom => {
  const desktopMin = 10.25
  const breakpoint = 767
  return window.innerWidth > breakpoint && zoom < desktopMin ? desktopMin : zoom
}

export const InteractiveTransactionMap = ({ transactions, modalActive, setMapLoaded }) => {
  const [map, setMap] = useState(null)
  const mapParent = useRef(null)
  const { latitude, longitude } = useContext(LeadContext)

  useEffect(() => {
    if (map && modalActive) {
      // Mapbox hard codes map dimensions by container. We want to load while hidden and get real dimensions on opening.
      map.resize()
    }
  }, [map, modalActive])

  const normalizeTransactions = () =>
    uniqBy(transactions, tx => `${tx.lng}${tx.lat}`).filter(tx => tx.lng && tx.lat)

  const setInitialBounds = (newMap, validTransactions) => {
    if (!!latitude && !!longitude) {
      // set center and zoom explicitly by lead geolocation
      const leadGeoPoint = { lat: latitude, lng: longitude }
      newMap.setZoom(getZoomForMapRatio(getExplicitMapboxZoom(leadGeoPoint, validTransactions)))
      newMap.setPadding(20)
      newMap.setCenter([longitude, latitude])
    } else {
      // create a bounding box and let map set center/zoom based on transactions
      const bounds = new mapboxgl.LngLatBounds()
      const { adjustedRangeLat, adjustedRangeLng } = transactionGeometryStats(validTransactions)

      validTransactions.forEach(tx => {
        const bounded =
          adjustedRangeLng[0] <= tx.lng &&
          tx.lng <= adjustedRangeLng[1] &&
          adjustedRangeLat[0] <= tx.lat &&
          tx.lat <= adjustedRangeLat[1]

        if (bounded) {
          bounds.extend([tx.lng, tx.lat])
        }
      })

      // Ensure bounds has _ne and _sw values, otherwise `fitBounds` will throw an exception
      if (Object.entries(bounds).length === 0) {
        bounds.extend([adjustedRangeLng[1], adjustedRangeLat[1]])
      }

      newMap.on('load', () => {
        newMap.fitBounds(bounds, { padding: 20, linear: true })
      })
    }
  }

  const mountMapBoxElement = () => {
    const newMap = new mapboxgl.Map({
      container: mapParent.current,
      style: MAP_STYLE
    })

    const validTransactions = normalizeTransactions()
    const geoJSON = createGeoJSON(validTransactions)

    newMap.scrollZoom.disable()
    setInitialBounds(newMap, validTransactions)

    newMap.addControl(new mapboxgl.NavigationControl({ showCompass: false }))
    addClusters(newMap, geoJSON)
    setMap(newMap)
  }

  useEffect(() => {
    if (transactions.length) {
      mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_GL_TOKEN
      mountMapBoxElement()
      setMapLoaded()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions])

  return <div ref={mapParent} style={{ height: '100%' }} />
}

InteractiveTransactionMap.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setMapLoaded: PropTypes.func.isRequired,
  modalActive: PropTypes.bool.isRequired
}
