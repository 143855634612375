import { Factory } from 'miragejs'
import faker from 'faker'

export default Factory.extend({
  is_sender: faker.datatype.boolean(),
  photo_url: faker.image.avatar(),
  slug: faker.lorem.slug(),
  user: {
    id: faker.datatype.number()
  }
})
