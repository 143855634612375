import faker from 'faker'

const SS_ITEM_TYPES = [
  // 'seller_flow_option',
  // 'sell_with_agent',
  // 'sell_to_investor',
  // 'agent_only_option',
  // 'investor_only_option',
  'hlss_eligible'
]

export default (overrides = {}) => {
  const attributes = {
    ss_item_type: faker.random.arrayElement(SS_ITEM_TYPES),
    home_estimate_val: faker.datatype.number({ min: 100000, max: 2000000 }),
    profit_from_agent_low: faker.datatype.number({ min: 1000, max: 10000 }),
    profit_from_agent_high: faker.datatype.number({ min: 10000, max: 30000 }),
    sell_to_investor_low: faker.datatype.number({ min: 100000, max: 2000000 }),
    sell_to_investor_high: faker.datatype.number({ min: 100000, max: 2000000 }),
    sell_with_opendoor_high: faker.datatype.number({ min: 100000, max: 2000000 }),
    sell_with_opendoor_low: faker.datatype.number({ min: 100000, max: 2000000 }),
    opendoor_home_estimate_val: faker.datatype.number({ min: 100000, max: 2000000 }),
    use_opendoor: faker.datatype.boolean()
  }

  return { ...attributes, ...overrides }
}
