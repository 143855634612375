// Static non-faker data (well, faker generated it once) for use in tests, so snapshots stay consistent

export default (overrides = {}) => {
  const attributes = {
    slug: 'repudiandae-quia-magni',
    badges: [
      { value: 49, award_type: 'years_experience' },
      {
        value: 537,
        area_name: 'South Joelle',
        award_type: 'local_area_expert'
      },
      {
        value: 'Auto Loan Account Sports',
        award_type: 'property_type_expert'
      },
      { value: 'Health', award_type: 'title_escrow' },
      { value: 'Colorado', award_type: 'luxury_specialist' },
      { value: 'Guernsey Alabama', award_type: 'luxury_specialist' }
    ],
    picture: 'https://s3.amazonaws.com/uifaces/faces/twitter/kianoshp/128.jpg',
    agent_id: 739547,
    agent_name: 'Marilyne Barrows',
    phone_twilio: '1-314-768-4480',
    elite_status: true,
    agent_lead_id: 889085,
    years_experience: 42,
    move_safe_certified: true,
    office_display_name: 'Antoinette',
    contact_capabilities: { can_call: true },
    transactions_count: 113,
    introduction_requested: true,
    area_transactions: [
      { latitude: 44, longitude: -116 },
      { latitude: 32, longitude: -112 }
    ],
    responsiveness_narrative: 'Responds quickly',
    deals_count: 106,
    bio: 'Id dolorem et vel excepturi at aut nesciunt ea non. Soluta ut aut repellendus dolores debitis aut. Debitis quas libero at dolorum laboriosam delectus.'
  }

  return { ...attributes, ...overrides }
}
