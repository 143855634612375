import React from 'react'
import PropTypes from 'prop-types'

export const EliteAgentLogo = ({ width, height }) => (
  <svg viewBox="0 0 502 72" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    <g fill="#FFF">
      <path d="M424.326 14.86l32.928 34.495V16.652h4.703V60.78L429.03 26.34v32.537h-4.703V14.86zm-71.48 1.063c7.393 0 13.385 3.305 17.863 9.13l-3.415 3.191c-3.528-4.985-8.288-7.952-14.505-7.952-9.63 0-17.247 8.12-17.247 17.64 0 4.48 1.792 8.736 4.928 11.927 3.08 3.08 7.56 5.377 11.984 5.377 7.335 0 15.064-5.377 15.064-13.217h-12.544v-4.367h17.584c.112 5.712-.504 10.416-4.368 14.896-3.863 4.48-9.744 7.055-15.623 7.055-11.873 0-21.73-9.855-21.73-21.727 0-12.096 9.857-21.953 22.009-21.953zm-235.486.73v4.366h-17.192v12.545h16.688v4.367h-16.688v16.576h17.192v4.37H95.464V16.651h21.896zm133.167 0v4.366h-17.192v12.545h16.689v4.367h-16.69v16.576h17.193v4.37H228.63V16.651h21.896zm54.264-2.017l18.984 44.24h-5.208l-4.985-11.984H295.55l-5.096 11.984h-5.152l19.488-44.24zm103.166 2.016v4.367h-17.192v12.545h16.689v4.367h-16.689v16.576h17.192v4.37h-21.895V16.651h21.895zm-266.686 0v37.856h11.648v4.368h-16.35V16.653h4.702zm32.313 0v42.224h-4.704V16.652h4.704zm41.272 0v4.368h-10.304v37.856h-4.704V21.02h-10.192v-4.368h25.2zm286.597 0v4.368H491.15v37.856h-4.704V21.02h-10.192v-4.368h25.2zm-196.774 8.96l-7.224 16.911h14.28l-7.056-16.91zM36.02 26.732l32.861 23.923C63.267 63.23 50.662 72 36.001 72 21.33 72 8.72 63.222 3.11 50.637l32.91-23.905zm8.485 27.018h-6.95v7.995h6.95V53.75zm-10.04 0h-6.951v7.995h6.95V53.75zm10.04-11.085h-6.95v7.995h6.95v-7.995zm-10.04 0h-6.951v7.995h6.95v-7.995z" />
      <path d="M71.195 43.573C71.717 41.131 72 38.598 72 36 72 16.117 55.882 0 36 0S0 16.117 0 36c0 2.593.28 5.118.8 7.555l35.222-25.588 35.173 25.606z" />
    </g>
  </svg>
)

EliteAgentLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

EliteAgentLogo.defaultProps = {
  width: null,
  height: null
}
