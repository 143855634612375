import React from 'react'
import PropTypes from 'prop-types'
import { FeedItem, WhyAgentsModal } from 'components/FeedItem'
import { AgentCard } from 'components/AgentCard'
import { LeadContext } from 'context/LeadContext'
import { numberWithCommas } from '../../utils/numberParser'
import styles from './FeedItem.module.scss'
import { listBuilder } from '../../utils/listBuilder'

function getDescription(leadType, prettyNumAgentsInArea, areasSelected) {
  if (leadType === 'buyer') {
    return (
      <div>
        <p>
          We&apos;ve analyzed all{prettyNumAgentsInArea}agents who&apos;ve successfully transacted
          in {areasSelected} in the past 3 years. Based on our data, here are the three we think you
          should interview. <WhyAgentsModal />
        </p>
      </div>
    )
  }
  return (
    <div>
      <p>We&apos;ve found three great agents for you to interview.</p>
      <br />
      <p>
        Why these agents? We&apos;ve evaluated all {prettyNumAgentsInArea} agents who have done
        transactions in {areasSelected} to find the best matches for your specific needs.&nbsp;
        <WhyAgentsModal />
      </p>
    </div>
  )
}

export const AgentRecommendations = ({
  agentMatches,
  agentsInAreaCount,
  city,
  additionalAreas,
  occurredAt,
  id,
  externalId,
  displayedAt
}) => {
  const citiesSelected = [city, ...additionalAreas.map(area => area.name)]
  const areasSelected = listBuilder(citiesSelected, 'and')
  const { leadType } = React.useContext(LeadContext)

  const prettyNumAgentsInArea =
    agentsInAreaCount && agentsInAreaCount > 0 ? ` ${numberWithCommas(agentsInAreaCount)} ` : ''
  const description = getDescription(leadType, prettyNumAgentsInArea, areasSelected)
  // elite on top
  const orderedAgentMatches = [...agentMatches].sort((a, b) =>
    a.elite_status && !b.elite_status ? -1 : 1
  )

  return (
    <FeedItem
      key="agent-matches"
      classname={styles.agentResultsFeedItem}
      occurredAt={occurredAt}
      id={id}
      displayedAt={displayedAt}
    >
      {description}
      {orderedAgentMatches.map(agentMatch => (
        <AgentCard
          key={agentMatch.agent_id}
          feedItemId={id}
          externalId={externalId}
          agentMatch={agentMatch}
          city={city}
          feedItemType="agent_recommendation"
        />
      ))}
    </FeedItem>
  )
}

AgentRecommendations.propTypes = {
  city: PropTypes.string.isRequired,
  additionalAreas: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, type: PropTypes.string })
  ),
  occurredAt: PropTypes.string.isRequired,
  agentsInAreaCount: PropTypes.number,
  agentMatches: PropTypes.arrayOf(
    PropTypes.shape({
      agent_id: PropTypes.number,
      agent_name: PropTypes.string,
      office_display_name: PropTypes.string,
      elite_status: PropTypes.bool,
      move_safe_certified: PropTypes.bool,
      picture: PropTypes.string,
      responsiveness_narrative: PropTypes.string.isRequired,
      transactions_count: PropTypes.number.isRequired,
      area_transactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      slug: PropTypes.string,
      badges: PropTypes.arrayOf(PropTypes.shape({})),
      contact_capabilities: PropTypes.shape({
        can_call: PropTypes.bool
      })
    })
  ).isRequired,
  id: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

AgentRecommendations.defaultProps = {
  displayedAt: null,
  agentsInAreaCount: 0,
  additionalAreas: []
}
