import React from 'react'
import { Row, Col } from 'react-simple-flex-grid'
import { PopOver } from 'components/PopOver'
import { Button } from 'components/Button'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import moment from 'moment'
import Raven from 'raven-js'
import styles from './ContactButtons.module.scss'
import { trackFeedItemGenericEvent } from '../../utils/tracking'
import { postHlRequest } from '../../utils/makeRequest'
import { trackSessionEvent } from '../../utils/feedItemClient'

export const ContactButtons = ({ feedItemType, agentId, agentLeadId, phoneNumber, show }) => {
  const { leadType, leadId } = React.useContext(LeadContext)
  const getIntroducedUrl = '/quiz_introduction_client_dashboard'
  const onTextButtonTap = async () => {
    try {
      trackFeedItemGenericEvent(feedItemType, 'Tap', leadType, 'contact_text')
      if (agentLeadId) {
        trackSessionEvent(
          leadId,
          'Client',
          'ClientTapAgentSMSButton',
          `agent_lead_id_${agentLeadId}`
        )
      }
      if (agentId) {
        const postData = {
          lead_id: leadId,
          agent_id: agentId,
          message: `Client requested intro on ${moment().format('MM/DD')} via sms`
        }
        await postHlRequest(getIntroducedUrl, postData)
      }
    } catch (error) {
      Raven.captureException(error)
    }
  }
  const onCallButtonTap = async () => {
    try {
      trackFeedItemGenericEvent(feedItemType, 'Tap', leadType, 'contact_call')
      if (agentLeadId) {
        trackSessionEvent(
          leadId,
          'Client',
          'ClientTapAgentCallButton',
          `agent_lead_id_${agentLeadId}`
        )
      }
      if (agentId) {
        const postData = {
          lead_id: leadId,
          agent_id: agentId,
          message: `Client requested intro on ${moment().format('MM/DD')} via phone call`
        }
        await postHlRequest(getIntroducedUrl, postData)
      }
    } catch (error) {
      Raven.captureException(error)
    }
  }
  return (
    <PopOver show={show}>
      <Row className={styles.contactButtons} align="middle" justify="space-between">
        <Col className={styles.buttonContainer} span={6}>
          <a href={`tel:${phoneNumber}`}>
            <Button as="primary" onClick={onCallButtonTap}>
              Call
            </Button>
          </a>
        </Col>
        <Col className={styles.buttonContainer} span={6}>
          <a href={`sms:${phoneNumber}`}>
            <Button as="primary" onClick={onTextButtonTap}>
              Text
            </Button>
          </a>
        </Col>
      </Row>
    </PopOver>
  )
}

ContactButtons.propTypes = {
  feedItemType: PropTypes.string.isRequired,
  agentId: PropTypes.number,
  agentLeadId: PropTypes.number,
  phoneNumber: PropTypes.string.isRequired,
  show: PropTypes.bool
}

ContactButtons.defaultProps = {
  show: false,
  agentId: null,
  agentLeadId: null
}

export default ContactButtons
