import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { PageResizeContext } from 'components/PageResize'
import styles from './AgentCard.module.scss'
import { filterByProximity } from '../../utils/geometry'
import { isLargerViewport } from '../../utils/breakpoint'
import { roundToDecimal } from '../../utils/numberParser'

const BASE_URI = 'https://api.mapbox.com/styles/v1/homelight'
const ACCESS_TOKEN =
  'pk.eyJ1IjoiaG9tZWxpZ2h0IiwiYSI6ImNqNmZka24yNDJtd3QzMm8xYm1hbWFhcnIifQ.BT1EY0MP0qndOTsWsvv8FA'
const MAP_STYLE = 'cjqsm3msvakki2sp7j1ugl9gy'
const MAP_DOT_PATH = 'https://hl-production-assets.s3.amazonaws.com/maps/dot.png'
const ENCODED_DOT_PATH = MAP_DOT_PATH.replace(/:/g, '%3A').replace(/\//g, '%2F')
// If we try much more than this max, the url gets too big and mapbox returns a 413 Request entity too large
const MAX_TRANSACTIONS = 65
const STATIC_MAP_DIMENSIONS = {
  mobile: { width: 340, height: 300 },
  desktop: { width: 280, height: 440 }
}

const markerToMapboxString = marker =>
  marker.type === 'client'
    ? `pin-s+46b6ff(${marker.lon},${marker.lat})`
    : `url-${ENCODED_DOT_PATH}(${marker.lon},${marker.lat})`

export const AgentTransactionMap = ({
  agentDisplayName,
  clientType,
  transactions,
  clientLocation,
  areaName
}) => {
  const { width } = useContext(PageResizeContext)
  const mapDimensions = isLargerViewport(width)
    ? STATIC_MAP_DIMENSIONS.desktop
    : STATIC_MAP_DIMENSIONS.mobile

  const transactionAction = clientType === 'buyer' ? 'closed' : 'sold'
  const markers = filterByProximity(transactions, clientLocation)
    .slice(0, MAX_TRANSACTIONS)
    .map(coords => ({
      lon: roundToDecimal(coords.longitude, 4),
      lat: roundToDecimal(coords.latitude, 4),
      type: 'transaction'
    }))

  if (clientLocation && clientLocation.latitude && clientLocation.longitude) {
    markers.push({ lat: clientLocation.latitude, lon: clientLocation.longitude, type: 'client' })
  }
  const markerString = markers.map(markerToMapboxString).join(',')
  const zoom = 'auto'

  const mapUrl = `${BASE_URI}/${MAP_STYLE}/static/${markerString}/${zoom}/${mapDimensions.width}x${mapDimensions.height}@2x/?access_token=${ACCESS_TOKEN}&attribution=false&logo=false`

  return (
    <div className={styles.transactionMap}>
      <div className={styles.mapKey}>
        <img
          src={MAP_DOT_PATH}
          className={styles.mapKeyDot}
          alt={`Each dot represents a transaction closed by ${agentDisplayName}`}
        />
        <div className={styles.mapKeyDescription}>
          {`Homes ${agentDisplayName} has ${transactionAction} ${
            clientType === 'seller' ? 'near you' : `in ${areaName}`
          }`}
        </div>
      </div>
      <div style={{ backgroundImage: `url('${mapUrl}')` }} className={styles.mapCanvas} />
    </div>
  )
}

AgentTransactionMap.propTypes = {
  agentDisplayName: PropTypes.string.isRequired,
  clientType: PropTypes.string,
  areaName: PropTypes.string,
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number
    })
  ).isRequired,
  clientLocation: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string
  })
}

AgentTransactionMap.defaultProps = {
  clientType: 'seller',
  areaName: 'your area',
  clientLocation: null
}
