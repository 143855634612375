import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-simple-flex-grid'
import Jsona from 'jsona'
import PropTypes from 'prop-types'
import { TransitionGroup } from 'react-transition-group'
import Raven from 'raven-js'
import { ExpandTransition } from 'components/common/ExpandTransition'
import { MessageItem, MessageItemCurrentUser } from 'components/Messages'
import { getRequest, postRequest } from '../../../utils/makeRequest'
import { LoadingSpinner } from '../../Loading/LoadingSpinner'
import { scrollToFirstUnreadMessage } from '../../../utils/messageHelpers'
import { trackMessagesEvent } from '../../../utils/tracking'
import { GoBackHeader } from '../../PageHeader/GoBackHeader'
import { LeadContext } from '../../../context/LeadContext'
import { Toast } from '../../common/Toast'
import { MessageComposer } from '../MessageComposer'
import { API_ENDPOINTS } from '../../../constants/apiEndpoints'

import { pageLoadingIndicator } from '../../../pages/Home/Home.module.scss'
import styles from './FullMessageViewIsolated.module.scss'

export const FullMessageViewIsolated = ({ updateDeliveryCb }) => {
  const { id } = useParams()
  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSending, setIsSending] = useState(false)
  const [recipient, setRecipient] = useState('HomeLight')
  const [recipientFormattedName, setRecipientFormattedName] = useState('HomeLight')
  const { userId } = React.useContext(LeadContext)

  // eslint-disable-next-line no-unused-vars
  const messageThreadApiUrl = `/api/messaging-service/messages/message-thread?include=message_participants,message_participants.user&filter[message_id]=${id}`
  // eslint-disable-next-line no-unused-vars
  const [isError, setIsError] = useState(false)
  const endOfMessagesRef = useRef(null)
  const [isFirstPageLoad, setisFirstPageLoad] = useState(true)

  // show intro notification once if client has sent an initial message
  const showIntroToast =
    messages && messages.length === 1 && messages[0].sent_by_current_user && isFirstPageLoad

  useEffect(() => {
    // moving this here to avoid react-hooks/exhaustive-deps warning
    const updateDeliveryByParent = () => {
      const postData = {
        data: {
          parent_message_id: id
        }
      }

      postRequest(API_ENDPOINTS.updateMessageRead, postData)
        .then(() => updateDeliveryCb(true))
        .catch(error => {
          Raven.captureException(error)
        })
    }

    const fetchData = async () => {
      try {
        const result = await getRequest(messageThreadApiUrl)
        const dataFormatter = new Jsona()
        const data = dataFormatter.deserialize(result.data)
        setMessages(data)
        setIsLoading(false)
        updateDeliveryByParent()
        scrollToFirstUnreadMessage(data, isFirstPageLoad)
        setisFirstPageLoad(false)
        // check our first message back and find the non-sender to figure out the recipient
        const recipientList = data[0].message_participants.filter(
          participant => participant.user.id !== userId.toString()
        )

        let formattedDisplayName = 'HomeLight'
        let displayName = 'HomeLight'

        if (
          recipientList.length &&
          recipientList[0].user &&
          recipientList[0].user.first_name !== 'HomeLight' && // don't show a last initial if first name is HomeLight
          recipientList[0].user.last_name
        ) {
          formattedDisplayName = `${recipientList[0].user.first_name} ${recipientList[0].user.last_name[0]}.`
          displayName = recipientList[0].user.first_name
        }

        setRecipientFormattedName(formattedDisplayName)
        setRecipient(displayName)
      } catch (error) {
        setIsError(true)
      }
    }

    fetchData()
  }, [id, isFirstPageLoad, messageThreadApiUrl, updateDeliveryCb, userId])

  const sendMessage = async messageText => {
    const url = `/api/messaging-service/messages/new-message-reply?`
    const postData = {
      data: {
        content: {
          body: messageText
        },
        parent_message_id: id,
        classification_name: 'in_app_message'
      },
      filter: { user_id: null },
      include: 'message_participants,message_participants.user'
    }
    setIsSending(true)
    const result = await postRequest(url, postData)
    const dataFormatter = new Jsona()
    const newMessage = dataFormatter.deserialize(result.data)
    setMessages([...messages, newMessage])
    trackMessagesEvent('Tap', 'sent_message')
    setIsSending(false)
    endOfMessagesRef.current.scrollIntoView()
  }

  const renderLoadingSection = () => (
    <div className={pageLoadingIndicator}>
      <LoadingSpinner />
    </div>
  )

  const renderMessageViewSection = () => (
    <Row align="bottom">
      <div className={styles.beginningMessageHistory}>
        This is the beginning of your direct message history with {recipient}. You can write them a
        message here.
      </div>
      <TransitionGroup className={styles.messagesContainer}>
        {messages.map(message => {
          const messageBool = !!message
          const sender =
            message.message_participants && message.message_participants.find(p => p.is_sender)
          return (
            <ExpandTransition key={message.id} expand={messageBool}>
              {message.sent_by_current_user ? (
                <MessageItemCurrentUser message={message} />
              ) : (
                <MessageItem message={message} messageParticipant={sender} />
              )}
            </ExpandTransition>
          )
        })}
      </TransitionGroup>
      <div ref={endOfMessagesRef} style={{ float: 'left', clear: 'both' }} />
      <Col span={12}>
        <MessageComposer
          className="transition-with-page"
          isSending={isSending}
          sendMessage={sendMessage}
        />
        <div className={styles.toastContainer}>
          <Toast show={showIntroToast} className={styles.newIntroToast} maxTimeout={10000} useIcon>
            {`We've let ${recipient} know you would like them to message you. We'll notify you when they send you a message.`}
          </Toast>
        </div>
      </Col>
    </Row>
  )

  return (
    <div>
      <div className={styles.tabHeading}>
        <h2>{recipientFormattedName}</h2>
      </div>
      <div className={styles.messages}>
        <div className={styles.fullMessagesContainer}>
          <GoBackHeader goBackLink="/messages" headerText={recipientFormattedName} />
          <Row align="bottom">
            <Col span={12}>{isLoading ? renderLoadingSection() : renderMessageViewSection()}</Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

FullMessageViewIsolated.propTypes = {
  updateDeliveryCb: PropTypes.func
}

FullMessageViewIsolated.defaultProps = {
  updateDeliveryCb: null
}
