import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { Graphic } from 'components/Graphic'
import { Checklist } from 'components/Checklist/Checklist'
import { LeadContext } from 'context/LeadContext'
import { isDuringSalesBusinessHours } from '../../utils/businessHours'
import styles from './DashboardSidebar.module.scss'
import { formatPhoneNumber } from '../../utils/numberParser'

export const DashboardSidebar = () => {
  const { salesPhoneNumber } = React.useContext(LeadContext)
  const DIALABLE_HC_PHONE = salesPhoneNumber
    ? `tel:${salesPhoneNumber}`
    : `tel:${process.env.REACT_APP_PHONE_HC}`
  const { leadFirstName } = React.useContext(LeadContext)

  const shouldDisplayHcPhone = isDuringSalesBusinessHours(moment())

  const callUsSection = (
    <div className={styles.callUs}>
      <p>Want to talk right now?</p>
      <a href={DIALABLE_HC_PHONE}>Call us {formatPhoneNumber(`${DIALABLE_HC_PHONE}`)}</a>
    </div>
  )

  return (
    <div className={styles.sidebar}>
      <a href={process.env.REACT_APP_HOMELIGHT_URL} className={styles.link}>
        <Graphic.SolidLogoWithText width={160} height={32} className={styles.logoWithText} />
      </a>
      <h1 className={styles.greeting}>Hi{leadFirstName ? `, ${leadFirstName}` : ''}!</h1>
      <p className={styles.yourProgress}>Your progress</p>
      <Checklist title="" />
      {shouldDisplayHcPhone ? callUsSection : null}
      <div className={styles.callUsBuffer} />
    </div>
  )
}

export default DashboardSidebar
