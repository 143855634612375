import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DayBreak } from './DayBreak'

export const DayBreakWrapper = ({ prevFeedItem, currentFeedItem, idx }) => {
  const prevFeedItemDateTime = prevFeedItem ? moment(prevFeedItem.occurred_at) : null
  const currentFeedItemDateTime = moment(currentFeedItem.occurred_at)
  const isNextDay = prevFeedItemDateTime
    ? currentFeedItemDateTime.isAfter(prevFeedItemDateTime, 'day')
    : false
  // Always show for first feed item. Thereafter only show if item is for next day
  const showDayBreak = idx === 0 || isNextDay
  return <div>{showDayBreak ? <DayBreak timestamp={currentFeedItem.occurred_at} /> : null}</div>
}

DayBreakWrapper.propTypes = {
  prevFeedItem: PropTypes.shape({ occurred_at: PropTypes.string }),
  currentFeedItem: PropTypes.shape({ occurred_at: PropTypes.string.isRequired }).isRequired,
  idx: PropTypes.number.isRequired
}

DayBreakWrapper.defaultProps = {
  prevFeedItem: null
}
