/* istanbul ignore file */
import { JSONAPISerializer } from 'miragejs'

export default JSONAPISerializer.extend({
  keyForAttribute(attr) {
    return attr.replace(/-/g, '_')
  },
  // Convert to snake_case from camelcase
  keyForRelationship(modelName) {
    return modelName.replace(/(?:^|\.?)([A-Z])/g, (x, y) => `_${y.toLowerCase()}`).replace(/^_/, '')
  },

  // Convert dasherized to underscore to match HAPI
  typeKeyForModel(model) {
    return model.modelName.replace(/-/g, '_')
  }
})
