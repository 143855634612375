import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { CheckCircleIcon, TimesSolid } from 'components/Graphic'
import styles from './Toast.module.scss'

const icons = {
  error: <TimesSolid className={styles.icon} />,
  default: <CheckCircleIcon className={styles.icon} />
}

export const Toast = ({ show, className, maxTimeout, useIcon, state, children }) => {
  const [showToast, setShowToast] = useState(false)
  const handler = useRef(null)
  const stateIcon = state || 'default'

  useEffect(() => {
    if (show) {
      clearTimeout(handler)
      setShowToast(true)
      handler.current = setTimeout(() => {
        setShowToast(false)
      }, maxTimeout)
    }
  }, [handler, maxTimeout, show])

  return (
    <>
      {showToast && (
        <div
          className={`${styles.toast} ${className} ${showToast ? styles.show : styles.hidden}`}
          data-testid="toast"
        >
          {useIcon && <div className={styles.icon}>{icons[stateIcon]}</div>}
          <div className={styles.toastBody}>{children}</div>
        </div>
      )}
    </>
  )
}

Toast.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  maxTimeout: PropTypes.number,
  useIcon: PropTypes.bool,
  state: PropTypes.oneOf(['error', 'default']),
  children: PropTypes.node
}

Toast.defaultProps = {
  show: false,
  className: null,
  maxTimeout: 5000,
  useIcon: true,
  state: 'default',
  children: null
}
