import { useState, useEffect } from 'react'
import {
  isMobile,
  isTablet,
  isDesktop,
  isSmallerViewport,
  isLargerViewport
} from 'utils/breakpoint'

export function useBreakpoint() {
  const [mobile, setMobile] = useState(false)
  const [tablet, setTablet] = useState(false)
  const [desktop, setDesktop] = useState(false)
  const [smallerViewport, setSmallerViewport] = useState(false)
  const [largerViewport, setLargerViewport] = useState(false)

  useEffect(() => {
    function handleWidth() {
      const windowWidth = window ? window.innerWidth : 0
      const mobileWidth = isMobile(windowWidth)
      const tabletWidth = isTablet(windowWidth)
      const desktopWidth = isDesktop(windowWidth)
      const smallerViewportWidth = isSmallerViewport(windowWidth)
      const largerViewportWidth = isLargerViewport(windowWidth)

      setMobile(mobileWidth)
      setTablet(tabletWidth)
      setDesktop(desktopWidth)
      setSmallerViewport(smallerViewportWidth)
      setLargerViewport(largerViewportWidth)
    }

    window.addEventListener('resize', handleWidth)

    handleWidth()

    return () => {
      window.removeEventListener('resize', handleWidth)
    }
  })

  return { mobile, tablet, desktop, smallerViewport, largerViewport }
}
