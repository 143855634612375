import { Factory } from 'miragejs'
import faker from 'faker'

export default Factory.extend({
  is_read: false,
  content() {
    return { body: faker.lorem.sentence() }
  },
  created_at() {
    return faker.date.past()
  },
  updated_at() {
    return faker.date.past()
  },
  sent_by_current_user: false
})
