import React, { useState, useEffect, useContext } from 'react'
import { LeadContext } from 'context/LeadContext'
import { Row, Col } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'
import { PageResizeContext } from 'components/PageResize'
import styles from './Checklist.module.scss'
import ChecklistTask from './ChecklistTask'
import { PageContext } from '../../context/PageContext'
import { FEED_ITEM_ANCHOR } from '../../utils/feedHelpers'
import { isDesktop } from '../../utils/breakpoint'
import { HardcodedChecklist } from './HardcodedChecklist'

export const Checklist = ({ title }) => {
  const { salesPhoneNumber, concierge, leadType } = useContext(LeadContext)
  const { feed } = useContext(PageContext)
  const checklist = HardcodedChecklist(leadType, salesPhoneNumber, concierge)
  const { width } = useContext(PageResizeContext)
  const [simpleSaleFeedId, setSimpleSaleFeedId] = useState()
  const [agentFeedId, setAgentFeedId] = useState()

  useEffect(() => {
    if (!isDesktop(width)) window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function findAgentFeedId() {
      const agent =
        feed.data && feed.data.find(feedItem => feedItem.item_type === 'agent_recommendation')

      if (agent && agent.external_id) {
        setAgentFeedId(`${FEED_ITEM_ANCHOR}${agent.external_id}`)
      }
    }

    function findSimpleSaleFeedId() {
      const simpleSale =
        feed.data && feed.data.find(feedItem => feedItem.item_type === 'simple_sale')

      if (simpleSale && simpleSale.external_id) {
        setSimpleSaleFeedId(`${FEED_ITEM_ANCHOR}${simpleSale.external_id}`)
      }
    }

    if (feed && feed.data) {
      findAgentFeedId()
      findSimpleSaleFeedId()
    }
  }, [feed])

  function renderChecklists() {
    return (
      <div className={styles.progressContainer}>
        <h3>{title}</h3>
        <div className={styles.checklistContainer}>
          <Row justify="start">
            <Col span={12}>
              {checklist.data.map(checklistItem => (
                <ChecklistTask
                  key={checklistItem.id}
                  title={checklistItem.title}
                  completed={checklistItem.percent_complete === 1.0}
                  tasks={checklistItem.tasks}
                  checklistTasks={checklistItem.checklist_tasks}
                  simpleSaleFeedId={simpleSaleFeedId}
                  agentFeedId={agentFeedId}
                />
              ))}
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  return renderChecklists()
}

export default Checklist

Checklist.propTypes = {
  title: PropTypes.string
}

Checklist.defaultProps = {
  title: 'Progress'
}
