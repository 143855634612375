import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import { GenericCallToActionOptions } from 'components/FeedItem/GenericCallToActionOptions'
import Raven from 'raven-js'
import { FeedItem } from './FeedItem'
import { updateFeedItem, unhideFeedItems } from '../../utils/feedItemClient'
import {
  optimisticallyUnhideInvestorIntroOption,
  optimisticallyUnhideSoftAgentIntroOption
} from './helpers/OptimisticUnhideFeedItem'
import { PageContext } from '../../context/PageContext'
import { trackFeedItemEvent } from '../../utils/tracking'

export const InvestorIntroOption = ({ userFeedItem, text, occurredAt, id, displayedAt }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(userFeedItem.item.selected_answer)
  const { leadType } = React.useContext(LeadContext)
  const { feed, updateFeed } = React.useContext(PageContext)

  const unhideInvestorIntro = () => {
    setSelectedAnswer('yes')
    trackFeedItemEvent(userFeedItem, 'Tap', leadType, 'yes')

    optimisticallyUnhideInvestorIntroOption(feed, updateFeed)
    optimisticallyUnhideSoftAgentIntroOption(feed, updateFeed)

    // fire the PUT request to change them to unhidden in the database
    unhideFeedItems(leadType, 'investor_intro').catch(error => {
      setSelectedAnswer(null)
      Raven.captureException(error)
    })
    updateFeedItem('yes', userFeedItem)
  }

  const unhideSoftAgentIntroOption = () => {
    setSelectedAnswer('no')
    trackFeedItemEvent(userFeedItem, 'Tap', leadType, 'no')

    optimisticallyUnhideSoftAgentIntroOption(feed, updateFeed)

    // fire the PUT request to change them to unhidden in the database
    unhideFeedItems(leadType, 'simple_home_value_soft_agent_intro_option').catch(error => {
      setSelectedAnswer(null)
      Raven.captureException(error)
    })
    updateFeedItem('no', userFeedItem)
  }

  const options = [
    { buttonText: 'Yes, please', onOptionClick: unhideInvestorIntro, answerKey: 'yes' },
    { buttonText: 'No, thank you', onOptionClick: unhideSoftAgentIntroOption, answerKey: 'no' }
  ]

  return (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      <GenericCallToActionOptions
        itemType={userFeedItem.item_type}
        title=""
        text={text}
        options={options}
        selectedAnswerKey={selectedAnswer}
      />
    </FeedItem>
  )
}

InvestorIntroOption.propTypes = {
  userFeedItem: PropTypes.shape({
    item: PropTypes.shape({ selected_answer: PropTypes.string }),
    item_type: PropTypes.string.isRequired
  }).isRequired,
  text: PropTypes.string.isRequired,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

InvestorIntroOption.defaultProps = {
  displayedAt: null
}
