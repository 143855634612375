import React from 'react'
import { InvestorOption } from '../../../FeedItem'

export const RenderInvestorIntro = userFeedItem => {
  const {
    item,
    id,
    item_type: itemType,
    occurred_at: occurredAt,
    displayed_at: displayedAt
  } = userFeedItem

  let offerLow = item.sell_to_investor_low
  let offerHigh = item.sell_to_investor_high
  if (item.use_opendoor) {
    offerLow = item.sell_with_opendoor_low
    offerHigh = item.sell_with_opendoor_high
  }
  return (
    <InvestorOption
      key={id}
      offerLow={offerLow}
      offerHigh={offerHigh}
      itemType={itemType}
      occurredAt={occurredAt}
      id={id}
      displayedAt={displayedAt}
      headerText="Sell now to an investor"
      description="Great! Our home buying program is called Simple Sale. Here's our early estimate of your home's value. When we talk, we can discuss more of your home's specifics and get to an accurate offer."
    />
  )
}
