import React from 'react'
import PropTypes from 'prop-types'
import { CheckIcon } from 'components/Graphic'
import { Link } from 'react-router-dom'
import styles from './SimpleSaleCard.module.scss'
import { Button } from '../Button/Button'
import { trackFeedItemGenericEvent, trackEvent } from '../../utils/tracking'
import { LeadContext } from '../../context/LeadContext'

export const InvestorSeeHowItWorksButton = ({
  itemType,
  buttonText,
  goToLink,
  onClick,
  selected,
  simpleSaleItemType,
  unhideRemainingFeedItems
}) => {
  // If linking to a different page/component, use the `goToLink` prop
  const newPageLink = goToLink ? (
    <Link to={goToLink}>
      <Button as="primary" text={buttonText} onClick={unhideRemainingFeedItems} />
    </Link>
  ) : null
  const { leadType } = React.useContext(LeadContext)

  const tracking = () => {
    if (simpleSaleItemType === 'hlss_eligible') {
      trackEvent(
        'Dashboard Feed',
        'Tap',
        'simple_sale_introduce_homelight_as_buyer_Learn_More',
        0,
        'DashboardDataLayer'
      )
    } else {
      trackFeedItemGenericEvent(itemType, 'Tap', leadType, buttonText)
    }
  }

  // If trying to execute a callback function without linking to a new page/component,
  // use the `onClick` prop
  const ctaLink = onClick ? (
    <div>
      <Button
        className={`${styles.optionButton} ${selected ? styles.selected : ''}`}
        as="primary"
        onClick={() => {
          onClick()
          tracking()
        }}
      >
        <CheckIcon
          className={`${styles.checkIcon} ${selected ? styles.selected : styles.hidden}`}
        />
        {buttonText}
      </Button>
    </div>
  ) : null

  return ctaLink || newPageLink
}

InvestorSeeHowItWorksButton.propTypes = {
  itemType: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  goToLink: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  simpleSaleItemType: PropTypes.string,
  unhideRemainingFeedItems: PropTypes.func
}

InvestorSeeHowItWorksButton.defaultProps = {
  goToLink: null,
  onClick: null,
  selected: false,
  simpleSaleItemType: null,
  unhideRemainingFeedItems: null
}

export default InvestorSeeHowItWorksButton
