import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { CallButton } from 'components/Button/CallButton'
import { DisplayNumberButton } from 'components/Button/DisplayNumberButton'
import { PageResizeContext } from 'components/PageResize'
import { isMobile } from '../../utils/breakpoint'
import FeedItem from './FeedItem'

export const MissedAgentCallItem = ({ agentName, phoneNumber, occurredAt, id, displayedAt }) => {
  const { width } = useContext(PageResizeContext)
  const feedItemType = 'missed_agent_call'
  return (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      <div>
        Real estate agent {agentName} attempted to call you to learn more about your situation.
      </div>
      {isMobile(width) ? (
        <CallButton buttonText="Call Back" phoneNumber={phoneNumber} feedItemType={feedItemType} />
      ) : (
        <DisplayNumberButton text="Call Back" phone={phoneNumber} feedItemType={feedItemType} />
      )}
    </FeedItem>
  )
}

MissedAgentCallItem.propTypes = {
  agentName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

MissedAgentCallItem.defaultProps = {
  displayedAt: null
}
