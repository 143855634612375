import React from 'react'
import PropTypes from 'prop-types'

import { Graphic } from '../Graphic'
import styles from '../AgentCard/AgentCard.module.scss'

export const AwardIcon = ({ awardType, rankType, iconStyle }) => {
  function renderIcon() {
    const AWARD_LIGHT_BADGES = [
      'title_escrow',
      'trade_in',
      'simple_sale',
      'property_type_expert',
      'number_one',
      'cash_offer'
    ]

    switch (awardType) {
      case 'neighborhood_knowledge':
        return <Graphic.MapMarker />
      case 'cash_offer':
        return <Graphic.CashOffer />
      case 'trade_in':
        return <Graphic.TradeIn />
      case 'simple_sale':
        return <Graphic.SimpleSale />
      case 'title_escrow':
        return <Graphic.TitleAndEscrow />
      default:
        if (AWARD_LIGHT_BADGES.includes(awardType) && !rankType.includes('sold')) {
          return <Graphic.AwardLight />
        }
        return <Graphic.Trophy />
    }
  }

  return <div className={iconStyle}>{renderIcon()}</div>
}

AwardIcon.propTypes = {
  awardType: PropTypes.string.isRequired,
  rankType: PropTypes.string,
  iconStyle: PropTypes.string
}

AwardIcon.defaultProps = {
  rankType: '',
  iconStyle: styles.graphic
}
