import React from 'react'
import PropTypes from 'prop-types'
import styles from '../AgentCard/AgentCard.module.scss'
import { AwardIcon } from './AwardIcon'

export const AgentAwardBadge = ({ badge, children }) => (
  <div className={styles.awardBadgeSection}>
    <div className={styles.fact}>
      <AwardIcon awardType={badge.award_type} rankType={badge.rank_type} />
    </div>
    <div className={styles.description}>
      {badge.value}
      <span className={styles.tooltipWrapper}>{children}</span>
    </div>
  </div>
)

AgentAwardBadge.propTypes = {
  badge: PropTypes.shape({
    award_type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    rank_type: PropTypes.string
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

AgentAwardBadge.defaultProps = {
  badge: {
    rank_type: ''
  }
}
