/* istanbul ignore file */

import { Server } from 'miragejs'
import faker from 'faker'
import JSONAPISerializer from 'serializers/application'
import moment from 'moment'
import CallToActionFactory from 'mirage/factories/FeedItemFactories/CallToActionFactory'
import Models from './mirage/Models'
import Factories from './mirage/factories/Factories'

import GenericMessageFactory from './mirage/factories/FeedItemFactories/GenericMessageFactory'
import WelcomeItemFactory from './mirage/factories/FeedItemFactories/WelcomeItemFactory'
import AgentCardMock from './jest/mocks/AgentCardMock'
import SimpleSaleFactory from './mirage/factories/FeedItemFactories/SimpleSaleFactory'

const USER_FIRST_NAME = 'Tom'
const USER_LAST_NAME = 'Bombadil'
const USER_EMAIL_ADDRESS = 'tom.bombadil@withywindle.nz'

const createDefaultHomeLightMessage = server => {
  const messageClassification = server.create('messageClassification')
  const messageParticipants = server.createList('messageParticipant', 2, { is_sender: false })
  messageParticipants[0].is_sender = true
  server.create('message', {
    messageParticipants,
    messageClassification,
    content: {
      body:
        `Hi ${USER_FIRST_NAME},\n` +
        '\n' +
        "Welcome to HomeLight! You're on your way to finding the perfect real estate agent.\n" +
        '\n' +
        "A HomeLight Home Consultant will give you a quick call to answer any questions you may have about the home selling process. This is a great opportunity to tell us anything else you're looking for in an agent so that we can make the best possible match for you.\n" +
        '\n' +
        'At any time, you can come back to your HomeLight dashboard to review your results.\n' +
        '\n' +
        'If you ever have questions, feel free to reply back to this message.\n' +
        '\n' +
        'Thanks and we look forward to helping you!\n' +
        '\n' +
        '- The HomeLight Team'
    }
  })
}

export function makeServer({ environment = 'test' } = {}) {
  const LEAD_TYPES = ['seller', 'buyer']
  window.TEST_LEAD_LOCATION = {
    latitude: faker.datatype.number({ min: 30, max: 45 }),
    longitude: faker.datatype.number({ min: -122, max: -75 })
  }

  return new Server({
    environment,

    serializers: {
      application: JSONAPISerializer,
      checklistTask: JSONAPISerializer.extend({
        alwaysIncludeLinkageData: true
      })
    },

    models: Models(),

    factories: Factories(),

    seeds(server) {
      // Turn off logging mirage requests to the console
      // server.logging = false

      const user = server.create('user')
      const checklists = server.createList('checklist', 3)

      // Creates a welcome item
      server.create('userFeedItem', {
        id: 1,
        item_type: 'welcome_item',
        item: WelcomeItemFactory({ welcome_type: 'hve_update_welcome' }),
        user,
        displayed_at: faker.date.recent,
        occurred_at: new Date(),
        hidden_at: null
      })

      server.create('userFeedItem', {
        id: 2,
        item_type: 'simple_sale',
        item: SimpleSaleFactory(),
        user,
        displayed_at: faker.date.recent,
        occurred_at: new Date(),
        hidden_at: null
      })

      // Create agent recommendations
      server.create('userFeedItem', {
        id: 3,
        item_type: 'agent_recommendation',
        user,
        displayed_at: faker.date.recent,
        occurred_at: new Date(),
        hidden_at: new Date()
      })

      // Create agent recommendation feedback
      server.create('userFeedItem', {
        id: 4,
        item_type: 'agent_recommendation_feedback',
        user,
        displayed_at: faker.date.recent,
        occurred_at: new Date(),
        hidden_at: new Date()
      })

      // Create next steps
      server.create('userFeedItem', {
        id: 5,
        item_type: 'multiple_choice',
        user,
        displayed_at: faker.date.recent,
        occurred_at: new Date(),
        hidden_at: new Date()
      })

      server.create('userFeedItem', {
        id: 6,
        item_type: 'hlca_product_eligibility',
        user,
        displayed_at: faker.date.recent,
        occurred_at: new Date(),
        hidden_at: null
      })

      server.create('userFeedItem', {
        id: 7,
        item_type: 'call_to_action',
        item: CallToActionFactory({
          reason: 'hlca',
          action_type: 'hlca_calendly'
        }),
        user,
        displayed_at: faker.date.recent,
        occurred_at: new Date(),
        hidden_at: null
      })

      checklists.forEach(checklist => {
        const task = server.create('task', { checklist })
        server.create('checklistTask', { checklist, task })
      })

      createDefaultHomeLightMessage(server)
      server.createList('agentTransaction', 30)
    },

    routes() {
      /* GET routes */

      this.get('/api/user-feed-service/feed', (schema, request) => {
        const filter = Object.keys(request.queryParams).find(key =>
          key.toString().includes('filter[')
        )

        if (filter) {
          const date = new Date(request.queryParams[filter]).toISOString()
          // creates a new user feed item to simulate pulling in new data
          const user = schema.users.find(1)
          schema.userFeedItems.create({
            user,
            item_type: 'generic_message',
            created_at: new Date().toISOString(),
            occurred_at: new Date().toISOString(),
            displayed_at: new Date().toISOString(),
            hidden_at: null,
            item: GenericMessageFactory()
          })
          // updates an existing user feed item to simulate replacing an old item with the newer version
          const feedItem = schema.userFeedItems.find(1) // arbitrary; finds the first feed item
          feedItem.item.address = 'Bag End'
          feedItem.item.city = 'Underhill'
          feedItem.updated_at = new Date().toISOString() // mark it as recently updated so it returns
          feedItem.save()
          return schema.userFeedItems
            .where(userFeedItem => userFeedItem.created_at > date || userFeedItem.updated_at > date)
            .sort((a, b) => (moment(a.occurred_at) < moment(b.occurred_at) ? -1 : 1))
        }
        const feedItems = schema.userFeedItems.all()
        return feedItems.sort((a, b) => (moment(a.occurred_at) < moment(b.occurred_at) ? -1 : 1))
      })

      this.get('/api/task-service/checklists', schema => schema.checklists.all())

      this.get('/api/messaging-service/messages/inbox-messages', schema => schema.messages.all())

      this.get('/api/messaging-service/messages/message-thread', (schema, request) => {
        const filter = Object.keys(request.queryParams)[1]
        const messageId = request.queryParams[filter]
        return schema.messages.where({ id: messageId })
      })

      this.get('/api/messaging-service/client/check-conversation-history', () => ({
        message_id: null,
        messages_exist: false
      }))

      this.get('api/user-feed-service/feed/get-calendly-sales-event', () => ({
        sales_event_exists: false
      }))

      this.get('/api/messaging-service/messages/unread-messages-count', () => {})

      this.get('/api/user-auth-service/users/current', () => ({
        data: {
          id: '162765',
          type: 'user',
          attributes: {
            id: 162765,
            email: USER_EMAIL_ADDRESS,
            first_name: USER_FIRST_NAME,
            last_name: USER_LAST_NAME
          },
          relationships: {}
        }
      }))

      this.get('/api/user-auth-service/users/current_lead_globals', () => ({
        data: {
          id: '2661452',
          type: 'lead_global',
          attributes: {
            stage: 'new',
            name: `${USER_FIRST_NAME} ${USER_LAST_NAME}`,
            latitude: window.TEST_LEAD_LOCATION.latitude,
            longitude: window.TEST_LEAD_LOCATION.longitude,
            user_id: 1,
            user_type: LEAD_TYPES[faker.datatype.number({ min: 0, max: LEAD_TYPES.length - 1 })],
            email: USER_EMAIL_ADDRESS,
            url_safe_token: faker.datatype.uuid
          },
          relationships: {
            sales_phone_number: {
              data: null
            }
          }
        },
        simple_sale: faker.datatype.boolean(),
        included: []
      }))

      this.get('/api/lead-data-service/simple-sale/user-results', () => ({
        data: {
          id: '5e47413a-0bdf-11e8-8710-128184e0bc72',
          type: 'sales_math',
          attributes: {
            address: '2175 SAN FRANCISCO AVE, LONG BEACH, CA 90806',
            estimated_home_value: '703710.0',
            home_value_high: '766427.0',
            home_value_low: '640992.0',
            agent: {
              sale_low: '640992.0',
              sale_high: '766427.0',
              market_prep: '7037.1',
              agent_commissions: '42222.6',
              closing_costs: '10555.65',
              total_low: '581176.65',
              total_high: '706611.65',
              estimated_net_total: '643894.65'
            },
            investor: {
              market_prep: 0,
              closing_costs: 0,
              sale_low: '577042.2',
              sale_high: '654450.3',
              net_low: '577042.2',
              net_high: '654450.3',
              agent_commissions: '0.0',
              total_low: '577042.2',
              total_high: '654450.3'
            },
            use_opendoor: false,
            opendoor_high_estimate: null,
            opendoor_low_estimate: null
          }
        }
      }))

      this.get('/api/transaction-service/agent-transactions/for-dynamic-map', schema =>
        schema.agentTransactions.all()
      )

      /* PUT routes */

      this.put('/api/user-feed-service/feed/update-multiple-choice', (schema, request) => {
        const body = JSON.parse(request.requestBody)
        const feedItem = schema.userFeedItems.find(body.user_feed_item_id)
        feedItem.item.selected_answer = body.selected_answer
        feedItem.save()
        return feedItem
      })

      this.put('/api/user-feed-service/feed/update-call-to-action', () => ({}))

      this.put('/api/user-feed-service/feed/update-updated-home-value-estimate-item', schema => {
        const feedItem = schema.userFeedItems.findBy({ item_type: 'updated_home_val_estimate' })
        const date = new Date()
        feedItem.item.home_value_high = 1200000
        feedItem.item.home_value_low = 600000
        feedItem.item.hve_updated_at = date.toISOString()
        feedItem.save()
        return feedItem
      })

      this.put('/api/user-feed-service/feed/unhide-feed-items', schema => {
        schema.userFeedItems.all().models.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item.hidden_at = null
          item.save()
        })
        return schema.userFeedItems.all()
      })

      this.put('/api/user-feed-service/feed/mark-displayed-at', (schema, request) => {
        const body = JSON.parse(request.requestBody)
        const feedItem = schema.userFeedItems.find(body.user_feed_item_id)
        const date = new Date()
        feedItem.displayed_at = date.toISOString()
        feedItem.save()
        return feedItem
      })

      this.put('/api/user-feed-service/feed/update-agent-card', (schema, request) => {
        const body = JSON.parse(request.requestBody)
        const feedItem = schema.userFeedItems.find(body.user_feed_item_id)
        feedItem.item.agent_match.introduction_requested = body.introduction_requested
        feedItem.save()
        return feedItem
      })

      /* POST routes */

      this.post('/api/lead-data-service/client-dashboard/leads/create-duplicate-lead', () => ({}))

      this.post(`${process.env.REACT_APP_HOMELIGHT_REPO_URL}/agent-lead-stage-update`, () => ({}))

      this.post('/intro-agent-from-message', () => ({}))

      this.post('/api/user-feed-service/feed/create-generic-message', (schema, request) => {
        const body = JSON.parse(request.requestBody)
        return schema.userFeedItems.create({
          item_type: 'generic_message',
          item: body,
          user: schema.users.first()
        })
      })

      this.post('/api/user-feed-service/feed/create-blog-post', () => ({}))

      this.post('/api/messaging-service/messages/new-message-reply', (schema, request) => {
        const body = JSON.parse(request.requestBody)
        return schema.messages.create({
          content: {
            body: body.data.content.body
          },
          sent_by_current_user: true,
          created_at: faker.date.recent(),
          updated_at: null
        })
      })

      this.post('/api/messaging-service/client/new-message', (schema, request) => {
        const body = JSON.parse(request.requestBody)
        return schema.messages.create({
          content: {
            body: body.data.content.body
          },
          created_at: faker.date.recent(),
          updated_at: null
        })
      })

      this.post('/api/user-feed-service/feed/create-client-dashboard-feedback', () => ({}))

      this.post('/api/messaging-service/messages/update-delivery-by-parent', () => ({}))

      this.post(process.env.REACT_APP_SS_INTERESTED_SLACK_WEBHOOK, () => ({
        data: 'ok'
      }))

      this.post('/api/user-feed-service/feed/find-or-create-agent-team-member-details', () => ({
        agent_team_details: [AgentCardMock(), AgentCardMock()]
      }))

      this.post(
        `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/quiz_introduction_client_dashboard`,
        () => new Promise(resolve => setTimeout(() => resolve('ok'), 1000))
      )

      this.post('/api/messaging-service/messages/welcome-message-reply', () => ({}))

      this.passthrough('https://api.mapbox.com/**')
      this.passthrough('https://events.mapbox.com/**')
      this.passthrough('https://vimeo.com/**')
    }
  })
}
