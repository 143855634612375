import faker from 'faker'

export default (overrides = {}) => {
  const attributes = {
    full_address:
      `${faker.address.streetAddress()}, ${faker.address.city()}, ${faker.address.stateAbbr()}`.toLowerCase(),
    new_valuation: faker.datatype.number({ min: 10000, max: 20000 }),
    percent_change: faker.datatype.number({ min: 1, max: 5, precision: 0.1 }).toString(),
    price_range_max: faker.datatype.number({ min: 500001, max: 1000000 }),
    price_range_min: faker.datatype.number({ min: 100000, max: 500000 }),
    property_uuid: faker.random.uuid()
  }

  return { ...attributes, ...overrides }
}
