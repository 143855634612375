import faker from 'faker'
import AgentCardFactory from './AgentCardFactory/AgentCardFactory'

const DIRECT_MATCH_TYPES = ['warm_transfer', 'hand_match']

export default (overrides = {}) => {
  const attributes = {
    direct_match_type: faker.random.arrayElement(DIRECT_MATCH_TYPES),
    agent_match: AgentCardFactory(),
    city: faker.address.city()
  }

  return { ...attributes, ...overrides }
}
