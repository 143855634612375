import React from 'react'
import PropTypes from 'prop-types'
import { CallButton } from 'components/Button/CallButton'
import { CardHeaderText } from 'components/FeedItem'

export const CallUsOption = ({ title, text, buttonText }) => (
  <div>
    <CardHeaderText title={title} text={text} />
    <CallButton buttonText={buttonText} feedItemType="call_us" />
  </div>
)

CallUsOption.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string
}

CallUsOption.defaultProps = {
  title: null,
  text: 'If you would like HomeLight to match you with top performing real estate agents in your area, please reach out to speak with a Home Consultant. \n We hope to hear from you soon! \n Best Regards, \n The HomeLight Team',
  buttonText: 'Call Us'
}
