import React from 'react'
import PropTypes from 'prop-types'
import { dayBreakParser } from '../../utils/relativeDateParser'
import styles from './DayBreak.module.scss'

export const DayBreak = ({ timestamp }) => {
  const formattedDay = dayBreakParser(timestamp)
  return (
    <div className={styles.dayBreak}>
      <div className={styles.dayBreakTitle}>{formattedDay}</div>
    </div>
  )
}

DayBreak.propTypes = {
  timestamp: PropTypes.string.isRequired
}
