import React, { createContext, useState, useEffect } from 'react'
import Jsona from 'jsona'
import PropTypes from 'prop-types'
import { mergeObjectArraysByKey } from 'utils/mergeObjectArraysByKey'
import { FEED_POLLING_INTERVAL } from '../constants/constants'
import { PAGE_NAMES } from '../constants/pages'
import { getRequest } from '../utils/makeRequest'
import { useInterval } from '../hooks/useInterval'
import { usePrevious } from '../hooks/usePrevious'
import { GetUrl, IsNavigatingAwayFromFeed } from './helpers/PageContextHelpers'

const emptyData = {
  [PAGE_NAMES.home]: [],
  [PAGE_NAMES.progress]: [],
  [PAGE_NAMES.messages]: [],
  [PAGE_NAMES.team]: [],
  [PAGE_NAMES.simpleSale]: {}
}

const storedPages = Object.keys(emptyData)

export const PageContext = createContext({})

export const PageProvider = ({ children, currentPage, mobileMenuOpen, setMobileMenuOpen }) => {
  const previousPage = usePrevious(currentPage)
  const [visitedPages, setVisitedPages] = useState([])
  const currentPagePreviouslyVisited = visitedPages.includes(currentPage)
  if (!currentPagePreviouslyVisited) {
    setVisitedPages([...visitedPages].concat(currentPage))
  }

  const [feedData, setFeedData] = useState({ data: emptyData[PAGE_NAMES.home], isLoading: true })
  const [checklistData, setChecklistData] = useState({
    data: emptyData[PAGE_NAMES.progress],
    isLoading: true
  })
  const [messages, setMessages] = useState({
    data: emptyData[PAGE_NAMES.messages],
    isLoading: true
  })
  const [teamData, setTeamData] = useState({ data: emptyData[PAGE_NAMES.team], isLoading: true })
  const [simpleSaleData, setSimpleSaleData] = useState({
    data: emptyData[PAGE_NAMES.simpleSale],
    isLoading: true
  })

  const pageDataMap = {
    [PAGE_NAMES.home]: { get: feedData, set: setFeedData },
    [PAGE_NAMES.progress]: { get: checklistData, set: setChecklistData },
    [PAGE_NAMES.messages]: { get: messages, set: setMessages },
    [PAGE_NAMES.team]: { get: teamData, set: setTeamData },
    [PAGE_NAMES.simpleSale]: { get: simpleSaleData, set: setSimpleSaleData }
  }

  const dataFormatter = new Jsona()

  const deserialize = data => dataFormatter.deserialize(data)

  const setData = (result, pageName, isError = false) => {
    const data = deserialize(result.data)
    pageDataMap[pageName].set({ data, isLoading: false, isError })
  }

  const setPageError = page => setData({ data: emptyData[page] }, page, true)

  const getNewLoadFromTime = () => {
    const now = Date.now()
    const fiveMinutesAgo = now - FEED_POLLING_INTERVAL

    return new Date(fiveMinutesAgo).toISOString()
  }

  const fetchData = async pageName => {
    const url = GetUrl(pageName)

    try {
      const result = await getRequest(url)

      if (result && result.data) {
        setData(result, pageName)
      } else {
        setPageError(pageName)
      }
    } catch (error) {
      setPageError(pageName)
    }
  }

  const fetchFilteredFeedData = async pageName => {
    const url = GetUrl(pageName)
    const loadFromFilter = getNewLoadFromTime()
    const urlWithFilter = `${url}&filter[changed][since]=${loadFromFilter}`

    try {
      const result = await getRequest(urlWithFilter)

      if (result && result.data) {
        const deserializedData = deserialize(result.data)
        const mergedData = mergeObjectArraysByKey(
          feedData.data,
          deserializedData,
          'updated_at',
          true
        )
        setFeedData({ data: mergedData })
      } else {
        setPageError(pageName)
      }
    } catch (error) {
      setPageError(pageName)
    }
  }

  // Load data for current page first, then load for any remaining pages that don't have data
  useEffect(() => {
    const currentPageApiUrl = GetUrl(currentPage)
    if (currentPage && currentPageApiUrl) {
      fetchData(currentPage).then(() => {
        const remainingPages = storedPages.filter(page => page !== currentPage)
        remainingPages.map(page => {
          if (pageDataMap[page].get.data === emptyData[page]) {
            fetchData(page)
          }
          return null
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  // FEED-SPECIFIC FETCHES - extra data fetches to prioritize keeping the feed fresh
  //
  // Fetch after a visit to the feed to update any visited_at changes for items
  const isNavigatingAwayFromFeed = IsNavigatingAwayFromFeed(previousPage, currentPage)
  useEffect(() => {
    if (isNavigatingAwayFromFeed) {
      fetchData(PAGE_NAMES.home)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNavigatingAwayFromFeed])

  // Poll every 5 minutes
  useInterval(() => {
    if (currentPage === PAGE_NAMES.home) {
      fetchFilteredFeedData(PAGE_NAMES.home)
    }
  }, FEED_POLLING_INTERVAL)

  // Re-request feed data 4sec after initial page load
  // for feed items that took longer to generate
  useEffect(() => {
    setTimeout(() => fetchData(PAGE_NAMES.home), 4000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // END FEED_SPECIFIC FETCHES

  const getCommonPageProps = name => ({
    currentlyVisiting: currentPage === name,
    previouslyVisited: visitedPages.includes(name),
    refreshData: () => (name === PAGE_NAMES.home ? fetchFilteredFeedData(name) : fetchData(name))
  })

  return (
    <PageContext.Provider
      value={{
        mobileMenuOpen,
        setMobileMenuOpen,
        feed: { ...feedData, ...getCommonPageProps(PAGE_NAMES.home) },
        checklist: { ...checklistData, ...getCommonPageProps(PAGE_NAMES.progress) },
        messages: { ...messages, ...getCommonPageProps(PAGE_NAMES.messages) },
        team: { ...teamData, ...getCommonPageProps(PAGE_NAMES.team) },
        simpleSale: { ...simpleSaleData, ...getCommonPageProps(PAGE_NAMES.simpleSale) },
        updateFeed: feed => {
          setFeedData(feed)
        }
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

PageProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  currentPage: PropTypes.string.isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired
}
