import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import moment from 'moment'
import Raven from 'raven-js'

import { useScroll } from 'hooks/useScroll'
import { useBreakpoint } from 'hooks/useBreakpoint'

import { Toast } from 'components/common/Toast'
import { Button } from '../Button'
import { LoadingSpinner } from '../../Loading/LoadingSpinner'
import { putRequest, postHlRequest } from '../../../utils/makeRequest'
import { trackFeedItemGenericEvent } from '../../../utils/tracking'
import { trackSessionEvent } from '../../../utils/feedItemClient'
import { CheckIcon } from '../../Graphic'

import styles from './GetIntroducedButton.module.scss'

export const GetIntroducedButton = ({
  agentId,
  agentLeadId,
  feedItemId,
  feedItemType,
  agentName,
  introductionAlreadyRequested,
  className
}) => {
  const { leadId, leadType } = React.useContext(LeadContext)
  const [introRequested, setIntroRequested] = useState(false)
  const [introPending, setIntroPending] = useState(false)
  const [isError, setIsError] = useState(false)

  const { scrollingUp } = useScroll()
  const { mobile } = useBreakpoint()

  const introRequestComplete = introRequested || introductionAlreadyRequested

  const showToast = introRequested || isError
  const toastIntroText = `We'll share your contact information with ${agentName} A member of our team will reach out if we need any additional information. This usually only takes a day or so.`
  const toastErrorText = 'Sorry, something went wrong. Can you try that again?'
  const toastText = isError ? toastErrorText : toastIntroText

  const getIntroducedUrl = '/quiz_introduction_client_dashboard'

  const updateAgentCardUrl = '/api/user-feed-service/feed/update-agent-card'

  const postData = {
    lead_id: leadId,
    agent_id: agentId,
    message: `Client requested intro on ${moment().format('MM/DD')}`
  }

  const updateCardData = {
    agent_lead_id: agentLeadId,
    user_feed_item_id: Number(feedItemId),
    introduction_requested: true
  }

  const updateAgentCard = async () => {
    await putRequest(updateAgentCardUrl, updateCardData)
  }

  const requestIntroduction = async () => {
    if (introRequestComplete) {
      return
    }
    try {
      setIsError(false)
      setIntroPending(true)
      await postHlRequest(getIntroducedUrl, postData)
      setIntroRequested(true)
      setIntroPending(false)
      updateAgentCard()
      trackFeedItemGenericEvent(feedItemType, 'Tap', leadType, 'introduction_requested')
      trackSessionEvent(
        leadId,
        'Client',
        'ClientTapAgentIntroRequestButton',
        `agent_lead_id_${agentLeadId}`
      )
    } catch (error) {
      Raven.captureException(error)
      setIsError(true)
      setIntroPending(false)
    }
  }

  return (
    <div className={className}>
      <Toast
        show={showToast}
        className={`${styles.introRequestToast} ${
          mobile && scrollingUp ? styles.navVisible : styles.navHidden
        }`}
        maxTimeout={5000}
        useIcon
        state={isError ? 'error' : 'default'}
      >
        <p>{toastText}</p>
      </Toast>
      <Button
        as="primary"
        className={styles.agentCard}
        onClick={requestIntroduction}
        disabled={introRequestComplete}
      >
        {introRequestComplete && <CheckIcon className={styles.checkIcon} />}
        {introPending && (
          <span className={styles.loadingSpinner}>
            <LoadingSpinner size={20} color="#FFFFFF" />
          </span>
        )}
        Get Introduced
      </Button>
    </div>
  )
}

GetIntroducedButton.propTypes = {
  agentId: PropTypes.number.isRequired,
  agentLeadId: PropTypes.number.isRequired,
  feedItemId: PropTypes.string.isRequired,
  agentName: PropTypes.string.isRequired,
  introductionAlreadyRequested: PropTypes.bool,
  feedItemType: PropTypes.string.isRequired,
  className: PropTypes.string
}

GetIntroducedButton.defaultProps = {
  introductionAlreadyRequested: false,
  className: ''
}
