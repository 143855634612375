import { datadogRum } from '@datadog/browser-rum'
import { version } from '../../../package.json'

export function initializeDatadogRum() {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: process.env.REACT_APP_DATADOG_SITE,
      service: 'client-dashboard',
      env: process.env.REACT_APP_ENVIRONMENT,
      version,
      sampleRate: 100,
      trackInteractions: true
    })
  }
}
