import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './PopOver.module.scss'
import { cx } from '../../utils/cx'

export const PopOver = ({ children, show, hideDelay }) => {
  const timeoutRef = useRef(null)
  const [showPopover, setShowPopover] = useState(false)
  const [hideAnimation, setHideAnimation] = useState(false)

  useEffect(() => {
    if (show) {
      setHideAnimation(false)
      setShowPopover(true)
      clearTimeout(timeoutRef.current)
    } else {
      setHideAnimation(true)
      timeoutRef.current = setTimeout(() => setShowPopover(false), hideDelay)
    }

    return () => clearTimeout(timeoutRef.current)
  }, [hideDelay, show])

  return (
    <div
      className={cx(
        styles.popOverContainer,
        showPopover ? styles.show : styles.hidden,
        hideAnimation && styles.hideAnimation
      )}
    >
      {children}
    </div>
  )
}

PopOver.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
  hideDelay: PropTypes.number
}

PopOver.defaultProps = {
  children: null,
  show: false,
  hideDelay: 0
}

export default PopOver
