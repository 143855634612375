import React from 'react'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import { Row } from 'react-simple-flex-grid'
import { Link } from 'react-router-dom'

import { trackChecklistEvent } from '../../utils/tracking'

import styles from './Task.module.scss'

const HIDE_TITLE = [
  'list_home_task',
  'review_offers_task',
  'close_home_task',
  'find_loan_task',
  'find_home_task',
  'make_offer_task',
  'buyer_close_home_task',
  'concierge_sell_strategy_task',
  'concierge_buy_strategy_task'
]

export const Task = ({ task, checklistTask, simpleSaleFeedId, agentFeedId }) => {
  const { leadType } = React.useContext(LeadContext)
  const taskTitle = task.title
  const taskInfo = task.note
  const taskInfoButtonText = checklistTask.cta_text
  const taskUrl = checklistTask.url
  let disableButton = false
  const hideTitle = HIDE_TITLE.includes(taskTitle)

  const isAbsoluteUrl = url => {
    const pat = /^tel|^https?:\/\//i
    return pat.test(url)
  }

  const renderTitle = () => {
    if (HIDE_TITLE.includes(taskTitle)) {
      return null
    }
    return <p className={styles.taskTitle}>{taskTitle}</p>
  }

  const renderBody = () => {
    let className
    if (HIDE_TITLE.includes(taskTitle)) {
      className = styles.textOnlyDesc
    } else {
      className = styles.taskDesc
    }
    return taskInfo && taskInfo.split('\n').map(i => <p className={className}>{i}</p>)
  }

  const trackChecklistEventWrapper = () => {
    if (disableButton) {
      return false // links will not navigate to their `href` after handling `onclick` if the function returns false
    }
    trackChecklistEvent('Tap', leadType, taskTitle)
    return true // links will navigate to their `href` after handling `onclick` if the function returns true
  }

  const renderButton = () => {
    if (!taskInfoButtonText) return null

    let relativeUrl
    let absoluteUrl

    switch (taskTitle) {
      case 'List with a HomeLight agent':
        if (!agentFeedId) disableButton = true

        relativeUrl = `/#${agentFeedId}`
        break
      case 'Sell your home to HomeLight':
        if (!simpleSaleFeedId) disableButton = true

        relativeUrl = `/#${simpleSaleFeedId}`
        break

      default:
        if (taskUrl && isAbsoluteUrl(taskUrl)) {
          absoluteUrl = taskUrl
        } else if (taskUrl) {
          absoluteUrl = `${process.env.REACT_APP_HOMELIGHT_REPO_URL}${taskUrl}`
        } else {
          absoluteUrl = ''
          disableButton = true
        }
        break
    }

    if (relativeUrl) {
      return (
        <Link
          to={relativeUrl}
          className={`${styles.taskLink} ${disableButton ? styles.disabled : ''}`}
          onClick={trackChecklistEventWrapper}
        >
          {taskInfoButtonText}
        </Link>
      )
    }
    if (absoluteUrl) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={absoluteUrl}
          className={`${styles.taskLink} ${disableButton ? styles.disabled : ''}`}
          onClick={trackChecklistEventWrapper}
        >
          {taskInfoButtonText}
        </a>
      )
    }
    return null
  }

  return (
    <div className={hideTitle ? styles.textOnly : styles.taskContent}>
      <Row>
        <div className={styles.taskInfo}>
          {renderTitle()}
          {renderBody()}
          {renderButton()}
        </div>
      </Row>
    </div>
  )
}

Task.propTypes = {
  task: PropTypes.shape({
    title: PropTypes.string,
    note: PropTypes.string
  }).isRequired,
  checklistTask: PropTypes.shape({
    cta_text: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  simpleSaleFeedId: PropTypes.string,
  agentFeedId: PropTypes.string
}

Task.defaultProps = {
  agentFeedId: '',
  simpleSaleFeedId: ''
}

export default Task
