import React from 'react'
import PropTypes from 'prop-types'
import { FeedItem } from 'components/FeedItem/index'
import { Button } from 'components/Button'
import { Graphic } from '../Graphic'
import styles from './WelcomeItem.module.scss'

export const WelcomeItemAgent = ({ name, occurredAt, id, displayedAt }) => (
  <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
    <div>
      <h3 className={styles.nameTitle}>Hi {name}! </h3>
      <div className={styles.welcomeText}>
        Welcome to HomeLight! <Graphic.LaughingSmiley />
      </div>
    </div>
    <br />
    <div>
      <div className={styles.boldText}>
        From the contact information you submitted, it looks like you may be a real estate agent.
      </div>
      <br />
      <div className={styles.boldText}>
        We strive to partner real estate agents in every area and would love to learn more about
        you.
      </div>
    </div>
    <Button as="primary" className={styles.primaryCta}>
      <a href={process.env.REACT_APP_PROS_SIGN_UP_URL}>Sign up</a>
    </Button>
  </FeedItem>
)

WelcomeItemAgent.propTypes = {
  name: PropTypes.string.isRequired,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

WelcomeItemAgent.defaultProps = {
  displayedAt: null
}
