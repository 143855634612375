import faker from 'faker'

const PROMPT_TYPES = [
  // 'agent_recommendation_feedback',
  'best_way_to_connect'
  // 'agent_connected',
  // 'investor_intro_option',
  // 'soft_agent_intro_option'
]

export default (overrides = {}) => {
  const promptType = faker.random.arrayElement(PROMPT_TYPES)
  let answerChoices = []
  const source = []
  if (promptType === 'agent_connected') {
    answerChoices = [
      { meet: 'I want to meet Jenny' },
      { already_scheduled: 'We already scheduled a meeting' },
      { dont_meet: "I don't want to meet with Jenny" }
    ]
  } else {
    answerChoices = [{ text: 'Call us' }, { call: 'Text us' }]
  }

  if (promptType === 'best_way_to_connect') {
    // source = ''
    // answerChoices = [
    //   { within_three_months: 'Within 3 months' },
    //   { three_to_twelve_months: '3-12 months' },
    //   { more_than_a_year: 'More than a year' }
    // ]
  }

  const attributes = {
    prompt_type: promptType,
    answer_choices: answerChoices,
    selected_answer: faker.random.word(),
    source
  }

  return { ...attributes, ...overrides }
}
