import React, { useState, useRef } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { Row, Col } from 'react-simple-flex-grid'
import Jsona from 'jsona'
import Raven from 'raven-js'
import { API_ENDPOINTS } from '../../constants/apiEndpoints'
import { postHlRequest, postRequest } from '../../utils/makeRequest'
import { trackMessagesEvent } from '../../utils/tracking'
import { GoBackHeader } from '../PageHeader/GoBackHeader'
import { MessageComposer } from './MessageComposer'

import styles from './NewAgentMessageViewIsolated.module.scss'

export const NewAgentMessageViewIsolated = () => {
  const { id } = useParams()
  const [messages, setMessages] = useState([])
  const [isSending, setIsSending] = useState(false)
  const endOfMessagesRef = useRef(null)

  const query = new URLSearchParams(useLocation().search)
  const agentFirstName = query.get('agentFirstName')
  const agentLeadId = query.get('agentLeadId')
  const agentDisplayName = query.get('agentDisplayName')
  const externalId = query.get('externalId')
  const history = useHistory()

  const introAgentThenRedirectToMessageThread = async messageId => {
    const postData = {
      agent_lead_id: agentLeadId,
      message_id: messageId
    }

    try {
      await postHlRequest(API_ENDPOINTS.introAgent, postData)
    } catch (error) {
      Raven.captureException(error)
    }

    history.push(`/messages/${messageId}`)
  }

  const sendMessage = async messageText => {
    const postData = {
      data: {
        content: { body: messageText },
        classification_name: 'in_app_message',
        recipient_id: id,
        recipient_type: 'agent'
      }
    }
    setIsSending(true)
    postRequest(API_ENDPOINTS.newMessage, postData).then(result => {
      const dataFormatter = new Jsona()
      const newMessage = dataFormatter.deserialize(result.data)
      setMessages([...messages, newMessage])
      trackMessagesEvent('Tap', 'sent_message')

      introAgentThenRedirectToMessageThread(newMessage.id)
      setIsSending(false)
    })
  }

  return (
    <div>
      <GoBackHeader goBackLink={`/#feed-item-${externalId}`} headerText={agentDisplayName} />
      <div className={styles.fullMessagesContainer}>
        <Row align="bottom">
          <Col span={12}>
            <div className={styles.beginningMessageHistory}>
              This is the beginning of your direct message history with {agentFirstName}. You can
              write them a message here.
            </div>
            <div ref={endOfMessagesRef} style={{ float: 'left', clear: 'both' }} />
          </Col>
          <Col span={12}>
            <MessageComposer
              className="transition-with-page"
              isSending={isSending}
              sendMessage={sendMessage}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}
