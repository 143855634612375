import React from 'react'
import PropTypes from 'prop-types'
import { AgentInvestorComparisonCard } from 'components/SimpleSale'
import { FeedItem } from '../FeedItem'
import styles from '../../SimpleSale/SimpleSaleCard.module.scss'
import {
  formatEstimatedValue,
  calculatePercentageOfEstimatedValue
} from '../../../utils/numberParser'

export const InvestorOption = ({
  scheduleCall,
  itemType,
  offerLow,
  offerHigh,
  occurredAt,
  id,
  displayedAt,
  description,
  headerText,
  simpleSaleItemType,
  userFeedItem
}) => (
  <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
    <div>
      <div className={styles.ssIntroDesc}>{description}</div>
      <AgentInvestorComparisonCard
        simpleSaleItemType={simpleSaleItemType}
        scheduleCall={scheduleCall}
        providerType="investor"
        itemType={itemType}
        headerText={headerText}
        description="Your estimated offer"
        marketPrepVal="0%"
        agentCommissionVal="0%"
        closingCostVal="0%"
        amount={formatEstimatedValue(offerLow, offerHigh)}
        amountLow={calculatePercentageOfEstimatedValue(offerLow, 0.85)}
        amountHigh={calculatePercentageOfEstimatedValue(offerHigh, 0.91)}
        buttonText="See how it works"
        goToLink="/simple-sale-results"
        disableSelected
        userFeedItem={userFeedItem}
      />
    </div>
  </FeedItem>
)

InvestorOption.propTypes = {
  offerLow: PropTypes.number.isRequired,
  offerHigh: PropTypes.number.isRequired,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  scheduleCall: PropTypes.bool,
  simpleSaleItemType: PropTypes.string,
  userFeedItem: PropTypes.shape({ item_type: PropTypes.string })
}

InvestorOption.defaultProps = {
  displayedAt: null,
  scheduleCall: false,
  simpleSaleItemType: null,
  userFeedItem: null
}
