export const FEED_ITEM_ANCHOR = 'feed-item-'

export function scrollToFeedItem() {
  if (window.location.hash) {
    const el = document.getElementById(window.location.hash.split('#').join(''))
    if (!el || !el.scrollIntoView) return
    el.scrollIntoView({ behavior: 'smooth' })
  }
}

export function anchorExistsAndMatchesRegex() {
  const REGEX = `#${FEED_ITEM_ANCHOR}[a-zA-Z]*`
  return (
    window.location.hash.match(REGEX) &&
    document.getElementById(window.location.hash.split('#').join(''))
  )
}
