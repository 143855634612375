export function toNearestThousandth(num) {
  return Math.ceil(Math.ceil(num) / 1000)
}

export function prettifiedEstimatedVal(num) {
  const roundedNum = toNearestThousandth(num)
  if (roundedNum >= 1000) {
    const millNum = roundedNum / 1000
    return `$${millNum}M`
  }
  return `$${new Intl.NumberFormat().format(roundedNum)}K`
}

export function formatEstimatedValue(min, max) {
  return prettifiedEstimatedVal((parseFloat(min) + parseFloat(max)) / 2)
}

export function roundToDecimal(num, decimalPlaces = 2) {
  if (!num) {
    return num
  }
  return +`${Math.round(`${num}e+${decimalPlaces}`)}e-${decimalPlaces}`
}

export function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

export function calculatePercentageOfEstimatedValue(value, percentage) {
  const newValue = value * percentage

  return prettifiedEstimatedVal(parseFloat(newValue))
}
