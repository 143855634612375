import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import moment from 'moment'
import Raven from 'raven-js'
import buttonStyles from './Button.module.scss'
import agentButtonStyles from './DisplayNumberButton.module.scss'
import { Graphic } from '../Graphic'
import { Button } from './Button'
import { cx } from '../../utils/cx'
import { formatPhoneNumber } from '../../utils/numberParser'
import { trackFeedItemGenericEvent } from '../../utils/tracking'
import { postHlRequest } from '../../utils/makeRequest'
import { trackSessionEvent } from '../../utils/feedItemClient'

export const DisplayNumberButton = ({ feedItemType, agentId, text, phone, agentLeadId }) => {
  const { leadType, leadId } = React.useContext(LeadContext)
  const [displayPhone, setDisplayPhone] = useState(false)
  const getIntroducedUrl = '/quiz_introduction_client_dashboard'
  const renderPhoneNumberAndRequestIntro = async () => {
    try {
      trackFeedItemGenericEvent(feedItemType, 'Tap', leadType, 'contact_call')
      setDisplayPhone(true)
      if (agentId) {
        const postData = {
          lead_id: leadId,
          agent_id: agentId,
          message: `Client requested intro on ${moment().format('MM/DD')} via phone call`
        }
        if (agentLeadId) {
          trackSessionEvent(leadId, 'Client', 'ClientTapAgentCallButton', `agent_id_${agentLeadId}`)
        }
        await postHlRequest(getIntroducedUrl, postData)
      }
    } catch (error) {
      Raven.captureException(error)
    }
  }

  return (
    // eslint-disable-next-line
    <div className={cx(agentButtonStyles.agentNumButton, buttonStyles.flex)}>
      <Button
        as="primary"
        className={cx(buttonStyles.primary, buttonStyles.agentCard)}
        onClick={renderPhoneNumberAndRequestIntro}
      >
        {displayPhone ? (
          <div>
            <Graphic.Phone className={agentButtonStyles.phoneIcon} />
            {formatPhoneNumber(phone)}
          </div>
        ) : (
          text
        )}
      </Button>
    </div>
  )
}

DisplayNumberButton.propTypes = {
  feedItemType: PropTypes.string.isRequired,
  agentId: PropTypes.number,
  text: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  agentLeadId: PropTypes.number
}

DisplayNumberButton.defaultProps = {
  agentId: null,
  agentLeadId: null
}
