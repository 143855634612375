import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import './ExpandTransition.scss'

export const ExpandTransition = ({
  expand,
  duration,
  children,
  // eslint-disable-next-line react/jsx-props-no-spreading
  ...props
}) => (
  <CSSTransition
    in={expand}
    timeout={duration}
    classNames="expand"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </CSSTransition>
)

ExpandTransition.propTypes = {
  expand: PropTypes.bool.isRequired,
  duration: PropTypes.number,
  children: PropTypes.node.isRequired,
  props: PropTypes.shape({})
}

ExpandTransition.defaultProps = {
  duration: 300,
  props: {}
}
