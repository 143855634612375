import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { FeedItem } from 'components/FeedItem'
import { PageResizeContext } from 'components/PageResize'
import { DisplayNumberButton } from 'components/Button/DisplayNumberButton'
import { isMobile } from '../../utils/breakpoint'
import { Button } from '../Button'
import { ContactButtons } from '../AgentCard'

export const CallGeneric = ({
  feedItemType,
  text,
  occurredAt,
  id,
  displayedAt,
  phoneNumber,
  buttonText
}) => {
  const [showContactButtons, setShowContactButtons] = useState(false)
  const { width } = useContext(PageResizeContext)

  const toggleContactButtons = () => {
    setShowContactButtons(!showContactButtons)
  }

  const formattedView = () => {
    if (isMobile(width)) {
      return (
        <Button as="primary" style={{ width: '100%' }} onClick={toggleContactButtons}>
          {buttonText}
        </Button>
      )
    }

    return <DisplayNumberButton text={buttonText} phone={phoneNumber} feedItemType={feedItemType} />
  }

  return (
    <div>
      <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
        <div>{text}</div>
        <div>{formattedView()}</div>
      </FeedItem>
      <ContactButtons
        show={showContactButtons}
        phoneNumber={phoneNumber}
        feedItemType={feedItemType}
      />
    </div>
  )
}

CallGeneric.propTypes = {
  feedItemType: PropTypes.string.isRequired,
  text: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

CallGeneric.defaultProps = {
  text: '',
  displayedAt: null
}
