import React from 'react'
import { LeadContext } from 'context/LeadContext'
import {
  WelcomeItemAgent,
  WelcomeItemBadPropertyType,
  WelcomeItemIncomplete,
  WelcomeItem,
  WelcomeItemWithEstimate,
  GenericCallToActionItem,
  GenericMessage
} from '../../../FeedItem'
import { titleize } from '../../../../utils/titleize'

export const RenderWelcomeItem = userFeedItem => {
  const firstName = titleize(userFeedItem.user.first_name)
  const { leadStatus } = React.useContext(LeadContext)

  if (leadStatus === 'bad_number') {
    const text =
      'If you would like HomeLight to match you with top performing real estate agents in your area, please reach out to speak with a Home Consultant. \n We hope to hear from you soon! \nBest Regards, \nThe HomeLight Team'
    return (
      <>
        <WelcomeItemIncomplete
          key={userFeedItem.id}
          name={firstName}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
        <GenericCallToActionItem
          text={text}
          actionType="callUs"
          buttonText="Contact us"
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      </>
    )
  }
  let prettyAddress
  let fullMessage

  switch (userFeedItem.item.welcome_type) {
    case 'hve_update_welcome':
      fullMessage = `Welcome back, ${firstName}.`
      return (
        <GenericMessage
          occurredAt={userFeedItem.occurred_at}
          text={fullMessage}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'invalid_known_agent':
      return (
        <WelcomeItemAgent
          key={userFeedItem.id}
          name={firstName}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'invalid_land_property':
      return (
        <WelcomeItemBadPropertyType
          key={userFeedItem.id}
          name={firstName}
          propertyType="vacant land"
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'invalid_mobile_home':
      return (
        <WelcomeItemBadPropertyType
          key={userFeedItem.id}
          name={firstName}
          propertyType="a mobile home"
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'valid_buyer_matches':
      prettyAddress = titleize(userFeedItem.item.address, ' ')
      return (
        <WelcomeItem
          key={userFeedItem.id}
          name={firstName}
          propertyType={userFeedItem.item.property_type || 'property'}
          address={prettyAddress}
          city={userFeedItem.item.city}
          additionalAreas={userFeedItem.item.additional_areas}
          transactionType="buy"
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'valid_seller_matches':
      prettyAddress = titleize(userFeedItem.item.address, ' ')
      return (
        <WelcomeItem
          key={userFeedItem.id}
          name={firstName}
          propertyType={userFeedItem.item.property_type || 'property'}
          address={prettyAddress}
          city={userFeedItem.item.city}
          transactionType="sell"
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'simple_sale':
      prettyAddress = titleize(userFeedItem.item.address, ' ')
      return (
        <WelcomeItemWithEstimate
          key={userFeedItem.id}
          type={userFeedItem.item.welcome_type}
          name={firstName}
          propertyType={userFeedItem.item.property_type || 'property'}
          address={prettyAddress}
          city={userFeedItem.item.city}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
          estimatedHomeValueLow={userFeedItem.item.home_value_low}
          estimatedHomeValueHigh={userFeedItem.item.home_value_high}
        />
      )
    case 'simple_sale_concierge':
      prettyAddress = titleize(userFeedItem.item.address, ' ')
      return (
        <WelcomeItemWithEstimate
          key={userFeedItem.id}
          type={userFeedItem.item.welcome_type}
          name={firstName}
          propertyType={userFeedItem.item.property_type || 'property'}
          address={prettyAddress}
          city={userFeedItem.item.city}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
          estimatedHomeValueLow={userFeedItem.item.home_value_low}
          estimatedHomeValueHigh={userFeedItem.item.home_value_high}
        />
      )
    case 'simple_home_value':
      prettyAddress = titleize(userFeedItem.item.address, ' ')
      return (
        <WelcomeItemWithEstimate
          key={userFeedItem.id}
          type={userFeedItem.item.welcome_type}
          name={firstName}
          propertyType={userFeedItem.item.property_type || 'property'}
          address={prettyAddress}
          city={userFeedItem.item.city}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
          estimatedHomeValueLow={userFeedItem.item.home_value_low}
          estimatedHomeValueHigh={userFeedItem.item.home_value_high}
        />
      )
    case 'simple_home_value_concierge':
      prettyAddress = titleize(userFeedItem.item.address, ' ')
      return (
        <WelcomeItemWithEstimate
          key={userFeedItem.id}
          type={userFeedItem.item.welcome_type}
          name={firstName}
          propertyType={userFeedItem.item.property_type || 'property'}
          address={prettyAddress}
          city={userFeedItem.item.city}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
          estimatedHomeValueLow={userFeedItem.item.home_value_low}
          estimatedHomeValueHigh={userFeedItem.item.home_value_high}
        />
      )
    default:
      return ''
  }
}
