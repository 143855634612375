import { titleize } from '../../utils/titleize'

export const GetLeadType = data => {
  const simpleHomeValLead = data.simple_home_value
  const simpleSaleLead = data.simple_sale
  const userType = data.user_type
  if (simpleHomeValLead) {
    return 'simple_home_value'
    // "simple_home_val" is included in the lead.simple_sale? check
    // so always check for home val before checking for simple sale
  }
  if (simpleSaleLead) {
    return 'simple_sale'
  }
  // default is seller.
  return userType === 'buyer' ? userType : 'seller'
}

export const GetFirstName = data => {
  const names = data.name && data.name.split(' ')
  // handle middle names
  const firstNames = names && (names.length > 1 ? names.slice(0, names.length - 1) : names)
  // Make 2-letter first names all uppercase
  const formattedFirstNames =
    firstNames && firstNames.map(name => (name.length < 3 ? name.toUpperCase() : titleize(name)))
  return formattedFirstNames && formattedFirstNames.join(' ')
}

export const GetLastName = data => data.name && data.name.split(' ').slice(1).join(' ')

export const GetPhoneNumber = (data, isTexting = false) => {
  const defaultPhone = isTexting
    ? process.env.REACT_APP_TWILIO_TEXTING_NUMBER
    : process.env.REACT_APP_PHONE_HC
  return (
    (data.sales_phone_number &&
      data.sales_phone_number.phone_numbers &&
      data.sales_phone_number.phone_numbers.length > 0 &&
      data.sales_phone_number.phone_numbers[0].number) ||
    defaultPhone
  )
}

export const FormatPhoneNumber = salesPhone => {
  if (salesPhone && salesPhone[0] === '+') {
    return salesPhone
  }
  // e_164 format
  return salesPhone ? `+1${salesPhone}` : null
}
