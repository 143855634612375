import React from 'react'
import PropTypes from 'prop-types'
import { cx } from '../../utils/cx'
import { PageTitle } from '../PageTitle'
import { PageSubTitle } from '../PageSubTitle'
import { PageHeader } from '../PageHeader'
import { PageContents } from '../PageContents'
import styles from './Page.module.scss'

export const Page = ({ className, title, subtitle, actions, nopad, children }) => {
  let pageHeader
  if (title || actions) {
    pageHeader = (
      <PageHeader>
        <PageTitle>
          {title}
          {subtitle ? <PageSubTitle>{subtitle}</PageSubTitle> : null}
        </PageTitle>
        {actions}
      </PageHeader>
    )
  }

  return (
    <div className={cx(nopad ? null : styles.pad, styles.page, className)} data-testid="page">
      {pageHeader}
      <PageContents>{children}</PageContents>
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  actions: PropTypes.node,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  nopad: PropTypes.bool
}

Page.defaultProps = {
  children: undefined,
  className: undefined,
  actions: undefined,
  title: undefined,
  subtitle: undefined,
  nopad: undefined
}

export default Page
