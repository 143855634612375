import faker from 'faker'

export default (overrides = {}) => {
  const attributes = {
    home_value_high: faker.datatype.number({ min: 100000, max: 2000000 }),
    home_value_low: faker.datatype.number({ min: 10000, max: 100000 }),
    hve_updated_at: faker.date.past
  }

  return { ...attributes, ...overrides }
}
