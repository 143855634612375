import { RenderWelcomeItem } from './Shared/WelcomeItem'
import { RenderAgentRecommendation } from './Shared/AgentRecommendation'
import { RenderBlogPost } from './Shared/BlogPost'
import { RenderDirectAgentMatch } from './Shared/DirectAgentMatch'
import { RenderCallToAction } from './Shared/CallToAction'
import { RenderMissedHCCall } from './Shared/MissedHCCall'
import { RenderMissedAgentCall } from './Shared/MissedAgentCall'
import { RenderAgentRecommendationFeedback } from './Shared/AgentRecommendationFeedback'
import { RenderUpdatedHomeValEstimate } from './Shared/UpdatedHomeValEstimate'
import { RenderDefault } from './Shared/Default'
import { RenderMultipleChoice } from './Shared/MultipleChoice'
import { RenderUserInput } from './Shared/UserInput'

export const RenderSharedFeedItem = (userFeedItem, leadType, refreshData) => {
  switch (userFeedItem.item_type) {
    case 'welcome_item':
      return RenderWelcomeItem(userFeedItem)
    case 'updated_home_val_estimate':
      return RenderUpdatedHomeValEstimate(userFeedItem)
    case 'agent_recommendation':
      return RenderAgentRecommendation(userFeedItem)
    case 'agent_recommendation_feedback':
      return RenderAgentRecommendationFeedback(userFeedItem)
    case 'call_to_action':
      return RenderCallToAction(userFeedItem)
    case 'multiple_choice':
      return RenderMultipleChoice(userFeedItem, leadType, refreshData)
    case 'direct_agent_match':
      return RenderDirectAgentMatch(userFeedItem)
    case 'missed_hc_call':
      return RenderMissedHCCall(userFeedItem)
    case 'missed_agent_call':
      return RenderMissedAgentCall(userFeedItem)
    case 'blog_post':
      return RenderBlogPost(userFeedItem)
    case 'user_input':
      return RenderUserInput(userFeedItem, refreshData)
    case 'my_team_agent':
      return null
    default:
      return RenderDefault(userFeedItem)
  }
}
