import React from 'react'
import PropTypes from 'prop-types'
import { cx } from '../../utils/cx'
import styles from './UnreadMessageCountNotification.module.scss'

export const UnreadMessageCountNotification = ({ unreadMessagesCount, mobile }) =>
  unreadMessagesCount && unreadMessagesCount > 0 ? (
    <span className={cx(styles.unreadMessageCountNotification, mobile ? styles.mobile : '')}>
      {unreadMessagesCount}
    </span>
  ) : null

UnreadMessageCountNotification.propTypes = {
  unreadMessagesCount: PropTypes.number,
  mobile: PropTypes.bool
}

UnreadMessageCountNotification.defaultProps = {
  unreadMessagesCount: 0,
  mobile: false
}

export default UnreadMessageCountNotification
