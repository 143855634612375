export const titleize = (sourceString, separator = /[\s_-]+/g) => {
  if (!sourceString) {
    return ''
  }
  return sourceString
    .toLowerCase()
    .split(separator)
    .join(' ')
    .replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
}
