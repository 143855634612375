/* istanbul ignore file */

import { putRequest, postRequest, postHlRequest } from 'utils/makeRequest'

export const updateFeedItem = (answer, feedItem) => {
  // originally made for the call_to_action items but can be used for more generic item updates
  const url = `/api/user-feed-service/feed/update-call-to-action`
  const putData = {
    user_feed_item_id: feedItem.id,
    selected_answer: answer
  }
  return putRequest(url, putData)
}

export const updateUserInputItem = (answer, feedItemId) => {
  const url = `/api/user-feed-service/feed/update-user-input`
  const putData = {
    user_feed_item_id: feedItemId,
    selected_answer: answer
  }
  return putRequest(url, putData)
}

export const sendReplytoWelcomeMessage = replyMessage => {
  const url = `/api/messaging-service/messages/welcome-message-reply?`
  const postData = {
    data: {
      content: {
        body: replyMessage
      },
      classification_name: 'in_app_message'
    }
  }
  return postRequest(url, postData)
}

export const updateMultipleChoiceItem = (answer, feedItem) => {
  const url = `/api/user-feed-service/feed/update-multiple-choice`
  const putData = {
    user_feed_item_id: feedItem.id,
    selected_answer: answer
  }
  if (feedItem.item.prompt_type === 'agent_recommendation_feedback' && answer === 'more_agents') {
    putData.num_agents = 2
    putData.last_agent_lead_rank = 3
    putData.add_more_agents = true
  }
  return putRequest(url, putData)
}

export const clientCommunicationAction = (option, leadSecureToken) => {
  const scheduleCallUrl = `${process.env.REACT_APP_HOMELIGHT_REPO_URL}/schedule-client-call`
  const scheduleCall = callMeTime => {
    postRequest(scheduleCallUrl, {
      call_me_time: callMeTime,
      lead_token: leadSecureToken
    })
  }

  switch (option) {
    case 'call_me_asap':
      return scheduleCall('now')
    case 'schedule_call_select_time':
      return scheduleCall('later')
    default:
      return null
  }
}

export const createMultipleChoice = (
  promptType = 'schedule_call_select_day',
  agentName = null,
  agentTwilioPhone = null
) => {
  const url = `/api/user-feed-service/feed/create-multiple-choice`
  const postData = {
    prompt_type: promptType,
    agent_name: agentName,
    agent_twilio_phone: agentTwilioPhone
  }

  return postRequest(url, postData)
}

export const createUserInput = (inputType = 'schedule_call_select_time', previousAnswer = null) => {
  const url = `/api/user-feed-service/feed/create-user-input`
  const postData = {
    input_type: inputType,
    previous_answer: previousAnswer
  }

  return postRequest(url, postData)
}

export const createGenericMessage = (
  messageType = 'generic_message',
  agent_name = null,
  agent_twilio_phone = null
) => {
  const url = `/api/user-feed-service/feed/create-generic-message`
  const postData = {
    message_type: messageType,
    agent_name,
    agent_twilio_phone
  }

  return postRequest(url, postData)
}

export const createAgentConnectedBlogPost = agentName => {
  const url = `/api/user-feed-service/feed/create-blog-post`
  const blogAttributes = {
    title: '24 Questions to ask a real estate agent worth their salt',
    text: 'Selling a home is one of the largest transactions people will undertake in their lifetime. So, why do 72% of recent sellers contact only one realtor before finding a listing agent?',
    url: 'https://www.homelight.com/blog/questions-to-ask-a-real-estate-agent/',
    description: 'Here is a good read to prepare for your meeting with your real estate agent.'
  }
  const postData = {
    title: blogAttributes.title,
    text: blogAttributes.text,
    url: blogAttributes.url,
    tag: blogAttributes.tag,
    hero_image: blogAttributes.hero,
    description: blogAttributes.description,
    agent_name: agentName
  }
  return postRequest(url, postData)
}

export const markAgentLeadMeetingScheduled = agentLeadId => {
  const updateAgentLeadStageData = {
    agent_lead_id: Number(agentLeadId),
    new_stage: 'meeting_scheduled'
  }
  const url = '/agent-lead-stage-update'
  postHlRequest(url, updateAgentLeadStageData)
}

export const createNewItemsFromMultChoice = (
  answer,
  agent_name = null,
  agent_lead_id = null,
  agent_twilio_phone = null
) => {
  switch (answer) {
    case 'accept':
      return createGenericMessage()
    case 'call_me_asap':
      return createGenericMessage('call_me_asap_selected')
    case 'schedule_call':
      return createMultipleChoice('schedule_call_select_day')
    case 'first_day_selected':
    case 'second_day_selected':
    case 'third_day_selected':
      return createUserInput('schedule_call_select_time', answer)
    case 'text_me':
      return sendReplytoWelcomeMessage('I’d prefer to connect through texting.').then(
        createGenericMessage('text_me_selected')
      )
    case 'not_interested':
      return sendReplytoWelcomeMessage('I’m not ready to talk.').then(
        createGenericMessage('not_interested_selected')
      )
    case 'meet':
      return createGenericMessage('agent_meeting_requested', agent_name, agent_twilio_phone)
    case 'already_scheduled':
      return (
        createGenericMessage('agent_meeting_scheduled', agent_name),
        createAgentConnectedBlogPost(agent_name),
        markAgentLeadMeetingScheduled(agent_lead_id)
      )
    case 'dont_meet':
      return createGenericMessage('agent_meeting_declined', agent_name)
    default:
      return null
  }
}

// Used primarily for the flows that require new feed items to load in
// in the middle of the feed by being "unhidden"
export const unhideFeedItems = (leadType, itemType, chosenAnswer = null) => {
  const url = `/api/user-feed-service/feed/unhide-feed-items`

  const putData = {
    lead_type: leadType,
    request_item_type: itemType,
    chosen_answer: chosenAnswer
  }
  return putRequest(url, putData)
}

export const updateHomeValueEstimateItem = () => {
  const url = `/api/user-feed-service/feed/update-updated-home-value-estimate-item`
  return putRequest(url, {})
}

export const trackSessionEvent = (leadId, subCategory, label, value) => {
  const url = `api/events-service/session-events/record-session-event`

  const postData = {
    lead_id: leadId,
    category: 'ClientDashboard',
    sub_category: subCategory,
    label,
    value
  }
  return postRequest(url, postData)
}
