import Raven from 'raven-js'
import { fireCustomEvent } from '../../../utils/fireCustomEvent'
import { fetchUnreadMessagesCount } from './fetchUnreadMessagesCount'

export const fetchAndPublishUnreadMessagesCount = () => {
  fetchUnreadMessagesCount()
    .then(result => {
      window.unreadMessagesCount = result.data.count
      fireCustomEvent('unreadMessagesCount', { count: result.data.count })
    })
    .catch(error => {
      Raven.captureException(error)
    })
}
