import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import moment from 'moment-timezone'
import { RenderBadNumberFeedItem } from './BadNumberFeed'
import { RenderSimpleSaleFeedItem } from './SimpleSaleFeed'
import { RenderBuyerFeedItem } from './BuyerFeed'
import { RenderSellerFeedItem } from './SellerFeed'
import { RenderHlcaFeedItem } from './HlcaFeed'
import { RenderSimpleHomeValueFeedItem } from './SimpleHomeValueFeed'
import { ItemLoadingSequence } from './ItemLoadingSequence'
import { CallMeMaybeModal } from './CallMeMaybeModal'
import {
  FEED_ITEM_ANCHOR,
  scrollToFeedItem,
  anchorExistsAndMatchesRegex
} from '../../utils/feedHelpers'
import { initializeGTM } from '../../utils/tracking'
import { isDuringSalesBusinessHours } from '../../utils/businessHours'

initializeGTM('DashboardDataLayer')

export const DashboardFeed = ({ feedItems, refreshData, mobileMenuOpen }) => {
  const nonHiddenItems = feedItems.filter(item => item.hidden_at === null)
  const seenItems = nonHiddenItems.filter(item => item.displayed_at)
  const lastSeenItem = seenItems && seenItems[seenItems.length - 1]
  const loadingItemIndex = nonHiddenItems.findIndex(item => !item.displayed_at)

  const [isFirstPageLoad, setisFirstPageLoad] = useState(true)
  const [midFeedLoad, setMidFeedLoad] = useState(false)
  const {
    leadStatus,
    leadType,
    callReview,
    hlcaQuizLead,
    leadPhoneNumber,
    leadId,
    isLoading,
    showCallMeMaybe
  } = useContext(LeadContext)
  const [showModal, setShowModal] = useState(false)

  const getCookie = name => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  }

  const leadIsSem = () => {
    const originalUtmCampaign = getCookie('original_utm_campaign')
    const originalGclid = getCookie('original_gclid')

    return Boolean(originalUtmCampaign || originalGclid)
  }

  useEffect(() => {
    if (isLoading) return

    if (!showCallMeMaybe) return

    if ((leadType === 'seller' || leadType === 'buyer') && !leadIsSem) return

    if (isDuringSalesBusinessHours(moment().tz('America/Los_Angeles'))) {
      setShowModal(true)
    }
  }, [leadType, isLoading])

  useEffect(() => {
    const existingHash = window.location.hash

    if (!existingHash && lastSeenItem && lastSeenItem.external_id && !mobileMenuOpen) {
      window.location.hash = `${FEED_ITEM_ANCHOR}${lastSeenItem.external_id}`
    }

    if (isFirstPageLoad && anchorExistsAndMatchesRegex() && !mobileMenuOpen) {
      scrollToFeedItem()
      setisFirstPageLoad(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadFromMiddleOfFeed = () => {
    setMidFeedLoad(true)
    refreshData()
  }

  function renderFeedItemByLeadType(userFeedItem) {
    if (leadStatus === 'bad_number') {
      return RenderBadNumberFeedItem(userFeedItem)
    }
    /* istanbul ignore next */
    if (hlcaQuizLead && process.env.REACT_APP_HLCA_CLIENT_FF) {
      return RenderHlcaFeedItem(userFeedItem, callReview, leadType, refreshData)
    }
    switch (leadType) {
      case 'simple_home_value':
        return RenderSimpleHomeValueFeedItem(userFeedItem, leadType, loadFromMiddleOfFeed)
      case 'simple_sale':
        return RenderSimpleSaleFeedItem(userFeedItem, leadType, loadFromMiddleOfFeed)
      case 'buyer':
        return RenderBuyerFeedItem(userFeedItem, leadType, refreshData)
      default:
        return RenderSellerFeedItem(userFeedItem, leadType, refreshData)
    }
  }

  return (
    <>
      {showModal && <CallMeMaybeModal setShowModal={setShowModal} leadType={leadType} />}

      <ItemLoadingSequence
        seenItems={seenItems}
        refreshData={refreshData}
        loadingItem={nonHiddenItems[loadingItemIndex]}
        loadingItemIndex={loadingItemIndex}
        renderByLeadType={renderFeedItemByLeadType}
        midFeedLoad={midFeedLoad}
        leadType={leadType}
      />
    </>
  )
}

DashboardFeed.propTypes = {
  feedItems: PropTypes.arrayOf(PropTypes.any),
  refreshData: PropTypes.func.isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired
}

DashboardFeed.defaultProps = {
  feedItems: []
}
