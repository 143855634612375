import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Graphic } from 'components/Graphic'
import { PageResizeContext } from 'components/PageResize'

import { isDesktop } from '../../utils/breakpoint'
import { cx } from '../../utils/cx'

import { Avatar } from '../Avatar'
import styles from './AccountMenu.module.scss'

export const AccountMenuTrigger = ({ name, onClick }) => {
  const { width } = useContext(PageResizeContext)

  return (
    <div className={styles.accountMenuTrigger} onClick={onClick} data-testid="account-menu-trigger">
      <Avatar name={name} />
      {isDesktop(width) && <Graphic.SmallAngleDown className={styles.angle} />}
    </div>
  )
}

export const AccountUserInfo = ({ name, email }) => (
  <section className={styles.accountMenuUserInfo}>
    <Avatar name={name} />
    <div>
      <p className={styles.accountUserName} data-testid="account-menu-user-name">
        {name}
      </p>
      <p className={styles.accountUserEmail} data-testid="account-menu-user-email">
        {email}
      </p>
    </div>
  </section>
)

export const AccountSection = ({ children }) => (
  <section className={styles.accountMenuSection}>{children}</section>
)

export const AccountOption = ({ item, onClick, id }) => {
  const { text, href, local } = item
  const testId = `account-menu-option-${id}`

  return (
    <>
      {local ? (
        <Link className={styles.option} to={href} onClick={onClick} data-testid={testId}>
          {text}
        </Link>
      ) : (
        <a
          href={href}
          className={styles.option}
          target="_blank"
          rel="noopener noreferrer"
          data-testid={testId}
        >
          {text}
        </a>
      )}
    </>
  )
}
