/* istanbul ignore file */
/**
 *  Test coverage ignored here for a few reasons; one, the file is complex when working with the Mapbox package's events.
 *  Second, the nature of this component (rendering visible map layers) makes it more aesthetically visible and QA
 *  testable by hand.
 */
import mapboxgl from 'mapbox-gl'
import moment from 'moment'

const createGeoJSON = transactions => {
  const features = transactions.map(transaction => {
    const { address, city, state, propertyType, represented, price, date } = transaction

    return {
      type: 'Feature',
      properties: { address, city, state, propertyType, represented, price, date },
      geometry: {
        type: 'Point',
        coordinates: [transaction.lng, transaction.lat]
      }
    }
  })

  return {
    type: 'FeatureCollection',
    features
  }
}

const clustersLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'transactions',
  filter: ['has', 'point_count'],
  paint: {
    // step expressions (https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
    'circle-color': [
      'step',
      ['get', 'point_count'],
      'rgba(70, 182, 255, 0.8)',
      100,
      'rgba(255,165,59, 0.8)'
    ],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff'
  }
}

const clusterCountLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'transactions',
  filter: ['has', 'point_count'],
  paint: { 'text-color': '#fff' },
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12
  }
}

const unclusteredPointLayer = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'transactions',
  filter: ['!has', 'point_count'],
  paint: {
    'circle-color': 'rgb(70, 182, 255)',
    'circle-radius': 6,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff'
  }
}

const zoomIntoCluster = map => e => {
  const features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] })
  const clusterId = features[0].properties.cluster_id

  map.getSource('transactions').getClusterExpansionZoom(clusterId, (err, zoom) => {
    if (err) return

    map.easeTo({
      center: features[0].geometry.coordinates,
      zoom
    })
  })
}

const showTransactionDetails = map => e => {
  const feature = e.features[0]
  const coordinates = feature.geometry.coordinates.slice()
  const { address, city, state, propertyType, represented, price, date } = feature.properties
  const description = []

  if (price) {
    description.push(`<div class="map-pop-stat map-pop-price">${price}</div>`)
    description.push(
      `<div class="map-pop-stat map-pop-sale-date">Sold ${moment(date).fromNow()}</div>`
    )
  }

  const locationDisplay = address ? `${address}, ${city}` : `${city}, ${state}`
  description.push(`<div class="map-pop-stat map-pop-address">${locationDisplay}</div>`)

  description.push(
    `<div class="map-pop-stat map-pop-property-info">${propertyType} &bull; Represented ${represented}</div>`
  )

  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
  }

  new mapboxgl.Popup().setLngLat(coordinates).setHTML(description.join('')).addTo(map)
}

const addClusters = (map, data) => {
  map.on('load', () => {
    map.addSource('transactions', {
      type: 'geojson',
      data,
      cluster: true,
      clusterMaxZoom: 18, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    })

    map.addLayer(clustersLayer)

    // Add the count of transactions being clustered
    map.addLayer(clusterCountLayer)

    // When zoomed in far enough, create an unclustered point to represent the transaction
    map.addLayer(unclusteredPointLayer)

    map.on('click', 'clusters', zoomIntoCluster(map))
    map.on('click', 'unclustered-point', showTransactionDetails(map))

    const makePointer = () => {
      // eslint-disable-next-line no-param-reassign
      map.getCanvas().style.cursor = 'pointer'
    }

    const clearPointer = () => {
      // eslint-disable-next-line no-param-reassign
      map.getCanvas().style.cursor = ''
    }

    map.on('mouseenter', 'clusters', makePointer)
    map.on('mouseleave', 'clusters', clearPointer)
    map.on('mouseenter', 'unclustered-point', makePointer)
    map.on('mouseleave', 'unclustered-point', clearPointer)
  })
}

export { createGeoJSON, addClusters }
