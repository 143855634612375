import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CardHeaderText } from 'components/FeedItem'
import { CallToAction } from 'components/common/CallToAction'

import inactiveStyles from './DisabledFeedItem.module.scss'

export const GenericCallToActionOptions = ({
  title,
  text,
  selectedAnswerKey,
  disableReselect,
  options
}) => {
  const [answerKeySelected, setAnswerKeySelected] = useState(selectedAnswerKey)
  const disabled = answerKeySelected && disableReselect

  return (
    <div className={disabled ? inactiveStyles.disabled : ''}>
      <CardHeaderText title={title} text={text} />
      {options.map(option => (
        <div key={option.buttonText}>
          <CallToAction
            selected={answerKeySelected === option.answerKey}
            disabled={disabled}
            onClick={() => {
              setAnswerKeySelected(option.answerKey)
              option.onOptionClick()
            }}
          >
            {option.buttonText}
          </CallToAction>
        </div>
      ))}
    </div>
  )
}

GenericCallToActionOptions.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  selectedAnswerKey: PropTypes.string,
  disableReselect: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onOptionClick: PropTypes.func,
      buttonText: PropTypes.string,
      answerKey: PropTypes.string
    })
  )
}

GenericCallToActionOptions.defaultProps = {
  text: "We'd love to connect you with top real estate agents like you requested, but we need a little more info to confirm they're the right fit. \nWhat's the best way for us to connect?",
  title: null,
  selectedAnswerKey: null,
  disableReselect: true,
  options: [
    {
      onOptionClick: null,
      buttonText: 'Call me ASAP',
      answerKey: '1'
    },
    {
      onOptionClick: null,
      buttonText: 'Schedule a call',
      answerKey: '2'
    },
    {
      onOptionClick: null,
      buttonText: 'Text me',
      answerKey: '3'
    },
    {
      onOptionClick: null,
      buttonText: "I don't want to be contacted",
      answerKey: '4'
    }
  ]
}
