import React, { useState, useContext } from 'react'

import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { Link } from 'react-router-dom'
import { CheckCircleIcon, MinusCircleIcon } from 'components/Graphic'
import { CallToAction } from 'components/common/CallToAction'
import { LeadContext } from 'context/LeadContext'
import Raven from 'raven-js'
import { SimpleSaleCard } from './SimpleSaleCard'
import styles from './SimpleSaleCard.module.scss'
import InvestorSeeHowItWorksButton from './InvestorSeeHowItWorksButton'
import { ScheduleCallButton } from '../common/ScheduleCall'
import { optimisticallyUnhideAgentRecommendations } from '../FeedItem/helpers/OptimisticUnhideFeedItem'
import { PageContext } from '../../context/PageContext'
import { updateFeedItem, unhideFeedItems } from '../../utils/feedItemClient'
import { trackFeedItemEvent } from '../../utils/tracking'

export const AgentInvestorComparisonCard = ({
  providerType,
  itemType,
  headerText,
  description,
  amount,
  amountHigh,
  amountLow,
  marketPrepVal,
  agentCommissionVal,
  closingCostVal,
  buttonText,
  onClick,
  goToLink,
  selected,
  scheduleCall,
  simpleSaleItemType,
  disableSelected,
  userFeedItem
}) => {
  const [disabled, setDisabled] = useState(false)
  const disableSelectedOption = disabled && disableSelected
  const { feed, updateFeed } = useContext(PageContext)
  const { leadType } = useContext(LeadContext)

  const captureError = error => {
    Raven.captureException(error)
  }

  const unhideRemainingFeedItems = () => {
    optimisticallyUnhideAgentRecommendations(feed, updateFeed)
    unhideFeedItems(leadType, 'agent_recommendation').catch(captureError)
    unhideFeedItems(leadType, 'agent_recommendation_feedback').catch(captureError)
    unhideFeedItems(leadType, 'multiple_choice').catch(captureError)
  }

  const yesAndUnhideFeedItems = () => {
    unhideRemainingFeedItems()
    updateFeedItem('yes', userFeedItem)
  }

  const noAndUnhideFeedItems = () => {
    unhideRemainingFeedItems()
    updateFeedItem('no', userFeedItem)
    setDisabled(true)
    trackFeedItemEvent(userFeedItem, 'Tap', leadType, 'not_interested')
  }

  const icon =
    providerType === 'agent' ? (
      <MinusCircleIcon className={styles.checkIcon} />
    ) : (
      <CheckCircleIcon className={styles.checkIcon} />
    )
  const benefitsSection = (
    <>
      <Row align="bottom" className={styles.lineItem}>
        <Col span={1}>
          <div className={styles.iconContainer}>{icon}</div>
        </Col>
        <Col span={7}>
          <div className={styles.points}>Market preparation</div>
        </Col>
        <Col span={4}>
          <div className={styles.pointsBold}>{marketPrepVal}</div>
        </Col>
      </Row>
      <Row align="bottom" className={styles.lineItem}>
        <Col span={1}>
          <div className={styles.iconContainer}>{icon}</div>
        </Col>
        <Col span={7}>
          <div className={styles.points}>Agent commissions</div>
        </Col>
        <Col span={4}>
          <div className={styles.pointsBold}>{agentCommissionVal}</div>
        </Col>
      </Row>
      <Row align="bottom" className={styles.lineItem}>
        <Col span={1}>
          <div className={styles.iconContainer}>{icon}</div>
        </Col>
        <Col span={7}>
          <div className={styles.points}>Closing costs</div>
        </Col>
        <Col span={4}>
          <div className={styles.pointsBold}>{closingCostVal}</div>
        </Col>
      </Row>
    </>
  )

  function calendlySchedule(shouldShowCalendlyLink) {
    if (shouldShowCalendlyLink) {
      return (
        <Col span={12}>
          <ScheduleCallButton yesAndUnhideFeedItems={yesAndUnhideFeedItems} />
        </Col>
      )
    }

    return null
  }

  const buttonSection = (
    <div>
      <Row align="middle" justify="start">
        {simpleSaleItemType === 'hlss_eligible' ? null : (
          <Col span={12}>
            <InvestorSeeHowItWorksButton
              simpleSaleItemType={simpleSaleItemType}
              itemType={itemType}
              buttonText={buttonText}
              goToLink={goToLink}
              onClick={onClick}
              selected={selected}
              unhideRemainingFeedItems={unhideRemainingFeedItems}
            />
          </Col>
        )}
        {calendlySchedule(scheduleCall)}
        {scheduleCall ? (
          <CallToAction
            disabled={disableSelectedOption}
            onClick={() => {
              noAndUnhideFeedItems()
            }}
          >
            I&apos;m not interested
          </CallToAction>
        ) : null}
      </Row>
    </div>
  )

  return (
    <>
      {simpleSaleItemType === 'hlss_eligible' ? (
        <Link to="/simple-sale-results">
          <a
            className={styles.learnMore}
            rel="noopener noreferrer"
            onClick={unhideRemainingFeedItems}
          >
            Learn more
          </a>
        </Link>
      ) : null}
      <SimpleSaleCard
        headerText={headerText}
        description={description}
        amount={simpleSaleItemType === 'hlss_eligible' ? `${amountLow} - ${amountHigh}` : amount}
        benefitsNode={benefitsSection}
        buttonSection={buttonSection}
      />
    </>
  )
}

AgentInvestorComparisonCard.propTypes = {
  itemType: PropTypes.string.isRequired,
  amount: PropTypes.string,
  amountHigh: PropTypes.string,
  amountLow: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  marketPrepVal: PropTypes.string.isRequired,
  agentCommissionVal: PropTypes.string.isRequired,
  closingCostVal: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  goToLink: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  providerType: PropTypes.string.isRequired,
  scheduleCall: PropTypes.bool,
  simpleSaleItemType: PropTypes.string,
  disableSelected: PropTypes.bool.isRequired,
  userFeedItem: PropTypes.shape({
    item: PropTypes.shape({ selected_answer: PropTypes.string }),
    item_type: PropTypes.string
  })
}

AgentInvestorComparisonCard.defaultProps = {
  amount: '$540k',
  amountHigh: '$640k',
  amountLow: '$340k',
  goToLink: null,
  onClick: null,
  buttonText: 'See how it works',
  selected: false,
  scheduleCall: false,
  simpleSaleItemType: null,
  userFeedItem: PropTypes.shape({})
}

export default AgentInvestorComparisonCard
