import { Factory } from 'miragejs'
import faker from 'faker'

export default Factory.extend({
  title() {
    return faker.lorem.sentence()
  },
  percent_complete() {
    return faker.datatype.number({ min: 0.9, max: 1, precision: 0.1 })
  }
})
