import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { Link } from 'react-router-dom'
import { Graphic } from '../Graphic'
import styles from './GoBackHeader.module.scss'

export const DismissHeader = ({ dismissLink, headerText }) => (
  <div className={styles.goBackHeaderContainer}>
    <div className={styles.header}>
      <Row justify="left" align="middle">
        <Col className={styles.buttonContainer} span={1}>
          <div className={styles.backArrow}>
            <Link to={dismissLink}>
              <Graphic.CloseIcon className={styles.dismissIcon} />
            </Link>
          </div>
        </Col>
        <Col span={11}>
          <div className={styles.headerText}>{headerText}</div>
        </Col>
      </Row>
    </div>
  </div>
)

DismissHeader.propTypes = {
  dismissLink: PropTypes.string.isRequired,
  headerText: PropTypes.string
}

DismissHeader.defaultProps = {
  headerText: 'HomeLight'
}
