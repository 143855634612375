import faker from 'faker'

export default (overrides = {}) => {
  const attributes = {
    agent_id: faker.datatype.number({ min: 0, max: 1000000 }),
    agent_name: `${faker.name.firstName()} ${faker.name.lastName()}`,
    agent_twilio_phone: faker.phone.phoneNumber()
  }

  return { ...attributes, ...overrides }
}
