import TagManager from 'react-gtm-module'
import { camelToSnakeCase } from '../camelToSnakeCase'

export function initializeGTM(dataLayerName = undefined) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    dataLayerName
  }
  TagManager.initialize(tagManagerArgs)
}

export function trackEvent(
  category,
  action,
  label,
  value = 0,
  dataLayerName = 'DashboardDataLayer'
) {
  const tagManagerArgs = {
    dataLayer: {
      event: 'track_event',
      category,
      action,
      label,
      value
    },
    dataLayerName
  }
  TagManager.dataLayer(tagManagerArgs)
}

export function trackVariable(name, value, dataLayerName = 'DashboardDataLayer') {
  const tagManagerArgs = {
    dataLayer: {
      [name]: value
    },
    dataLayerName
  }

  TagManager.dataLayer(tagManagerArgs)
}

export function getFeedItemName(userFeedItem, leadType) {
  const itemType = userFeedItem.item_type
  const getMultipleChoiceName = feedItem => {
    if (feedItem.item.source && feedItem.item.source === 'updated_home_value_estimate') {
      return 'HVE_email_clickthru_nextsteps'
    }

    return feedItem.item.prompt_type
  }
  const getWelcomeItemName = feedItem => {
    if (feedItem.item.welcome_type === 'hve_update_welcome') {
      return 'HVE_email_clickthru_welcome'
    }
    return `${feedItem.item.welcome_type}_welcome_item`
  }

  const getSimpleSaleName = feedItem => {
    if (feedItem.item.ss_item_type === 'hlss_eligible') {
      return 'introduce_homelight_as_buyer'
    }
    return userFeedItem.item_type
  }

  let itemName

  switch (itemType) {
    case 'call_to_action':
      itemName = `${userFeedItem.item.reason}_${userFeedItem.item.action_type}`
      break
    case 'simple_sale':
      itemName = getSimpleSaleName(userFeedItem)
      break
    case 'multiple_choice':
      itemName = getMultipleChoiceName(userFeedItem)
      break
    case 'generic_message':
      itemName = userFeedItem.item.message_type
      break
    case 'welcome_item':
      itemName = getWelcomeItemName(userFeedItem)
      break
    case 'direct_agent_match':
      itemName = `${userFeedItem.item.direct_match_type}`
      break
    case 'updated_home_val_estimate':
      itemName = 'HVE_email_clickthru_estimate'
      break
    default:
      itemName = itemType
  }

  return leadType ? `${leadType}_${itemName}` : itemName
}

export function getFeedItemEventName(userFeedItem, action, leadType = null, selectedOption = null) {
  const itemName = getFeedItemName(userFeedItem, leadType)
  let labelName = itemName
  if (action === 'Tap' && selectedOption) {
    labelName = `${itemName}.${selectedOption}`
  }
  return labelName
}

export function getChecklistEventName(leadType, taskName) {
  if (leadType) {
    return taskName
      ? `progress_checklist_${leadType}.${camelToSnakeCase(taskName)}`
      : `progress_checklist_${leadType}`
  }
  return taskName ? `progress_checklist.${camelToSnakeCase(taskName)}` : 'progress_checklist'
}

export function getMessagesEventName(messageAction) {
  return `messages.${camelToSnakeCase(messageAction)}`
}

export function getAccountEventName(accountAction, leadType) {
  return leadType ? `account_${leadType}.${accountAction}` : `account.${accountAction}`
}

export function getContactEventName(leadType, feedItemType, selectedOption) {
  return leadType
    ? `${leadType}_${feedItemType}.${selectedOption}`
    : `${feedItemType}.${selectedOption}`
}

export function trackMessagesEvent(action, messageAction) {
  const labelName = getMessagesEventName(messageAction)
  trackEvent('Dashboard Messages', action, labelName, 0, 'DashboardDataLayer')
}

export function trackChecklistEvent(action, leadType, taskName) {
  const labelName = getChecklistEventName(leadType, taskName)
  trackEvent('Dashboard Checklist', action, labelName, 0, 'DashboardDataLayer')
}

export function trackFeedItemEvent(userFeedItem, action, leadType, selectedOption = null) {
  const labelName = getFeedItemEventName(userFeedItem, action, leadType, selectedOption)
  trackEvent('Dashboard Feed', action, labelName, 0, 'DashboardDataLayer')
}

export function trackFeedItemGenericEvent(feedItemType, action, leadType, selectedOption) {
  const labelName = getContactEventName(leadType, feedItemType, selectedOption)
  trackEvent('Dashboard Feed', action, labelName, 0, 'DashboardDataLayer')
}

export function trackAccountEvent(action, accountAction, leadType) {
  const labelName = getAccountEventName(accountAction, leadType)
  trackEvent('Dashboard Account', action, labelName, 0, 'DashboardDataLayer')
}

export function trackPageView(pageViewPath, dataLayerName) {
  const tagManagerArgs = {
    dataLayer: {
      event: 'track_page_view',
      page_view_path: pageViewPath
    },
    dataLayerName
  }

  TagManager.dataLayer(tagManagerArgs)
}
