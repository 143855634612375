import React from 'react'
import PropTypes from 'prop-types'
import { FeedItem } from 'components/FeedItem/index'
import { LeadContext } from 'context/LeadContext'
import { Graphic } from '../Graphic'
import styles from './WelcomeItem.module.scss'

export const WelcomeItemBadPropertyType = ({ name, propertyType, occurredAt, id, displayedAt }) => {
  const { leadType } = React.useContext(LeadContext)
  const leadAction = leadType === 'buyer' ? 'buy' : 'sell'
  return (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      <div>
        <h3 className={styles.nameTitle}>Hi {name}! </h3>
        <div className={styles.welcomeText}>
          Welcome to HomeLight! <Graphic.LaughingSmiley />
        </div>
      </div>
      <br />
      <div>
        <div className={styles.boldText}>
          Unfortunately, we do not have any agents who are able to help you {leadAction}{' '}
          {propertyType} in your area at this time.
        </div>
        <br />
        <div className={styles.boldText}>We wish you the best of luck with your search.</div>
      </div>
    </FeedItem>
  )
}

WelcomeItemBadPropertyType.propTypes = {
  name: PropTypes.string.isRequired,
  propertyType: PropTypes.string.isRequired,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

WelcomeItemBadPropertyType.defaultProps = {
  displayedAt: null
}
