import { belongsTo, hasMany, Model } from 'miragejs'

export default () => ({
  userFeedItem: Model.extend({
    user: belongsTo(),
    activity: belongsTo()
  }),
  user: Model.extend({
    userFeedItem: hasMany()
  }),
  checklist: Model.extend({
    tasks: hasMany(),
    checklistTasks: hasMany()
  }),
  task: Model.extend({
    checklist: belongsTo(),
    checklistTask: belongsTo()
  }),
  checklistTask: Model.extend({
    checklist: belongsTo(),
    task: belongsTo()
  }),
  message: Model.extend({
    messageParticipants: hasMany(),
    messageClassification: belongsTo()
  }),
  messageParticipant: Model.extend({
    message: belongsTo()
  }),
  messageClassification: Model.extend({
    messages: hasMany()
  }),
  agentTransaction: Model.extend({})
})
