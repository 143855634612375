import { findObjectIndicesByKey } from '../findObjectIndicesByKey'
import { getObjectPriorityOrder } from '../getObjectPriorityOrder'
import { mergeObjects } from '../mergeObjects'

export const mergeObjectArraysByKey = (arr1, arr2, priorityKey, updateDupe = false) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2) || !priorityKey) {
    return null
  }

  const duplicateIndices = []

  const updatedArr1 = arr1.map(item => {
    // Take the first found duplicate index (assumes only 1 exists)
    const duplicateIndex = findObjectIndicesByKey(arr2, 'id', item.id)[0]

    if (duplicateIndex > -1) {
      duplicateIndices.push(duplicateIndex)
      const objects = getObjectPriorityOrder(item, arr2[duplicateIndex], priorityKey)
      return updateDupe ? mergeObjects(objects[0], objects[1]) : objects[0]
    }

    return item
  })

  const filteredArr2 = arr2.filter((item, index) => !duplicateIndices.includes(index))
  return updatedArr1.concat(filteredArr2)
}
