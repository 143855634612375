import React from 'react'
import { CallButton } from 'components/Button/CallButton'
import styles from './FeedItem.module.scss'

export const ContactUsOptionAgent = () => (
  <div>
    If you have questions about how HomeLight works, visit our{' '}
    <a className={styles.linkText} href={process.env.REACT_APP_PROS_SIGN_UP_URL}>
      pros page
    </a>{' '}
    or contact our agent support team at{' '}
    <a className={styles.linkText} href="mailto:support@homelight.com">
      support@homelight.com
    </a>
    .
    <CallButton buttonText="Contact Us" feedItemType="call_us" />
  </div>
)
