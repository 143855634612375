import React, { useState } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import Raven from 'raven-js'
import { Graphic } from 'components/Graphic'
import styles from './MessagePreview.module.scss'
import { postRequest } from '../../utils/makeRequest'
import { API_ENDPOINTS } from '../../constants/apiEndpoints'
import { fetchAndPublishUnreadMessagesCount } from './services/fetchAndPublishUnreadMessagesCount'

const updateMessageRead = id => {
  const postData = {
    data: {
      parent_message_id: id
    }
  }

  postRequest(API_ENDPOINTS.updateMessageRead, postData)
    .then(() => fetchAndPublishUnreadMessagesCount())
    .catch(error => {
      Raven.captureException(error)
    })
}

export const EmailMessagePreview = ({ id, subject, isRead, messageContent, timestamp }) => {
  const [showFullMessage, setShowFullMessage] = useState(false)
  const toggleFullMessage = () => {
    if (!isRead && !showFullMessage) {
      updateMessageRead(id)
    }

    setShowFullMessage(!showFullMessage)
  }
  const content = parse(messageContent)

  return (
    <div>
      <div
        className={`${styles.msgPreview} ${styles.emailPreview}${
          isRead ? ` ${styles.isRead}` : ''
        }`}
        onClick={toggleFullMessage}
        onKeyPress={toggleFullMessage}
        role="button"
        tabIndex={0}
      >
        <div className={styles.previewHeader}>
          <div className={styles.senderImage}>
            <div className={styles.logoWrap}>
              <Graphic.Logo />
            </div>
          </div>
          <div className={styles.previewContent}>
            <h3>HomeLight</h3>
            <p className={styles.subHeader}>{subject}</p>
          </div>
          <div className={styles.dateTime}>
            <p>
              {timestamp}&nbsp;
              <span className={styles.sideCaret}>&gt;</span>
            </p>
          </div>
        </div>
      </div>
      {showFullMessage ? (
        <div className={styles.emailContent}>
          <p>{content}</p>
        </div>
      ) : null}
    </div>
  )
}

EmailMessagePreview.propTypes = {
  id: PropTypes.number.isRequired,
  messageContent: PropTypes.string.isRequired,
  isRead: PropTypes.bool,
  subject: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired
}

EmailMessagePreview.defaultProps = {
  isRead: false
}
