import React, { useContext } from 'react'
import { PageResizeContext } from 'components/PageResize'
import styles from './SimpleSaleResultsPage.module.scss'
import { isMobile } from '../../utils/breakpoint'
import RecentTransactionsCarousel from './RecentTransactionsCarousel'

export const RecentTransactions = () => {
  const { width } = useContext(PageResizeContext)
  const mobile = isMobile(width)
  return (
    <div
      className={`${styles.recentTransactionsSection} ${mobile ? styles.mobile : styles.desktop}`}
    >
      <h1>Recent transactions</h1>
      <div className={styles.transactionsCarousel}>
        <RecentTransactionsCarousel mobile={mobile} />
      </div>
      <div className={styles.testimonial}>
        <p>
          &quot;HomeLight helped me find someone to buy my home quickly! The process was simple and
          I avoided having to renovate my house or have 100s of people trudge through. HomeLight got
          me a cash offer in just over a week with no work.&quot;
        </p>
        <p className={styles.testimonialAuthor}>Sarah, Travis City, MN</p>
        <p>A HomeLight Simple Sale client</p>
      </div>
    </div>
  )
}
export default RecentTransactions
