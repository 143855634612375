import React from 'react'
import PropTypes from 'prop-types'

import { fetchAndPublishUnreadMessagesCount } from './services/fetchAndPublishUnreadMessagesCount'
import { FullMessageViewIsolated } from './FullMessageViewIsolated'

export const FullMessageView = ({ header }) => {
  const updateDeliveryCb = () => {
    fetchAndPublishUnreadMessagesCount()
  }

  return (
    <div>
      {header}
      <div>
        <FullMessageViewIsolated updateDeliveryCb={updateDeliveryCb} />
      </div>
    </div>
  )
}

FullMessageView.propTypes = {
  header: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default FullMessageView
