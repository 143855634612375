import faker from 'faker'
import AgentCardFactory from './AgentCardFactory/AgentCardFactory'

const PROPERTY_TYPES = ['single_family_home', 'townhomes', 'condominium', 'tenancy_in_common']
const LEAD_USER_TYPES = ['seller']

const statesLatRange = () => faker.datatype.number({ min: 30, max: 45 })
const statesLongRange = () => faker.datatype.number({ min: -122, max: -75 })

export default (overrides = {}) => {
  const attributes = {
    lead_id: faker.datatype.number({ min: 0, max: 1000000 }),
    agent_id: faker.datatype.number({ min: 0, max: 1000000 }),
    lead_name: `${faker.name.firstName()} ${faker.name.lastName()}`,
    lead_user_type: faker.random.arrayElement(LEAD_USER_TYPES),
    property_type: faker.random.arrayElement(PROPERTY_TYPES),
    city: faker.address.city(),
    state_code: faker.address.stateAbbr(),
    lat: statesLatRange(),
    long: statesLongRange(),
    agent_match: AgentCardFactory()
  }

  return { ...attributes, ...overrides }
}
