/* istanbul ignore file */

export const AGENT_RECOMMENDATION = 'agent_recommendation'
export const AGENT_RECOMMENDATION_FEEDBACK = 'agent_recommendation_feedback'
export const BLOG_POST = 'blog_post'
export const CALL_TO_ACTION = 'call_to_action'
export const DIRECT_AGENT_MATCH = 'direct_agent_match'
export const GENERIC_MESSAGE = 'generic_message'
export const HOME_ESTIMATE = 'home_estimate'
export const INVESTOR_INTRO = 'investor_intro'
export const MISSED_AGENT_CALL = 'missed_agent_call'
export const MISSED_HC_CALL = 'missed_hc_call'
export const MULTIPLE_CHOICE = 'multiple_choice'
export const MY_TEAM_AGENT = 'my_team_agent'
export const SIMPLE_SALE = 'simple_sale'
export const WELCOME_ITEM = 'welcome_item'
export const UPDATED_HOME_VAL_ESTIMATE = 'updated_home_val_estimate'

export const USER_FEED_ITEM_TYPES = [
  AGENT_RECOMMENDATION,
  AGENT_RECOMMENDATION_FEEDBACK,
  BLOG_POST,
  CALL_TO_ACTION,
  GENERIC_MESSAGE,
  HOME_ESTIMATE,
  INVESTOR_INTRO,
  MISSED_AGENT_CALL,
  MISSED_HC_CALL,
  MULTIPLE_CHOICE,
  MY_TEAM_AGENT,
  SIMPLE_SALE,
  WELCOME_ITEM,
  UPDATED_HOME_VAL_ESTIMATE
]
