export const isDuringSalesBusinessHours = now => {
  const currentDay = now.day()
  const currentHour = now.hour()
  // Saturday, Sunday
  const weekendDays = [0, 6]
  const weekendHours = [7, 8, 9, 10, 11, 12, 13, 14, 15]
  const weekdayHours = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
  if (weekendDays.includes(currentDay)) {
    if (weekendHours.includes(currentHour)) {
      return true
    }
  }
  return weekdayHours.includes(currentHour)
}
