import React, { useState } from 'react'
import { AgentFaceInCircle } from 'components/AgentFaceInCircle'
import { Graphic } from 'components/Graphic'
import PropTypes from 'prop-types'
import { cx } from '../../utils/cx'
import styles from './MessagePreview.module.scss'
import { LeadContext } from '../../context/LeadContext'

export const ChatMessagePreview = ({ participants, isRead, messageContent, timestamp }) => {
  const sender = participants.find(p => p.is_sender)
  const { userId } = React.useContext(LeadContext)
  const recipientList = userId
    ? participants.filter(participant => participant.user.id !== userId.toString())
    : []
  let recipient = 'HomeLight'
  if (
    recipientList.length &&
    recipientList[0].user &&
    recipientList[0].user.first_name !== 'HomeLight' && // don't show a last initial if first name is HomeLight
    recipientList[0].user.last_name
  ) {
    recipient = `${recipientList[0].user.first_name} ${recipientList[0].user.last_name[0]}.`
  }

  const [showFullMessage, setShowFullMessage] = useState(false)
  const toggleFullMessage = () => {
    setShowFullMessage(!showFullMessage)
  }

  return (
    <div>
      <div
        className={`${styles.msgPreview} ${styles.chatPreview}${isRead ? ` ${styles.isRead}` : ''}`}
        onClick={toggleFullMessage}
        onKeyPress={toggleFullMessage}
        role="row"
        tabIndex="-1"
      >
        <div className={styles.senderImage}>
          {sender.user.first_name === 'HomeLight' ? (
            <div className={styles.logoWrap}>
              <Graphic.Logo />
            </div>
          ) : (
            <div className={styles.profilePhotoWrap}>
              <AgentFaceInCircle photoUrl={sender.photo_url} />
            </div>
          )}
        </div>
        <div className={styles.previewContent}>
          <div className={cx(styles.senderReceiver, isRead ? styles.isRead : '')}>{recipient}</div>
          <div className={styles.messageContent}>
            <p>{messageContent}</p>
          </div>
        </div>
        <div className={styles.dateTime}>
          <p>
            {timestamp}&nbsp;
            <span className={styles.sideCaret}>&gt;</span>
          </p>
        </div>
      </div>
    </div>
  )
}

ChatMessagePreview.propTypes = {
  isRead: PropTypes.bool,
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      is_sender: PropTypes.bool.isRequired,
      photo_url: PropTypes.string
    })
  ).isRequired,
  messageContent: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired
}

ChatMessagePreview.defaultProps = {
  isRead: false
}
