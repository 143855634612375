import moment from 'moment-timezone'

const CALL_CENTER_TIMEZONE = 'America/Phoenix'

// We currently have HCs staffed 6am-6pm Mon-Fri and 7am-4pm Sat & Sun (AZ time)
const WEEKLY_HOURS = [
  { start: 7, end: 16 }, // Sunday
  { start: 6, end: 18 }, // Monday
  { start: 6, end: 18 }, // Tuesday
  { start: 6, end: 18 }, // Wednesday
  { start: 6, end: 18 }, // Thursday
  { start: 6, end: 18 }, // Friday
  { start: 7, end: 16 } // Saturday
]

export const openTimeslotsToday = (startingTime = null) => {
  // returns a 0-6 depending on the weekday
  const today = (startingTime ? moment(startingTime) : moment()).tz(CALL_CENTER_TIMEZONE).day()
  // dont show hours past
  const hoursPast = moment(startingTime).tz(CALL_CENTER_TIMEZONE).hour()
  // returns the start & end object for the current weekday
  const openHours = WEEKLY_HOURS[today]
  const startTime = hoursPast < openHours.start ? openHours.start : hoursPast
  const endTime = openHours.end
  const timeslots = []
  // For each of the available timeslots, adjust the timezone based on the user's
  // location (done by default through moment.js & the browser) and then format the
  // result to something readable.
  // eslint-disable-next-line no-plusplus
  for (let hour = startTime; hour < endTime; hour++) {
    const mmt = moment.tz({ hour }, CALL_CENTER_TIMEZONE).local()
    const formattedStartTime = mmt.format('h:mm A')
    const formattedEndTime = mmt.add(1, 'hour').format('h:mm A')
    timeslots.push({
      value: hour.toString(),
      option: `${formattedStartTime} - ${formattedEndTime}`
    })
  }
  return timeslots
}
