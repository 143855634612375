import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { breakpoint } from '../../utils/breakpoint'

const PageResizeContext = createContext({
  width: window.innerWidth,
  size: breakpoint(window.innerWidth)
})

const PageResizeProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <PageResizeContext.Provider value={{ width, size: breakpoint(width) }}>
      {children}
    </PageResizeContext.Provider>
  )
}

PageResizeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export { PageResizeContext, PageResizeProvider }
