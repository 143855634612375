import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import { SimpleSaleDefaultBenefits, HlssSellerCard } from 'components/SimpleSale'
import { RenderSharedFeedItem } from 'components/DashboardFeed/SharedFeed'
import { FeedItem } from './FeedItem'
import { updateFeedItem } from '../../utils/feedItemClient'
import { GenericCallToActionOptions } from './GenericCallToActionOptions'
import styles from '../SimpleSale/SimpleSaleCard.module.scss'
import { trackFeedItemEvent } from '../../utils/tracking'
import { formatEstimatedValue, calculatePercentageOfEstimatedValue } from '../../utils/numberParser'

export const SellerSimpleSaleOption = ({
  userFeedItem,
  refreshData,
  selectedAnswer,
  occurredAt,
  id,
  displayedAt
}) => {
  const [isSelected, setIsSelected] = useState(selectedAnswer)
  const headerText = 'Sell now to an investor'
  const description = 'Your estimated offer'
  const hlssDescription = 'Great news! HomeLight will pay cash for your home now!'
  let offerLow
  let offerHigh
  const { leadType } = useContext(LeadContext)
  const headerTextHlss = 'Sell now to HomeLight'

  const updateSelectedAnswer = () => {
    setIsSelected('yes')
    updateFeedItem('yes', userFeedItem).then(refreshData)
    trackFeedItemEvent(userFeedItem, 'Tap', leadType, 'yes')
  }
  const options = [
    { buttonText: 'Yes, please', onOptionClick: updateSelectedAnswer, answerKey: 'yes' }
  ]

  if (userFeedItem.item.use_opendoor) {
    offerLow = userFeedItem.item.sell_with_opendoor_low
    offerHigh = userFeedItem.item.sell_with_opendoor_high
  } else {
    offerLow = userFeedItem.item.sell_to_investor_low
    offerHigh = userFeedItem.item.sell_to_investor_high
  }
  if (!offerLow && !offerHigh) {
    return null
  }

  useEffect(() => {
    if (userFeedItem.item.ss_item_type === 'hlss_eligible') {
      trackFeedItemEvent(userFeedItem, 'View', leadType)
    }
  })

  switch (userFeedItem.item.ss_item_type) {
    case 'hlss_eligible':
      return (
        <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
          <div className={styles.ssIntroDesc}>{hlssDescription}</div>
          <Link to="/simple-sale-results">
            <a className={styles.learnMore} rel="noopener noreferrer">
              Learn more
            </a>
          </Link>
          <HlssSellerCard
            scheduleCall
            providerType="investor"
            headerText={headerTextHlss}
            description="Your estimated offer"
            marketPrepVal="0%"
            agentCommissionVal="0%"
            closingCostVal="0%"
            amount={formatEstimatedValue(offerLow, offerHigh)}
            amountLow={calculatePercentageOfEstimatedValue(offerLow, 0.85)}
            amountHigh={calculatePercentageOfEstimatedValue(offerHigh, 0.91)}
            createDuplicateCCTILead
          />
        </FeedItem>
      )
    case 'seller_flow_option':
      return (
        <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
          <GenericCallToActionOptions
            text="There's also one other option you might want to consider: if you'd like to skip the listing process entirely, you can sell your home directly to HomeLight. Any interest in discussing that option with us?"
            options={options}
            selectedAnswerKey={selectedAnswer}
          />
          {isSelected ? (
            <div>
              <br />
              <div className={styles.ssIntroDesc}>
                Great, our home buying program is called Simple Sale. Here&apos;s our early estimate
                of your offer. When we talk, we can discuss more of your home&apos;s specifics and
                an accurate offer.
              </div>
              <SimpleSaleDefaultBenefits
                headerText={headerText}
                description={description}
                amount={formatEstimatedValue(offerLow, offerHigh)}
                buttonText="See how it works"
                goToLink="/simple-sale-results"
                itemType={userFeedItem.item_type}
              />
            </div>
          ) : null}
        </FeedItem>
      )
    default:
      return RenderSharedFeedItem(userFeedItem, leadType, refreshData)
  }
}

SellerSimpleSaleOption.propTypes = {
  userFeedItem: PropTypes.shape({
    item_type: PropTypes.string.isRequired,
    item: PropTypes.shape({
      use_opendoor: PropTypes.bool,
      sell_with_opendoor_low: PropTypes.number,
      sell_with_opendoor_high: PropTypes.number,
      sell_to_investor_low: PropTypes.number,
      sell_to_investor_high: PropTypes.number
    }).isRequired
  }).isRequired,
  refreshData: PropTypes.func.isRequired,
  selectedAnswer: PropTypes.string,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

SellerSimpleSaleOption.defaultProps = {
  displayedAt: null,
  selectedAnswer: null
}
