import { useState, useContext, useMemo, useCallback } from 'react'

import { usePageResize } from 'components/PageResize'
import { Graphic } from 'components/Graphic'
import { LeadContext } from 'context/LeadContext'

import { cx } from '../../utils/cx'
import { signOutAndRedirect } from '../../utils/userAuth'
import { isDesktop } from '../../utils/breakpoint'
import { initializeGTM, trackAccountEvent } from '../../utils/tracking'
import { Button } from '../Button'

import {
  AccountMenuTrigger,
  AccountOption,
  AccountSection,
  AccountUserInfo
} from './AccountMenuFragments'

import styles from './AccountMenu.module.scss'

initializeGTM('DashboardDataLayer')

export const AccountMenu = () => {
  const [isActive, setIsActive] = useState(false)
  const { width } = usePageResize()
  const { leadFirstName, leadType, leadEmail } = useContext(LeadContext)

  const menuSections = useMemo(() => {
    const savedSearches = `${process.env.REACT_APP_HOME_SEARCH_URL}/homes/saved-property-searches`
    const savedHomes = `${process.env.REACT_APP_HOME_SEARCH_URL}/homes/favorites`
    const manageSubscription = `${process.env.REACT_APP_HOMELIGHT_URL}/ultra-low-rates/dashboard`

    return [
      [{ id: 'my-dashboard', text: 'My dashboard', href: '/', local: true }],
      [
        { id: 'saved-searches', text: 'Saved searches', href: savedSearches },
        { id: 'saved-homes', text: 'Saved homes', href: savedHomes },
        { id: 'manage-subscription', text: 'Manage Subscription', href: manageSubscription }
      ]
    ]
  }, [])

  const onToggle = useCallback(() => {
    trackAccountEvent('Tap', 'account_menu', leadType)
    setIsActive(prev => !prev)
  }, [leadType])

  const onSignOutClicked = useCallback(() => {
    trackAccountEvent('Tap', 'log_out')
    signOutAndRedirect()
  }, [])

  return (
    <>
      <AccountMenuTrigger name={leadFirstName} onClick={onToggle} />

      <div
        className={cx(styles.accountMenuContainer, isActive && styles.menuActive)}
        data-testid="account-menu-container"
      >
        {isActive && !isDesktop(width) && (
          <div className={styles.accountMobileHeader} onClick={onToggle}>
            <div className={styles.closeIcon}>
              <Graphic.CloseIcon />
            </div>
          </div>
        )}

        <AccountSection>
          <AccountUserInfo name={leadFirstName} email={leadEmail} />
        </AccountSection>

        {menuSections.map((items, i) => (
          <AccountSection key={i}>
            {items.map(item => (
              <AccountOption key={item.id} item={item} onClick={onToggle} id={item.id} />
            ))}
          </AccountSection>
        ))}

        <AccountSection>
          <Button
            as="link"
            className={styles.signOut}
            onClick={onSignOutClicked}
            data-testid="account-menu-sign-out-btn"
          >
            Sign Out
          </Button>
        </AccountSection>
      </div>
    </>
  )
}
