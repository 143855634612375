import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { Button } from 'components/Button'
import styles from './BlogArticleCard.module.scss'

export const BlogArticleCard = ({ title, text, url, imgUrl }) => {
  const img =
    imgUrl ||
    'https://3xlsey17pnzh3nf35w1wwnug-wpengine.netdna-ssl.com/wp-content/uploads/2017/01/adult-business-businessman-1061588-e1549586238907.jpg'

  return (
    <Row>
      <Col span={12}>
        <div className={styles.blogArticleCardContainer}>
          <a href={url}>
            <Row align="middle" justify="start" gutter={10}>
              <Col span={12}>
                <div className={styles.picture}>
                  <img src={img} alt="blog-article" />
                </div>
              </Col>
              <Col span={12}>
                <div className={styles.textContainer}>
                  <Row align="middle" justify="start" gutter={10}>
                    <Col span={12}>
                      <div className={styles.title}>{title}</div>
                    </Col>
                    <Col span={12}>
                      <div className={styles.descp}>{text}</div>
                    </Col>
                    <Col span={12}>
                      <Button as="primary" className={styles.primaryCta}>
                        Read more
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </a>
        </div>
      </Col>
    </Row>
  )
}

BlogArticleCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  imgUrl: PropTypes.string
}

BlogArticleCard.defaultProps = {
  title: 'Find a realtor you can trust in 30 minutes flat',
  text: 'As of this writing, there are over 30,000 active real estate agents in Los Angeles, CA. There are over 14,000 active real estate agents in Houston, TX.',
  url: 'https://www.homelight.com/blog/find-a-realtor/',
  imgUrl:
    'https://3xlsey17pnzh3nf35w1wwnug-wpengine.netdna-ssl.com/wp-content/uploads/2017/01/adult-business-businessman-1061588-e1549586238907.jpg'
}

export default BlogArticleCard
