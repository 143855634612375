import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import styles from './RecentTransactions.module.scss'

export const RecentTransactionCard = ({
  price,
  address,
  numBeds,
  numBaths,
  sqFt,
  imgUrl,
  closeTime
}) => (
  <div>
    <div className={styles.closingTiming}>{`Closed in under ${closeTime}`}</div>
    <div className={styles.transactionCard}>
      <img className={styles.houseImg} src={imgUrl} alt="house" />
      <div className={styles.transactionBody}>
        <h2 className={styles.price}>{price}</h2>
        <p className={styles.address}>{address}</p>
        <Row>
          <Col span={3}>
            <p>
              <span className={styles.boldNumer}>{numBeds}</span> Beds
            </p>
          </Col>
          <Col span={3}>
            <p>
              <span className={styles.boldNumer}>{numBaths}</span>Baths
            </p>
          </Col>
          <Col span={6}>
            <p>
              <span className={styles.boldNumer}>{sqFt}</span>Sq. Ft.
            </p>
          </Col>
        </Row>
        <div className={styles.extraFees}>
          <Row>
            <Col span={7}>
              <p className={styles.category}>Home renovations</p>
            </Col>
            <Col span={5} className={styles.boldText}>
              <p>$0</p>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <p className={styles.category}>Openhouses</p>
            </Col>
            <Col span={5} className={styles.boldText}>
              <p>$0</p>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <p className={styles.category}>Agent fees</p>
            </Col>
            <Col span={5} className={styles.boldText}>
              <p>$0</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </div>
)

RecentTransactionCard.propTypes = {
  price: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  numBeds: PropTypes.string.isRequired,
  numBaths: PropTypes.string.isRequired,
  sqFt: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  closeTime: PropTypes.string.isRequired
}

export default RecentTransactionCard
