import React from 'react'
import { UpdatedHomeValEstimate } from '../../../FeedItem'

function renderHomeValEstimate(userFeedItem) {
  return (
    <UpdatedHomeValEstimate
      estimatedHomeValueLow={userFeedItem.item.home_value_low}
      estimatedHomeValueHigh={userFeedItem.item.home_value_high}
      hveUpdatedAt={userFeedItem.item.hve_updated_at}
      occurredAt={userFeedItem.occurred_at}
      id={userFeedItem.id}
      displayedAt={userFeedItem.displayed_at}
    />
  )
}

export const RenderUpdatedHomeValEstimate = userFeedItem => renderHomeValEstimate(userFeedItem)
