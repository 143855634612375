import AgentCardFactory from './AgentCardFactory/AgentCardFactory'

export default (overrides = {}) => {
  const attributes = {
    agent_matches: [AgentCardFactory(), AgentCardFactory(), AgentCardFactory()],
    answer_choices: [
      { accept: "Great, I'll continue with them" },
      { more_agents: "I'd like to see a few more" }
    ],
    selected_answer: null
  }

  return { ...attributes, ...overrides }
}
