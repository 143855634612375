import React from 'react'
import PropTypes from 'prop-types'
import { CardHeaderText, FeedItem } from 'components/FeedItem/index'

export const GenericMessage = ({ title, text, occurredAt, id, displayedAt, graphic }) => (
  <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
    <CardHeaderText title={title} text={text} graphic={graphic} />
  </FeedItem>
)

GenericMessage.propTypes = {
  graphic: PropTypes.shape({}),
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

GenericMessage.defaultProps = {
  graphic: null,
  title: null,
  displayedAt: null
}
