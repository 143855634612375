import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { CheckCircleIcon } from 'components/Graphic'
import { SimpleSaleCard } from './SimpleSaleCard'
import styles from './SimpleSaleCard.module.scss'

export const SimpleSaleDefaultBenefits = ({
  headerText,
  description,
  amount,
  goToLink,
  itemType
}) => {
  const icon = <CheckCircleIcon className={styles.checkIcon} />
  const benefitsSection = (
    <>
      <Row align="bottom" className={styles.lineItem}>
        <Col span={1}>
          <div className={styles.iconContainer}>{icon}</div>
        </Col>
        <Col span={7}>
          <div className={styles.points}>Market preparation</div>
        </Col>
        <Col span={4}>
          <div className={styles.pointsBold}>0%</div>
        </Col>
      </Row>
      <Row align="bottom" className={styles.lineItem}>
        <Col span={1}>
          <div className={styles.iconContainer}>{icon}</div>
        </Col>
        <Col span={7}>
          <div className={styles.points}>Agent commissions</div>
        </Col>
        <Col span={4}>
          <div className={styles.pointsBold}>0%</div>
        </Col>
      </Row>
      <Row align="bottom" className={styles.lineItem}>
        <Col span={1}>
          <div className={styles.iconContainer}>{icon}</div>
        </Col>
        <Col span={7}>
          <div className={styles.points}>Closing costs</div>
        </Col>
        <Col span={4}>
          <div className={styles.pointsBold}>0%</div>
        </Col>
      </Row>
    </>
  )

  return (
    <SimpleSaleCard
      headerText={headerText}
      description={description}
      amount={amount}
      benefitsNode={benefitsSection}
      goToLink={goToLink}
      itemType={itemType}
    />
  )
}

SimpleSaleDefaultBenefits.propTypes = {
  amount: PropTypes.string,
  headerText: PropTypes.string,
  description: PropTypes.string,
  goToLink: PropTypes.string,
  itemType: PropTypes.string.isRequired
}

SimpleSaleDefaultBenefits.defaultProps = {
  headerText: '',
  description: '',
  amount: '$540k',
  goToLink: '/simple-sale-results'
}

export default SimpleSaleDefaultBenefits
