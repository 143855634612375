import React from 'react'
import { RenderSharedFeedItem } from './SharedFeed'
import { AgentOption, InvestorOption } from '../FeedItem'

export const RenderSimpleSaleFeedItem = (userFeedItem, leadType, refreshData) => {
  let offerLow
  let offerHigh
  let description

  switch (userFeedItem.item.ss_item_type) {
    case 'hlss_eligible':
      description =
        'HomeLight wants to buy your home! Please schedule a call with us to discuss the simplicity and certainty of selling to HomeLight and our process for getting you a formal offer.'
      return (
        <InvestorOption
          scheduleCall
          offerLow={userFeedItem.item.sell_to_investor_low}
          offerHigh={userFeedItem.item.sell_to_investor_high}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayedAt}
          itemType={userFeedItem.item_type}
          description={description}
          headerText="Sell now to HomeLight"
          simpleSaleItemType={userFeedItem.item.ss_item_type}
          userFeedItem={userFeedItem}
        />
      )
    case 'agent_only_option':
      description =
        'Simple Sale is not yet available in your area and it looks like listing with a real estate agent is the best way for you to sell your home. ' +
        'We’ve found the top performing agents nearby to help you sell your home for the most money possible!'
      return (
        <AgentOption
          profitLow={userFeedItem.item.profit_from_agent_low}
          profitHigh={userFeedItem.item.profit_from_agent_high}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayedAt}
          refreshData={refreshData}
          userFeedItem={userFeedItem}
          description={description}
        />
      )
    case 'sell_with_agent':
      description =
        'One option is selling with a real estate agent. We’ve found the top performing agents in ' +
        'your area to help you sell your home for the most money possible!'
      return (
        <AgentOption
          profitLow={userFeedItem.item.profit_from_agent_low}
          profitHigh={userFeedItem.item.profit_from_agent_high}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayedAt}
          refreshData={refreshData}
          userFeedItem={userFeedItem}
          description={description}
        />
      )
    case 'sell_to_investor':
      if (userFeedItem.item.use_opendoor) {
        offerLow = userFeedItem.item.sell_with_opendoor_low
        offerHigh = userFeedItem.item.sell_with_opendoor_high
      } else {
        offerLow = userFeedItem.item.sell_to_investor_low
        offerHigh = userFeedItem.item.sell_to_investor_high
      }
      return (
        <InvestorOption
          offerLow={offerLow}
          offerHigh={offerHigh}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayedAt}
          itemType={userFeedItem.item_type}
          description="Another option is selling to an investor. We’ve found investors in your area that will pay
          cash for your home now!"
          headerText="Sell now to an investor"
        />
      )
    case 'investor_only_option':
      if (userFeedItem.item.use_opendoor) {
        offerLow = userFeedItem.item.sell_with_opendoor_low
        offerHigh = userFeedItem.item.sell_with_opendoor_high
      } else {
        offerLow = userFeedItem.item.sell_to_investor_low
        offerHigh = userFeedItem.item.sell_to_investor_high
      }
      return (
        <InvestorOption
          offerLow={offerLow}
          offerHigh={offerHigh}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayedAt}
          itemType={userFeedItem.item_type}
          headerText="Sell now to an investor"
          description="Great news! We’ve found investors in your area that will pay cash for your home now!"
        />
      )
    default:
      return RenderSharedFeedItem(userFeedItem, leadType, refreshData)
  }
}
