import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { LeadContext } from '../../context/LeadContext'
import { Graphic } from '../Graphic'
import styles from '../AgentCard/AgentCard.module.scss'

function renderContent(badge, agentName, leadType) {
  const formattedTransactionDate = badge.transaction_date
    ? moment(new Date(badge.transaction_date)).format('MMMM YYYY')
    : null
  const addressComponents = badge.address ? badge.address.split(',') : null
  const city =
    addressComponents && addressComponents.length > 1
      ? addressComponents[1].replace(/[0-9]/g, '').replace(/\s/g, '')
      : null
  const formattedAddress = city && addressComponents ? `${addressComponents[0]} in ${city}` : null
  const buyOrSell = leadType === 'buyer' ? 'purchase' : 'sell'
  switch (badge.award_type) {
    case 'move_safe':
      return `${agentName} leverages tools, technology, and practices to help keep you safe.`
    case 'property_type_expert':
      return `${agentName} ranks in the top ${badge.value} of ${badge.agent_in_area_count} agents.`
    case 'neighborhood_knowledge':
      return `${agentName} helped ${buyOrSell} ${
        badge.address || formattedAddress
      } in ${formattedTransactionDate}.`
    case 'number_one':
      return `${agentName} is the #1 ${badge.rank_type} in ${badge.area_name}.`
    case 'responsiveness_narrative':
      return `${agentName} typically ${badge.responsiveness_narrative.toLowerCase()} to HomeLight Clients.`
    case 'title_escrow':
      return "Expert in HomeLight's Closing Services, a secure title and escrow platform."
    case 'home_loans':
      return 'Works with HomeLight Home Loans to get you preapproved and ahead of cash buyers.'
    case 'simple_sale':
      return 'Works closely with HomeLight to provide competitive cash offers for your home.'
    case 'cash_offer':
      return "Expert in HomeLight's Cash Offer services."
    case 'trade_in':
      return "Expert in HomeLight's Buy Before You Sell services."
    default:
      return `${agentName} ranks in the top 5% of agents.`
  }
}

export function BadgeMoreInfo({ badge, agentName, infoAsTooltips, descriptionStyle }) {
  const { leadType } = React.useContext(LeadContext)
  return infoAsTooltips ? (
    <div className={styles.moreInfo}>
      <Graphic.QuestionCircle />
      <div className={styles.badgeTooltip}>
        {renderContent(badge, agentName, leadType)}
        <div className={styles.tooltipPointer} />
      </div>
    </div>
  ) : (
    <div className={descriptionStyle}>{renderContent(badge, agentName)}</div>
  )
}

BadgeMoreInfo.propTypes = {
  badge: PropTypes.shape({
    award_type: PropTypes.string.isRequired,
    value: PropTypes.string
  }).isRequired,
  agentName: PropTypes.string.isRequired,
  infoAsTooltips: PropTypes.bool,
  descriptionStyle: PropTypes.string
}

BadgeMoreInfo.defaultProps = {
  infoAsTooltips: true,
  descriptionStyle: styles.badgeInfo
}
