import { useState, useEffect } from 'react'
import { breakpoint } from '../../utils/breakpoint'

let handler

export const usePageResize = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(handler)
      handler = setTimeout(() => {
        setWidth(window.innerWidth)
      }, 300)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { width, size: breakpoint(width) }
}

export default usePageResize
