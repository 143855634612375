import React from 'react'
import { MissedAgentCallItem } from '../../../FeedItem'

export const RenderMissedAgentCall = userFeedItem => {
  const { item, id, occurred_at: occurredAt, displayed_at: displayedAt } = userFeedItem

  return (
    <MissedAgentCallItem
      key={id}
      agentName={item.agent_name}
      phoneNumber={item.agent_twilio_phone}
      occurredAt={occurredAt}
      id={id}
      displayedAt={displayedAt}
    />
  )
}
