import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FeedItem } from 'components/FeedItem'
import moment from 'moment'
import Raven from 'raven-js'
import styles from './WelcomeItem.module.scss'
import { updateHomeValueEstimateItem } from '../../utils/feedItemClient'
import { Loading } from '../Graphic'
import { formatEstimatedValue } from '../../utils/numberParser'
import { PageContext } from '../../context/PageContext'

export const UpdatedHomeValEstimate = ({
  estimatedHomeValueLow,
  estimatedHomeValueHigh,
  hveUpdatedAt,
  occurredAt,
  id,
  displayedAt
}) => {
  const { feed } = React.useContext(PageContext)

  // finds other HVE items that aren't hidden
  function findHveItems(items) {
    return items.item_type === 'updated_home_val_estimate' && items.hidden_at === null
  }

  // sorts items by id descending
  function sortHveItems(first, second) {
    return second.id - first.id
  }

  const [isLoading, setIsLoading] = useState(false)

  const formatDisplay = (lowValue, highValue) => {
    // Negated values are required since Number.isNaN(null) returns false
    if (!lowValue || !highValue || Number.isNaN(lowValue) || Number.isNaN(highValue)) {
      return '-'
    }

    return formatEstimatedValue(lowValue, highValue)
  }

  const [displayValue, setDisplayValue] = useState(
    formatDisplay(estimatedHomeValueLow, estimatedHomeValueHigh)
  )

  const shouldUpdate = lastUpdatedDate => {
    // returns early if this is an older HVE card that shouldn't update
    if (feed.data.filter(findHveItems).sort(sortHveItems)[0].id !== id) {
      return false
    }

    const thirtyDaysAgo = new Date().getTime() - 30 * 24 * 60 * 60 * 1000
    const thirtyDaysAgoString = new Date(thirtyDaysAgo).toISOString()
    const validatedLastUpdatedDate = new Date(lastUpdatedDate)

    if (validatedLastUpdatedDate.toString() === 'Invalid Date') {
      Raven.captureException(`Last updated date not parseable: ${validatedLastUpdatedDate}`)
      return false
    }

    return (
      lastUpdatedDate === null || moment(validatedLastUpdatedDate).isBefore(thirtyDaysAgoString)
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const result = await updateHomeValueEstimateItem()

        if (result.data.data.attributes.item) {
          setDisplayValue(
            formatDisplay(
              result.data.data.attributes.item.home_value_low,
              result.data.data.attributes.item.home_value_high
            )
          )
        }
        setIsLoading(false)
      } catch (error) {
        Raven.captureException(error)
        setIsLoading(false)
      }
    }

    if (shouldUpdate(hveUpdatedAt)) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const description = `Based on recent sales in your area, the estimated value of your home is now:`
  return isLoading ? (
    <Loading wrapperClass={styles.loadingAnimation} />
  ) : (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      {description}
      <div className={styles.estimate}>
        <p className={styles.estimateTitle}>Estimated home value</p>
        <p className={styles.value} data-testid="hve-range">
          {displayValue}
        </p>
      </div>
    </FeedItem>
  )
}

UpdatedHomeValEstimate.propTypes = {
  estimatedHomeValueLow: PropTypes.number.isRequired,
  estimatedHomeValueHigh: PropTypes.number.isRequired,
  hveUpdatedAt: PropTypes.string,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

UpdatedHomeValEstimate.defaultProps = {
  displayedAt: null,
  hveUpdatedAt: null
}
