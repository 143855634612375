import faker from 'faker'

const WELCOME_TYPES = [
  'valid_seller_matches',
  'valid_buyer_matches',
  'invalid_known_agent',
  'invalid_land_property',
  'invalid_mobile_home',
  'invalid_bad_number',
  'simple_sale',
  'simple_home_value',
  'simple_home_value_concierge',
  'hve_update_welcome'
]

const PROPERTY_TYPES = ['single_family_home', 'condominium']

export default (overrides = {}) => {
  const attributes = {
    welcome_type: faker.random.arrayElement(WELCOME_TYPES),
    property_type: faker.random.arrayElement(PROPERTY_TYPES),
    address: null,
    city: faker.address.city(),
    additional_areas: [
      [],
      [
        { name: faker.address.city(), type: 'City' },
        { name: faker.address.city(), type: 'City' }
      ]
    ][faker.datatype.number({ min: 0, max: 1 })], // random; either is an empty array or an array of fake cities
    home_value_high: faker.datatype.number({ min: 100000, max: 2000000 }),
    home_value_low: faker.datatype.number({ min: 100000, max: 2000000 })
  }

  return { ...attributes, ...overrides }
}
