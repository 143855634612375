import { useState, useEffect } from 'react'

export function useScroll() {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [scrollingDown, setScrollingDown] = useState(false)
  const [scrollingUp, setScrollingUp] = useState(false)

  useEffect(() => {
    function handleScroll() {
      const position = window ? window.pageYOffset : 0
      const down = scrollPosition <= position
      const up = scrollPosition >= position

      setScrollPosition(position)
      setScrollingDown(down)
      setScrollingUp(up)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return { scrollPosition, scrollingDown, scrollingUp }
}
