import React from 'react'
import PropTypes from 'prop-types'
import { LeadContext } from 'context/LeadContext'
import styles from './Button.module.scss'
import { Button } from './Button'
import { trackFeedItemGenericEvent } from '../../utils/tracking'

export const TextUsButton = ({ feedItemType, buttonText, phoneNumber }) => {
  const { leadType } = React.useContext(LeadContext)
  const TEXTABLE_HC_PHONE = phoneNumber
    ? `sms:${phoneNumber}&body=''`
    : `sms:${process.env.REACT_APP_TWILIO_TEXTING_NUMBER}&body=''`
  const trackEvents = () => {
    trackFeedItemGenericEvent(feedItemType, 'Tap', leadType, 'contact_text')
    // temporary custom tracking to see how many times we're using the default texting number vs a different sales number
    trackFeedItemGenericEvent(
      feedItemType,
      'WhichTextUsNumberIsUsed',
      leadType,
      `${TEXTABLE_HC_PHONE}`
    )
  }
  return (
    <div>
      <a href={TEXTABLE_HC_PHONE}>
        <Button
          as="primary"
          className={styles.primaryCta}
          onClick={() => {
            trackEvents()
          }}
        >
          {buttonText}
        </Button>
      </a>
    </div>
  )
}

TextUsButton.propTypes = {
  feedItemType: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string
}

TextUsButton.defaultProps = {
  phoneNumber: process.env.REACT_APP_TWILIO_TEXTING_NUMBER
}

export default TextUsButton
