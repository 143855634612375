import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { Graphic } from 'components/Graphic'
import { LeadContext } from 'context/LeadContext'
import UnreadMessageCountNotification from 'components/Messages/UnreadMessageCountNotification'
import { useMessageCountNotification } from 'hooks/useMessageCountNotification/useMessageCountNotification'
import { AccountMenu } from '../Account/AccountMenu'
import { AccountDropDownMenu } from '../Account'
import styles from './DashboardDesktopNav.module.scss'
import { cx } from '../../utils/cx'
import { initializeGTM, trackAccountEvent } from '../../utils/tracking'

initializeGTM('DashboardDataLayer')

export const DashboardDesktopNav = ({ accountMenuActive, toggleAccountMenu, showNewMenu }) => {
  const { leadFirstName, leadType } = React.useContext(LeadContext)
  const userDisplayName = leadFirstName || 'Account'
  const { pathname } = useLocation()
  const [isScrollTop, setIsScrollTop] = useState(window.pageYOffset < 50)
  const { unreadMessageCount } = useMessageCountNotification()

  const handleScroll = () => {
    const position = window.pageYOffset

    if ((isScrollTop && position > 50) || (!isScrollTop && position < 50)) {
      setIsScrollTop(position < 50)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollTop])

  return (
    <div>
      <div
        className={cx(
          styles.navigation,
          showNewMenu && styles.newNavigation,
          !isScrollTop && styles.scrolling
        )}
      >
        <Row align="middle" justify="start">
          <Col span={12}>
            <ul>
              <li>
                <Link to="/" className={pathname === '/' ? styles.active : styles.inactive}>
                  <span className={styles.menuLinkText} title="Feed">
                    Feed
                  </span>
                </Link>
              </li>

              <li>
                {showNewMenu ? (
                  <AccountMenu />
                ) : (
                  <div
                    className={styles.accountHeader}
                    onClick={() => {
                      trackAccountEvent('Tap', 'account_menu', leadType)
                      toggleAccountMenu()
                    }}
                  >
                    <div className={styles.username} title={userDisplayName}>
                      {userDisplayName}
                    </div>
                    <Graphic.SmallAngleDown className={styles.angle} />
                  </div>
                )}
              </li>
            </ul>
          </Col>
        </Row>
        {!showNewMenu && (
          <AccountDropDownMenu active={accountMenuActive} toggleActive={toggleAccountMenu} />
        )}
      </div>
    </div>
  )
}

export default DashboardDesktopNav

DashboardDesktopNav.propTypes = {
  accountMenuActive: PropTypes.bool.isRequired,
  toggleAccountMenu: PropTypes.func.isRequired
}
