import React, { useContext, useState } from 'react'
import { Col, Row } from 'react-simple-flex-grid'
import { postRequest } from '../../utils/makeRequest'
import { Button } from '../Button'
import styles from './FeedbackForm.module.scss'
import { FeedbackConfirmation } from './FeedbackConfirmation'
import { DismissHeader } from '../PageHeader/DismissHeader'
import { LeadContext } from '../../context/LeadContext'

export const FeedbackForm = () => {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    feedback: ''
  })
  const [isSubmitted, setIsSubmitted] = useState({ isSubmitted: false })
  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }
  const { leadFirstName, leadLastName, leadEmail } = useContext(LeadContext)
  const submitFeedback = async () => {
    const { feedback } = values
    if (!leadFirstName || !leadLastName || !leadEmail || !feedback || feedback.length < 50) return

    const url = `/api/user-feed-service/feed/create-client-dashboard-feedback?`
    const postData = {
      first_name: leadFirstName,
      last_name: leadLastName,
      email: leadEmail,
      feedback
    }
    await postRequest(url, postData)
    setIsSubmitted(true)
  }

  const renderFeedbackForm = () => (
    <>
      <DismissHeader dismissLink="/" headerText="" />
      <div className={styles.feedbackFormContainer}>
        <h2>How are we doing?</h2>
        <div className={styles.feedbackPrompt}>
          Let us know if you&apos;re having any issues or would like to give us feedback (good or
          bad) on your experience with us. We&apos;d love to hear from you.
        </div>
        <div>
          <Row className={styles.feedbackFormField}>
            <Col span={12}>
              <p>First Name</p>
            </Col>
            <Col span={12}>
              <input name="firstName" value={leadFirstName} readOnly />
            </Col>
          </Row>
          <Row className={styles.feedbackFormField}>
            <Col span={12}>
              <p>Last Name</p>
            </Col>
            <Col span={12}>
              <input name="lastName" value={leadLastName} readOnly />
            </Col>
          </Row>
          <Row className={styles.feedbackFormField}>
            <Col span={12}>
              <p>Email</p>
            </Col>
            <Col span={12}>
              <input name="email" type="email" value={leadEmail} readOnly />
            </Col>
          </Row>
          <Row className={styles.feedbackFormField}>
            <Col span={12}>
              <p>Your Feedback</p>
            </Col>
            <Col span={12}>
              <textarea
                className={styles.feedbackText}
                placeholder="Start typing"
                name="feedback"
                onChange={handleInputChange}
                value={values.feedback}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p className={styles.charMinLabel}>50 character minimum</p>
            </Col>
          </Row>
          <Button as="primary" className={styles.feedbackSubmitButton} onClick={submitFeedback}>
            Submit feedback
          </Button>
        </div>
      </div>
    </>
  )

  return (
    <div className={styles.feedbackPage}>
      {isSubmitted === true ? <FeedbackConfirmation /> : renderFeedbackForm()}
    </div>
  )
}
