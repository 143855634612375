import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { Link } from 'react-router-dom'
import { Graphic } from '../Graphic'
import { cx } from '../../utils/cx'
import styles from './GoBackHeader.module.scss'

export const GoBackHeader = ({ goBackLink, headerText }) => (
  <div className={cx(styles.goBackHeaderContainer, 'transition-with-page')}>
    <div className={styles.header}>
      <Row justify="center" align="middle">
        <Col className={styles.buttonContainer} span={1}>
          <div className={styles.backArrow}>
            <Link to={goBackLink}>
              <Graphic.ChevronLeft />
            </Link>
          </div>
        </Col>
        <Col span={10}>
          <div className={styles.headerText}>{headerText}</div>
        </Col>
        <Col span={1} />
      </Row>
    </div>
  </div>
)

GoBackHeader.propTypes = {
  goBackLink: PropTypes.string.isRequired,
  headerText: PropTypes.string
}

GoBackHeader.defaultProps = {
  headerText: 'HomeLight'
}
