import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { CheckCircleIcon, MinusCircleIcon } from 'components/Graphic'
import { SimpleSaleCard } from './SimpleSaleCard'
import styles from './SimpleSaleCard.module.scss'
import { ScheduleCallButton } from '../common/ScheduleCall'

export const HlssSellerCard = ({
  providerType,
  headerText,
  description,
  amount,
  amountHigh,
  amountLow,
  marketPrepVal,
  agentCommissionVal,
  closingCostVal,
  scheduleCall,
  simpleSaleItemType,
  createDuplicateCCTILead
}) => {
  const icon =
    providerType === 'agent' ? (
      <MinusCircleIcon className={styles.checkIcon} />
    ) : (
      <CheckCircleIcon className={styles.checkIcon} />
    )
  const benefitsSection = (
    <>
      <Row align="bottom" className={styles.lineItem}>
        <Col span={1}>
          <div className={styles.iconContainer}>{icon}</div>
        </Col>
        <Col span={7}>
          <div className={styles.points}>Market preparation</div>
        </Col>
        <Col span={4}>
          <div className={styles.pointsBold}>{marketPrepVal}</div>
        </Col>
      </Row>
      <Row align="bottom" className={styles.lineItem}>
        <Col span={1}>
          <div className={styles.iconContainer}>{icon}</div>
        </Col>
        <Col span={7}>
          <div className={styles.points}>Agent commissions</div>
        </Col>
        <Col span={4}>
          <div className={styles.pointsBold}>{agentCommissionVal}</div>
        </Col>
      </Row>
      <Row align="bottom" className={styles.lineItem}>
        <Col span={1}>
          <div className={styles.iconContainer}>{icon}</div>
        </Col>
        <Col span={7}>
          <div className={styles.points}>Closing costs</div>
        </Col>
        <Col span={4}>
          <div className={styles.pointsBold}>{closingCostVal}</div>
        </Col>
      </Row>
    </>
  )

  function calendlySchedule(shouldShowCalendlyLink) {
    if (shouldShowCalendlyLink) {
      return (
        <Col span={12}>
          <ScheduleCallButton
            buttonText="Get an offer"
            createDuplicateCCTILead={createDuplicateCCTILead}
          />
        </Col>
      )
    }
    return null
  }

  const buttonSection = (
    <div>
      <Row align="middle" justify="start">
        {calendlySchedule(scheduleCall)}
      </Row>
    </div>
  )

  return (
    <SimpleSaleCard
      headerText={headerText}
      description={description}
      amount={simpleSaleItemType === 'hlss_seller' ? `${amountLow} - ${amountHigh}` : amount}
      benefitsNode={benefitsSection}
      buttonSection={buttonSection}
    />
  )
}

HlssSellerCard.propTypes = {
  amount: PropTypes.string,
  amountHigh: PropTypes.string,
  amountLow: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  marketPrepVal: PropTypes.string.isRequired,
  agentCommissionVal: PropTypes.string.isRequired,
  closingCostVal: PropTypes.string.isRequired,
  providerType: PropTypes.string.isRequired,
  scheduleCall: PropTypes.bool,
  simpleSaleItemType: PropTypes.string,
  createDuplicateCCTILead: PropTypes.bool
}

HlssSellerCard.defaultProps = {
  amount: '$540k',
  amountHigh: '$640k',
  amountLow: '$340k',
  scheduleCall: false,
  simpleSaleItemType: null,
  createDuplicateCCTILead: true
}

export default HlssSellerCard
