import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Raven from 'raven-js'
import { Button } from '../../Button'
import { LoadingSpinner } from '../../Loading/LoadingSpinner'
import { getRequest } from '../../../utils/makeRequest'
import { API_ENDPOINTS } from '../../../constants/apiEndpoints'
import styles from '../../AgentCard/AgentCard.module.scss'
import { ScheduleButton } from '../../Calendly/ScheduleButton'

export const ScheduleCallButton = ({
  buttonText,
  yesAndUnhideFeedItems,
  createDuplicateCCTILead
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasSalesEvent, setHasSalesEvent] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const calendlyResult = await getRequest(`${API_ENDPOINTS.getCalendlySalesEvent}`)

        if (calendlyResult.data && 'sales_event_exists' in calendlyResult.data) {
          setHasSalesEvent(calendlyResult.data.sales_event_exists)
        }

        setIsLoading(false)
      } catch (error) {
        Raven.captureException(error)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  const renderButton = alreadyScheduled => {
    if (!alreadyScheduled) {
      return (
        <ScheduleButton
          buttonText={buttonText}
          yesAndUnhideFeedItems={yesAndUnhideFeedItems}
          createDuplicateCCTILead={createDuplicateCCTILead}
        />
      )
    }
    return (
      <Button as="primary" disabled data-testid="schedule-call-disabled">
        Schedule call
      </Button>
    )
  }

  return (
    <div className={styles.agentMessageButton}>
      {isLoading ? (
        <Button as="primary">
          <LoadingSpinner color="white" size={16} />
          Schedule call
        </Button>
      ) : (
        renderButton(hasSalesEvent)
      )}
    </div>
  )
}

ScheduleCallButton.propTypes = {
  createDuplicateCCTILead: PropTypes.bool,
  yesAndUnhideFeedItems: PropTypes.func,
  buttonText: PropTypes.string
}

ScheduleCallButton.defaultProps = {
  createDuplicateCCTILead: false,
  yesAndUnhideFeedItems: () => {},
  buttonText: 'Schedule call'
}
