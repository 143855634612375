import { mean, meanBy, sortBy } from 'lodash'

export const MILES_IN_LAT_DEGREE = 69.2
export const MILES_IN_LNG_DEGREE = 54.6

const latDegreesToZoomScale = [
  { zoom: 9.5, degrees: 0.3 },
  { zoom: 9.75, degrees: 0.24 },
  { zoom: 10, degrees: 0.16 },
  { zoom: 10.5, degrees: 0.12 },
  { zoom: 11, degrees: 0.08 },
  { zoom: 11.5, degrees: 0.06 },
  { zoom: 12, degrees: 0.04 }
]

const lngDegreesToZoomScale = [
  { zoom: 9.5, degrees: 0.2 },
  { zoom: 9.75, degrees: 0.16 },
  { zoom: 10, degrees: 0.11 },
  { zoom: 10.5, degrees: 0.08 },
  { zoom: 11, degrees: 0.06 },
  { zoom: 11.5, degrees: 0.04 },
  { zoom: 12, degrees: 0.02 }
]

const average = (arrayOfObjects, key) => meanBy(arrayOfObjects, tx => tx[key])

const calculateDeviationOfAxisValues = (values, key) => {
  const avg = average(values, key)
  const squareDiffs = values.map(value => {
    const diff = value[key] - avg
    const sqrDiff = diff * diff
    return sqrDiff
  })

  const avgSquareDiff = mean(squareDiffs)
  const stdDev = Math.sqrt(avgSquareDiff)
  return [stdDev, avg]
}

// Get a range based on standard deviation and average, filter outliers.
// Optionally rinse and repeat using filtered values so bad data won't throw off the numbers.
const filterForNormalizedStats = (values, key, passes = 1) => {
  let filteredValues = values
  for (let i = 0; i < passes; i += 1) {
    const [dev, avg] = calculateDeviationOfAxisValues(filteredValues, key)
    const adjustedRange = sortBy([avg - dev, avg + dev])
    filteredValues = filteredValues.filter(
      x => adjustedRange[0] <= x[key] && x[key] <= adjustedRange[1]
    )
  }
  return calculateDeviationOfAxisValues(filteredValues, key)
}

// If range for transactions is over the max, shave distance equally from min and max
const limitRangeForZoom = (range, maxRangeInMiles, milesInDegree) => {
  const rangeInMiles = (range[1] - range[0]) * milesInDegree
  const rangeDiff = rangeInMiles - maxRangeInMiles
  if (rangeDiff <= 0) {
    return range
  }

  const diffInDegrees = rangeDiff / milesInDegree
  return [+(range[0] + diffInDegrees / 2).toFixed(2), +(range[1] - diffInDegrees / 2).toFixed(2)]
}

const transactionGeometryStats = (transactions, maxRange = 25, multiplier = 4) => {
  const filteringPasses = 1
  const validTransactions = transactions.filter(tx => !!tx.lat && !!tx.lng)
  const [devLat, avgLat] = filterForNormalizedStats(validTransactions, 'lat', filteringPasses)
  const [devLng, avgLng] = filterForNormalizedStats(validTransactions, 'lng', filteringPasses)

  // Multiplier determines how much we're allowing a coord to deviate from average
  const adjustedRangeLat = sortBy([avgLat - devLat * multiplier, avgLat + devLat * multiplier])
  const adjustedRangeLng = sortBy([avgLng - devLng * multiplier, avgLng + devLng * multiplier])

  const limitedRangeLat = limitRangeForZoom(adjustedRangeLat, maxRange, MILES_IN_LAT_DEGREE)
  const limitedRangeLng = limitRangeForZoom(adjustedRangeLng, maxRange, MILES_IN_LNG_DEGREE)

  return {
    devLat,
    avgLat,
    devLng,
    avgLng,
    adjustedRangeLat: limitedRangeLat,
    adjustedRangeLng: limitedRangeLng
  }
}

const boundsToZoom = (bounds, center) => {
  const defaultZoom = 9.5
  // find greatest radius from center in lat and lng
  const latRadius = Math.max(
    Math.abs(bounds.sw[0] - center.lat),
    Math.abs(bounds.ne[0] - center.lat)
  )
  const lngRadius = Math.max(
    Math.abs(bounds.sw[1] - center.lng),
    Math.abs(bounds.ne[1] - center.lng)
  )

  if (!latRadius || !lngRadius) {
    return defaultZoom
  }

  let latZoom = 13
  let lngZoom = 13

  // eslint-disable-next-line no-restricted-syntax
  for (const scale of latDegreesToZoomScale) {
    if (latRadius > scale.degrees) {
      latZoom = scale.zoom
      break
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const scale of lngDegreesToZoomScale) {
    if (lngRadius > scale.degrees) {
      lngZoom = scale.zoom
      break
    }
  }

  // return lowest zoom level (most zoomed out) of the two
  return !!latZoom && !!lngZoom ? Math.min(latZoom, lngZoom) : defaultZoom
}

const getExplicitMapboxZoom = (center, transactions, maxRange = 25) => {
  const maxLatDegreesFromCenter = maxRange / MILES_IN_LAT_DEGREE
  const maxLngDegreesFromCenter = maxRange / MILES_IN_LNG_DEGREE

  const bounds = {
    sw: [center.lat, center.lng],
    ne: [center.lat, center.lng]
  }

  // map transactions, ignore any outside max range
  transactions.forEach(tx => {
    const outOfRange =
      Math.abs(center.lat - tx.lat) > maxLatDegreesFromCenter ||
      Math.abs(center.lng - tx.lng) > maxLngDegreesFromCenter
    if (outOfRange) {
      return
    }

    // build bounding box of transaction lat/long extremes
    bounds.sw = [Math.min(bounds.sw[0], tx.lat), Math.min(bounds.sw[1], tx.lng)]
    bounds.ne = [Math.max(bounds.ne[0], tx.lat), Math.max(bounds.ne[1], tx.lng)]
  })

  // translate to mapbox zoom
  return boundsToZoom(bounds, center)
}

const filterByProximity = (transactions, clientLocation) => {
  const txConvertedKeys = transactions.map(tx => ({
    lat: Number(tx.latitude),
    lng: Number(tx.longitude)
  }))
  const { adjustedRangeLat, adjustedRangeLng } = transactionGeometryStats(txConvertedKeys, 30)

  return transactions.filter(tx => {
    if (!tx.latitude || !tx.longitude) {
      return false
    }

    // skip showing if further than 20-mile radius bounding box from client
    if (clientLocation && clientLocation.latitude && clientLocation.longitude) {
      const maxMiles = 20
      if (
        Math.abs(tx.latitude - clientLocation.latitude) > maxMiles / MILES_IN_LAT_DEGREE ||
        Math.abs(tx.longitude - clientLocation.longitude) > maxMiles / MILES_IN_LNG_DEGREE
      ) {
        return false
      }
    }

    return (
      adjustedRangeLng[0] <= tx.longitude &&
      tx.longitude <= adjustedRangeLng[1] &&
      adjustedRangeLat[0] <= tx.latitude &&
      tx.latitude <= adjustedRangeLat[1]
    )
  })
}

export {
  average,
  calculateDeviationOfAxisValues,
  filterForNormalizedStats,
  transactionGeometryStats,
  limitRangeForZoom,
  getExplicitMapboxZoom,
  boundsToZoom,
  filterByProximity
}
