import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { AccountMenu } from 'components/Account/AccountMenu'
import { AccountTab } from 'components/Account/AccountTab'
import { Graphic } from 'components/Graphic'
import { LeadContext } from 'context/LeadContext'
import { PageResizeContext } from 'components/PageResize'
import { Link, useLocation } from 'react-router-dom'
import UnreadMessageCountNotification from 'components/Messages/UnreadMessageCountNotification'
import { cx } from 'utils/cx'
import { useMessageCountNotification } from 'hooks/useMessageCountNotification/useMessageCountNotification'
import { isMobile } from '../../utils/breakpoint'
import style from './DashboardLogoHeader.module.scss'

export const DashboardLogoHeader = ({ accountMenuActive, toggleAccountMenu, showNewMenu }) => {
  const { leadFirstName } = React.useContext(LeadContext)
  const { width } = useContext(PageResizeContext)
  const { pathname } = useLocation()
  const { unreadMessageCount } = useMessageCountNotification()

  const getStyles = useCallback(
    (path, hasNotification) => {
      const activeClass = pathname === path ? style.active : style.inactive
      const notificationClass = hasNotification ? style.hasNotification : null
      return cx(activeClass, notificationClass)
    },
    [pathname, unreadMessageCount]
  )

  function renderTabletNav() {
    return (
      !isMobile(width - 90) && (
        <ul className={style.list}>
          <li>
            <Link to="/" className={getStyles('/')}>
              <span className={style.menuLinkText} title="Feed">
                Feed
              </span>
            </Link>
          </li>
          <li>
            <Link to="/progress" className={getStyles('/progress')}>
              <span className={style.menuLinkText} title="Feed">
                Progress
              </span>
            </Link>
          </li>
        </ul>
      )
    )
  }

  return (
    <div className={style.headerLogo}>
      <div className={style.logoWrapper}>
        <a
          href={process.env.REACT_APP_HOMELIGHT_REPO_URL}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Graphic.SolidLogoWithText className={style.graphicLogo} />
        </a>
      </div>
      <div className={style.accountWrapper}>
        {renderTabletNav()}
        {showNewMenu ? (
          <AccountMenu />
        ) : (
          <>
            <div className={style.userName} onClick={toggleAccountMenu}>
              {leadFirstName || 'Account'}
            </div>
            {/* eslint-disable-next-line */}
            <div onClick={toggleAccountMenu}>
              <Graphic.ChevronDown className={style.graphicChevron} />
            </div>
          </>
        )}
      </div>
      {!showNewMenu && <AccountTab active={accountMenuActive} toggleActive={toggleAccountMenu} />}
    </div>
  )
}

DashboardLogoHeader.propTypes = {
  accountMenuActive: PropTypes.bool.isRequired,
  toggleAccountMenu: PropTypes.func.isRequired
}
