import moment from 'moment'

export const relativeDateParser = (timestamp, showJustNow = true) => {
  const occurredAt = moment(timestamp).local()
  const oneHour = moment().subtract(1, 'hours')
  const oneDay = moment().subtract(1, 'days')
  const oneWeek = moment().subtract(7, 'days')
  if (occurredAt.isAfter(oneHour) && showJustNow) {
    return 'Just now'
  }
  if (occurredAt.isAfter(oneDay)) {
    return occurredAt.format('h:mm A')
  }
  if (occurredAt.isAfter(oneWeek)) {
    return occurredAt.format('dddd')
  }
  return occurredAt.format('MMM D')
}

export const timeOnlyParser = (timestamp, showJustNow = true) => {
  const occurredAt = moment(timestamp).local()
  const oneHour = moment().subtract(1, 'hours')
  if (occurredAt.isAfter(oneHour) && showJustNow) {
    return 'Just now'
  }
  return occurredAt.format('h:mm A')
}

export const dayBreakParser = timestamp => {
  const occurredAt = moment(timestamp).local()
  const now = moment().local()
  const oneWeek = moment().subtract(7, 'days')
  if (occurredAt.isSame(now, 'day')) {
    return 'Today'
  }
  if (occurredAt.isAfter(oneWeek)) {
    return occurredAt.format('dddd')
  }
  return occurredAt.format('MMM D, YYYY')
}
