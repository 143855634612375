import React, { createContext, useState, useEffect } from 'react'
import Jsona from 'jsona'
import PropTypes from 'prop-types'
import { getRequest } from '../utils/makeRequest'
import {
  FormatPhoneNumber,
  GetFirstName,
  GetLastName,
  GetLeadType,
  GetPhoneNumber
} from './helpers/LeadContextHelpers'

export const LeadContext = createContext({})

export const LeadProvider = ({ children }) => {
  const [data, setData] = useState({})
  const url =
    '/api/user-auth-service/users/current_lead_globals?include=sales_phone_number.phone_numbers'
  // eslint-disable-next-line no-unused-vars
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataFormatter = new Jsona()
        const result = await getRequest(url)
        setData(dataFormatter.deserialize(result.data))
        setIsLoading(false)
      } catch (error) {
        setIsError(true)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [url])

  const callReview = data.call_review
  const concierge = data.concierge_triggered
  const hlssEligible = data.hlss_eligible
  const hlcaQuizLead = data.hlca_quiz
  const leadId = data.id
  const userId = data.user_id
  const leadStatus = data.stage
  const leadName = data.name
  const leadFirstName = GetFirstName(data)
  const leadLastName = GetLastName(data)
  const salesPhone = GetPhoneNumber(data)
  const salesTextingPhone = GetPhoneNumber(data, true)
  const salesPhoneNumber = FormatPhoneNumber(salesPhone)
  const salesTextingPhoneNumber = FormatPhoneNumber(salesTextingPhone)
  const { latitude } = data
  const { longitude } = data
  const leadType = GetLeadType(data)
  const leadEmail = data.email
  const leadDataReady = data && Object.keys(data).length > 0
  const leadSecureToken = data.url_safe_token
  const leadCampaign = data.utm_campaign
  const leadPhoneNumber = data.phone_number
  const showCallMeMaybe = data.show_call_me_maybe
  const sellerConsentAgentSelected = data.seller_consent_agent_selected
  const sellerConsentImmediateIntroVariant = data.seller_consent_immediate_intro_variant
  const showDualPathCmm = data.show_dual_path_cmm

  return (
    <LeadContext.Provider
      value={{
        callReview,
        concierge,
        hlssEligible,
        hlcaQuizLead,
        leadId,
        userId,
        leadStatus,
        leadName,
        leadFirstName,
        leadLastName,
        latitude,
        longitude,
        leadType,
        salesPhoneNumber,
        salesTextingPhoneNumber,
        leadDataReady,
        leadEmail,
        leadSecureToken,
        leadCampaign,
        leadPhoneNumber,
        isLoading,
        showCallMeMaybe,
        sellerConsentAgentSelected,
        sellerConsentImmediateIntroVariant,
        showDualPathCmm
      }}
    >
      {children}
    </LeadContext.Provider>
  )
}

LeadProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}
