import React from 'react'
import { titleize } from '../../../../utils/titleize'
import { MissedHCCallItem } from '../../../FeedItem'

export const RenderMissedHCCall = userFeedItem => {
  const { item, id, occurred_at: occurredAt, displayed_at: displayedAt, user } = userFeedItem

  const firstName = titleize(user.first_name)

  return (
    <MissedHCCallItem
      key={id}
      name={firstName}
      phoneNumber={item.hc_phone}
      occurredAt={occurredAt}
      id={id}
      displayedAt={displayedAt}
    />
  )
}
