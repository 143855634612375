import { Col, Row } from 'react-simple-flex-grid'
import React from 'react'
import { LeadContext } from 'context/LeadContext'
import PropTypes from 'prop-types'
import moment from 'moment'
import Raven from 'raven-js'
import styles from './AgentCard.module.scss'
import { GetIntroducedButton } from '../Button/GetIntroducedButton'
import { DisplayNumberButton } from '../Button/DisplayNumberButton'
import { Button } from '../Button'
import { trackFeedItemGenericEvent } from '../../utils/tracking'
import { postHlRequest } from '../../utils/makeRequest'
import { trackSessionEvent } from '../../utils/feedItemClient'

export const AgentCardContactArea = ({
  desktopLayout,
  callableAgent,
  feedItemId,
  feedItemType,
  agentMatch,
  agentDisplayName,
  leadType,
  externalId,
  agentNames
}) => {
  const { leadId } = React.useContext(LeadContext)
  const getIntroducedUrl = '/quiz_introduction_client_dashboard'
  const postData = {
    lead_id: leadId,
    agent_id: agentMatch.agent_id,
    message: `Client requested intro on ${moment().format('MM/DD')} via phone call`
  }
  const onTapCallAgent = async () => {
    try {
      trackFeedItemGenericEvent(feedItemType, 'Tap', leadType, 'contact_call')
      trackSessionEvent(
        leadId,
        'Client',
        'ClientTapAgentCallButton',
        `agent_lead_id_${agentMatch.agent_lead_id}`
      )
      await postHlRequest(getIntroducedUrl, postData)
    } catch (error) {
      Raven.captureException(error)
    }
  }
  const renderCallableAgentDesktop = () => (
    <div style={{ width: '100%' }}>
      <Row align="middle" justify="start" gutter={10}>
        <Col className={styles.buttonContainer} span={6}>
          <DisplayNumberButton
            phone={agentMatch.phone_twilio}
            text={`Call ${agentMatch.agent_name.split(' ')[0]}`}
            feedItemType={feedItemType}
            agentId={agentMatch.agent_id}
            agentLeadId={agentMatch.agent_lead_id}
          />
        </Col>
      </Row>
    </div>
  )

  const renderDefault = () => (
    <GetIntroducedButton
      agentId={agentMatch.agent_id}
      feedItemType={feedItemType}
      feedItemId={feedItemId}
      agentLeadId={agentMatch.agent_lead_id}
      agentName={agentDisplayName}
      introductionAlreadyRequested={agentMatch.introduction_requested}
    />
  )

  const renderDesktop = () => (
    <div className={`${callableAgent ? styles.desktopContactButtons : null}`}>
      {callableAgent ? renderCallableAgentDesktop() : renderDefault()}
    </div>
  )

  const renderCallableAgentMobile = () => (
    <Row align="middle" justify="start" gutter={10}>
      <Col className={styles.buttonContainer} span={6}>
        <a href={`tel:${agentMatch.phone_twilio}`}>
          <Button as="primary" onClick={onTapCallAgent}>
            Call
          </Button>
        </a>
      </Col>
    </Row>
  )

  const renderLayout = () => {
    if (desktopLayout) {
      return renderDesktop()
    }

    if (callableAgent) {
      return renderCallableAgentMobile()
    }

    return renderDefault()
  }

  return renderLayout()
}

AgentCardContactArea.propTypes = {
  desktopLayout: PropTypes.bool.isRequired,
  callableAgent: PropTypes.bool.isRequired,
  feedItemId: PropTypes.string.isRequired,
  feedItemType: PropTypes.string.isRequired,
  agentNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  agentMatch: PropTypes.shape({
    agent_id: PropTypes.number.isRequired,
    agent_lead_id: PropTypes.number.isRequired,
    agent_name: PropTypes.string.isRequired,
    introduction_requested: PropTypes.bool,
    phone_twilio: PropTypes.string.isRequired
  }).isRequired,
  agentDisplayName: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  leadType: PropTypes.string
}

AgentCardContactArea.default = {
  leadType: ''
}
