import React from 'react'
import moment from 'moment'
import { DelayedContactMessage, GenericMessage } from '../../../FeedItem'
import { CallGeneric } from '../../../FeedItem/CallGeneric'
import { Graphic } from '../../../Graphic'

export const RenderDefault = userFeedItem => {
  let msgText
  let buttonText
  let humanTime
  switch (userFeedItem.item && userFeedItem.item.message_type) {
    case 'delayed_intro':
      msgText = 'It was nice speaking with you! You can expect to have agents reach out to you on '
      return (
        <DelayedContactMessage
          key={userFeedItem.id}
          text={msgText}
          dateTime={userFeedItem.item.delayed_until}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'interested_followup':
      msgText = "It was nice speaking with you! As discussed, we'll reach back out to you on "
      return (
        <DelayedContactMessage
          key={userFeedItem.id}
          text={msgText}
          dateTime={userFeedItem.item.delayed_until}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'good_luck':
      msgText = 'It was nice speaking with you! Good luck chatting with your agents!'
      return (
        <GenericMessage
          key={userFeedItem.id}
          text={msgText}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'insufficient_transaction_data':
      msgText = 'It looks like the transaction data is limited in your area.'
      return (
        <GenericMessage
          key={userFeedItem.id}
          text={msgText}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'call_me_asap_selected':
      msgText = 'No problem! We’ll give you a call shortly.'
      return (
        <GenericMessage
          key={userFeedItem.id}
          text={msgText}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'text_me_selected':
      msgText = "No problem! We'll text you ASAP."
      return (
        <GenericMessage
          key={userFeedItem.id}
          text={msgText}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'not_interested_selected':
      msgText =
        'No problem! Feel free to give us a call or send us a text if you have any questions.'
      return (
        <GenericMessage
          key={userFeedItem.id}
          text={msgText}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'scheduled_call_date_time_set':
      humanTime = moment(userFeedItem.item.scheduled_call_date_time)
        .local()
        .format('dddd, MMMM Do [after] h:mm A')
      msgText = `Confirmed! We’ll reach back out to you on ${humanTime}.`
      return (
        <GenericMessage
          key={userFeedItem.id}
          text={msgText}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'agent_meeting_requested':
      msgText = `Great! Contact ${userFeedItem.item.agent_name} to schedule a meeting.`
      buttonText = `Contact ${userFeedItem.item.agent_name}`
      return (
        <CallGeneric
          buttonText={buttonText}
          text={msgText}
          id={userFeedItem.id}
          feedItemType={userFeedItem.item_type}
          occurredAt={userFeedItem.occurred_at}
          displayedAt={userFeedItem.displayed_at}
          phoneNumber={userFeedItem.item.agent_twilio_phone}
        />
      )
    case 'agent_meeting_scheduled':
      msgText = `Great! We're happy to hear that you scheduled a meeting with real estate agent ${userFeedItem.item.agent_name}.`
      return (
        <GenericMessage
          key={userFeedItem.id}
          text={msgText}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'agent_meeting_declined':
      msgText = 'No problem!  Try connecting with one of your other agents.'
      return (
        <GenericMessage
          key={userFeedItem.id}
          text={msgText}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'direct_agent_intro':
      msgText = `We've connected you with ${userFeedItem.item.agent_name}, one of the three best agents in your area. They'll be calling soon to discuss your plans.`
      return (
        <GenericMessage
          key={userFeedItem.id}
          text={msgText}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'thank_you_response':
      msgText = `Okay, just checking. Thanks for letting us know.`
      return (
        <GenericMessage
          key={userFeedItem.id}
          text={msgText}
          occurredAt={userFeedItem.occurred_at}
          graphic={<Graphic.Smiley />}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    case 'simple_sale_no_agents':
      msgText =
        "We looked at millions of real estate transactions, and tried to find the best match given your answers to our questions. But we weren't able to find the perfect agent. Sometimes this happens because we don't have enough transactions in your area, or our database tells us we need to ask a few more questions about you and your property to find the perfect match."
      return (
        <GenericMessage
          key={userFeedItem.id}
          text={msgText}
          occurredAt={userFeedItem.occurred_at}
          id={userFeedItem.id}
          displayedAt={userFeedItem.displayed_at}
        />
      )
    default:
      return null
  }
}
