import React, { useState, useContext, useEffect } from 'react'
import Raven from 'raven-js'
import { Graphic } from 'components/Graphic'
import { LeadContext } from 'context/LeadContext'
import moment from 'moment-timezone'
import styles from './CallMeMaybeModal.module.scss'
import { postRequest } from '../../utils/makeRequest'
import { API_ENDPOINTS } from '../../constants/apiEndpoints'
import { formatPhoneNumber } from '../../utils/numberParser'
import { cx } from '../../utils/cx'
import { isDuringSalesBusinessHours } from '../../utils/businessHours'
import { trackSessionEvent } from '../../utils/feedItemClient'

export const CallMeMaybeModal = ({ setShowModal, pathname }) => {
  const {
    salesPhoneNumber,
    leadId,
    sellerConsentAgentSelected,
    sellerConsentImmediateIntroVariant,
    isLoading,
    callReview,
    showDualPathCmm,
    leadType
  } = useContext(LeadContext)
  const [screen, setScreen] = useState('initial')
  const [phone, setPhone] = useState(salesPhoneNumber || process.env.REACT_APP_PHONE_HC)
  const [subtext, setSubtext] = useState(
    "We're outside business hours, but will be calling you tomorrow morning."
  )
  const [timeRemaining, setTimeRemaining] = useState(60)
  const inBusinessHours = isDuringSalesBusinessHours(moment().tz('America/Los_Angeles'))

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (prevTime === 0) {
          clearInterval(timerInterval)

          return 0
        }
        return prevTime - 1
      })
    }, 1000)

    return () => clearInterval(timerInterval)
  }, [])

  useEffect(() => {
    if (leadId) {
      const userEventData = {
        event_action: 'call_me_maybe_viewed_modal',
        result: {
          lead_id: leadId
        }
      }

      postRequest(API_ENDPOINTS.recordUserEvent, userEventData).catch(error => {
        console.log('error', error)
        Raven.captureException(error)
      })
    }

    if (inBusinessHours) {
      setSubtext(`Just click the button and your phone will ring. It's that simple!`)
    }
  }, [])

  useEffect(() => {
    if (leadId) {
      if (pathname?.pathname === '/pv1') {
        trackSessionEvent(leadId, 'PageView', 'PhoneVerificationVariant1', 0)
      }

      if (pathname?.pathname === '/pv2') {
        trackSessionEvent(leadId, 'PageView', 'PhoneVerificationVariant2', 0)
      }
    }
  }, [leadId])

  const handleSubmit = () => {
    const data = {
      lead_id: leadId
    }
    const userEventData = {
      event_action: 'call_me_maybe_clicked_call_me',
      result: {
        lead_id: leadId
      }
    }

    postRequest(API_ENDPOINTS.queueLead, data)
      .then(res => {
        if (res?.data?.data?.sales_phone_number) {
          setPhone(res.data.data.sales_phone_number)
        }
        setScreen('confirm')
      })
      .catch(error => {
        console.log('error', error)
        Raven.captureException(error)
      })

    postRequest(API_ENDPOINTS.recordUserEvent, userEventData).catch(error => {
      console.log('error', error)
      Raven.captureException(error)
    })

    if (pathname?.pathname === '/pv1') {
      trackSessionEvent(leadId, 'Click', 'PhoneVerificationVariant1', 0)
    }

    if (pathname?.pathname === '/pv2') {
      trackSessionEvent(leadId, 'Click', 'PhoneVerificationVariant2', 0)
    }
  }

  const handleClose = setShowModal => {
    setShowModal(false)
  }

  const renderConfirmScreen = () => (
    <div className={styles.modal}>
      <div className={styles.header}>
        <div onClick={() => handleClose(setShowModal)}>
          <Graphic.CloseIcon className={styles.closeIcon} />
        </div>
      </div>

      <div className={styles.body}>
        <img src={`${process.env.PUBLIC_URL}/phone-call.png`} className={styles.phoneCallImage} />
        <h2 className={styles.title}>Calling you soon!</h2>
        <p className={styles.subtitle}>
          Our next available representative will call you. You can expect a call within 10 minutes.
        </p>
        <p className={styles.subtitle}>
          We'll call from <b>{formatPhoneNumber(phone)}</b>
        </p>
        <button className={styles.bottomButton} onClick={() => handleClose(setShowModal)}>
          Finished the call
        </button>
      </div>
    </div>
  )

  const renderFullScreenConfirmScreen = () => (
    <div className={styles.modal}>
      <div className={styles.header}>
        <img
          src={`${process.env.PUBLIC_URL}/homelight-logo.png`}
          className={styles.homelightLogo}
        />
      </div>

      <div className={styles.body}>
        <div className={styles.left}>
          <img src={`${process.env.PUBLIC_URL}/phone-call.png`} className={styles.phoneCallImage} />
        </div>
        <div className={styles.right}>
          <h2 className={styles.title}>Calling you soon!</h2>
          <p className={styles.subtitle}>
            Our next available representative will call you. You can expect a call within 10
            minutes.
          </p>
          <p className={styles.subtitle}>
            We'll call from <b>{formatPhoneNumber(phone)}</b>
          </p>
        </div>
      </div>
    </div>
  )

  const renderSimpleSale = () => (
    <div className={styles.callMeMaybeModal}>
      <div className={styles.overlay} onClick={() => handleClose(setShowModal)} />
      {screen === 'initial' ? (
        <div className={styles.modal}>
          <div className={styles.header}>
            <div onClick={() => handleClose(setShowModal)}>
              <Graphic.CloseIcon className={styles.closeIcon} />
            </div>
          </div>

          <div className={styles.body}>
            <h2 className={styles.title}>Your offer is {`\n`} almost ready!</h2>
            <p className={styles.subtitle}>
              Click the button below to verify your ownership and complete your offer.
            </p>
            <img src={`${process.env.PUBLIC_URL}/house.png`} className={styles.houseImage} />
            <button className={styles.button} onClick={handleSubmit}>
              <Graphic.Phone className={styles.phoneIcon} />
              Call Me Now
            </button>
            <p className={styles.subtext}>Just click the button and your phone will ring.</p>
            <p className={styles.subtext}>It's that simple!</p>
          </div>
        </div>
      ) : (
        renderConfirmScreen()
      )}
    </div>
  )

  const renderSimpleSaleInvestorOnly = () => (
    <div className={cx(styles.callMeMaybeModal, styles.dual)}>
      <div className={styles.overlay} onClick={() => handleClose(setShowModal)} />
      {screen === 'initial' ? (
        <div className={styles.modal}>
          <div className={styles.header}>
            <div onClick={() => handleClose(setShowModal)}>
              <Graphic.CloseIcon className={styles.closeIcon} />
            </div>
          </div>

          <div className={styles.body}>
            <h2 className={styles.title}>Connecting you with investors</h2>
            <p className={styles.subtitle}>
              We are matching you with investors and you may receive calls from a few that you can
              compare
            </p>
            <p className={styles.subtitle}>
              Also, you can speak to a HomeLight Home Consultant and explore more options by
              clicking the button below.
            </p>
            <img src={`${process.env.PUBLIC_URL}/dual.png`} className={styles.houseImage} />
            <button
              className={cx(
                styles.button,
                styles.orange,
                (!inBusinessHours || timeRemaining === 0) && styles.disabled
              )}
              onClick={handleSubmit}
              disabled={!inBusinessHours || timeRemaining === 0}
            >
              <Graphic.Phone className={styles.phoneIcon} />
              Talk to HomeLight ({timeRemaining} seconds)
            </button>
            <p className={styles.subtext}>Just click the button and your phone will ring.</p>
            <p className={styles.subtext}>It's that simple!</p>
          </div>
        </div>
      ) : (
        renderConfirmScreen()
      )}
    </div>
  )

  const renderSimpleSaleDual = () => (
    <div className={cx(styles.callMeMaybeModal, styles.dual)}>
      <div className={styles.overlay} onClick={() => handleClose(setShowModal)} />
      {screen === 'initial' ? (
        <div className={styles.modal}>
          <div className={styles.header}>
            <div onClick={() => handleClose(setShowModal)}>
              <Graphic.CloseIcon className={styles.closeIcon} />
            </div>
          </div>

          <div className={styles.body}>
            <h2 className={styles.title}>
              Connecting you with
              <br />
              investors and agents
            </h2>

            <p className={styles.subtitle}>
              You may receive calls from investors and agents as you compare and get the best price
              for your home. If you prefer to talk to HomeLight first, click below.
            </p>
            <img src={`${process.env.PUBLIC_URL}/dual.png`} className={styles.houseImage} />
            <button
              className={cx(
                styles.button,
                styles.orange,
                (!inBusinessHours || timeRemaining === 0) && styles.disabled
              )}
              onClick={handleSubmit}
              disabled={!inBusinessHours || timeRemaining === 0}
            >
              <Graphic.Phone className={styles.phoneIcon} />
              Talk to HomeLight ({timeRemaining} seconds)
            </button>
            <p className={styles.subtext}>Just click the button and your phone will ring.</p>
            <p className={styles.subtext}>It's that simple!</p>
          </div>
        </div>
      ) : (
        renderConfirmScreen()
      )}
    </div>
  )

  const renderAgentMatch = () => (
    <div className={styles.callMeMaybeModal}>
      <div className={styles.overlay} onClick={() => handleClose(setShowModal)} />
      {screen === 'initial' ? (
        <div className={styles.modal}>
          <div className={styles.header}>
            <div onClick={() => handleClose(setShowModal)}>
              <Graphic.CloseIcon className={styles.closeIcon} />
            </div>
          </div>

          <div className={styles.body}>
            <h2 className={styles.title}>Speak with a top agent now!</h2>
            <p className={styles.subtitle}>We will connect you with a top agent over the phone.</p>
            <img src={`${process.env.PUBLIC_URL}/top-agent.png`} className={styles.houseImage} />
            <button className={styles.button} onClick={handleSubmit}>
              <Graphic.Phone className={styles.phoneIcon} />
              Call Me Now
            </button>
            <p className={styles.subtext}>Just click the button and your phone will ring.</p>
            <p className={styles.subtext}>It's that simple!</p>
          </div>
        </div>
      ) : (
        renderConfirmScreen()
      )}
    </div>
  )

  const renderAgentMatchConnectVariant = () => (
    <div className={styles.callMeMaybeModal}>
      <div className={styles.overlay} onClick={() => handleClose(setShowModal)} />
      {screen === 'initial' ? (
        <div className={styles.modal}>
          <div className={styles.header}>
            <div onClick={() => handleClose(setShowModal)}>
              <Graphic.CloseIcon className={styles.closeIcon} />
            </div>
          </div>

          <div className={styles.body}>
            <h2 className={styles.title}>Connect now with your selected agents</h2>
            <p className={styles.subtitle}>
              Jump the line and get on the phone with your top agents in minutes.
            </p>
            <img src={`${process.env.PUBLIC_URL}/top-agent.png`} className={styles.houseImage} />
            <button className={styles.button} onClick={handleSubmit}>
              <Graphic.Phone className={styles.phoneIcon} />
              Call Me Now
            </button>
            <p className={styles.subtext}>Just click the button and your phone will ring.</p>
            <p className={styles.subtext}>It's that simple!</p>
          </div>
        </div>
      ) : (
        renderConfirmScreen()
      )}
    </div>
  )

  const renderHomeVal = () => (
    <div className={styles.callMeMaybeModal}>
      <div className={styles.overlay} onClick={() => handleClose(setShowModal)} />
      {screen === 'initial' ? (
        <div className={styles.modal}>
          <div className={styles.header}>
            <div onClick={() => handleClose(setShowModal)}>
              <Graphic.CloseIcon className={styles.closeIcon} />
            </div>
          </div>

          <div className={styles.body}>
            <h2 className={styles.title}>Your estimate is {`\n`} almost ready!</h2>
            <p className={styles.subtitle}>
              Confirm a few details over the phone for your home value report with potential cash
              offers.
            </p>
            <img src={`${process.env.PUBLIC_URL}/house.png`} className={styles.houseImage} />
            <button className={styles.button} onClick={handleSubmit}>
              <Graphic.Phone className={styles.phoneIcon} />
              Call Me Now
            </button>
            <p className={styles.subtext}>Just click the button and your phone will ring.</p>
            <p className={styles.subtext}>It's that simple!</p>
          </div>
        </div>
      ) : (
        renderConfirmScreen()
      )}
    </div>
  )

  const renderConsentImmediateIntroAgent = () => (
    <div className={cx(styles.callMeMaybeModal, styles.fullScreen)}>
      <div className={styles.overlay} onClick={() => handleClose(setShowModal)} />
      {screen === 'initial' ? (
        <div className={styles.modal}>
          <div className={styles.header}>
            <img
              src={`${process.env.PUBLIC_URL}/homelight-logo.png`}
              className={styles.homelightLogo}
            />
          </div>

          <div className={styles.body}>
            <div className={styles.left}>
              <img src={`${process.env.PUBLIC_URL}/top-agent.png`} className={styles.houseImage} />
            </div>
            <div className={styles.right}>
              <h2 className={styles.title}>Your agents will be contacting you shortly</h2>
              <p className={styles.subtitle}>
                If you'd like to still talk to a HomeLight representative, request a call below
              </p>
              <button className={styles.button} onClick={handleSubmit}>
                <Graphic.Phone className={styles.phoneIcon} />
                Request a call from HomeLight
              </button>
              <p className={styles.subtext}>{subtext}</p>
            </div>
          </div>
        </div>
      ) : (
        renderFullScreenConfirmScreen()
      )}
    </div>
  )

  const renderConsentImmediateIntroNoAgent = () => (
    <div className={cx(styles.callMeMaybeModal, styles.fullScreen)}>
      <div className={styles.overlay} onClick={() => handleClose(setShowModal)} />
      {screen === 'initial' ? (
        <div className={styles.modal}>
          <div className={styles.header}>
            <img
              src={`${process.env.PUBLIC_URL}/homelight-logo.png`}
              className={styles.homelightLogo}
            />
          </div>

          <div className={styles.body}>
            <div className={styles.left}>
              <img src={`${process.env.PUBLIC_URL}/top-agent.png`} className={styles.houseImage} />
            </div>
            <div className={styles.right}>
              <h2 className={styles.title}>Your top agents are almost ready!</h2>
              <p className={styles.subtitle}>
                We have a few more questions before introducing you to top agents in your area.
              </p>
              <button
                className={cx(styles.button, styles.orange, !inBusinessHours && styles.disabled)}
                onClick={handleSubmit}
                disabled={!inBusinessHours}
              >
                <Graphic.Phone className={styles.phoneIcon} />
                Call Me Now
              </button>
              <p className={styles.subtext}>{subtext}</p>
            </div>
          </div>
        </div>
      ) : (
        renderFullScreenConfirmScreen()
      )}
    </div>
  )

  const renderPhoneVerificationVariant1 = () => (
    <div className={cx(styles.callMeMaybeModal, styles.fullScreen)}>
      <div className={styles.overlay} onClick={() => handleClose(setShowModal)} />
      <div className={styles.modal}>
        <div className={styles.header}>
          <img
            src={`${process.env.PUBLIC_URL}/homelight-logo.png`}
            className={styles.homelightLogo}
          />
        </div>

        <div className={styles.body}>
          <div className={styles.left}>
            <img src={`${process.env.PUBLIC_URL}/top-agent.png`} className={styles.topAgent} />
          </div>
          <div className={styles.right}>
            <h2 className={styles.title}>Connecting you with agents</h2>

            <p className={styles.subtitle}>
              You can sell for more on the market than a cash offer.
            </p>

            <p className={styles.subtitle}>
              We are kicking off your introduction process and you will receive a call from a few
              agents that you can compare between.
            </p>
          </div>
        </div>
      </div>
    </div>
  )

  const renderPhoneVerificationVariant2 = () => (
    <div className={cx(styles.callMeMaybeModal, styles.fullScreen)}>
      <div className={styles.overlay} onClick={() => handleClose(setShowModal)} />
      {screen === 'initial' ? (
        <div className={styles.modal}>
          <div className={styles.header}>
            <img
              src={`${process.env.PUBLIC_URL}/homelight-logo.png`}
              className={styles.homelightLogo}
            />
          </div>

          <div className={styles.body}>
            <div className={styles.left}>
              <img src={`${process.env.PUBLIC_URL}/top-agent.png`} className={styles.topAgent} />
            </div>
            <div className={styles.right}>
              <h2 className={styles.title}>Connecting you with agents</h2>

              <p className={styles.subtitle}>
                You can sell for more on the market than a cash offer.
              </p>

              <p className={styles.subtitle}>
                We are kicking off your introduction process and you will receive a call from a few
                agents that you can compare between.
              </p>

              {inBusinessHours && (
                <>
                  <p className={styles.subtitle}>Alternatively, you can speak to HomeLight.</p>
                  <button
                    className={cx(
                      styles.button,
                      (!inBusinessHours || timeRemaining === 0) && styles.disabled
                    )}
                    onClick={handleSubmit}
                    disabled={!inBusinessHours || timeRemaining === 0}
                  >
                    <Graphic.Phone className={styles.phoneIcon} />
                    Talk to HomeLight ({timeRemaining} seconds)
                  </button>
                  <p className={styles.subtext}>{subtext}</p>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        renderFullScreenConfirmScreen()
      )}
    </div>
  )

  if (isLoading) {
    return null
  }

  if (pathname?.pathname === '/pv1') {
    return renderPhoneVerificationVariant1()
  }

  if (pathname?.pathname === '/pv2') {
    return renderPhoneVerificationVariant2()
  }

  if (leadType === 'simple_sale') {
    if (showDualPathCmm && callReview.interested_in_investor === true) {
      if (callReview.interested_in_agent === true) {
        return renderSimpleSaleDual()
      }

      return renderSimpleSaleInvestorOnly()
    }

    return renderSimpleSale()
  }

  if (leadType === 'buyer' || leadType === 'seller') {
    if (sellerConsentImmediateIntroVariant) {
      if (sellerConsentAgentSelected) {
        return renderConsentImmediateIntroAgent()
      }
      return renderConsentImmediateIntroNoAgent()
    }

    if (sellerConsentAgentSelected) {
      return renderAgentMatchConnectVariant()
    }

    return renderAgentMatch()
  }
  if (leadType === 'simple_home_value') {
    return renderHomeVal()
  }
}
