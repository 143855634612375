import React, { useContext, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { AgentCardHeader, ContactButtons, AgentTransactionMap } from 'components/AgentCard'
import { PageResizeContext } from 'components/PageResize'
import { LeadContext } from 'context/LeadContext'
import axios from 'axios'
import { VIMEO_OEMBED_URL } from 'constants/constants'
import styles from './AgentCard.module.scss'
import {
  AgentAwardBadge,
  LocalAreaExpertBadge,
  BadgeMoreInfo,
  MoveSafeCertifiedBadge,
  YearsExperienceBadge,
  DealsBadge
} from '../AgentBadge'
import { AgentDetailsModal, ModalBadges } from '../AgentDetailsModal'
import { isLargerViewport } from '../../utils/breakpoint'
import { trackFeedItemGenericEvent } from '../../utils/tracking'
import { AgentCardContactArea } from './AgentCardContactArea'
import { titleize } from '../../utils/titleize'
import { AgentVideo } from '../AgentVideo/AgentVideo'

export const AgentCard = ({ feedItemId, externalId, feedItemType, agentMatch, city }) => {
  const [agentVideo, setAgentVideo] = useState(false)
  const agentCardElement = useRef(null)
  const agentCardElementDesktop = useRef(null)
  const [showContactButtons, setShowContactButtons] = useState(false)
  const [detailsModalActive, setDetailsModalActive] = useState(false)
  const [videoHeight, setVideoHeight] = useState(null)
  const { latitude, longitude, leadType, leadDataReady } = React.useContext(LeadContext)
  const leadLocation = { latitude, longitude }
  const callableAgent = Boolean(agentMatch.contact_capabilities.can_call && agentMatch.phone_twilio)
  const agentSlug = agentMatch.slug

  const hideOpenContactbuttons = () => {
    if (showContactButtons) {
      setShowContactButtons(!showContactButtons)
    }
  }
  const toggleContactButtons = () => {
    if (callableAgent) {
      setShowContactButtons(!showContactButtons)
    }
  }

  const { width } = useContext(PageResizeContext)
  const desktopLayout = isLargerViewport(width)

  const showModal = () => {
    document.body.classList.add('noscroll')
    setDetailsModalActive(true)
    trackFeedItemGenericEvent(feedItemType, 'Tap', leadType, 'agent_details')
  }
  const hideModal = () => {
    document.body.classList.remove('noscroll')
    setDetailsModalActive(false)
  }

  const agentNames = agentMatch.agent_name.split(' ')
  const agentDisplayName = `${titleize(agentNames[0])} ${titleize(agentNames[1][0])}.`

  const agentTransactionMap = leadDataReady ? (
    <AgentTransactionMap
      agentDisplayName={agentDisplayName}
      clientType={leadType}
      transactions={agentMatch.area_transactions}
      clientLocation={leadLocation}
      city={city}
    />
  ) : null

  const localAreaExpertBadges = agentMatch.badges.filter(
    badge => badge.award_type === 'local_area_expert'
  )
  const renderLocalAreaExpertSection = (infoAsTooltips = true) =>
    localAreaExpertBadges.length > 0 && (
      <LocalAreaExpertBadge
        badge={localAreaExpertBadges[0]}
        agentName={agentDisplayName}
        infoAsTooltips={infoAsTooltips}
      />
    )

  const yearsExperienceBadges = agentMatch.badges.filter(
    badge => badge.award_type === 'years_experience'
  )
  const renderYearsExperienceSection = (infoAsTooltips = true) =>
    yearsExperienceBadges.length > 0 && (
      <YearsExperienceBadge
        badge={yearsExperienceBadges[0]}
        agentName={agentDisplayName}
        infoAsTooltips={infoAsTooltips}
      />
    )

  const renderDealsBadge = () => {
    if (!agentMatch.deals_count) {
      return null
    }

    return (
      <DealsBadge
        agentName={agentDisplayName}
        value={agentMatch.deals_count}
        infoAsTooltips={false}
      />
    )
  }

  const modalAchievementBadges = agentMatch.badges.filter(
    badge =>
      badge.award_type !== 'local_area_expert' &&
      badge.award_type !== 'years_experience' &&
      badge.award_type !== 'homes_sold_in_city'
  )

  const achievementBadges = []
  const map = new Map()
  // this should always be a smallish array, so should not be an issue here.
  // some badge award types have the same display name -- show unique.
  // eslint-disable-next-line no-restricted-syntax
  for (const badge of modalAchievementBadges) {
    if (!map.has(badge.value)) {
      map.set(badge.value, true)
      achievementBadges.push(badge)
    }
  }

  const cardAchievementBadges = modalAchievementBadges.filter(
    badge =>
      // these are deprecaded badges
      badge.award_type !== 'cash_close' && badge.award_type !== 'home_loans'
  )

  const trimmedAchievementBadges = agentMatch.move_safe_certified
    ? cardAchievementBadges.slice(0, 3)
    : cardAchievementBadges.slice(0, 4)

  const modalBadges = (
    <ModalBadges
      agentMatch={agentMatch}
      agentDisplayName={agentDisplayName}
      yearsBadge={renderYearsExperienceSection(false)}
      localAreaExpertBadge={renderLocalAreaExpertSection(false)}
      dealsBadge={renderDealsBadge()}
      achievementBadges={modalAchievementBadges}
    />
  )

  const renderDetailsModal = () => (
    <AgentDetailsModal
      active={detailsModalActive}
      close={hideModal}
      agentMatch={agentMatch}
      agentDisplayName={agentDisplayName}
      modalBadges={modalBadges}
    />
  )

  const onHeaderClick = () => {
    showModal()
    toggleContactButtons()
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Maps to this example: https://vimeo.com/homelight/agentSlug
        const videoUrl = `${process.env.REACT_APP_AGENT_INTRO_VIDEOS_CHANNEL}${agentSlug}`
        const result = await axios.get(`${VIMEO_OEMBED_URL}${videoUrl}`)
        if (result.status === 200) {
          setAgentVideo(true)
          trackFeedItemGenericEvent(feedItemType, 'View', leadType, 'Agent Intro Video_View')
        }
      } catch (error) {
        setAgentVideo(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row onClick={hideOpenContactbuttons}>
      <Col span={12}>
        <div
          ref={agentCardElement}
          className={`${styles.agentCardContainer} ${
            agentMatch.elite_status ? styles.eliteAgentCardContainer : ''
          }`}
        >
          <div className={styles.cardBody}>
            {!desktopLayout && agentMatch.elite_status && (
              <div className={styles.introVideo} style={{ height: `${videoHeight}` }}>
                <AgentVideo
                  data-testid="agent-video-intro-mobile"
                  parentElement={agentCardElement}
                  setVideoHeight={setVideoHeight}
                  agentSlug={agentMatch.slug}
                />
              </div>
            )}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
            <div
              className={styles.headerWrapper}
              role="button"
              onClick={desktopLayout ? () => {} : onHeaderClick}
            >
              <AgentCardHeader agentMatch={agentMatch} showArrow={!desktopLayout} />
              {desktopLayout && !agentMatch.elite_status && (
                // eslint-disable-next-line react/button-has-type
                <button className={styles.viewDetails} onClick={showModal}>
                  View details
                </button>
              )}
              {desktopLayout && agentVideo && agentMatch.elite_status ? (
                // eslint-disable-next-line react/button-has-type
                <button className={styles.eliteViewDetails} onClick={showModal}>
                  View details
                </button>
              ) : (
                desktopLayout && (
                  // eslint-disable-next-line react/button-has-type
                  <button className={styles.viewDetails} onClick={showModal}>
                    View details
                  </button>
                )
              )}
            </div>
            <div className={styles.subTitle}>Why we recommend {agentDisplayName}:</div>
            <div className={styles.badgeSection}>
              <div className={styles.mainBadges}>
                <div>{renderYearsExperienceSection()}</div>
                <div>{renderLocalAreaExpertSection()}</div>
              </div>
              <div className={styles.achievementBadges}>
                <Row className={styles.achievements}>Achievements</Row>
                {trimmedAchievementBadges.map(badge => (
                  <AgentAwardBadge badge={badge} key={achievementBadges.indexOf(badge)}>
                    <BadgeMoreInfo badge={badge} agentName={agentDisplayName} />
                  </AgentAwardBadge>
                ))}
                {agentMatch.move_safe_certified ? (
                  <MoveSafeCertifiedBadge
                    agentName={agentDisplayName}
                    eliteStatus={agentMatch.elite_status}
                  >
                    <BadgeMoreInfo
                      badge={{ award_type: 'move_safe' }}
                      agentName={agentDisplayName}
                    />
                  </MoveSafeCertifiedBadge>
                ) : null}
              </div>
            </div>
            <AgentCardContactArea
              desktopLayout={desktopLayout}
              callableAgent={callableAgent}
              agentDisplayName={agentDisplayName}
              feedItemId={feedItemId}
              externalId={externalId}
              agentMatch={agentMatch}
              feedItemType={feedItemType}
              leadType={leadType}
              agentNames={agentNames}
            />
          </div>
          {desktopLayout && (
            <div ref={agentCardElementDesktop} className={styles.rightSideSection}>
              <div className={styles.agentTransactionMap}>{agentTransactionMap}</div>
            </div>
          )}
          {renderDetailsModal()}
        </div>
        <ContactButtons
          show={showContactButtons}
          phoneNumber={agentMatch.phone_twilio}
          agentId={agentMatch.agent_id}
          agentLeadId={agentMatch.agent_lead_id}
          feedItemType={feedItemType}
        />
      </Col>
    </Row>
  )
}

AgentCard.propTypes = {
  feedItemId: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  feedItemType: PropTypes.string.isRequired,
  agentMatch: PropTypes.shape({
    agent_id: PropTypes.number.isRequired,
    agent_lead_id: PropTypes.number.isRequired,
    agent_name: PropTypes.string.isRequired,
    phone_twilio: PropTypes.string.isRequired,
    office_display_name: PropTypes.string.isRequired,
    elite_status: PropTypes.bool.isRequired,
    move_safe_certified: PropTypes.bool.isRequired,
    responsiveness_narrative: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    slug: PropTypes.string,
    deals_count: PropTypes.number.isRequired,
    transactions_count: PropTypes.number.isRequired,
    badges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    bio: PropTypes.string,
    introduction_requested: PropTypes.bool,
    area_transactions: PropTypes.arrayOf(
      PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number
      })
    ).isRequired,
    contact_capabilities: PropTypes.shape({
      can_call: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  city: PropTypes.string
}

AgentCard.defaultProps = {
  city: ''
}
