import { API_ENDPOINTS } from '../../constants/apiEndpoints'
import { PAGE_NAMES } from '../../constants/pages'

export const GetUrl = page => {
  switch (page) {
    case PAGE_NAMES.home:
      return API_ENDPOINTS.feedItems
    case PAGE_NAMES.progress:
      return API_ENDPOINTS.checklist
    case PAGE_NAMES.messages:
      return API_ENDPOINTS.inboxMessages
    case PAGE_NAMES.team:
      return API_ENDPOINTS.team
    case PAGE_NAMES.simpleSale:
      return API_ENDPOINTS.simpleSale
    default:
      return ''
  }
}

export const IsNavigatingAwayFromFeed = (previousPage, currentPage) =>
  previousPage === PAGE_NAMES.home && currentPage !== PAGE_NAMES.home
