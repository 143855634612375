import React from 'react'
import { SellerSimpleSaleOption } from '../../../FeedItem'

export const RenderSimpleSale = (userFeedItem, refreshData) => {
  const {
    item,
    id,
    item_type: itemType,
    occurred_at: occurredAt,
    displayed_at: displayedAt
  } = userFeedItem

  return (
    <SellerSimpleSaleOption
      userFeedItem={userFeedItem}
      selectedAnswer={item.selected_answer}
      occurredAt={occurredAt}
      id={id}
      displayedAt={displayedAt}
      refreshData={refreshData}
      itemType={itemType}
    />
  )
}
