import React from 'react'
import { LeadContext } from 'context/LeadContext'
import PropTypes from 'prop-types'
import { CallButton } from 'components/Button/CallButton'
import FeedItem from './FeedItem'

export const MissedHCCallItem = ({ name, occurredAt, id, displayedAt }) => {
  const { salesPhoneNumber } = React.useContext(LeadContext)
  const feedItemType = 'missed_hc_call'
  return (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      <div>
        Hi {name}, we just tried to call you to talk through all the info we sent. Please call back
        when it&apos;s convenient.
      </div>
      <CallButton phoneNumber={salesPhoneNumber} feedItemType={feedItemType} />
    </FeedItem>
  )
}

MissedHCCallItem.propTypes = {
  name: PropTypes.string.isRequired,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

MissedHCCallItem.defaultProps = {
  displayedAt: null
}
