import React from 'react'
import { Graphic } from 'components/Graphic'
import PropTypes from 'prop-types'
import { cx } from '../../utils/cx'
import styles from '../AgentCard/AgentCard.module.scss'

export const RespondsQuicklyBadge = ({ children, iconStyle, descriptionStyle, factStyle }) => (
  <div className={styles.awardBadgeSection}>
    <div className={factStyle}>
      <div className={cx(iconStyle, styles.graphic)}>
        <Graphic.SpeechBubble />
      </div>
    </div>
    <div className={descriptionStyle}>
      Responds quickly
      {children}
    </div>
  </div>
)

RespondsQuicklyBadge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  iconStyle: PropTypes.string,
  descriptionStyle: PropTypes.string,
  factStyle: PropTypes.string
}

RespondsQuicklyBadge.defaultProps = {
  iconStyle: styles.speechBubble,
  descriptionStyle: styles.description,
  factStyle: styles.fact
}
