import PropTypes from 'prop-types'

/**
 * Takes a list and formats them accordingly:
 *
 * If only one item exists in the array, returns that item as a string.
 * If two items exist in the array, returns "Item or Secondary Item"
 * If more than two items are provided, returns "Item, Secondary Item, or Tertiary Item."
 *
 * Use the 'conjunction' parameter to specify if the word 'and' or the word 'or' should be used before the last item.
 *
 * @param list
 * @param conjunction
 * @returns {string|*}
 * @constructor
 */
export const listBuilder = (list, conjunction = 'or') => {
  if (list.length === 1) {
    return list[0]
  }

  if (list.length === 2) {
    return `${list[0]} ${conjunction} ${list[1]}`
  }

  let fullList = list[0]
  list.shift()

  list.forEach((listItem, index) => {
    if (index === list.length - 1) {
      fullList = `${fullList}, ${conjunction} ${listItem}`
    } else {
      fullList = `${fullList}, ${listItem}`
    }
  })
  return fullList
}

listBuilder.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  conjunction: PropTypes.string.isRequired
}
