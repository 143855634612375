import React from 'react'
import PropTypes from 'prop-types'
import { GenericMessage } from 'components/FeedItem/index'
import { absoluteDateParser } from '../../utils/absoluteDateParser'

export const DelayedContactMessage = ({ dateTime, occurredAt, id, displayedAt, text }) => {
  const [date, time] = absoluteDateParser(dateTime)
  const fullMessage = `${text} ${date} at ${time}.`

  return (
    <GenericMessage occurredAt={occurredAt} text={fullMessage} id={id} displayedAt={displayedAt} />
  )
}

DelayedContactMessage.propTypes = {
  text: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  occurredAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  displayedAt: PropTypes.string
}

DelayedContactMessage.defaultProps = {
  dateTime: null,
  displayedAt: null
}
