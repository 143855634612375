import React, { useState } from 'react'
import { CallMeMaybeModal } from './CallMeMaybeModal'

export const CallMeMaybeWrapper = pathname => {
  const [showModal, setShowModal] = useState(true)

  return (
    <>
      {showModal && (
        <CallMeMaybeModal setShowModal={setShowModal} leadType="seller" pathname={pathname} />
      )}
    </>
  )
}
