import React from 'react'

import { FeedItem } from 'components/FeedItem'
import { ProductEligibilityCard } from 'components/ProductEligibility/ProductEligibilityCard'
import { Icon } from 'components/Icon'
import { trackFeedItemEvent } from 'utils/tracking'
import styles from '../../ProductEligibility/ProductEligibilityCard.module.scss'

export function RenderHlcaFeed(userFeedItem, callReview, leadType) {
  const productCards = []

  const { id, occurred_at: occurredAt, displayed_at: displayedAt } = userFeedItem

  const eligibilityPropsMap = {
    eligible_for_hlss: {
      productIcon: <Icon.SimpleSale width="40" height="40" viewBox="0 0 40 40" />,
      productHeader: 'HomeLight Simple Sale',
      productDescription:
        'Our Simple Sale platform enables you to privately solicit offers from verified cash buyers for a quick and easy sale.'
    },
    eligible_for_hlco: {
      productIcon: <Icon.CashOffer width="40" height="40" viewBox="0 0 40 40" />,
      productHeader: 'HomeLight Cash Offer',
      productDescription:
        'Our Home Loans let you make a cash offer, using our cash, so you can gain all the negotiating leverage of a traditional cash buyer.'
    },
    eligible_for_hlti: {
      productIcon: <Icon.TradeIn width="40" height="40" viewBox="0 0 40 40" />,
      productHeader: 'HomeLight Buy Before You Sell',
      productDescription:
        'Our Buy Before You Sell product makes it simple and stress-free to buy your next home before selling your current home.'
    }
  }

  Object.keys(callReview).forEach(key => {
    if (callReview[key] && eligibilityPropsMap[key]) {
      productCards.push(<ProductEligibilityCard key={key} {...eligibilityPropsMap[key]} />)
      trackFeedItemEvent(userFeedItem, 'View', leadType, key)
    }
  })

  const hlcaDescription = `Great news! You have qualified for ${productCards.length} HomeLight ${
    productCards.length === 1 ? 'product' : 'products'
  }`

  return productCards.length > 0 ? (
    <FeedItem occurredAt={occurredAt} id={id} displayedAt={displayedAt}>
      <div className={styles.hlcaDescription}>{hlcaDescription}</div>
      {productCards}
    </FeedItem>
  ) : null
}
