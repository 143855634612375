import faker from 'faker'

const REASONS = [
  'valid_seller_matches',
  'valid_seller_concierge',
  'invalid_known_agent',
  'invalid_other',
  'hlca'
]
const ACTION_TYPES = ['call_us', 'selling_to_homelight', 'agent_contact_us', 'hlca_calendly']

export default (overrides = {}) => {
  const attributes = {
    action_type: faker.random.arrayElement(ACTION_TYPES),
    text: faker.random.words(),
    reason: faker.random.arrayElement(REASONS)
  }

  return { ...attributes, ...overrides }
}
