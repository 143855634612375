import PropTypes from 'prop-types'
import React from 'react'
import { cx } from '../../utils/cx'
import styles from './PageTitle.module.scss'

export const PageTitle = ({ className, children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <h1 className={cx(styles.title, className)} {...props}>
    {children}
  </h1>
)

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node
}

PageTitle.defaultProps = {
  className: undefined
}
